const UserIcon = (props) => {
    return (

        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 30 30">
                <g id="Group_1306" data-name="Group 1306" transform="translate(-1667 -29)">
                    <g id="Ellipse_15" data-name="Ellipse 15" transform="translate(1667 29)" fill="#fff"
                       stroke="#c1ccd7" stroke-width="1">
                        <circle cx="15" cy="15" r="15" stroke="none"/>
                        <circle cx="15" cy="15" r="14.5" fill="none"/>
                    </g>
                    <path id="user-solid_3_" data-name="user-solid (3)"
                          d="M8.094,9.25A4.625,4.625,0,1,0,3.469,4.625,4.625,4.625,0,0,0,8.094,9.25Zm3.238,1.156h-.6a6.29,6.29,0,0,1-5.268,0h-.6A4.858,4.858,0,0,0,0,15.262v1.5A1.735,1.735,0,0,0,1.734,18.5H14.453a1.735,1.735,0,0,0,1.734-1.734v-1.5A4.858,4.858,0,0,0,11.331,10.406Z"
                          transform="translate(1673.906 34.122)" fill="#c1ccd7"/>
                </g>
            </svg>
            &nbsp;&nbsp;{props.grettings}&nbsp;{props.text}
            <i className="force-show el-submenu__icon-arrow el-icon-caret-bottom"/>&nbsp;
            <br/>
            <div id={'correo'}>{props.mail}</div>
        </div>

    )
}

export default UserIcon;