import { Button, Dialog, Card, Layout } from 'element-react';
import { useHistory } from "react-router-dom";
import {isMobile} from 'react-device-detect';

function ProductBanner(props){
    const history = useHistory();
    const goToLogin = () => {
        history.push("/login");
    }
    const goToRegister = () => {
        history.push("/register");
    }

    return(
        <Dialog
                title={''}
                size={isMobile ? "large" : "small"}
                visible={ props.modalVisible }
                onCancel={ () => props.setViewModalUser(false) }
                lockScroll={ false }
            >
                <Dialog.Body>
                    <div className="product-banner">
                    <Layout.Row>
                        <Layout.Col span={isMobile ? "24" : "12"}>
                            <Card className="product-banner-image">
                                <Layout.Row className='text-center'>
                                    <Layout.Col>
                                        <h2>PLEASE LOGIN TO USE THIS FEATURE</h2>
                                    </Layout.Col>
                                </Layout.Row>                
                                <Layout.Row className='text-center'>
                                    <Layout.Col>
                                        <Button type="primary" onClick={goToLogin} className="primary-button"> Sign In Now</Button>
                                    </Layout.Col>
                                </Layout.Row>
                                <br/>
                                <Layout.Row >
                                    <Layout.Col>
                                        <span>North American suppliers in Bainub are ready to help!</span>
                                    </Layout.Col>
                                </Layout.Row>    
                                <br/>
                                <Layout.Row >
                                    <Layout.Col>
                                        <span>Log into your account and we'll take you back where you left so you can contact them in no time.</span>
                                    </Layout.Col>
                                </Layout.Row>    

                            </Card>
                        </Layout.Col>
                        {isMobile ? <><br/><br/></> : <></>}
                        <Layout.Col span={isMobile ? "24" : "12"}>
                            <Card className="product-banner-image">
                                <Layout.Row>
                                    <Layout.Col>
                                        <h2 className='main-color'>NEW TO BAINUB?</h2>
                                    </Layout.Col>
                                </Layout.Row>                
                                <Layout.Row>
                                    <Layout.Col>
                                        <span>Create a <b>FREE</b> account to unlock all the buyer tools.It's fast and you wont need a credit card.</span>
                                    </Layout.Col>
                                </Layout.Row>         
                                <br/>       
                                <Layout.Row>
                                    <Layout.Col>
                                        <Button type="primary" onClick={goToRegister} className="primary-button">Create Account</Button>
                                    </Layout.Col>
                                </Layout.Row>
                                <br/>
                                <Layout.Row >
                                    <Layout.Col>
                                        <h2 className='gray-color'>What do I get?</h2>
                                    </Layout.Col>
                                </Layout.Row>    
                                <Layout.Row >
                                    <Layout.Col>
                                        <ul>
                                            <li>Easily find suppliers in Mexico, USA, and Canada.</li>
                                            <li>Instant messaging with North American manufacturers.</li>
                                            <li>Negotiate trading terms and follow up orders in real time.</li>
                                            <li>Keep track of orders, delivery dates, payments and more.</li>
                                        </ul>
                                    </Layout.Col>
                                </Layout.Row> 
                            </Card>
                        </Layout.Col>
                    </Layout.Row>
                    </div>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <form-button-inverse style={{'padding-right' : '10px'}}>
                        <Button onClick={ () => { 
                            props.setViewModalUser(false)
                        }  
                        }>
                            {
                                'Close'
                            }
                        </Button>
                    </form-button-inverse>
                </Dialog.Footer>
            </Dialog>
        
    )
}

export default ProductBanner;