import { Layout, Form, 
    Input, Select } from 'element-react';
import ShippingIcon from './ShippingIcon';
import { shippingStatusOptions, countryOptions } from '../../utils/Catalogs';
import { calculateOrderTotal } from '../../services/OrderService';

function ShippingSection(props){
    const t = props.t;
    return(
        <>
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <ShippingIcon width={41} height={41}/> <div className="order-step-form">{t('orders.form.shipping-section')}</div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-method')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.shippingMethod}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, shippingMethod: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-cost')} prop="paymentCost">
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.paymentCost}
                            step=".01"
                            type="number"
                            onChange={(v) => {
                                let _total = calculateOrderTotal(props.formInputs.products, props.formInputs.fees, v)
                                props.setFormInputs({...props.formInputs, paymentCost: v, total: _total })
                            }} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-status')} >
                        <Select 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            filterable={false} 
                            value={props.formInputs.shippingStatus}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, shippingStatus: v })}} 
                        >
                                {
                                    shippingStatusOptions.options.map(el => {
                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                        </Select>
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <b>{t('orders.form.shipping-origin-adds')}</b>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={8} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-street')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.originStreet}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, originStreet: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-city')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.originCity}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, originCity: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-state')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.originState}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, originState: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-country')} >
                        <Select 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            filterable={false} 
                            value={props.formInputs.originCountry}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, originCountry: v })}} 
                        >
                                {
                                    countryOptions.options.map(el => {
                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                        </Select>
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-zip')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.originZip}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, originZip: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-phone')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.originPhone}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, originPhone: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-contact')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.originContact}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, originContact: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <b>{t('orders.form.shipping-destination-adds')}</b>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={8} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-street')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.destinationStreet}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, destinationStreet: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-city')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.destinationCity}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, destinationCity: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-state')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.destinationState}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, destinationState: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-country')} >
                        <Select 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            filterable={false} 
                            value={props.formInputs.destinationCountry}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, destinationCountry: v })}} 
                        >
                                {
                                    countryOptions.options.map(el => {
                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                        </Select>
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-zip')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.destinationZip}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, destinationZip: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-phone')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.destinationPhone}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, destinationPhone: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-contact')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            value={props.formInputs.destinationContact}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, destinationContact: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={12} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.shipping-special-info')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus !== 3 ? false : true}
                            type='textarea'
                            value={props.formInputs.shippingSpecialInfo}
                            rows={4}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, shippingSpecialInfo: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
        </>
    )
}

export default ShippingSection;