import React from 'react';
import { Layout, Button} from 'element-react';
import {Link} from 'react-router-dom';
import about_us01 from "../assets/imgs/about_us01.png";
import about_us02 from "../assets/imgs/about_us02.png";
import about_us03 from "../assets/imgs/about_us03.png";
import about_us04 from "../assets/imgs/about_us04.png";
import logo from "../assets/imgs/bainub_logo_white.png";


function AboutBainubComponent() {
    const activeName = "0";
    return (
        <div className='AboutUsPage'>
            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="1" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">About Us</h1>    
            Bainub originated as an idea in 2020 and officially launched operations in 2023 in Monterrey, Mexico. 
            Our primary objective is to build an efficient network of collaboration between businesses in the North American region, 
            aiming to streamline cooperation between suppliers and buyers across borders spanning Mexico, the USA, and Canada. <br></br><br></br>
            We are proudly a North American enterprise striving to benefit our hard-working communities by creating a new supply 
            chain arrangement that contributes to lead our region into a world leading industrial power. This is why our motto is 
            “Every Business in North America Linked Together”.
            </Layout.Col>
            <Layout.Col className="hidde-mobile img-center" xs="24" sm="24" md="11" lg="9">
            <img src={about_us01} alt="Bainub" />
            </Layout.Col>
            <Layout.Col className="hidde-mobile" xs="1" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" lg="24" className='section02 hidden-mobile'>
            <Layout.Col xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="18" lg="18">
                <h1>Our Goal</h1>
                <h2>Becoming the best B2B online trading platform in North America</h2>
                <p>Bainub  launched in 2023. We are currently building new collaborations with commerce/industrial organizations to make our network stronger every day.</p>
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="3" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" className="img-center" sm="24" md="11" lg="9">
            <img src={about_us02} className="hidde-mobile" alt="Bainub" />
            <img src={about_us03} className="hidde-desktop" alt="Bainub" />
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">Our Tool</h1>    
            Bainub is an online platform where you can meet reliable
            suppliers and potential customers all around North America. 
            Bainub provides the tools for a 360° experience: find suppliers, 
            connect, communicate in real time, create and formalize agreements, and keep track of all your trading operations.
            </Layout.Col>
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">Our Team</h1>    
            Our team has broad experience in international trading, product development,
            sales, and logistics. We developed Bainub by pouring this experience
            into it and optimizing the tools to facilitate the challenges that the
            procurement and sales teams face in their daily endeavors.
            </Layout.Col>
            <Layout.Col className="hidde-mobile img-center" xs="24" sm="24" md="11" lg="9">
            <img src={about_us03} alt="Bainub" />
            </Layout.Col>
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col className="hidde-mobile img-center" xs="24" sm="24" md="11" lg="9">
            <img src={about_us04} alt="Bainub" />
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">Our Purpose</h1>    
            Promoting nearshoring trading, Bainub aims to help making the supply
            chain, for every product that is developed and commercialized in North
            America, leaner and more efficient, benefiting costs, lead times, environment,
            and strengthening the links between our communities.
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>
            <div className='TalkContainer'>
            <div className='TalktoUs'>
                <Layout.Row>
                    <Layout.Col sm="24" md="16" lg="16">
                        <h2>Talk to us</h2>
                        <p>if you have any questions about the team, culture or values, we’re an
                           open book, just contact us!</p>
                    </Layout.Col>
                    <Layout.Col sm="24" md="8" lg="8">
                        <div className='responsiveSpacer'></div>
                    <Link to="/contact-us" target="_blank">
                        <div className='button'>
                            More Information
                        </div>
                    </Link>
                    </Layout.Col>
                </Layout.Row>
            </div>
            </div>

            <Layout.Row gutter="40" lg="24" className='section03'>
            <Layout.Col xs="24" sm="24" md="3" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="18" lg="18">
                <div className="responsiveSpacer2"></div>
                <h3>Ready to grow? Sign Up Now!</h3>
                <img src={logo}></img>
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="3" lg="3" ><br></br></Layout.Col>
            </Layout.Row>


            </div>
    );
}

export default AboutBainubComponent;
