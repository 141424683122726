import React from 'react';
import { Layout, Card } from 'element-react';
import { Link } from 'react-router-dom';
const CardDashboard = (props) => {
    const numberWithCommas = (x)=> {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  return (
            <>
                <Card className={props.premium ? "box-card-dash box-card-dash-premium" : "box-card-dash"}>
                    <Layout.Row type='flex'>
                        <Layout.Col className='centerCard'>
                            <h5>
                                {props.title}
                            </h5>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="20" type='flex'>
                        <Layout.Col span={6}>
                            <div className='card-dash-icon'>
                                {props.icon}
                            </div>
                            
                        </Layout.Col>
                        <Layout.Col span={18}>
                            {
                                props.premium
                                ?   
                                    <>
                                        <div className='buyer-note card-dash-note'>{props.message}</div>
                                    </>
                                :
                                    <div className='card-dash-total'>
                                        {numberWithCommas(props.total)}
                                    </div>
                            }
                            
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row type='flex' justify="end">
                        <Layout.Col span={8}>
                        </Layout.Col>
                        <Layout.Col span={12}>
                            <div className={props.premium ? 'card-dash-link card-dash-link-premium' : 'card-dash-link'}>
                                <Link onClick={()=>props.goToLink(props.link)} to={'#'}>
                                    {props.linkText}
                                </Link>
                            </div>
                            
                        </Layout.Col>
                    </Layout.Row>
                </Card>
            </>
  )
}

export default CardDashboard;