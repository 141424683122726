import React from 'react';
import { Card } from 'element-react';
function SearchSubCategoryDescription(props) {
    return (
        <div>
            <Card style={{border : 'none'}} className="search-subcategory-image">    
                <div className="search-subcategory-description">
                    <h4>{props.title}</h4>
                    <span className='result-customizable'>{props.description}</span>
                </div>
            </Card>
        </div>
    );
}

export default SearchSubCategoryDescription;
