import { useState } from "react"
import { Layout } from "element-react"
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { BiChat } from "react-icons/bi";

import ChatTitle from "../components/ChatTitle"
import ChatItemsSearchBox from "../components/ChatItemsSearchBox"
import ChatMessageInputs from "../components/ChatMessageInputs"

import ChatItemsContainer from "../components/ChatItemsContainer";
import ChatMessagesContainer from "../components/ChatMessagesContainer";
import ChatEmptyInbox from "../components/ChatEmptyInbox";

export default function MyChats() {
    const {t} = useTranslation();    
    const [[selectedChat, setSelectedChat], [chatTextFilter, setChatTextFilter], [chatTypeFilter, setChatTypeFilter], [hasNoChats, setHasNoChats]] = [useState(null), useState(""), useState(""), useState(false)]
    let { id } = useParams();
    return (
        <Layout.Row>
            <Layout.Col span="20" offset="2" className="title-primary-color m-t-md m-b-sm">
                <h2>{t('chat.title')}</h2>
            </Layout.Col>
            <Layout.Col span="20" offset="2" className="m-b-xl">
                { hasNoChats ? 
                    <ChatEmptyInbox /> 
                    :
                    <Layout.Col span="9" className="p-r-md">
                        <ChatItemsSearchBox onInputChange={setChatTextFilter} onChatTypeChange={setChatTypeFilter} />
                        <ChatItemsContainer indexChat={id} onChatSelect={setSelectedChat} filterValue={chatTextFilter} chatTypeFilter={chatTypeFilter} onEmptyChats={setHasNoChats}/>
                    </Layout.Col>
                }
                {selectedChat ? 
                    <Layout.Col span="15">
                        <ChatTitle chat={selectedChat}/>
                        <ChatMessagesContainer chat={selectedChat} />
                        <ChatMessageInputs chat={selectedChat} />
                    </Layout.Col>
                    : !hasNoChats ?
                    <div className="chat-non-chat-selected">
                        <BiChat size={80} className="m-b-md"/><br />
                        {t('chat.please-select-chat')}
                    </div>
                    : <></>
                    
                }
            </Layout.Col>
        </Layout.Row>
    )
}
