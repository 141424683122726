
const addRfqRules = (t) => {
    return  ({
        productName: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        category: [
            { required: true,trigger: 'change', message: t('my-store.create.form.validator.generic'), validator: (rule, value) =>{
                if(Array.isArray(value) && value.length > 0){
                    return true
                }else{
                    return false
                }
            }}
        ],
        requirements: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        quantity: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        unit: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        buyingFrequency: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        expiredIn: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
    })
    }

export {addRfqRules}