import { API } from 'aws-amplify'
import { isEmailUnsuscribed } from './emailLambdaInvokers'

export default async function sendEmailTemplate ({ templateName, templateLanguage, emailFrom, recipients, templateVars, subject }) {
    try {
        let newRecipients = []
        for (const recipient of recipients) {
            console.log(`Checking if ${recipient} is unsuscribed`)
            try{
                const isUnsuscribed = await isEmailUnsuscribed({ email: recipient })
                if (isUnsuscribed.data[0].count > 0) {
                    console.log(`Recipient ${recipient} is unsuscribed`)
                    continue
                }
            }catch(error){
                console.error(error)
            }
            newRecipients.push(recipient)
        }
        const result = await API.post('NotificationServiceLambda', '/newNotification/sendTemplate', {
            body: {
                templateName, 
                templateLanguage, 
                emailFrom, 
                recipients : newRecipients, 
                templateVars, 
                subject
            }
        })
        console.log(result)
    } catch(error) {
        console.error(error)
    }
}