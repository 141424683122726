import {getProduct} from '../graphql/queries';
import {createProduct} from '../graphql/mutations';
import {API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";

const cloneProduct = async (productId) => {
    try{
        let input = {
            id: productId
        }
        const result  = await API.graphql({
            query: getProduct,
            variables: input,
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM
        });
        console.log({result})
        //@ts-ignore
        const originalProduct = result.data.getProduct;

        const inputCreate = {
            name: originalProduct.name,
            description: originalProduct.description,
            productCategoryId: originalProduct.category.id,
            big_description: originalProduct.big_description,
            photo: originalProduct.photo,
            file: originalProduct.file,
            addtional_photo: originalProduct.addtional_photo,
            min_price: originalProduct.min_price,
            max_price: originalProduct.max_price,
            measurement: originalProduct.measurement,
            min_order: originalProduct.min_order,
            package_type: originalProduct.package_type,
            customizable: originalProduct.customizable,
            package_custom: originalProduct.package_custom,
            monthly_capacity: originalProduct.monthly_capacity,
            client_service: originalProduct.client_service,
            quantity_per_package: originalProduct.quantity_per_package,
            package_dimensions: originalProduct.package_dimensions,
            package_weight: originalProduct.package_weight,
            warranty: originalProduct.warranty,
            warranty_time: originalProduct.warranty_time,
            tags: originalProduct.tags,
            brand: originalProduct.brand,
            int_ext: originalProduct.int_ext,
            size: originalProduct.size,
            special_functions: originalProduct.special_functions,
            color: originalProduct.color,
            material: originalProduct.material,
            titles: originalProduct.titles,
            voltage: originalProduct.voltage,
            power: originalProduct.power,
            protection: originalProduct.protection,
            life_time: originalProduct.life_time,
            titles2: originalProduct.titles2,
            views: 0,
            search_field: originalProduct.search_field,
            status: 1,

            company_name: originalProduct.company_name,
            company_id: originalProduct.company_id,
            category_id: originalProduct.category_id,
            subcategory_id: originalProduct.subcategory_id,
            subcategory_name: originalProduct.subcategory_name,
            key: originalProduct.key,
            company_country: originalProduct.company_country,
            verified: originalProduct.verified,
            bainub_account_year: originalProduct.bainub_account_year,
            supplier_rating: originalProduct.supplier_rating
        };
        
        console.log({inputCreate});

        const resultCreate = await API.graphql(
            graphqlOperation(createProduct, { input : inputCreate })
        );
        console.log("Product created", resultCreate);
        //@ts-ignore
        return resultCreate.data.createProduct.id;
    }catch(e){
        console.log(e);
    }
}

export {cloneProduct};