import { Layout, Loading, Card, Input } from 'element-react';
import { useTranslation } from 'react-i18next';
import CardDashboard from '../components/dashboard/cardDash';
import CartShoppingIcon from '../components/dashboard/cartShoppingIcon';
import CompletedIcon from '../components/dashboard/completedIcon';
import BoxIcon from '../components/dashboard/boxIcon';
import ChatIcon from '../components/dashboard/chatIcon';
import UsersIcon from '../components/dashboard/usersIcon';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {listChats, listOrders, listCompanys, listUsers, listUserFavoritess} from '../graphql/queries';
import {updateProduct} from '../graphql/mutations';
import {Auth, API, graphqlOperation } from 'aws-amplify';
import menuStore from '../store/menu-selected';
import * as productController from '../graphql/productLambdaInvokes';
import PagerQuery from '../utils/PagerQuery';
import PortalNotifications from '../components/PortalNotifications';


function BuyerDashboard(props){
    const {setMenuIndex} = menuStore();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const [user, setUser] = useState({email : 'xx'});
    const [data , setData] = useState({
        activeSales : 0,
        completedSales : 0,
        products : 0,
        contacts: 0,
        rfqs: 0
    })
    useEffect(() => {
        console.log('Load counters');
        //Cualquiera puede entrar a esta pagina, teniendo sesion (comprador o vendedor)
        //checkProfile();
        loadCounters();
    },[]);

    async function checkProfile(){
        console.log('checkProfile');
        const user = await Auth.currentAuthenticatedUser();
        //const userResult = await API.graphql({ query: listUsers, variables: { filter: { cognito_id: { eq: user.username } }}});
        const input = { filter: { cognito_id: { eq: user.username } }}
        const pagerQuery = new PagerQuery(listUsers, input, Object.keys({ listUsers }));
        const userResult = await pagerQuery.searchPrivateData();
        console.log({userResult});
        if(userResult.data.listUsers.items.length > 0){
            if(userResult.data.listUsers.items[0].user_type != 'S'){
                //history.push("/my-account");
            }
        }
    }


    const setLinkReference = (url) =>{
        setMenuIndex(url);
        history.push(url)
    }

    const loadCounters = async () =>{
        try{
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            setUser(user);
            const input = { filter: { buyer_email: { eq: user.attributes.email }}}
            const pagerQuery = new PagerQuery(listOrders, input, Object.keys({ listOrders }));
            const resultOrders = await pagerQuery.searchPrivateData();

            const input2 = {filter: {owner: {eq: user.username}}};
            const pagerQuery2 = new PagerQuery(listUserFavoritess, input2, Object.keys({ listUserFavoritess }));
            const resultFavorites = await pagerQuery2.searchPrivateData();
            const _resultFavorites = resultFavorites.data.listUserFavoritess.items.filter(item => item.product.status == 1);


            const input3 = {filter: {owner: {eq: user.username}}};
            const pagerQuery3 = new PagerQuery(listChats, input3, Object.keys({ listChats }));
            const resultChats = await pagerQuery3.searchPrivateData();

            const result = await API.get('NotificationServiceLambda', '/rfq/by/' + user.attributes.email, {});
            console.log({result});


            setData({
                ...data, activeSales: resultOrders.data.listOrders.items.length,
                products: _resultFavorites.length,
                contacts: resultChats.data.listChats.items.length,
                rfqs: result.rfq.length
            });
        }catch(e){
            console.error(e);
        }finally{
            setLoading(false);
        }
    }

    
    return(
        <div className="product-wizard-div">
            {
                loading && <Loading fullscreen={loading} />
            }
            <div className="my-product-table">
            
            </div>
            <Layout.Row gutter="20">
                            <Layout.Col offset="2" span="20">
                                <h2>Dashboard</h2>
                            </Layout.Col>
            </Layout.Row>
            <Layout.Row type="flex">
                    <Layout.Col span="20" offset="2">
                                <Card className="box-card warning-card">
                                    
                                    <Layout.Row className="other-color top-separator bottom-separator rigth-separator" type="flex" justify="start">
                                        <Layout.Col  tag="form-button-warning" span="24" offset="1" xs="10" sm="10" lg="10">
                                            <div class="white-text left-separator title-text">
                                                <b>{t('dasboard.welcome')}</b>
                                            </div>   
                                        </Layout.Col>
                                    </Layout.Row>
                                    
                                    
                                </Card>    
                    </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20" justify="end" type="flex" >
                            <Layout.Col offset="1" lg="10">
                                &nbsp;
                            </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20" type='flex'>
                    <Layout.Col span="4" offset="2">
                        <CardDashboard
                            title={'Total Orders'}
                            icon={<CartShoppingIcon width={30} />}
                            total={data.activeSales}
                            link="/tools/my-orders"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
                    <Layout.Col span="4">
                        <CardDashboard
                            title={'Favorite Products'} 
                            icon={<BoxIcon width={25}/>}
                            total={data.products}
                            link="/tools/favorites"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
                    <Layout.Col span="4">
                        <CardDashboard
                            title={t('dasboard.cards.title4')}
                            icon={<ChatIcon width={30}/>}
                            total={data.contacts}
                            link="/my-chats/0"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
                    <Layout.Col span="4">
                        <CardDashboard
                            title={'Active RFQs'}
                            icon={<CompletedIcon width={30}/>}
                            total={data.rfqs}
                            link="/tools/rfq"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
            </Layout.Row> 
            <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
            </Layout.Row>
            <PortalNotifications notificationType={2} />
            <Layout.Row gutter="20" >
                    <Layout.Col offset="2" span="20">
                        <div className="form-separator"/>
                    </Layout.Col>
                        
            </Layout.Row>
            <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20" type='flex'>
                    {
                        
                        <Layout.Col span="9" offset="2">
                            <iframe width="420" height="240" src={t('dasboard.video-url2')} title="Bainub" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Layout.Col>
                        
                    }
                    
                    
            </Layout.Row>
        </div>
    )
}

export default BuyerDashboard;