import { useRef, useState, useEffect } from "react"

import { API, graphqlOperation } from 'aws-amplify';
import { messagesByDate } from '../graphql/queries';
import { updateChat } from '../graphql/mutations';
import { onCreateMessage } from '../graphql/subscriptions';
import userStore from '../store/user';

import ChatCompanyReferenceMessage from "../components/ChatCompanyReferenceMessage"
import ChatProductReferenceMessage from "../components/ChatProductReferenceMessage"
import ChatAttachmentMessage from "../components/ChatAttachmentMessage"
import ChatTextMessage from "../components/ChatTextMessage"

let onCreateMessageSubscription;
let globalSelectedChat;
export default function ChatMessagesContainer({ chat }) {
    const {user} = userStore();
    const chatMessagesBottom = useRef()
    const [[messages, setMessages], [loading, setLoading]] = [useState([]), useState(false), useState(null)]

    useEffect(function () {
        onCreateMessageSubscription = API.graphql(graphqlOperation(onCreateMessage)).subscribe({ next: subscription, error: error => console.warn(error) });
        return () =>{
            console.log('ChatMessagesContainer UNSUBSCRIBE')
            onCreateMessageSubscription.unsubscribe();
            console.log('ChatMessagesContainer AFTER UNSUBSCRIBE')
        }
    }, [])

    useEffect(function() {
        chatMessagesBottom.current.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" })
    }, [messages])

    useEffect(function() {
        setLoading(true)
        globalSelectedChat = chat
        loadMessages()
    }, [chat])

    async function loadMessages() {
        const listMessagesResult = await API.graphql({ 
            query: messagesByDate, 
            variables: {
                sortDirection: 'DESC',
                chat_id: globalSelectedChat.id
            } 
        })
        setMessages(listMessagesResult.data.messagesByDate.items)
        setLoading(false)
    }

    const renderMessages = () => {
        if(messages) {
            return messages.map(message => {
                console.log({message})
                let messageSendBy = message.from === user.cognitoId ? "own" : "other";
                if(message.message_type === "lets-chat") 
                    return <ChatProductReferenceMessage message={message} showContactPhone={false} showMessageText={false} messageSendBy={messageSendBy} key={message.id} />
                else if(message.message_type === "product-question") 
                    return <ChatProductReferenceMessage message={message} showContactPhone={false} showMessageText={true} messageSendBy={messageSendBy} key={message.id} />
                else if(message.message_type === "product-question-contact-phone") 
                    return <ChatProductReferenceMessage message={message} showContactPhone={true} showMessageText={false} messageSendBy={messageSendBy} key={message.id} />
                else if(message.message_type === "company-lets-chat")
                    return <ChatCompanyReferenceMessage message={message} showContactPhone={false} showMessageText={false} messageSendBy={messageSendBy} key={message.id} />
                else if(message.message_type === "company-question")
                    return <ChatCompanyReferenceMessage companyId={message.product_company_id} message={message} showContactPhone={false} showMessageText={true} messageSendBy={messageSendBy} key={message.id} />
                else if(message.message_type === "company-question-contact-phone")
                    return <ChatCompanyReferenceMessage message={message} showContactPhone={true} showMessageText={false} messageSendBy={messageSendBy} key={message.id} />
                else if(message.message_type === "attachment") 
                    return <ChatAttachmentMessage message={message} messageSendBy={messageSendBy} key={message.id} />
                else
                    return <ChatTextMessage message={message} messageSendBy={messageSendBy} key={message.id} />
            }).reverse()
        }
    }

    const subscription = async (data) => {
        const messageItem = data.value.data.onCreateMessage
        if(globalSelectedChat.id === messageItem.chat_id && !loading) {
            setLoading(true)
            loadMessages()
            const updateChatInput= { }
            updateChatInput["id"]= globalSelectedChat.id
            updateChatInput[chat.chat_type === "S" ? "company_user_last_message_read" : "owner_last_message_read"]= 0
            await API.graphql(graphqlOperation(updateChat, { input: updateChatInput }));
        }
    }

    return (
        <div className="chat-messages">
            {renderMessages()}
            <div ref={chatMessagesBottom} />
        </div>
    )
}
