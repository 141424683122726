import React from "react";
import { Layout } from 'element-react';
const BuyerToolsIcon = (props) => {
    return (
        <div className="flex-column">
        <Layout.Row type="flex" justify="center" align="middle">
            <Layout.Col offset="6" span="24">
        {
        <svg width="39" height="31"
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 1176 933">
	<g transform="translate(-1246 -860)">
		<g>
			<g>
				<g>
					<path d="M1687.99 1508.03 1687.99 1706.65 1886.6 1706.65 1886.6 1508.03ZM1748.44 1568.48 1713.9 1568.48 1713.9 1533.94 1748.44 1533.94Z" stroke="#C1CCD7" stroke-width="8.63552" fill="#C1CCD7" />
					<path d="M1912.51 1508.03 1912.51 1706.65 2111.12 1706.65 2111.12 1508.03ZM1972.96 1568.48 1938.42 1568.48 1938.42 1533.94 1972.96 1533.94Z" stroke="#C1CCD7" stroke-width="8.63552" fill="#C1CCD7" />
					<path d="M1800.25 1283.51 1800.25 1482.12 1998.86 1482.12 1998.86 1283.51ZM1860.7 1343.96 1826.16 1343.96 1826.16 1309.42 1860.7 1309.42Z" stroke="#C1CCD7" stroke-width="8.63552" fill="#C1CCD7" />
					<path d="M2024.77 1283.51 2024.77 1482.12 2223.39 1482.12 2223.39 1283.51ZM2085.22 1343.96 2050.68 1343.96 2050.68 1309.42 2085.22 1309.42Z" stroke="#C1CCD7" stroke-width="8.63552" fill="#C1CCD7" />
					<path d="M1912.51 1058.99 1912.51 1257.6 2111.12 1257.6 2111.12 1058.99ZM1972.96 1119.44 1938.42 1119.44 1938.42 1084.9 1972.96 1084.9Z" stroke="#C1CCD7" stroke-width="8.63552" fill="#C1CCD7" />
					<path d="M2137.03 1508.03 2137.03 1706.65 2327.01 1706.65 2327.01 1508.03ZM2197.48 1568.48 2162.94 1568.48 2162.94 1533.94 2197.48 1533.94Z" stroke="#C1CCD7" stroke-width="8.63552" fill="#C1CCD7" />
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path d="M1747.67 958.542C1747.67 998.122 1715.58 1030.21 1676 1030.21 1636.42 1030.21 1604.33 998.122 1604.33 958.542 1604.33 918.961 1636.42 886.875 1676 886.875 1715.58 886.875 1747.67 918.961 1747.67 958.542Z" stroke="#C1CCD7" stroke-width="35.8334" fill="none" />
					<path d="M1871.29 1327.63 1821.13 1114.42C1819.33 1107.25 1815.75 1100.08 1810.38 1094.71 1788.88 1076.79 1763.79 1064.25 1735.13 1055.29 1715.42 1051.71 1695.71 1048.13 1676 1048.13 1656.29 1048.13 1636.58 1051.71 1616.88 1057.08 1588.21 1064.25 1563.13 1078.58 1541.63 1096.5 1536.25 1101.88 1532.67 1109.04 1530.88 1116.21L1480.71 1329.42C1480.71 1331.21 1478.92 1334.79 1478.92 1338.38 1478.92 1358.08 1495.04 1374.21 1514.75 1374.21 1530.88 1374.21 1545.21 1361.67 1548.79 1347.33L1586.42 1191.46 1586.42 1693.13 1658.08 1693.13 1658.08 1370.63 1693.92 1370.63 1693.92 1693.13 1765.58 1693.13 1765.58 1189.67 1803.21 1345.54C1806.79 1359.88 1821.13 1372.42 1837.25 1372.42 1856.96 1372.42 1873.08 1356.29 1873.08 1336.58 1873.08 1333 1871.29 1329.42 1871.29 1327.63Z" stroke="#C1CCD7" stroke-width="35.8334" fill="none" />
				</g>
			</g>
		</g>
	</g>
</svg>
}
</Layout.Col>
            <Layout.Col span="24">
                <div className="header-icon-text">{props.text}</div>
            </Layout.Col>
        </Layout.Row>
    </div>
    );
};

export default BuyerToolsIcon;