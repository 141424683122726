

const processStatus = (process, t) =>{
    console.log(`process :: ${process}`);
    process = process + '';
    let status = 'N';
    switch(process){
        case '0' :
            status = t('orders.history.form.status0');
            break;
        case '1' :
            status = t('orders.history.form.status1');
            break;
        case '2' :
            status = t('orders.history.form.status2');
            break;
        case '3' :
            status = t('orders.history.form.status3');
            break;
        case '4' :
            status = t('orders.history.form.status4');
            break;
        case '5' :
            status = t('orders.history.form.status5');
            break;
        case '6' :
            status = t('orders.history.form.status6');
            break;
        case '7' :
            status = t('orders.history.form.status7');
            break;
        case '8' :
            status = t('orders.history.form.status8');
            break;
        default:
            status = t('orders.history.form.status0');
            break;
    }
    return status;
}

export { processStatus }