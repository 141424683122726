const myStoreMenuItems = {
    admin: [
        {
            label: "my-account.menu.dashboard",
            linkTo: null,
            icon: "",
            submenu: [
                {
                    label: "my-account.menu.notifications",
                    linkTo: "/dashboard#notifications",
                    icon: ""
                },
                {
                    label: "my-account.menu.user-guides",
                    linkTo: "/dashboard#user-guides",
                    icon: ""
                },
                {
                    label: "my-account.menu.demo-video2",
                    linkTo: "/dashboard#demo-video",
                    icon: ""
                },
                {
                    label: "my-account.menu.faq2",
                    linkTo: "/dashboard#faq",
                    icon: ""
                }
            ]
        },
        {
            label: "my-account.menu.my-company",
            linkTo: "/my-store",
            icon: ""
        },
        {
            label: "my-account.menu.products",
            linkTo: "/my-products",
            icon: ""
        },
        {
            label: "my-account.menu.orders",
            linkTo: "/orders",
            icon: ""
        },
        /*{
            label: "my-account.menu.calendar",
            linkTo: "/calendar",
            icon: ""
        },*/
        {
            label: "my-account.menu.sales-team",
            linkTo: "/sales-team",
            icon: ""
        },
        /*{
            label: "my-account.menu.analytics",
            linkTo: "/analytics",
            icon: ""
        },
        {
            label: "my-account.menu.visibility",
            linkTo: "/visibility",
            icon: ""
        },
        {
            label: "my-account.menu.services",
            linkTo: "/services",
            icon: ""
        }*/
    ],
    buyer: [
        {
            label: "my-account.menu.dashboard",
            linkTo: "/dashboard",
            icon: ""
        },
        {
            label: "my-account.menu.account",
            linkTo: null,
            icon: "",
            submenu: [
                {
                    label: "my-account.menu.my-account",
                    linkTo: "/my-account",
                    icon: ""
                },
                {
                    label: "my-account.menu.payment-detail",
                    linkTo: "/payment-detail",
                    icon: ""
                },
                {
                    label: "my-account.menu.history-logs",
                    linkTo: "/history-logs",
                    icon: ""
                },
                {
                    label: "my-account.menu.navigation-preferences",
                    linkTo: "/navigation-preferences",
                    icon: ""
                },
                {
                    label: "my-account.menu.site-configuration",
                    linkTo: "/site-configuration",
                    icon: ""
                }
            ]
        }
    ]
}

export {
    myStoreMenuItems
};