import { useTranslation } from 'react-i18next';
import { S3Image } from "../components/S3Image"
import { Card, Layout } from "element-react"
import { useHistory } from 'react-router';
import { useState } from 'react';
import {getCompany} from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

export default function ChatCompanyReferenceMessage( { message, showContactPhone, showMessageText, companyId } ) {
    const history = useHistory()
    const {t} = useTranslation();
    const [company, setCompany] = useState(null);
    
    const productImageTheme = {
        photoImg: { 
            maxWidth: "60px", 
            maxHeight: "60px", 
            minHeight: "60px",
            borderRadius: "5%"
        }
    }

    if(companyId && !company) {
        getData();
    }

    async function getData(){
        console.log("loading company data ...");
        console.log({companyId})
        const input = {
            id : companyId
        }
        const result = await API.graphql(graphqlOperation(getCompany, input));
        console.log({result});
        setCompany(result.data.getCompany);
    }

    return (
        <Card className="p-none m-none m-b-md card-box-shadow-none" bodyStyle={{ padding: "10px", paddingLeft: "20px"}}>
            <Layout.Row className="chat-product-referece-message-body m-none p-none">
                <Layout.Col span="24" className="m-b-sm">
                    <label className="chat-product-referece-message-title">
                        {t('chat.messages.system.company-reference-title')}
                        { showContactPhone ? <> , {t('chat.messages.system.show-contact-phone-message')} <b>{message.message}</b> </> : <></> }
                    </label>
                </Layout.Col>
                <Layout.Col span="3">
                    <S3Image className="cursor" onClick={() => history.push("/company-store/"+message.product_company_id)}
                        imgKey={company && company.cover_picture &&  company.cover_picture.key ? company.cover_picture.key   : "/DEEMSA/cover_picture/1628555070378-cover_picture-003.jpg"}
                        //imgKey="/DEEMSA/cover_picture/1628555070378-cover_picture-003.jpg"
                        theme={productImageTheme}
                        level="public"
                        />
                </Layout.Col>
                <Layout.Col span="21" className="chat-product-referece-message-product-detail">
                    <label onClick={() => history.push("/company-store/"+message.product_company_id)} className="cursor">
                        {t('chat.messages.system.products-link')}
                    </label>
                    <label className="chat-product-referece-message-product-name cursor" onClick={() => history.push("/company-store/"+message.product_company_id)}>
                        {message.product_company_name}
                    </label>
                </Layout.Col>
                {
                    showMessageText ? 
                        <Layout.Col span="24" className="m-t-sm chat-product-referece-message-product-message">
                            <label>{message.message}</label>
                        </Layout.Col>
                    : <></>
                }
            </Layout.Row>

        </Card>
    )
}
