import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { useHistory } from "react-router-dom";

import { API, graphqlOperation } from 'aws-amplify'
import { createBusinessPreference } from '../../graphql/mutations'

import { useTranslation } from 'react-i18next'
import { Layout, Button, Card, Steps, Form, Loading, Notification, Checkbox } from 'element-react'

import { categoryPreferencesOptions, businessDescriptionOptions } from '../../utils/Catalogs'
import { listCategorys } from '../../graphql/queries'


export default function MyPreferencesWizard({onWizardComplete}) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isEnabledNexStepButton, setIsEnabledNexStepButton] = useState(false);
    
    // S T E P   1   F O R M 
    const formBusinessFieldsRef = useRef(null);
    const formBusinessFieldsRules = useState({
        categories: [
            { type:'array', required: true, message: t('my-store.create.form.validator.generic') }
        ]
    });
    const [formBusinessFieldsInputs, setFormBusinessFieldsInputs] = useState(
        {
            categories: []
        }
    )

    const [tags0, setTags0] = useState([]);
    const [tags1, setTags1] = useState([]);
    const [tags2, setTags2] = useState([]);

    const saveFormBusinessFields = async (e) => {
        e.preventDefault();
        if(formBusinessFieldsInputs.categories.length != 3) {
            return;
        }
        setLoading(true);
        console.log('formBusinessFieldsInputs: ', formBusinessFieldsInputs)
        const result = await API.graphql({ query: listCategorys});
        console.log({result});
        result.data?.listCategorys?.items.forEach(category => {
            console.log('Category: ', category)
            if(category.name == formBusinessFieldsInputs.categories[0]) {
                setTags0(category.tags);
            }
            if(category.name == formBusinessFieldsInputs.categories[1]) {
                setTags1(category.tags);
            }
            if(category.name == formBusinessFieldsInputs.categories[2]) {
                setTags2(category.tags);
            }
        })
        setIsEnabledNexStepButton(formSubcategoriesInputs.subcategoriesOne.length > 0 && formSubcategoriesInputs.subcategoriesTwo.length > 0 && formSubcategoriesInputs.subcategoriesThree.length > 0)       
        setCurrentStep(1)
        setLoading(false);
    }

    const onChangeFormBusinessFields = (v) => {
        setFormBusinessFieldsInputs({
            ...formBusinessFieldsInputs, 
            categories: v 
        });
        setIsEnabledNexStepButton(formBusinessFieldsInputs.categories.length == 3);
    }

    const renderBusinessFields = categoryPreferencesOptions.options.map( category => {
        console.log('Category: ', category)
        return <Checkbox label={t(`my-preferences-wizard.categories.${category.translationCode}`)} value={category.value} name="categories" className="el-col el-col-xs-12 el-col-md-12 el-col-lg-12 m-t-xs m-l-none"></Checkbox>
    } )

    // S T E P   2   F O R M 
    const formSubcategoriesRef = useRef(null);
    const formSubcategoriesRules = useState({
        subcategories: [
            { type:'array', required: true, message: t('my-store.create.form.validator.generic') }
        ]
    });
    const [formSubcategoriesInputs, setFormSubcategoriesInputs] = useState(
        {
            subcategoriesOne: [],
            subcategoriesTwo: [],
            subcategoriesThree: []
        }
    )

    const saveFormSubcategories = (e) => {
        e.preventDefault();
        if(formSubcategoriesInputs.subcategoriesOne.length > 0 && formSubcategoriesInputs.subcategoriesTwo.length > 0 && formSubcategoriesInputs.subcategoriesThree.length > 0) {
            setIsEnabledNexStepButton(formDescriptionInputs.description.length > 0)
            setCurrentStep(2)
            return;
        } else {
            return;
        }
    }

    const onChangeFormSubcategories = (v, formProperty) => {
        let _formSubcategoriesInputs = {
            ...formSubcategoriesInputs
        }
        _formSubcategoriesInputs[formProperty] = v;
        setFormSubcategoriesInputs(_formSubcategoriesInputs);
    }

    useEffect(function() {
        setIsEnabledNexStepButton(
            formSubcategoriesInputs.subcategoriesOne.length > 0 && 
            formSubcategoriesInputs.subcategoriesTwo.length > 0 && 
            formSubcategoriesInputs.subcategoriesThree.length > 0);
    }, [formSubcategoriesInputs])


    // S T E P   3   F O R M 
    const formDescriptionRef = useRef(null);
    const formDescriptionRules = useState({
        description: [
            { type:'array', required: true, message: t('my-store.create.form.validator.generic') }
        ]
    });
    const [formDescriptionInputs, setFormDescriptionInputs] = useState(
        {
            description: []
        }
    )

    const saveFormDescription = async (e) => {
        e.preventDefault();
        setIsEnabledNexStepButton(false)
        if(formDescriptionInputs.description.length < 1) {
            return;
        }
        setLoading(true)
        let business_preferences = [
            {
                category: formBusinessFieldsInputs.categories[0],
                subcategories: formSubcategoriesInputs.subcategoriesOne
            },{
                category: formBusinessFieldsInputs.categories[1],
                subcategories: formSubcategoriesInputs.subcategoriesTwo
            },{
                category: formBusinessFieldsInputs.categories[2],
                subcategories: formSubcategoriesInputs.subcategoriesThree
            }
        ]
        
        let input = {
            business_preferences,
            business_definition: formDescriptionInputs.description
        }
        
        try {
            console.log("about to save business preferences ...")
            console.log(input)
            const createBusinessPreferenceResult = await API.graphql(
                graphqlOperation(createBusinessPreference, { input })
            );

            console.log("business preferences saved!")
            console.log(createBusinessPreferenceResult)
            setLoading(false)
            setTimeout(function(){
                Notification({
                    title: t('my-preferences-wizard.notification.success-title'),
                    message: t('my-preferences-wizard.notification.success-msg'),
                    type: 'success'
                });
                onWizardComplete();
            },300);
        } catch(ex) {
            console.log("error saving preferences!")
            console.log(ex)
            
            setLoading(false)
            setTimeout(function(){
                Notification({
                    title: t('my-preferences-wizard.notification.fail-title'),
                    message: t('my-preferences-wizard.notification.fail-msg'),
                    type: 'success'
                });
                onWizardComplete();
            },300);
        }
    }

    const onChangeFormDescription = (v) => {
        setFormDescriptionInputs({
            ...formDescriptionInputs, 
            description: v 
        });
        setIsEnabledNexStepButton(formDescriptionInputs.description.length >= 1);
    }

    const renderDescriptions = businessDescriptionOptions.options.map( description => <Checkbox label={t(`my-preferences-wizard.businessDescription.${description.translationCode}`)} value={description.value} name="description" className="el-col el-col-xs-12 el-col-md-12 el-col-lg-12 m-t-xs m-l-none"></Checkbox> )


    if(loading)
        return <Loading fullscreen={loading} />
    return (
        <div className="my-store-wizard-div">
            <Layout.Row gutter="20">
                <Layout.Col span="24">
                    <h2>{t('my-preferences-wizard.title')}</h2>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col span="24">
                    <div className="general-text">
                        {t('my-preferences-wizard.subtitle')}
                    </div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20" className="m-t-lg">
                <Layout.Col span="24">
                    <Layout.Row type="flex" justify="center">
                        <Layout.Col offset="1" lg="10" tag="creat-mystore-steps">
                            <Steps space={120} active={currentStep} finishStatus="success">
                                <Steps.Step  description={t('my-preferences-wizard.step-1-title')}></Steps.Step>
                                <Steps.Step  description={t('my-preferences-wizard.step-2-title')}></Steps.Step>
                                <Steps.Step  description={t('my-preferences-wizard.step-3-title')}></Steps.Step>
                            </Steps>
                        </Layout.Col>
                    </Layout.Row>
                    { currentStep == 0 ?
                        <Form ref={formBusinessFieldsRef} rules={formBusinessFieldsRules} model={formBusinessFieldsInputs}>
                            <Layout.Row type="flex" justify="start" className="m-t-lg">
                                <Layout.Col offset="2" xs="24" sm="24" md="20" lg="20">
                                    <Form.Item label={t('my-preferences-wizard.step-1-detail')} prop="categories"><br/>
                                        <Layout.Row gutter="5">
                                            <Checkbox.Group value={formBusinessFieldsInputs.categories} onChange={onChangeFormBusinessFields}  className="m-t-md">
                                                    { renderBusinessFields }
                                            </Checkbox.Group>
                                        </Layout.Row>
                                    </Form.Item>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex" justify="end">
                                <Layout.Col xs="24" sm="24" md="24" lg="24" tag="creat-mystore-steps">
                                    <Button disabled={!isEnabledNexStepButton} className="primary-button" type="primary" onClick={saveFormBusinessFields}>
                                        {t('my-preferences-wizard.button-continue')}
                                    </Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form> : <></>
                    }

                    { currentStep == 1 ?
                        <Form ref={formSubcategoriesRef} rules={formSubcategoriesRules} model={formSubcategoriesInputs}>
                            <Layout.Row type="flex" justify="start" className="m-t-lg">
                                <Layout.Col offset="2" xs="24" sm="24" md="20" lg="20">
                                    
                                    <Form.Item label={t('my-preferences-wizard.step-2-detail')} prop="subcategoriesOne" ><br/>
                                        <Layout.Row type="flex" justify="start" className="m-t-none m-b-xs">
                                            <Layout.Col span="24">
                                                <label>{formBusinessFieldsInputs.categories[0]}</label>
                                                <Layout.Row gutter="5">
                                                    <Checkbox.Group value={formSubcategoriesInputs.subcategoriesOne} onChange={(v) => { onChangeFormSubcategories(v, 'subcategoriesOne')}} className="m-t-md">
                                                        { /*renderSubcategories(formBusinessFieldsInputs.categories[0], "subcategoriesOne")*/ 
                                                            tags0.map( subcategory => 
                                                                <Checkbox label={subcategory} value={subcategory} name="subcategories" className="el-col el-col-xs-12 el-col-md-12 el-col-lg-12 m-t-xs m-l-none"/>
                                                            )
                                                        }
                                                    </Checkbox.Group>
                                                </Layout.Row>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Form.Item>

                                    <Form.Item prop="subcategoriesTwo" ><br/>
                                        <Layout.Row type="flex" justify="start" className="m-t-none m-b-xs">
                                            <Layout.Col span="24">
                                                <label>{formBusinessFieldsInputs.categories[1]}</label>
                                                <Layout.Row gutter="5">
                                                    <Checkbox.Group value={formSubcategoriesInputs.subcategoriesTwo} onChange={(v) => { onChangeFormSubcategories(v, 'subcategoriesTwo')}}  className="m-t-md">
                                                        { 
                                                            tags1.map( subcategory => 
                                                                <Checkbox label={subcategory} value={subcategory} name="subcategories" className="el-col el-col-xs-12 el-col-md-12 el-col-lg-12 m-t-xs m-l-none"/>
                                                            ) 
                                                        }
                                                    </Checkbox.Group>
                                                </Layout.Row>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Form.Item>

                                    <Form.Item prop="subcategoriesThree"><br/>
                                        <Layout.Row type="flex" justify="start" className="m-t-none m-b-xs">
                                            <Layout.Col span="24">
                                                <label>{formBusinessFieldsInputs.categories[2]}</label>
                                                <Layout.Row gutter="5">
                                                    <Checkbox.Group value={formSubcategoriesInputs.subcategoriesThree} onChange={(v) => { onChangeFormSubcategories(v, 'subcategoriesThree')}}  className="m-t-md">
                                                        { 
                                                            tags2.map( subcategory => 
                                                                <Checkbox label={subcategory} value={subcategory} name="subcategories" className="el-col el-col-xs-12 el-col-md-12 el-col-lg-12 m-t-xs m-l-none"/>
                                                            ) 
                                                        }
                                                    </Checkbox.Group>
                                                </Layout.Row>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Form.Item>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex" justify="end">
                                <Layout.Col xs="24" sm="24" md="12" lg="12" tag="creat-mystore-steps">
                                    <Button className="white-primary-button" type="primary" onClick={() => { setCurrentStep(0); setIsEnabledNexStepButton(true); }}>
                                        {t('my-preferences-wizard.button-previous')}
                                    </Button>
                                    <Button disabled={!isEnabledNexStepButton} className="primary-button" type="primary" onClick={saveFormSubcategories}>
                                        {t('my-preferences-wizard.button-continue')}
                                    </Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form> : <></>
                    }
                    { currentStep == 2 ?
                        <Form ref={formDescriptionRef} rules={formDescriptionRules} model={formDescriptionInputs}>
                            <Layout.Row type="flex" justify="start" className="m-t-lg">
                                <Layout.Col offset="2" xs="24" sm="24" md="20" lg="20">
                                    
                                    <Form.Item label={t('my-preferences-wizard.step-3-detail')} prop="description"><br/>
                                        <Layout.Row type="flex" justify="start" className="m-t-md m-b-xs">
                                            <Layout.Col span="24">
                                                <Layout.Row gutter="5">
                                                    <Checkbox.Group value={formDescriptionInputs.description} onChange={(v) => { onChangeFormDescription(v)}} className="m-t-md">
                                                        { renderDescriptions }
                                                    </Checkbox.Group>
                                                </Layout.Row>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Form.Item>

                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex" justify="end">
                                <Layout.Col xs="24" sm="24" md="12" lg="12" tag="creat-mystore-steps">
                                    <Button className="white-primary-button" type="primary" onClick={() => { setCurrentStep(1); setIsEnabledNexStepButton(true); }}>
                                        {t('my-preferences-wizard.button-previous')}
                                    </Button>
                                    <Button disabled={!isEnabledNexStepButton} className="primary-button" type="primary" onClick={saveFormDescription}>
                                        {t('my-preferences-wizard.button-save')}
                                    </Button>
                                </Layout.Col>
                            </Layout.Row>
                        </Form> : <></>
                    }
                </Layout.Col>
            </Layout.Row>
        </div>
    )
}
