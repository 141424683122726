import { Button, Input, Layout, Select } from "element-react"
import { useTranslation } from 'react-i18next';
import userStore from '../store/user';

export default function ChatItemsSearchBox({ onInputChange , onChatTypeChange }) {
    const {t} = useTranslation();
    const { user } = userStore();

    return (
        <>
            <Layout.Row span="24" gutter="2">
                {
                    user.type === "S" ?
                    <>
                        <Layout.Col span="13">
                            <Input placeholder={t('chat.search.input-placeholder')} onChange={(e)=> onInputChange(e)}/>
                        </Layout.Col>
                        <Layout.Col span="5">
                            <Select onChange={v => onChatTypeChange(v)}>
                                <Select.Option label={t('chat.search.chat-type.all')} value=""/>
                                <Select.Option label={t('chat.search.chat-type.seller')} value="S"/>
                                <Select.Option label={t('chat.search.chat-type.buyer')} value="B"/>
                            </Select>
                        </Layout.Col>
                        <Layout.Col span="5" offset="1" className="align-center" >
                            <Button className="white-primary-button p-l-md p-r-md m-t-xs">{t('chat.search.button')}</Button>
                        </Layout.Col>
                    </>
                :
                    <>
                        <Layout.Col span="17">
                            <Input placeholder={t('chat.search.input-placeholder')} onChange={(e)=> onInputChange(e)}/>
                        </Layout.Col>
                        <Layout.Col span="6" offset="1" className="align-center" >
                            <Button className="white-primary-button p-l-md p-r-md m-t-xs">{t('chat.search.button')}</Button>
                        </Layout.Col>
                    </>
                }
            </Layout.Row>
        </>
    )
}
