const surveyFormRules = (t) => {
    return ({
        fullname: [
            { 
                required: true, 
                message: t('register-form.validate-generic') 
            }
        ],
        email: [
            { 
                required: true, 
                message: t('register-form.validate-generic'), 
                type: 'email' 
            }
        ],
        phone: [
            { 
                required: true, 
                message: t('register-form.validate-generic') 
            }
        ],
        products: [
            { 
                required: true, 
                message: t('register-form.validate-generic') 
            }
        ],
        country: [
            { 
                required: true, 
                message: t('register-form.validate-generic') 
            }
        ],
        company_name: [
            { 
                required: true, 
                message: t('register-form.validate-generic') 
            }
        ]
    })
}

export default surveyFormRules;