import { Layout, Card, Loading } from 'element-react';
import SwiperCore, { Navigation, Pagination, Autoplay, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import welcomeBannerImg from '../assets/imgs/banner_bainub_b2b_portal.jpg'


import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function HomeSwiper({ slidesPerView, spaceBetween, suppliers, loading }) {
    return (
        <>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="HomeSwiper"
          >
            <SwiperSlide>
            
            </SwiperSlide>
          </Swiper>
        </>
      );
}
