const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      address_line1
      address_state
      address_zip
      cognito_id
      createdAt
      updatedAt
    }
  }
`;

export {createUser}