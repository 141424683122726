import {Layout, Loading, Select, Table, Dialog, Radio, Button, Input, Card} from 'element-react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {listProducts, listUsers, listCounterInfoTables, listCompanys} from '../graphql/queries';
import {updateProduct, updateCounterInfoTable} from '../graphql/mutations';
import {Auth, API, graphqlOperation} from 'aws-amplify';
import {S3Image} from "../components/S3Image";
import EditSolidIcon from '../components/EditSolidIcon';
import StarEmptyIcon from '../components/StarEmptyIcon';
import StarFilledIcon from '../components/StarFilledIcon';
import VendorIcon from '../components/VendorIcon';
import CloneIcon from '../components/CloneIcon';
import DeleteIcon from '../components/DeleteIcon';
import {useHistory, Link} from "react-router-dom";
import {statusOptions} from '../utils/Catalogs';
import {cloneProduct} from '../services/ProductService';
import * as productController from '../graphql/productLambdaInvokes';
import createImg from '../assets/imgs/create-company.png';
import PagerQuery from '../utils/PagerQuery';

function MyProducts() {
    const [companyPage, setCompanyPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation('', {useSuspense: true});
    const [products, setProducts] = useState([]);
    const [filterTable, setFilterTable] = useState('');
    const [rows, setRows] = useState([]);
    const [generalStatus, setGeneralStatus] = useState('');
    const [showVendorDialog, setShowVendorDialog] = useState(false);
    const [vendorDialogData, setVendorDialogData] = useState({
        key: '',
        productName: '',
        vendorName: ''
    });
    let history = useHistory();
    const columns =
        [
            {
                type: 'selection'
            },
            {
                label: t('my-products.table.product'),
                filterMethod(value, row) {
                    if (filterTable !== '') {
                        return row.name.toLowerCase().includes(filterTable.toLowerCase());
                    } else {
                        return true;
                    }

                },
                filters: [{text: 'Busqueda', value: filterTable}],
                filteredValue: [filterTable],
                render: (row, column, index) => {
                    return (
                        <span style={{display: 'inline-flex'}}>
                    {
                        row.photo && row.photo.length > 0
                            ? <S3Image imgKey={row.photo[0].key}
                                       theme={{photoImg: {maxWidth: "50px", maxHeight: "50px", marginRight: "5px"}}}
                                       level="public"/>
                            : <div style={{width: "50px", height: "50px", marginRight: "5px"}}></div>
                    }
                            &nbsp;

                            <div>
                                <Link target="_blank" to={"/product-detail/" + row.id}>{row.name}</Link>

                                <span id={'category-title'}><br/>Category: <span
                                    id={'product-category'}>{row.category.name}</span></span>
                            </div>
                </span>
                    )
                }
            },
            {
                label: t('my-products.table.status'),
                width: 135,
                filters: [{text: 'Paused', value: 0}, {text: 'Active', value: 1}, {text: 'Archived', value: 2}],
                filterMethod(value, row) {
                    return row.status === value;
                },
                filteredValue: [0, 1],
                render: (row, column, index) => {
                    return (
                        <>
                            <Select value={row.status}
                                    onChange={(v) => {
                                        changeStatus(row.id, v)
                                    }}
                            >
                                {
                                    statusOptions.options.map(el => {
                                        return <Select.Option key={el.value} label={el.label} value={el.value}/>
                                    })
                                }
                            </Select>
                        </>
                    )
                }
            },
            /*{
              width: 140,
              label: t('my-products.table.category'),
              render: (row, column, index)=>{
                return(
                    <span>
                    {row.category.category_name}/{row.category.name}
                    </span>
                )
              }
            },*/
            /*{
                width: 120,
                label: t('my-products.table.created'),
                render: (row, column, index)=>{
                  return(
                      <div>
                      {(row.createdAt + "").substring(0,10)}
                      </div>
                  )
                }
            },*/
            {
                width: 128,
                label: t('my-products.table.update'),
                render: (row, column, index) => {
                    return (
                        <span>
                  {(row.updatedAt + "").substring(0, 10)}
                  </span>
                    )
                }
            },
            {
                label: t('my-products.table.views'),
                prop: "views",
                width: 80,
                render: (row, column, index) => {
                    return (
                        <div className="text-center">
                            {row.views ? row.views : 0}
                        </div>

                    )
                }
            },
            {
                label: t('my-products.table.featured'),
                width: 100,
                render: (row, column, index) => {
                    return (
                        <div className="text-center">
                            {
                                row.featured && row.featured === 1
                                    ?
                                    <StarFilledIcon handleClick={() => {
                                        changeFeaturedProduct(row.id, 0)
                                    }} width={30} height={30}/>
                                    :
                                    <StarEmptyIcon handleClick={() => {
                                        changeFeaturedProduct(row.id, 1)
                                    }} width={30} height={30}/>
                            }

                        </div>
                    )
                }
            },
            {
                label: t('my-products.table.vendor'),
                width: 102,
                render: (row, column, index) => {
                    return (
                        <div className="text-center" style={{marginTop: '20px'}}>
                            <VendorIcon handleClick={() => {
                                viewVendorData(row, row.owner)
                            }} width={30} height={30}/>
                        </div>
                    )
                }

            },
            {
                label: t('my-products.table.actions'),
                width: 130,
                render: (row, column, index) => {
                    return (
                        <div className="text-center" style={{marginTop: '20px'}}>
                            <EditSolidIcon handleClick={() => {
                                goToEditProduct(row.id)
                            }} width={30} height={30}/>
                            <DeleteIcon handleClick={() => {
                                changeStatus(row.id, 2)
                            }} width={30} height={30}/>
                            <CloneIcon handleClick={() => {
                                cloneHandler(row.id)
                            }} width={30} height={30}/>
                        </div>
                    )
                }
            }
        ];

    useEffect(async () => {
        console.log('MyProducts - useEffect');
        await loadProducts();
    }, [filterTable]);

    const viewVendorData = async (row, vendor) => {
        console.log(`view vendor data, id ${vendor}`);
        try {
            setLoading(true);
            //const userResult = await API.graphql({query: listUsers, variables: {filter: {cognito_id: {eq: vendor}}}});
            const input = { filter: { cognito_id: { eq: vendor } }}
            const pagerQuery = new PagerQuery(listUsers, input, Object.keys({ listUsers }));
            const userResult = await pagerQuery.searchPrivateData();
            console.log({userResult});
            let vendorObj = {
                first_name: '',
                last_name: '',
            };
            if (userResult && userResult.data && userResult.data.listUsers && userResult.data.listUsers.items)
                vendorObj = userResult.data.listUsers.items[0]
            setVendorDialogData({
                productName: row.name,
                key: (row.photo && row.photo.length > 0) ? row.photo[0].key : '',
                vendorName: vendorObj.first_name + ' ' + vendorObj.last_name
            })
            setLoading(false);
            setShowVendorDialog(true);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }

    }

    const loadProducts = async () => {
        setLoading(true);
    
        const user = await Auth.currentAuthenticatedUser();
        console.log({user});
        //const result = await API.graphql(graphqlOperation(listCompanys, { filter: { owner: {eq: user.username } } }))
            //console.log({result})
        const input = { filter: { owner: {eq: user.username } }}
        const pagerQuery = new PagerQuery(listCompanys, input, Object.keys({ listCompanys }));
        const result = await pagerQuery.searchPrivateData();
        console.log({result})
            //@ts-ignore
            setCompanyPage(result.data.listCompanys.items[0]);
            
        /*const resultProducts = await API.graphql({
            query: listProducts,
            variables: {filter: {owner: {eq: user.username}, status: {lt: 3}}}
        });*/
        const input2 = {filter: {owner: {eq: user.username}, status: {lt: 3}}}
        const pagerQuery2 = new PagerQuery(listProducts, input2, Object.keys({ listProducts }));
        const resultProducts = await pagerQuery2.searchPrivateData();

        console.log({resultProducts});
        let items = resultProducts.data.listProducts.items;
        setProducts(items);
        setLoading(false);
    }

    const updateProductCounter = async (user) => {
        try {
            const result = await API.graphql({
                query: listCounterInfoTables, variables:
                    {
                        filter:
                            {
                                owner: {eq: user.username}
                            }
                    }
            });
            console.log('NEW PRODUCT :: updateProductCounter');
            console.log({result});
            if (result && result.data && result.data?.listCounterInfoTables?.items.length > 0) {
                const input = {
                    id: result.data.listCounterInfoTables.items[0].id,
                    products_total: result.data.listCounterInfoTables.items[0].products_total - 1
                };
                const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, {input}));
                console.log({resultUpdate});
            } else {
                console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
            }
        } catch (e) {
            console.error(e);
        }
    }

    const changeStatus = async (id, newStatus) => {
        console.log(`changeStatus => ${id} - ${newStatus}`)
        setLoading(true);
        try {
            const input = {
                id: id,
                status: newStatus
            };
            const result = await API.graphql(graphqlOperation(updateProduct, {input}));
            console.log({products});
            if (products.length == 0) await loadProducts();
            let objIndex = products.findIndex((obj => obj.id == id));
            console.log(`index to replace :: ${objIndex}`)
            let _products = products;
            let productModifieds = {...result.data.updateProduct, views: _products[objIndex].views}
            _products[objIndex] = productModifieds;
            setProducts([..._products]);
            if (newStatus == 2) {
                const u = await Auth.currentAuthenticatedUser();
                await updateProductCounter(u);
            }
            productController.updateProductStatus(input);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }

    }
    const changeFeaturedProduct = async (id, newFeaturedStatus) => {
        console.log(`changeFeaturedProduct => ${id} - ${newFeaturedStatus}`)
        setLoading(true);
        try {
            const input = {
                id: id,
                featured: newFeaturedStatus
            };
            const result = await API.graphql(graphqlOperation(updateProduct, {input}));
            //console.log("Product updated", result);

            console.log({products});
            if (products.length == 0) await loadProducts();
            let objIndex = products.findIndex((obj => obj.id == id));
            console.log(`index to replace :: ${objIndex}`)
            let _products = products;
            let productModifieds = {...result.data.updateProduct, views: _products[objIndex].views}
            _products[objIndex] = productModifieds;
            setProducts([..._products]);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }

    }
    const goToEditProduct = (id) => {
        console.log(`goToEditProduct => ${id}`)
        history.push("/add-product-wizard/" + id);
    }

    const goToAddProduct = () => {
        console.log(`goToAddProduct`)
        history.push("/add-product-wizard/0");
    }

    const changeGeneralStatus = async (newStatus) => {
        if (newStatus !== '') {
            try {
                await rows.forEach(async (element) => {
                    setLoading(true);
                    await changeStatus(element.id, newStatus);
                });
                setGeneralStatus('');
                setRows([]);
                setLoading(false);
            } catch (e) {
                console.error(e)
                setLoading(false);
            }
        }


    }

    const cloneHandler = async (id) => {
        setLoading(true);
        try {
            const newId = await cloneProduct(id);
            console.log('newId :: ' + newId);
            if (newId) {
                goToEditProduct(newId);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }

    }

    const toCreateStore = () => {
        history.push("/my-store-wizard/0");
    }

    if(companyPage == null) return (<>
        
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        <h2>{t('my-products.table.title')}</h2>
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        &nbsp;
                                    {
                                        /*
                                    <Button onClick={null} type="primary">Test crear empresa</Button>
                                        */
                                    }
                                    </Layout.Col>
                        </Layout.Row>


                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                            <Card className="box-card card-company">
                                                <Layout.Row type="flex" justify="start" align="top">
                                                        <Layout.Col offset="1">
                                                        </Layout.Col>
                                                </Layout.Row>
                                                <div className="center">
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                <img src={createImg}/>
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col tag="form-button">
                                                            <div className="text-center">
                                                                <Button type="primary" onClick={toCreateStore}>
                                                                                            {t('my-store.button-add-store')}
                                                                </Button>
                                                            </div>
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                </div>
                                                
                                            </Card>               
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        &nbsp;
                                    </Layout.Col>
                        </Layout.Row>
                        
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        &nbsp;
                                    </Layout.Col>
                        </Layout.Row>
    </>);


    return (
        <div>
            {
                loading && <Loading fullscreen={loading}/>
            }

            <Dialog size="tiny"
                    visible={showVendorDialog}
                    onCancel={() => {
                        console.log('cancel Diaglog');
                        setShowVendorDialog(false);
                    }}
            >
                <Dialog.Body>
                    <Layout.Row gutter="20">
                        <Layout.Col offset="1" span="7">
                            {
                                vendorDialogData.key
                                    ?
                                    <S3Image
                                        imgKey={vendorDialogData.key}
                                        theme={{
                                            photoImg: {maxWidth: "75px", maxHeight: "75px", marginRight: "15px"}
                                        }}
                                        level="public"/>
                                    :
                                    <></>
                            }

                        </Layout.Col>
                        <Layout.Col span="15">
                            {vendorDialogData.productName}
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <Layout.Col span="15">
                            &nbsp;
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row justify="center" type="flex">
                        <Layout.Col offset="1" span="6">
                            {t('my-products.table.vendors-modal')}:
                        </Layout.Col>
                        <Layout.Col offset="1" span="15">
                            <div className="radio-custom">
                                <Radio value="1" checked={true}>
                                    {vendorDialogData.vendorName}
                                </Radio>
                            </div>
                        </Layout.Col>
                    </Layout.Row>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">

                </Dialog.Footer>
            </Dialog>
            <div className="my-product-table">
                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="20">
                        <h2>{t('my-products.table.title')}</h2>
                    </Layout.Col>
                </Layout.Row>


                <Layout.Row type="flex">
                    <Layout.Col span="24" xs="20" sm="20" lg="23">
                        <Layout.Row type="flex" justify="end">
                            <Layout.Col tag="form-button">
                                <div style={{display: 'inline-flex'}}>
                                    <Input
                                        icon="search"
                                        placeholder="Search"
                                        value={filterTable}
                                        onChange={v => {
                                            setFilterTable(v)
                                        }}
                                        className="input-width-200"
                                    />
                                    <Button className="left-separator" type="primary" onClick={goToAddProduct}>
                                        {t('my-products.menu.button-add-product')}
                                    </Button>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="4">
                        {
                            rows.length > 0
                                ?
                                <Select value={generalStatus}
                                        placeholder={'Change Status'}
                                        onChange={(v) => {
                                            setGeneralStatus(v);
                                            changeGeneralStatus(v);
                                            console.log(v)
                                        }}
                                >
                                    <Select.Option key={-1} label={''} value={''}/>
                                    {
                                        statusOptions.options.map(el => {
                                            return <Select.Option key={el.value} label={el.label} value={el.value}/>
                                        })
                                    }

                                </Select>
                                :
                                <></>

                        }

                    </Layout.Col>
                </Layout.Row>


                <Layout.Row gutter="20" justify="end" type="flex">
                    <Layout.Col offset="1" lg="10">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="22">

                        <Table
                            className="products-table box-card"
                            data={products}
                            columns={columns}
                            emptyText="No data..."
                            onSelectChange={(selection) => {
                                setRows(selection);
                                console.log(selection)
                            }}
                            onSelectAll={(selection) => {
                                setRows(selection);
                                console.log(selection)
                            }}
                        />
                    </Layout.Col>
                </Layout.Row>
            </div>
        </div>

    )
}

export default MyProducts;