import create from 'zustand'
//import { persist } from 'zustand/middleware'
const [headerChatStore] = create(set => ({
  chatInfo: false,
  setChatInfo: (status) => {
    console.log('mew chatInfo status :: ' + status);
    set({ chatInfo: status });
  },
  cleanChatInfo : () => set({chatInfo: false})
}))

export default headerChatStore;