import { Layout, Button, Loading, Input, Card, Collapse } from 'element-react';
import { useTranslation } from 'react-i18next';
import CardDashboard from '../components/dashboard/cardDash';
import CartShoppingIcon from '../components/dashboard/cartShoppingIcon';
import CompletedIcon from '../components/dashboard/completedIcon';
import BoxIcon from '../components/dashboard/boxIcon';
import ChatIcon from '../components/dashboard/chatIcon';
import UsersIcon from '../components/dashboard/usersIcon';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {listCounterInfoTables, listCategorys, listSubcategorys, listProducts, listCompanys, listUsers, getProduct} from '../graphql/queries';
import {updateProduct} from '../graphql/mutations';
import {Auth, API, graphqlOperation } from 'aws-amplify';
import menuStore from '../store/menu-selected';
import * as categoryController from '../graphql/categoryLambdaInvokes';
import * as productController from '../graphql/productLambdaInvokes';
import * as companyController from '../graphql/companyLambdaInvokes';
import PagerQuery from '../utils/PagerQuery';
import PortalNotifications from '../components/PortalNotifications';
import PdfIcon from "../assets/imgs/pdf-download.png";
import Pdf01 from "../assets/imgs/Guia-Alta-Empresa-y-Productos.pdf";
import Pdf02 from "../assets/imgs/Guia-Optimizacion-Anuncios.pdf";
import ContactUs from './ContactUs';
import HelpAndSupportComponent from '../components/HelpAndSupportComponent';


function Dashboard(props){
    const {setMenuIndex} = menuStore();
    const [companyPage, setCompanyPage] = useState(null);
    const [numberProducts, setNumberProducts] = useState(0);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const [productId, setProductId] = useState('');
    const [user, setUser] = useState({email : 'xx'});
    const [data , setData] = useState({
        activeSales : 0,
        completedSales : 0,
        products : 0,
        contacts: 0
    })
    useEffect(() => {
        console.log('Load counters');
        checkProfile();
        loadCounters();
        
    },[]);

    const listProductsCOUNTER = `
    query ListProducts(
        $filter: ModelProductFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            id
        }
        nextToken
        }
    }

    `;


    async function checkProfile(){
        console.log('checkProfile');
        try{
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            //const userResult = await API.graphql({ query: listUsers, variables: { filter: { cognito_id: { eq: user.username } }}});
            const input = { filter: { cognito_id: { eq: user.username } }}
            const pagerQuery = new PagerQuery(listUsers, input, Object.keys({ listUsers }));
            const userResult = await pagerQuery.searchPrivateData();
            console.log({userResult});
            if(userResult.data.listUsers.items.length > 0){
                if(userResult.data.listUsers.items[0].user_type != 'S'){
                    history.push("/my-account");
                }
            }
    
            const input2 = { filter: { owner: {eq: user.username } }}
            const pagerQuery2 = new PagerQuery(listCompanys, input2, Object.keys({ listCompanys }));
            const result = await pagerQuery2.searchPrivateData();
            console.log({result})
            //@ts-ignore
            setCompanyPage(result.data.listCompanys.items[0]);

            const input3 = { filter: { owner: {eq: user.username } }}
            const pagerQuery3 = new PagerQuery(listProductsCOUNTER, input3, Object.keys({ listProducts }));
            const result3 = await pagerQuery3.searchPrivateData();
            console.log({result3})
            //@ts-ignore
            setNumberProducts(result3.data.listProducts.items.length);

        }catch(e){
            console.error(e);
        }finally{
            setLoading(false);
        }
    }

    /*useEffect(() => {
        console.log('useEffect, userInfo');
        console.log(userInfo);
        if(userInfo != null && userInfo.type != 'S'){
            history.push("/my-account");
        }
    },[userInfo]);*/

    const setLinkReference = (url) =>{
        setMenuIndex(url);
        history.push(url)
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
    }

    const syncSubCategories = async () =>{
        console.log('INIT SYNC SUB-CATEGORIES');
        let result = await API.graphql({ query: listSubcategorys, variables: 
            { 
            } 
        });
        console.log('CSUB ATS');
        console.log({result});
        while(result.data.listSubcategorys.nextToken){
                console.log('CSUB ATS');
                console.log({result});
                result = await API.graphql({ query: listSubcategorys, variables: 
                    { 
                        nextToken: result.data.listSubcategorys.nextToken
                    } 
                });
                await pagedSyncSubCategories(result);
                console.log('CSUB ATS');
                console.log({result});
        }
        
    }

    const pagedSyncSubCategories = async (result) =>{
        if(result && result.data && result.data?.listSubcategorys?.items.length > 0){
            asyncForEach(result.data?.listSubcategorys?.items, async (element) => {
                var result = await categoryController.saveSubCategory({
                    id: element.id,
                    name: element.name,
                    description: element.description,
                    currentUser: user.username,
                    categoryId: element.category_id
                });
                console.log('RESULT', result);
                console.log('element.tags :: ' + element.tags.length);
                if(element.tags?.length > 0){
                    asyncForEach(element.tags, async (tag) => {
                        console.log('TAG', tag);
                        var resultTags = await categoryController.saveSubCategoryTag({
                            tagName: tag,
                            subCategoryId: element.id
                        });
                        console.log('RESULT TAGS', resultTags);
                    });
                }
              })
        }
    }


    const syncCategories = async () =>{
        console.log('INIT SYNC');
        const result = await API.graphql({ query: listCategorys, variables: 
            { 
            } 
        });
        console.log('CATS');
        console.log({result});
        if(result && result.data && result.data?.listCategorys?.items.length > 0){
            asyncForEach(result.data?.listCategorys?.items, async (element) => {
                var result = await categoryController.saveCategory({
                    id: element.id,
                    name: element.name,
                    description: element.description,
                    currentUser: user.username
                });
                console.log('RESULT', result);
                console.log('element.tags :: ' + element.tags.length);
                if(element.tags?.length > 0){
                    asyncForEach(element.tags, async (tag) => {
                        console.log('TAG', tag);
                        var resultTags = await categoryController.saveCategoryTag({
                            tagName: tag,
                            categoryId: element.id
                        });
                        console.log('RESULT TAGS', resultTags);
                    });
                }
              })
        }
    }

    const syncProducts = async () =>{
        console.log('INIT PRODUCTS SYNC');
        const result = await API.graphql({ query: listProducts, variables: 
            { 
            } 
        });
        console.log('PRODUCTS');
        console.log({result});
        const user = await Auth.currentAuthenticatedUser();
        if(result && result.data && result.data?.listProducts?.items.length > 0){
            asyncForEach(result.data?.listProducts?.items, async (element) => {
                //'e7a0dd62-4b78-4df5-a04a-a42a9e0eff6c',
                //'876e064b-eea0-4224-aaf7-e155a1e99777'
                /*if(element.id == 'e7a0dd62-4b78-4df5-a04a-a42a9e0eff6c' || 
                    element.id == '876e064b-eea0-4224-aaf7-e155a1e99777'){*/
                    var resultSave = await productController.saveProduct({
                        id: element.id,
                        name: element.name,
                        description: element.description != null ? element.description.substring(0,400) : '',
                        brand: element.brand,
                        owner: element.owner,
                        minPrice: element.min_price,
                        maxPrice: element.max_price,
                        photoA: element.photo[0]?.key,
                        photoB : element.photo[0]?.bucket,
                        photoC : element.photo[0]?.region,
                        companyId: element.company_id,
                        status : element.status,
                        warranty: element.warranty == true ? 1 : 0,
                        customizable: element.customizable == true ? 1 : 0,
                        minOrder: element.min_order,
                        cert1: element.titles,
                        cert2: element.titles2,
                        cert3: element.titles2,
                        productOrder: 100,
                        subCategoryId: element.subcategory_id,
                        featured: 0,
                        currentUser: user.username
                    });
                    console.log('RESULT', resultSave);
                //} 
              })
        }
    }

    const syncCompanies = async () =>{
        console.log('INIT COMPANYS SYNC');
        const result = await API.graphql({ query: listCompanys, variables: 
            { 
            } 
        });
        console.log('COMPANYS');
        console.log({result});
        const user = await Auth.currentAuthenticatedUser();
        if(result && result.data && result.data?.listCompanys?.items.length > 0){
            asyncForEach(result.data?.listCompanys?.items, async (element) => {
                var resultSave = await companyController.saveCompany({
                    id: element.id,
                    name: element.name,
                    owner: element.owner,
                    logoA: element.cover_picture != null && element.cover_picture.length > 0 ? element.cover_picture[0].key : null,
                    logoB : element.cover_picture != null && element.cover_picture.length > 0 ? element.cover_picture[0]?.bucket : null,
                    logoC : element.cover_picture != null && element.cover_picture.length > 0 ? element.cover_picture[0]?.region : null,
                    currentUser: user.username
                });
                console.log('RESULT', resultSave);
              })
        }
    }

    const syncProductTags = async () =>{
        console.log('INIT PRODUCT TAGS SYNC');
        const result = await API.graphql({ query: listProducts, variables: 
            { 
            } 
        });
        console.log('PRODUCT TAGS');
        console.log({result});
        if(result && result.data && result.data?.listProducts?.items.length > 0){
            asyncForEach(result.data?.listProducts?.items, async (element) => {
               asyncForEach(element.tags, async (tag) => {
                    console.log('TAG', tag);
                    var resultTags = await productController.saveProductTag({
                        tagName: tag,
                        productId: element.id
                    });
                    console.log('RESULT TAGS', resultTags);
                });
            });
        }
    }

    const loadCounters = async () =>{
        try{
            const user = await Auth.currentAuthenticatedUser();
            setUser(user);
            const resultCompany = await API.graphql(graphqlOperation(listCompanys, { filter: { owner: {eq: user.username } } }));
            const company = resultCompany.data != null && resultCompany.data.listCompanys.items.length > 0 ? resultCompany.data.listCompanys.items[0] : null;
            const result = await API.graphql({ query: listCounterInfoTables, variables: 
                { 
                    filter: 
                        { 
                            owner: { eq: user.username } 
                        } 
                } 
            });
            console.log('NEW PRODUCT :: updateProductCounter');
            console.log({result});
            const totalProducts = await productController.getNumberOrProductsByCompanyId({companyId : company.id});


            //const pagerQuery = new PagerQuery(listProducts, {filter: {owner: {eq: user.username}}}, Object.keys({ listProducts }));
            //const totalProducts = await pagerQuery.searchPrivateData();

            console.log('TOTAL PRODUCTS', totalProducts);
            if(result && result.data && result.data?.listCounterInfoTables?.items.length > 0){
                setData({
                    activeSales : result.data.listCounterInfoTables.items[0].active_orders_total,
                    completedSales : result.data.listCounterInfoTables.items[0].completed_orders_total,
                    //products : totalProducts.data.listProducts.items.length,
                    products : totalProducts != null && totalProducts.data != null ? totalProducts.data.count: 0,
                    contacts: result.data.listCounterInfoTables.items[0].chats_total
                })
            }
        }catch(e){
            console.error(e);
        }
    }
    const goToCompany = () =>{
        history.push("/my-store");
    }



    const fixProduct = async () =>{
        console.log('FIX PRODUCT');
        console.log(productId);
        const input = {
            id: productId
        }
        const result  = await API.graphql({
            query: getProduct,
            variables: input
          });
        console.log({result});
        let prdInfo = result.data.getProduct;
        console.log('PRD INFO');
        console.log({prdInfo});
        let inputUpdate = {
            id: productId,
            category_id: prdInfo.category.category_id,
            subcategory_name: prdInfo.category.name
        }
        const resultUpdate  = await API.graphql({
            query: updateProduct,
            variables: {input: inputUpdate}
        });
        console.log({resultUpdate});
    }
    
    return(
        <div className='dashboard-bg'>
            {
                loading && <Loading fullscreen={loading}/>
            }
            <div className="my-product-table">
            <Layout.Row gutter="20">
                            <Layout.Col offset="2" span="20">
                                <div className="mt-3 mb-3">
                                <span className='h1-1'>Dash</span><span className='h1-2'>board</span>
                                </div>
                               
                                {
                                    user != null && user?.attributes != null && user?.attributes['email'] == 'ska_su@hotmail.com' ?
                                    <>
                                        <Input value = {productId} 
                                        onChange={(v) => {setProductId(v)}}  />
                                        <Button type="primary" onClick={()=>{
                                                fixProduct();
                                            }
                                        }>
                                            Fix Product
                                        </Button>
                                    </>
                                    : <></>
                                }
                                {
                                    user != null && user?.attributes != null && user?.attributes['email'] == 'ska_su@hotmail.com' ?
                                    <Button type="primary" onClick={()=>{
                                            syncCategories();
                                        }
                                    }>
                                        SYNC CATEGORIES
                                    </Button>
                                    : <></>
                                }
                                {
                                    user != null && user?.attributes != null && user?.attributes['email'] == 'ska_su@hotmail.com' ?
                                    <Button type="primary" onClick={()=>{
                                            syncSubCategories();
                                        }
                                    }>
                                        SYNC SUB-CATEGORIES
                                    </Button>
                                    : <></>
                                }
                                {
                                    user != null && user?.attributes != null && user?.attributes['email'] == 'ska_su@hotmail.com' ?
                                    <Button type="primary" onClick={()=>{
                                            syncCompanies();
                                        }
                                    }>
                                        SYNC COMPANIES
                                    </Button>
                                    : <></>
                                }
                                {
                                    user != null && user?.attributes != null && user?.attributes['email'] == 'ska_su@hotmail.com' ?
                                    <Button type="primary" onClick={()=>{
                                            syncProducts();
                                        }
                                    }>
                                        SYNC PRODUCTS
                                    </Button>
                                    : <></>
                                }
                                {
                                    user != null && user?.attributes != null && user?.attributes['email'] == 'ska_su@hotmail.com' ?
                                    <Button type="primary" onClick={()=>{
                                            syncProductTags();
                                        }
                                    }>
                                        SYNC PRODUCT TAGS
                                    </Button>
                                    : <></>
                                }
                                
                            </Layout.Col>
            </Layout.Row>
            </div>
             {/*<Layout.Col span="20" offset="2">
                                <Card className="box-card warning-card">
                                    <Layout.Row className="other-color top-separator bottom-separator rigth-separator" type="flex" justify="start">
                                        <Layout.Col  tag="form-button-warning" span="24" offset="1" xs="10" sm="10" lg="10">
                                            <div class="white-text left-separator title-text">
                                                <b>{t('dasboard.welcome')}</b>
                                            </div>   
                                        </Layout.Col>
                                    </Layout.Row>
                                    <br/>
                                    <Layout.Row className="top-separator bottom-separator rigth-separator" type="flex" justify="space-between">
                                        <Layout.Col  tag="form-button-warning" span="24" offset="1" xs="10" sm="10" lg="10">
                                            <div class="white-text left-separator">
                                            {t('dasboard.title-sub-message1')}&nbsp;<b>{t('dasboard.title-sub-message2')}</b>&nbsp;{t('dasboard.title-sub-message3')}&nbsp;<b>{t('dasboard.title-sub-message4')}</b>
                                            </div>   
                                        </Layout.Col>
                                        <Layout.Col  tag="form-button-warning" span="24" offset="1" xs="10" sm="10" lg="10">
                                                <Button type="primary" onClick={()=>goToCompany()}>
                                                    {t('my-store.button-to-edit') t('dasboard.button-go') + " >"}
                                                </Button>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <br/>
                                </Card>    
                    </Layout.Col>*/} 

            {/*<Layout.Row gutter="20" justify="end" type="flex" >
                            <Layout.Col offset="1" lg="10">
                                &nbsp;
                            </Layout.Col>
            </Layout.Row> */}

            {
                
                    <Layout.Row type='flex'>
                        <Layout.Col sm="2" lg="2"></Layout.Col>
                        <Layout.Col sm="10" lg="8">
                                {
                                    companyPage == null
                                    ?
                                    <div class="db-stepbox-container" onClick={()=>history.push('/my-store-wizard/0')}>
                                        <div class="header-box">STEP 1</div>
                                        <div class="box-title"> CREATE YOUR MICROSITE</div>
                                        <div class="icon-box">GO</div>
                                    </div>
                                    :
                                    <div class="db-stepbox-container-done" onClick={()=>history.push(`/my-store-wizard/${companyPage.id}`)}>
                                        <div class="header-box">STEP 1</div>
                                        <div class="box-title"> EDIT YOUR MICROSITE</div>
                                        <div>
                                            <div class="check-circle">
                                            <div class="check">✓</div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                }
                        </Layout.Col>
                        <Layout.Col sm="10" lg="8">
                        {
                            numberProducts == 0 ?
                                <div class="db-stepbox-container" onClick={()=>history.push('/my-products')}>
                                    <div class="header-box">STEP 2</div>
                                    <div class="box-title">ADD A PRODUCT</div>
                                    <div class="icon-box">GO</div>
                                </div>
                            :
                                <div class="db-stepbox-container-done" onClick={()=>history.push('/my-products')}>
                                    <div class="header-box">STEP 2</div>
                                    <div class="box-title">EDIT A PRODUCT</div>
                                    <div>
                                        <div class="check-circle">
                                        <div class="check">✓</div>
                                        </div>
                                    </div>
                                </div>
                        }
                        
                        </Layout.Col>
                        {/*
                        <Layout.Col lg="6">
                        <div class="db-stepbox-container">
                                <div class="header-box">STEP 3</div>
                                <div class="box-title"> REQUEST VERIFICATION</div>
                                <div class="icon-box">GO</div>
                            </div>
                        </Layout.Col>*/}
                    </Layout.Row>
            }
            <hr className='hr-dashboard'/>

            <PortalNotifications notificationType={1} />

            <Layout.Row className="mt-3" gutter="20" type='flex'>
                    <Layout.Col span="4" offset="2">
                        <CardDashboard
                            title={t('dasboard.cards.title1')}
                            icon={<CartShoppingIcon width={30} />}
                            total={data.activeSales}
                            link="/orders"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
                    <Layout.Col span="4">
                        <CardDashboard
                            title={t('dasboard.cards.title2')}
                            icon={<CompletedIcon width={30}/>}
                            total={data.completedSales}
                            link="/orders"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
                    <Layout.Col span="4">
                        <CardDashboard
                            title={t('dasboard.cards.title3')}
                            icon={<BoxIcon width={25}/>}
                            total={data.products}
                            link="/my-products"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
                    <Layout.Col span="4">
                        <CardDashboard
                            title={t('dasboard.cards.title4')}
                            icon={<ChatIcon width={30}/>}
                            total={data.contacts}
                            link="/my-chats/0"
                            linkText={t('dasboard.cards.link')}
                            goToLink={setLinkReference}
                        />
                    </Layout.Col>
                    <Layout.Col span="4">
                        <CardDashboard
                            title={t('dasboard.cards.title5')}
                            icon={<UsersIcon width={30}/>}
                            total={12}
                            message={t('dasboard.cards.comment-premium')}
                            link="/premium"
                            linkText={t('dasboard.cards.link-premium')}
                            goToLink={setLinkReference}
                            premium={true}
                        />
                    </Layout.Col>
            </Layout.Row> 
            <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
            </Layout.Row>
           
            <Layout.Row gutter="20" >
                    <Layout.Col offset="2" span="20">
            </Layout.Col>
                        
            </Layout.Row>

                                
            <Layout.Row>
            <Layout.Col className="notifications-header" offset="2" span="20">
                        <Layout.Row>
                            <Layout.Col xs="12" sm="12" lg="12"><h3 id="user-guides">USER GUIDES</h3></Layout.Col>
                            <Layout.Col xs="12" sm="12" lg="12" className='text-right'></Layout.Col>
                        </Layout.Row>
            </Layout.Col>
            </Layout.Row>
            <Layout.Row>               
                <Layout.Col className="mb-2" offset="2" span="20">
                <Card>
                    <Layout.Row>
                        <Layout.Col xs="23" sm="23" lg="23">
                        <a href="https://files.bainub.com/static/Guia-Alta-Empresa-y-Productos.pdf" download target='_blank'>
                            <img className="PdfIcon" src={PdfIcon} alt="Descargar" /> 
                            Guía Paso a Paso para la Alta de tu Empresa y Productos en Bainub
                            </a>
                            <br />
                            <a href="https://files.bainub.com/static/Guia-Optimizacion-Anuncios.pdf" download target='_blank'>
                            <img className="PdfIcon" src={PdfIcon} alt="Descargar" /> 
                            Optimización de Anuncios en Bainub: GUÍA DE MEJORES PRÁCTICAS
                            </a>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row>
                    <Layout.Col xs="23" sm="23" lg="23">
                            <h3 id="demo-video">Videos</h3>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" lg="12">
                        <iframe width="420" height="340" src={t('https://www.youtube.com/embed/Fwn72yd9sDM?si=Kl_HLgx5ZInViOlQ')} title="Bainub" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="24" lg="12">
                        <iframe width="420" height="340" src={t('https://www.youtube.com/embed/nZYHpFxR4EQ?si=QM8t-j_gKMLXVjGC')} title="Bainub" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Layout.Col>
                    </Layout.Row>
                    </Card>
                </Layout.Col>           
            </Layout.Row>

            <Layout.Row>
            <Layout.Col className="notifications-header" offset="2" span="20">
                        <Layout.Row>
                            <Layout.Col xs="12" sm="12" lg="12"><h3 id="faq">FAQ</h3></Layout.Col>
                            <Layout.Col xs="12" sm="12" lg="12" className='text-right'></Layout.Col>
                        </Layout.Row>
            </Layout.Col>
            </Layout.Row>
            <Layout.Row>
                <Layout.Col className="mb-2" offset="2" span="20">
                    <Card>
                <HelpAndSupportComponent menu={props.menu} />
                    </Card>
                </Layout.Col>
            </Layout.Row>
{/* 
            <Layout.Row>
            <Layout.Col className="notifications-header mt-4" offset="2" span="20">
                        <Layout.Row>
                            <Layout.Col xs="12" sm="12" lg="12"><h3>FAQ</h3></Layout.Col>
                            <Layout.Col xs="12" sm="12" lg="12" className='text-right'></Layout.Col>
                        </Layout.Row>
            </Layout.Col>
            </Layout.Row>


            <Layout.Row gutter="20" type='flex'>
                    <Layout.Col span="9" offset="2">
                        <iframe width="420" height="240" src={t('dasboard.video-url')} title="Bainub" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Layout.Col>
                    <Layout.Col span="11">
                        <Layout.Row>
                            <Layout.Col className='dash-test-list clean-text'>
                                <span> <b>{t('dasboard.text1')}</b> </span>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row>
                            <Layout.Col className='dash-test-list clean-text'>
                                <span> {t('dasboard.text2-1')}
                                       <b>{t('dasboard.text2-2')}</b>
                                       {t('dasboard.text2-3')} 
                                </span>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row>
                            <Layout.Col className='dash-test-list card-dash-link'>
                                <Link to="#">
                                    {t('dasboard.text3')}
                                </Link>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row>
                            <Layout.Col className='dash-test-list card-dash-link'>
                                <Link to="#">
                                    {t('dasboard.text4')}
                                </Link>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row>
                            <Layout.Col className='dash-test-list card-dash-link'>
                                <Link to="#">
                                    {t('dasboard.text5')}
                                </Link>
                            </Layout.Col>
                        </Layout.Row>
                    </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
            </Layout.Row>
*/}

        </div>
    )
}

export default Dashboard;