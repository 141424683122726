import React from "react";
import { Layout } from "element-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import welcomeBannerImg from "../assets/imgs/fondo01.jpg";
import computerDemoImg from "../assets/imgs/hero-img.png";
import bainubLogo from "../assets/imgs/bainub_logo_white.png";
import Survey from "../pages/Survey";
import HomeSwiper from "./HomeSwiper";

export default function WelcomeBanner() {
  const { t } = useTranslation();
  return (
    <Layout.Row>
      <Layout.Col xs="24" sm="24" md="16" lg="16" className="login-card-image-col" style={{ backgroundImage: `url(${welcomeBannerImg})` }}>
        <Layout.Row className="welcome-banner-card-content">
          <Layout.Col xs="24" sm="24" md="11" lg="11">
          <img src={bainubLogo} className="logo hidden-mobile" alt="Bainub" />
                <h1>Marketplace<br></br>B2B</h1>
          </Layout.Col>
          <Layout.Col xs="24" sm="24" md="13" lg="13">
          <img src={computerDemoImg} alt="Bainub" />
          </Layout.Col>
        </Layout.Row>
        <Layout.Row className="welcome-banner-card-content">
        <Layout.Col xs="24" sm="24" md="20" lg="20">
        <Layout.Row>
          <Layout.Col  xs="24" sm="24" md="24" lg="24">
          <div class="circle"></div>
          <h2 className="ml-2">BUY, SELL AND CONNECT </h2>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row>
                <p>
                  Simplify your sourcing process with our B2B Marketplace.
                  Bainub is an online platform accessible anywhere where you can meet suppliers
                  and potential customers from Mexico, USA and Canada. Experience the benefits of the Nearshoring model and expand
                  your business opportunities connecting with potential buyers and suppliers in North America. {" "}
                </p>
        </Layout.Row>
          </Layout.Col>
          <Layout.Col xs="24" sm="24" md="4" lg="4">
          </Layout.Col>
          <Layout.Row className="pb-1">
          <Layout.Col xs="24" sm="24" md="16" lg="16">
            <Layout.Row>
              <Layout.Col xs="24" sm="24" md="12" lg="12" className="pt-1">
              <Link to="/register" className="el-button el-button--primary primary-button no-margin"> <b>{t("home-page.banner.create-account-button")}</b></Link>
              </Layout.Col>
              <Layout.Col xs="24" sm="24" md="12" lg="12" className="pt-1">
              <a href="https://www.bainub.com/b2b-trading-platform" className="white-anchor">How does it work?</a>
              </Layout.Col>
            </Layout.Row>
         
           </Layout.Col>
           <Layout.Col xs="24" sm="24" md="8" lg="8">
           </Layout.Col>
          </Layout.Row>
        </Layout.Row>
      </Layout.Col>


      <Layout.Col xs="24" sm="24" md="8" lg="8" className="pt-2" >
       <Survey surveyTitle={t('home-page.product-survey.form-title')} formType={1} />
      </Layout.Col>
    </Layout.Row>
  );
}
