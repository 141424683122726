import { Layout, Button, Card, Checkbox, Input} from 'element-react';
import { useTranslation } from 'react-i18next';
import { S3Image } from "../components/S3Image";
import { useHistory } from "react-router-dom";
import {yearsFromToday, yearFromDate} from '../utils/FormatUtils';
import aboutUsImg from '../assets/imgs/about-us-default.png';
import companyImg from '../assets/imgs/my-company-default.png';
import ProductBox from '../components/ProductBox';
import {IoIosChatbubbles} from "react-icons/io";
import {countryOptions} from '../utils/Catalogs';
// Import Swiper React components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


const MyPage = (props) =>{
    const history = useHistory();
    const { t } = useTranslation();
    const toAddProduct = () =>{
        history.push('/add-product-wizard/0');
    }
    const themeConfig = {
        photoImg: { maxWidth: "650px", maxHeight: "335px", minHeight: "320px" }
    }
    return(
        <div>
            <Layout.Row gutter="20">
                <Layout.Col offset="1" span="20">
                    &nbsp;
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="22">
                <Layout.Col offset="1" span="22">
                        <div className="card-mypage">
                                <Layout.Row type="flex" justify="center">
                                    <Layout.Col span="18">
                                        <div className="card-container">
                                            {
                                                props.companyPage.cover_picture
                                                ?
                                                    <S3Image
                                                    imgKey={props.companyPage.cover_picture.key}
                                                    theme={themeConfig}
                                                    level="public"
                                                    />
                                                :
                                                    <img alt='img' src={companyImg} style={{ maxWidth: "650px", maxHeight: "335px", minHeight: "235px" }}/>
                                            }
                                            
                                            <div className="company-title">
                                                {props.companyPage.name}
                                            </div>
                                            {props.owner === true 
                                                ?
                                                    <div className="company-title-img-btn">
                                                            <div className="text-center">
                                                                <Button type="primary" onClick={()=> props.editCompanyImage('cover_picture')}>
                                                                    {t('my-store.page.button-change-img')}
                                                                </Button>
                                                            </div>
                                                    </div>
                                                :
                                                    <></>
                                            }
                                        </div>
                                        
                                    </Layout.Col>
                                    <Layout.Col span="6" >
                                            <Layout.Row type="flex">
                                                <Layout.Col>
                                                    <h3>{t('my-store.page.type-title')}</h3>        
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageText">
                                                    {t('my-store.page.name')} :
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageTextSub">
                                                    <b>{props.companyPage.name}</b>
                                                    {
                                                        
                                                        props.companyPage.verified == 1
                                                        ? 
                                                        //TODO insertar Icono de verificado
                                                            <span className="result-verified">&nbsp;&nbsp;Verified &nbsp;&nbsp;</span>
                                                        : 
                                                            ""
                                                    }
                                                    {
                                                        
                                                        props.companyPage.supplier_rating 
                                                        ? 
                                                        //TODO insertar Icono de supplier_rating
                                                            <span className="result-supplier-rating">{props.companyPage.supplier_rating} </span>
                                                        : 
                                                            ""
                                                    }
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageText">
                                                    {t('my-store.page.type')} :
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageTextSub">
                                                <b>{props.companyPage.type}</b>
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageText">
                                                    {t('my-store.page.member-since')} :
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageTextSub">
                                                <b>{props.companyPage ? yearFromDate(props.companyPage.createdAt): ""}</b>
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageText">
                                                    {t('my-store.page.location')} :
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageTextSub">
                                                <b>{props.companyPage.region}, {
                                                    props.ownerData
                                                    ?
                                                        countryOptions.options.find(country => country.value === props.ownerData?.country)?.label
                                                    :
                                                    ""
                                                }</b>
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col>
                                                    &nbsp;
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex" justify="center">
                                                <Layout.Col tag="form-button">
                                                    <div className="text-center my-page-chat-btn">
                                                        <Button type="primary" onClick={props.handleChatWithUs}>
                                                            <IoIosChatbubbles className="p-r-xxs"/>{t('my-store.page.button-contact')}
                                                        </Button>
                                                    </div>
                                                </Layout.Col>
                                            </Layout.Row>
                                    </Layout.Col>
                                </Layout.Row>
                        </div>               
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                
                <Layout.Row gutter="22">
                    <Layout.Col offset="1" span="22">
                        <Card className="box-card"
                            header={
                                <h3>
                                    {t('my-store.page.featured-products')}
                                </h3>
                            }
                        >

                                <Layout.Row gutter="22" className="m-t-md">
                                    <Layout.Col span="24">
                                    <div className="demo-3 medium">

                                        <Swiper 
                                            slidesPerView={4} spaceBetween={10} slidesPerGroup={1} 
                                            loop={true}
                                            //loopFillGroupWithBlank={true} 
                                            pagination={{
                                                "clickable": true,
                                                "bulletActiveClass": 'dots-pagination'
                                            }} 
                                            navigation={true}
                                            className="mySwiper"
                                            centeredSlides="true"
                                            centeredSlidesBounds="true"
                                            preventClicks="true"
                                            //slidesOffsetAfter={30}
                                            //slidesOffsetBefore={30}
                                            //width="950"
                                        >
                                            {
                                            props.featuresProduct && props.featuresProduct.map((item, index) => {
                                                return (
                                                <SwiperSlide key={index} className="m-l-none m-r-none p-l-none p-r-none swiper-item">
                                                    <ProductBox 
                                                        minPrice={item.min_price} 
                                                        maxPrice={item.max_price} 
                                                        img={item.photo && item.photo.length > 0 ? item.photo[0].key : ""} 
                                                        name={item.name}
                                                        companyName={props.companyPage.name}
                                                        id={item.id}
                                                    />
                                                </SwiperSlide>
                                                )
                                            })
                                            }
                                        </Swiper>
                                        </div>
                                    </Layout.Col>
                                </Layout.Row>
                               
                                <Layout.Row type="flex" justify="center" align="align" className="m-t-lg m-b-lg">
                                    <Layout.Col tag="form-button" span="24" offset="1" xs="20" sm="20" lg="20">
                                        <div className="center text-center">
                                        {    props.owner === true ?
                                                <Button className="btn-width-200" onClick={toAddProduct} type="primary">
                                                {t('my-store.page.button-add-product')}
                                                </Button>
                                            : <></>
                                        }    
                                        </div>
                                    </Layout.Col>
                                </Layout.Row>
                        </Card>                    
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="20">
                        <h2 className="company-page">
                            {t('my-store.page.who-are-we')}
                        </h2>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="22">
                <Layout.Col offset="1" span="22">
                        <Card className="box-card card-whoarewe">
                        <div class="special-container">
                            {
                                    props.companyPage && props.companyPage.company_logo
                                    ?
                                        <S3Image
                                            className="special-container__image"
                                            imgKey={props.companyPage.company_logo.key}
                                            theme={{
                                                photoImg: { maxWidth: "40vw" }
                                            }}
                                            level="public"
                                        />
                                    :
                                        <img alt='img' className="special-container__image" src={aboutUsImg} style={{ maxWidth: "40vw" }}/>
                            }
                            {props.owner === true 
                                                ?
                                                    <div className="company-title-img-btn2">
                                                            <div className="text-center">
                                                                <Button type="primary" onClick={()=> props.editCompanyImage('company_logo')}>
                                                                    {t('my-store.page.button-change-img')}
                                                                </Button>
                                                            </div>
                                                    </div>
                                                :
                                                    <></>
                            }
                        <div class="special-container__text">
                            <span className='description'>{props.companyPage? props.companyPage.description : ""}</span>
                        </div>
                        </div>
                                
                                {
/*
                                <Layout.Row type="flex" justify="center">
                                    <Layout.Col span="12">
                                        {
                                            props.companyPage.company_logo
                                            ?
                                                <S3Image
                                                    imgKey={props.companyPage.company_logo.key}
                                                    theme={{
                                                        photoImg: { maxWidth: "550px", maxHeight: "200px" }
                                                    }}
                                                    level="public"
                                                />
                                            :
                                                <div>IMG Default</div>
                                        }
                                        {props.owner === true 
                                                ?
                                                    <div className="company-title-img-btn2">
                                                            <div className="text-center">
                                                                <Button type="primary" onClick={()=> props.editCompanyImage('company_logo')}>
                                                                    {t('my-store.page.button-change-img')}
                                                                </Button>
                                                            </div>
                                                    </div>
                                                :
                                                    <></>
                                        }
                                    </Layout.Col>
                                    <Layout.Col span="12" >
                                            <Layout.Row type="flex">
                                                <Layout.Col>
                                                    &nbsp;
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col tag="cardPageTextSub">
                                                    <span className='description'> {props.owner === true ? <EditIcon handleClick={()=> props.editCompanyText('description')} width="25" height="25"/> : <></>} {props.companyPage.description}</span>
                                                </Layout.Col>
                                            </Layout.Row>
                                            <Layout.Row type="flex">
                                                <Layout.Col>
                                                    &nbsp;
                                                </Layout.Col>
                                            </Layout.Row>
                                            
                                    </Layout.Col>
                                </Layout.Row>
*/
                                }
                                
                        </Card>               
                    </Layout.Col>
                </Layout.Row>
                
                <Layout.Row type="flex" className="m-t-lg">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                        <Card className="box-card"
                            header={
                                <h3>
                                    {t('my-store.page.additional-info')}
                                </h3>
                            }
                        >
                        
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    <p className="detail-sub-title">{t('my-store.page.business-type')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    <p className="detail-sub-title">{t('my-store.page.member-since')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    <p className="detail-sub-title">{t('my-store.page.year-stablished')}</p>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    {props.companyPage.type}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    {yearsFromToday(props.companyPage.createdAt)} years
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    {props.companyPage.age}
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    <p className="detail-sub-title">{t('my-store.page.city')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    <p className="detail-sub-title">{t('my-store.page.annual-revenue')}</p>
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    <p className="detail-sub-title">{t('my-store.page.allow-custom-prd')}</p>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    {props.companyPage.region}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    {props.companyPage.anual_revenue}
                                </Layout.Col>
                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                    {props.companyPage.allow_custom_product == 1 ? 'Yes' : 'No'}
                                </Layout.Col>
                                
                            </Layout.Row>
                        </Card>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row type="flex" className="m-t-lg">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                    <Card className="box-card"
                    
                        header={
                            <h3 className="company-page">
                            {t('my-store.page.capacity')}
                            </h3>
                        }
                    >
                        <Layout.Row type="flex" justify="center">
                            <Layout.Col span="7" >
                                    <Layout.Row type="flex" justify="center">
                                        <Layout.Col tag="cardPageText">
                                            {t('my-store.page.employees_qty')}
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex">
                                        <Layout.Col tag="cardPageTextSub">
                                            {props.companyPage.employees_qty}
                                        </Layout.Col>
                                    </Layout.Row>
                            </Layout.Col>
                            <Layout.Col span="7" >
                                    <Layout.Row type="flex" justify="center">
                                        <Layout.Col tag="cardPageText">
                                            {t('my-store.page.main-market')}
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex">
                                        <Layout.Col tag="cardPageTextSub">
                                            {props.companyPage.main_market}
                                        </Layout.Col>
                                    </Layout.Row>
                            </Layout.Col>
                            <Layout.Col span="7" >
                                    <Layout.Row type="flex" justify="center">
                                        <Layout.Col tag="cardPageText">
                                            {t('my-store.page.production_lines_qty')}
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex">
                                        <Layout.Col tag="cardPageTextSub">
                                            {props.companyPage.production_lines_qty}
                                        </Layout.Col>
                                    </Layout.Row>
                            </Layout.Col>
                        </Layout.Row>
                        <br/>
                        <Layout.Row type="flex" >
                            <Layout.Col tag="cardPageText" offset="1" span="7">
                                {t('my-store.page.titles')}    
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row type="flex" >
                            <Layout.Col offset="1" span="7">
                                {props.companyPage.titles}
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row type="flex" >
                            <Layout.Col offset="1" span="7">
                                {props.companyPage.additional_titles1}
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row type="flex" >
                            <Layout.Col offset="1" span="7">
                                {props.companyPage.additional_titles2}
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row type="flex" >
                            <Layout.Col offset="1" span="7">
                                {props.companyPage.additional_titles3}
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row type="flex" >
                            <Layout.Col offset="1" span="7">
                                {props.companyPage.additional_titles4}
                            </Layout.Col>
                        </Layout.Row>

                        
                    </Card>
                
                </Layout.Col>
                </Layout.Row>

                <Layout.Row type="flex" className="m-t-lg m-b-lg">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="23">
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="22">
                                    <h3 className="company-page">{t('product.create.preview.contact-us')}</h3>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="21">
                                    <p className="pp-line-height">{t('product.create.preview.contact-us-inquiry')}</p>
                                </Layout.Col>
                            </Layout.Row>
                                
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Input
                                        type="textarea"
                                        value={props.questionInput}
                                        onChange={(v)=>{props.setQuestionInput != undefined ? props.setQuestionInput(v) : console.log(v)}}
                                        autosize={{ minRows: 8, maxRows: 8}}
                                    />
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex" >
                                <Layout.Col span="24" xs="20" sm="20" lg="21">
                                    <Checkbox 
                                        checked={props.checkStatus} 
                                        onChange={(v)=>{props && props.setCheckStatus ? props.setCheckStatus(v) : console.log(v)}}
                                    
                                    >{t('product.create.preview.contact-us-permission')}</Checkbox>
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Layout.Row type="flex" justify="end">
                                        <Layout.Col tag="form-button">
                                        
                                            <Button onClick={props.handleChatInputMessage} type="primary">
                                                    {t('product.create.preview.button-send')}
                                            </Button>
                                        
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                            </Layout.Row>
                    </Layout.Col>
                </Layout.Row>
                
        </div>
    )
}

export default MyPage;