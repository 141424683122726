import React from 'react';
const PaymentIcon = (props) => {
  return (
      <svg onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.coords ? props.coords : "0 0 41 41"}>
      <g id="Group_1387" data-name="Group 1387" transform="translate(-371.336 -3364.334)">
        <g id="Ellipse_58" data-name="Ellipse 58" transform="translate(371.336 3364.334)" fill="#fff" stroke="#929498" stroke-width="2">
          <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
          <circle cx="20.5" cy="20.5" r="19.5" fill="none"/>
        </g>
        <path id="hand-holding-dollar-solid" d="M22.957,13.588a1.612,1.612,0,0,0-2.26-.342l-4.836,3.562H10.989a.621.621,0,0,1-.61-.646.645.645,0,0,1,.61-.646H14.15A1.374,1.374,0,0,0,15.5,14.44a1.3,1.3,0,0,0-1.278-1.51h-6.5A4.831,4.831,0,0,0,4.726,13.99L2.848,15.515.61,15.479a.681.681,0,0,0-.61.683v3.878a.644.644,0,0,0,.61.647H14.594a4.234,4.234,0,0,0,2.493-.819l5.459-4.021A1.571,1.571,0,0,0,22.957,13.588ZM11.285,7.071a6.131,6.131,0,0,1-1.067-.319l-.2-.069a.95.95,0,0,0-1.236.554.986.986,0,0,0,.589,1.238l.188.067a9.99,9.99,0,0,0,1.107.336v.5a.969.969,0,1,0,1.939,0v-.42a2.212,2.212,0,0,0,1.863-1.847c.351-2.022-1.571-2.572-2.6-2.867l-.214-.074c-1.063-.3-1-.432-.974-.6.047-.273.618-.406,1.3-.3a4.868,4.868,0,0,1,.794.219.97.97,0,0,0,.634-1.833,7.685,7.685,0,0,0-.8-.231V.97a.969.969,0,1,0-1.939,0v.425A2.379,2.379,0,0,0,8.731,3.238c-.311,2,1.64,2.572,2.376,2.791l.263.076c1.279.366,1.259.48,1.226.672C12.549,7.05,11.98,7.184,11.285,7.071Z" transform="translate(380.202 3373.915)" fill={props.color ? props.color : "#929498"}/>
      </g>
    </svg>
  )
}

export default PaymentIcon;