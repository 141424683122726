import create from 'zustand'
//import { persist } from 'zustand/middleware'
const [userStore] = create(set => ({
  user: {},
  setUserInfo: (userData, userDetail) => {
    console.log('En el store');
    console.log({userData});
    console.log({userDetail});
    set({ user: {
      name : userData.attributes.email,
      status: userData.attributes.email_verified, 
      type : userDetail.user_type === 'S' ? userDetail.type : userDetail.user_type, 
      originalType : userDetail.user_type, 
      country : userDetail.country,
      firstName : userDetail.first_name,
      phoneNumber: userDetail.phone_number,
      pendingSetupBusinessPreferences: userDetail.pendingSetupBusinessPreferences,
      cognitoId: userDetail.cognito_id,
      id: userDetail.id,
      createdAt: userDetail.createdAt,
      fullName: userDetail.first_name + " " + userDetail.last_name
    }});
  },
  changeUserType: (user, newType) =>{
    console.log("changing userType...")
    const userAux = {
      ...user,
      type: newType
    }
    console.log(userAux)
    set({ user: userAux});
  },
  setPendingSetupBusinessPreferences: (user, pendingSetupBusinessPreferences) => {
    console.log("updating pending setup business preferences ...")
    const userAux = {
      ...user,
      pendingSetupBusinessPreferences: pendingSetupBusinessPreferences
    }
    console.log(userAux)
    set({ user: userAux});
  },
  cleanUser : () => set({user : {}})
}))

export default userStore;