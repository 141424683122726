import { Layout, Menu } from 'element-react';
import { useTranslation } from 'react-i18next';
import categoryStore from '../store/categories';
import {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import filterSearchStore from '../store/filter-search';
const LeftMenu = () =>{
    let history = useHistory();
    const {t} = useTranslation();
    const {categories, setCategories}  = categoryStore();
    const {setFilterInfo} = filterSearchStore();
    const onOpen =(menu)=> {
        console.log('open :: ' + menu);
    }
    const onSelect =(index)=> {
        console.log('selected :: ' + index);
        setFilterInfo( 25,  0,  10000000,  false,  false,  false);
        setTimeout(function(){
            history.push(`/search-result/$EMPTY$/${index}?query=$EMPTY$`);    
        },500);
    }

    useEffect(() => {
        /*
        TODO VALIDAR EL COMPORTAMIENTO DEL CARGO DE CATEGORIOS...
        console.log('useEffect leftMenu :: ' + categories.length);
        if(categories.length === 0){
            console.log('loading categories');
            setCategories();
            console.log({categories});
        }*/
    },[])

   
    return(
        <Layout.Row className="tac main-left-menu">
            <Layout.Col span={24}>
                <div className="sidenav">
                    <Menu defaultActive="1" className="el-menu-vertical-demo" onOpen={onOpen.bind(this)} onSelect={(index) =>{onSelect(index)}}>
                        <Menu.Item index="all"><b>{t('header.categories')}</b></Menu.Item>
                        <Menu.Item index="all">{t('header.all')}</Menu.Item>
                        {
                                categories.map(group => {
                                    return(
                                        <Menu.SubMenu index={group.label} key={group.label} title={<span className='menu1-title menu2-title'>{group.label}</span>}>
                                            {
                                                group.options.map(el => {
                                                    return (
                                                        <Menu.Item className='menu1-title' index={el.value}>{el.label}</Menu.Item>
                                                    )
                                                })
                                            }
                                        </Menu.SubMenu>
                                        
                                    )
                                })
                            }

                    </Menu>
                </div>
            </Layout.Col>
        </Layout.Row>
    )
}

export default LeftMenu;