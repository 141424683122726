import create from 'zustand'
import { persist } from 'zustand/middleware'
//@ts-ignore
const [mainSearchStore] = create(persist(set => ({
  mainInfo: { selectIndex : "", searchQuery : ""},
  setMainInfo: (selectIndexData, searchQueryData) => {
    set({ mainInfo: {selectIndex : selectIndexData, searchQuery: searchQueryData}});
  },
  clearMainInfo : () => set({mainInfo : { selectIndex : "", searchQuery : ""}})
})))

export default mainSearchStore;