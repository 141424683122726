import create from 'zustand'
import { persist } from 'zustand/middleware'
//@ts-ignore
const [filterSearchStore] = create(persist(set => ({
  filterInfo: { pageSize : 25, minPrice: 0, maxPrice : 10000000, customAllowed: false, warranty: false, minBuy: false},
  setFilterInfo: (pageSize, minPrice, maxPrice, customAllowed, warranty, minBuy) => {
    set({ filterInfo: {pageSize, minPrice, maxPrice , customAllowed, warranty, minBuy}});
  },
  clearFilterInfo : () => set({filterInfo : { pageSize : 25, minPrice: 0, maxPrice : 10000000, customAllowed: false, warranty: false, minBuy: false}})
})))

export default filterSearchStore;