import { API } from 'aws-amplify'

async function addUnsuscribeEmail ({ email }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/unsuscribe/add', {
            body: {
                email
            }
        })
        console.log(result)
        return result;
    } catch(error) {
        console.error(error)
    }
}

async function deleteUnsuscribeEmail ({ email }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/unsuscribe/delete', {
            body: {
                email
            }
        })
        console.log(result)
        return result;
    } catch(error) {
        console.error(error)
    }
}

async function isEmailUnsuscribed ({ email }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/unsuscribe/isEmail', {
            body: {
                email
            }
        })
        console.log(result)
        return result;
    } catch(error) {
        console.error(error)
    }
}

export  { addUnsuscribeEmail, deleteUnsuscribeEmail, isEmailUnsuscribed };