import React, { Component } from "react";
var __html = require('./html1.html.js');
var template = { __html: __html };

function Cookie () {
    return (
      <div style={{"height" : "3000px"}}>
        <span dangerouslySetInnerHTML={template} />
      </div>
    )
}
export default Cookie;
