import React, { useState, useEffect } from 'react';
//import { Storage } from 'aws-amplify';

function S3Image (props) {
    const { imgKey, level, theme, onClick, className } = props;
    //const [imgSrc, setImgSrc] = useState(null);

    let _imgKey = encodeURIComponent(imgKey);
    console.log('imgKey: ', _imgKey);

    useEffect(() => {
        
    }, []);

    return (
        <img src={'https://images.bainub.com/public/'+_imgKey} alt={imgKey} onClick={onClick} className={className} style={theme.photoImg} />
    );
}

export { S3Image } ;