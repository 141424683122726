import { Layout, Loading, Pagination, Message } from 'element-react';
import {useHistory} from "react-router-dom";
import mainSearchStore from '../store/main-search';
import {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import {listUsers, listProducts, listProductsOrderByMinPrice, listUserFavoritess} from '../graphql/queries';
import {API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import ResultCard from '../components/ResultCard';
import { useTranslation } from 'react-i18next';
import {engPropositions, spaPropositions} from '../utils/Prepositions';
import FilterHeader from '../components/FilterHeader';
import filterSearchStore from '../store/filter-search';
import { Auth } from 'aws-amplify';
import {createUserFavorites, deleteUserFavorites} from '../graphql/mutations';
import userStore from '../store/user';
import {generateProductChat} from '../services/ChatService';
import {CHAT_PRODUCT_MESSAGE} from '../utils/ChatConstants';
import PagerQuery from '../utils/PagerQuery';
import Survey from "./Survey";
import surveyInfo from "../store/surveyStatus";
import ProductBanner from "../components/ProductBanner";
import SearchSubCategoryDescription from "../components/SearchSubCategoryDescription";
import {SEARCH_URL} from '../utils/Constants';

const listProductsCOUNTER = `
query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }

`;
let tokenArray = [];
function SearchResultNG(){
    let history = useHistory();
    const { t } = useTranslation();
    const {filterInfo, setFilterInfo} = filterSearchStore();
    const {mainInfo} = mainSearchStore();
    let { query , tag} = useParams();
    const [favorites, setFavorites] = useState([]);
    const [loading , setLoading] = useState(false); 
    const [productsState, setProductsState ] = useState();
    const [total, setTotal] = useState(0);
    const [filterCerts, setFilterCerts] = useState("");
    const {user} = userStore();
    const [page, setPage] = useState(1);
    //const [size, setSize] = useState(5);
    const [sortBy, setSortBy] = useState('')
    const [viewModalUser, setViewModalUser] = useState(false);
    const {surveyStatus, sessionSurveyStatus} = surveyInfo();
    const [surveyVisible, setSurveyVisible] = useState(false);
    const [viewSubCategoryDescription, setViewSubCategoryDescription] = useState(false);
    const [titleAndDescription, setTitleAndDescription] = useState({title: '', description: ''});

    useEffect(() => {
        console.log('Searching for...');
        console.log({mainInfo});
        //let _array = [];
        //setTokenArray(_array);
        tokenArray = [];
        setPage(1);
        console.log('query ::: ' + query);    
        getActiveProducts(0);
        searchUserFavorites();
        
        return function cleanup() {
            console.log('============ cleanup ============');
            //setFilterInfo( 25,  0,  10000000,  false,  false,  false);
            //clearFilterInfo();
        };
    }, [query , tag, sortBy, 
        filterInfo.minPrice, filterInfo.pageSize, filterInfo.maxPrice, 
        filterInfo.customAllowed, filterInfo.minBuy, filterInfo.warranty,
        filterCerts
    ]);


    const searchUserFavorites = async () =>{
        try{
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
            let filter = {
                owner: {eq: u.username}
            };
            const result = await API.graphql({ query: listUserFavoritess, variables: {filter}});
            console.log({result});
            setFavorites(result.data.listUserFavoritess.items);
        }catch(e){
            console.error(e);
        }
        
    }

    const addToFavorites = async (productId) =>{
        console.log('addToFavorites');
        try{
            
                setLoading(true);
                //setFavoriteLoading(true);
                const u = await Auth.currentAuthenticatedUser();
                console.log({u});
                console.log('Adding to favorites product :: ' + productId);
                const input = {
                    userFavoritesProductId : productId
                }
                const result = await API.graphql(
                    graphqlOperation(createUserFavorites, { input })
                );
                console.log(result);
                //setFavorite(true);
                //@ts-ignore
                let favs = favorites;
                favs.push(result.data.createUserFavorites);
                console.log("favs :: ", {favs});
                setFavorites(favs);
                //setFavoriteId(result.data.createUserFavorites.id);
                setLoading(false);
                
            
        }catch(e){
            console.error(e);
            setLoading(false);
            if(e === 'The user is not authenticated'){
                console.log('Alert to user');
                /*Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });*/
                setViewModalUser(true);
            }
        }
        
    }

    const removeFromFavorites = async(fav) =>{
        console.log('removeFromFavorites');
        console.log({fav});
        try{
            setLoading(true);
            const input = {
                id : fav.id
            }
            const result = await API.graphql(
                graphqlOperation(deleteUserFavorites, { input })
            );
            console.log(result);
             
            let favs = favorites.filter(function( obj ) {
                return obj.id !== fav.id;
            });
            console.log("favs :: ", {favs});
            setFavorites(favs);
            setLoading(false);
            
        }catch(e){
            console.error(e);
            setLoading(false);
        }
    }

    const getActiveProducts = async(_page) =>{
        setLoading(true);
        try{

            const response = await fetch(SEARCH_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'Authorization': token
                },
                body: JSON.stringify(
                    {
                    sortBy: sortBy,
                    filterInfo: filterInfo,
                    filterCerts: filterCerts,
                    searchQuery: query,
                })
            });
            const data = await response.json();
            console.log(data);
            setTotal(data.length);
            //pagination, slice data to show
            const _array = data.slice(_page * filterInfo.pageSize, (_page * filterInfo.pageSize) + filterInfo.pageSize);
            setProductsState(_array);
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
            setProductsState();
        }
        
    }

    const changePage = (currentPage) =>{
        console.log(currentPage);
        setPage(currentPage);
        getActiveProducts(currentPage-1);
    }

    const changeTableSize = (newSize) =>{
        //setSize(newSize);
        tokenArray = [];
        setPage(1);
        setFilterInfo(newSize, filterInfo.minPrice, filterInfo.maxPrice, filterInfo.customAllowed, filterInfo.warranty, filterInfo.minBuy);
        //setTimeout(function(){
        //    getActiveProducts(0);
        //}, 1000);
        
    }

    const handleChatWithUs = async(productOwner, productPage) =>{
        console.log('handletChatWithUs');
        setLoading(true);
        try{
            const u = await Auth.currentAuthenticatedUser();
            //console.log({user})
            const filter = {
                cognito_id : {eq : productOwner}
            }
            console.log({filter});
            //const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});
            const pager = new PagerQuery(listUsers, { filter }, Object.keys({ listUsers }));
            const resultCompanyUser = await pager.searchPrivateData();

            console.log({resultCompanyUser});
            const chatResult  = await generateProductChat(CHAT_PRODUCT_MESSAGE, productPage, t, user, resultCompanyUser.data.listUsers.items[0]);
            if(chatResult === true){
                history.push("/my-chats/1");
            }
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
            if(e === 'The user is not authenticated'){
                console.log('Alert to user');
                /*Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });*/
                setViewModalUser(true);
            }
        }
        
    } 

    if(loading)
        return  <Loading fullscreen={loading} />;
    if(total === 0)
        return <>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                    <FilterHeader filterCerts={filterCerts} setFilterCerts={setFilterCerts} sortBy={sortBy} setSortBy={setSortBy} t={t}/>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row className="mt-5" >
                <Layout.Col offset="1"  xs="15" sm="15" md="15" lg="15">
                    <Survey surveyTitle={t('product.search-result.no-results-title')} />
                </Layout.Col>
            </Layout.Row>
        </>;
    return(
        <>
            {
                viewSubCategoryDescription 
                ? 
                <Layout.Row style={{marginTop : '10px'}} type="flex" span="24">
                    <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                        <SearchSubCategoryDescription title={titleAndDescription.title} description={titleAndDescription.description}/> 
                    </Layout.Col>
                </Layout.Row>
                : <></>
            }
            <ProductBanner setViewModalUser={setViewModalUser} modalVisible={viewModalUser} title={''}/>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                    <FilterHeader filterCerts={filterCerts} setFilterCerts={setFilterCerts} sortBy={sortBy} setSortBy={setSortBy} t={t}/>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                {
                    productsState && productsState.map((element, i) =>{
                        return( 
                            <>                              
                                <ResultCard handleChatWithUs={handleChatWithUs} removeFromFavorites={removeFromFavorites} addToFavorites={addToFavorites} favorites={favorites} t={t} key={element.id} product={element} />
                                <br/>
                            </>
                        )
                    })
                }
                </Layout.Col>
            </Layout.Row>

            <Layout.Row type="flex" span="24">
                <Layout.Col style={{textAlign : 'center'}}  xs="21" sm="22" md="22" lg="22">
                    {
                        
                        <Pagination 
                        layout="sizes, prev, pager, next" 
                        total={total} 
                        pageSizes={[5, 25, 50, 100]}
                        onCurrentChange={changePage}
                        onSizeChange={changeTableSize}
                        currentPage={page}
                        pageSize={filterInfo.pageSize}/>
                        
                    }
                </Layout.Col> 
            </Layout.Row>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                    {
                    //filterInfo.pageSize
                    }
                </Layout.Col>
            </Layout.Row>
        </>
    );
}

export default SearchResultNG;