// ZohoWidget.js
import React from 'react';
import { Helmet } from 'react-helmet';

const ZohoWidget = () => {
  return (
    <Helmet>
      {/* Include Zoho SalesIQ script in the head */}
      
      <script type="text/javascript">
      {
        `
        `
      }
    

</script>
    </Helmet>
  );
};

export default ZohoWidget;
