import create from 'zustand'
import {persist} from 'zustand/middleware'
//@ts-ignore
const [surveyInfo] = create(persist(set => ({
        surveyStatus: false,
        sessionSurveyStatus: false,
        setSurveyStatus: (newStatus) => {
            console.log('setting survey status to :: ' + newStatus);
            set({
                surveyStatus: newStatus,
            });
        },
        setSessionSurveyStatus: (newStatus) => {
            console.log('setting session survey to :: ' + newStatus);
            set({
                sessionSurveyStatus: newStatus,
            });
        }
    })
    , {
        name: "surveyStatusStore",
        getStorage: () => sessionStorage // (optional) by default the 'localStorage' is used
    }))

export default surveyInfo;