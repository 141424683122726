import React, {useEffect} from 'react'
import * as surveyController from "../graphql/surveyLambdaInvokes";
import sendEmailTemplate from '../graphql/lambdaEmailCleanInvokes';
import * as Constans from '../../src/utils/Constants';
import { useLocation, useHistory } from "react-router-dom";

function EmailForm(){
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    let history = useHistory();
    useEffect(() => {
        console.log("useEffect");
        callEmail();
    }, []);
    async function callEmail(){
        const name = params.get("name");
        const email = params.get("email");
        const products = params.get("products");
        const phone = params.get("phone");
        const plan = params.get("plan");
        console.log("name", name);
        console.log("email", email);
        console.log("products", products);
        console.log("phone", phone);
        await sendEmailTemplate({
            templateName: "form", 
            subject: 'FORMULARIO LANDING',
            emailFrom: Constans.FROM_EMAIL,
            recipients: [ "customerservice@bainub.com", "czuniga@automateit.mx" ],
            //recipients: [ "czuniga@automateit.mx" ],
            templateVars: {
                content:  name
                + "<br/>" + email
                + "<br/>" + phone
                + "<br/>" + products
                + "<br/> null"
                + "<br/> null"
                + "<br/> null"
                + "<br/>" + new Date().toLocaleString("en-US", {timeZone: "America/Mexico_City"})
                + "<br/>" + plan
            },
            templateLanguage: "en"
        });
        //window.location = "https://www.bainub.com/b2b-trading-platform/thank-you";
        history.goBack();
    }
    return (
        <>

       <div className='center-page'>
        Enviando...
       </div>
        </>
    )
}

export default EmailForm;