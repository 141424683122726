import {Auth, API } from 'aws-amplify';
import { Layout, Button, Card, Input, Alert} from 'element-react';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import NotificationsIcon from "../assets/imgs/alert.png";



function PortalNotifications({notificationType}) {
    const [portalNotifications, setPortalNotifications] = useState([]);
    const history = useHistory();

    useEffect(() => {
        loadNotifications();
    }, []);

    const loadNotifications = async () =>{
        console.log('loadNotifications');
        const user = await Auth.currentAuthenticatedUser();
        const result = await API.get('NotificationServiceLambda', '/portalNotification/user/active/'+ user.attributes.email, {
        })
        console.log(result);
        const data = result.data.filter((notification) => notification.type == notificationType);
        console.log({data});
        setPortalNotifications(data);
    }

    const dismissNotification = async (notificationId) =>{
        console.log('DISMISS NOTIFICATION');
        //setLoading ?  setLoading(true) : null;
        console.log(notificationId);
        try{
            const result = await API.post('NotificationServiceLambda', '/portalNotification/user/dismiss', {
                body: {
                    notification_id: notificationId,
                    user_email: (await Auth.currentAuthenticatedUser()).attributes.email
                }
            })
            console.log(result);
            loadNotifications();
        }catch(error){
            console.log(error);
        }finally{
            //setLoading ?  setLoading(false) : null;
        }
        
    }

    const goTo = (link) =>{
        console.log('GO TO');
        console.log(link);
        history.push(link);
    }

    return (
        <>
            <Layout.Row gutter="10">
                <Layout.Col className="notifications-header" offset="2" span="20">
                    <Layout.Row>
                        <Layout.Col xs="12" sm="12" lg="12">
                            <h3 id="notifications">{'NOTIFICATIONS'}</h3>
                        </Layout.Col>
                        <Layout.Col xs="12" sm="12" lg="12" className="text-right">
                            <img
                                className="NotificationsIcon"
                                src={NotificationsIcon}
                                alt="Notifications"
                            />
                        </Layout.Col>
                    </Layout.Row>
                </Layout.Col>
            </Layout.Row>
            {portalNotifications.length > 0 ? (
                <div className="my-product-table">
                    <Layout.Row>
                        <Layout.Col offset="2" span="20">
                            <Card>
                                {portalNotifications.map((notification, index) => (
                                    <>
                                        <Alert
                                            title="Notification"
                                            type="info"
                                            description={
                                                <>
                                                    <span>
                                                        {index + 1}. {notification.message}
                                                    </span>
                                                    <br />
                                                    <br />
                                                    <Button
                                                        type="primary"
                                                        onClick={() => {
                                                            goTo(notification.link_to);
                                                        }}
                                                    >
                                                        {notification.link_to_text}
                                                    </Button>
                                                    {notification.show_dismiss === 1 ? (
                                                        <Button
                                                            type="secondary"
                                                            onClick={() => {
                                                                dismissNotification(notification.id);
                                                            }}
                                                        >
                                                            Dismiss
                                                        </Button>
                                                    ) : null}
                                                </>
                                            }
                                            showIcon={true}
                                            closable={false}
                                        />
                                        {index === portalNotifications.length - 1 ? null : (
                                            <Layout.Row gutter="24">
                                                <Layout.Col span="24">
                                                    <div className="mt-2 mb-1" />
                                                </Layout.Col>
                                            </Layout.Row>
                                        )}
                                    </>
                                ))}
                            </Card>
                        </Layout.Col>
                    </Layout.Row>
                </div>
            ) : (
                <Layout.Row>
                    <Layout.Col offset="2" span="20">
                        <Card>
                            <p style={{ color: '#A3A3A4', fontWeight: 'bold' }}>You don't have pending Notifications. Keep up the good work!</p>
                        </Card>
                    </Layout.Col>
                </Layout.Row>
            )}
        </>
    );
}

export default PortalNotifications;