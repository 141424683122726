import React from 'react';
import { Layout } from 'element-react';

//TEXT for the Badge
//<text id="_1" data-name="1" transform="translate(1352 52)" fill="#fff" font-size="14" font-family="Poppins-SemiBold, Poppins" font-weight="600"><tspan x="0" y="0">1</tspan></text>
const MessagesIcon = (props) => {
  return (
    <div className="flex-column">
        <Layout.Row type="flex" justify="center" align="middle">
            <Layout.Col style={{width: '60%'}} offset="6" span="20">
                {
                    props.hasMessage ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="32.806" height="25.294" viewBox="0 0 37.903 32.538">
                        <g id="Group_1380" data-name="Group 1380" transform="translate(-1326.097 -24.462)">
                            <path id="comments-solid" d="M24.416,41.391C24.416,36.2,18.951,32,12.208,32S0,36.2,0,41.391a7.962,7.962,0,0,0,2.23,5.4,13.21,13.21,0,0,1-2.1,3.2.467.467,0,0,0-.088.511.46.46,0,0,0,.428.282,10.334,10.334,0,0,0,5.206-1.467,14.934,14.934,0,0,0,6.532,1.467C18.951,50.781,24.416,46.579,24.416,41.391ZM31.576,54.3a7.951,7.951,0,0,0,2.23-5.4c0-3.926-3.14-7.289-7.589-8.692a8.721,8.721,0,0,1,.076,1.18c0,6.215-6.321,11.269-14.086,11.269a17.6,17.6,0,0,1-1.861-.112c1.849,3.375,6.192,5.746,11.251,5.746a14.852,14.852,0,0,0,6.532-1.467,10.334,10.334,0,0,0,5.206,1.467.463.463,0,0,0,.428-.282.47.47,0,0,0-.088-.511A13.069,13.069,0,0,1,31.576,54.3Z" transform="translate(1326.097 -7.538)" fill="#c1ccd7"/>
                            <circle id="Ellipse_74" data-name="Ellipse 74" cx="8.5" cy="8.5" r="8.5" transform="translate(1347 38)" fill="#ee746a"/>
                            <text id="_1" data-name="1" transform="translate(1352 52)" fill="#fff" fontSize="14" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600"><tspan x="0" y="0">1</tspan></text>
                        </g>
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="32.806" height="25.294" viewBox="0 0 37.903 32.538">
                        <g id="Group_1380" data-name="Group 1380" transform="translate(-1326.097 -24.462)">
                            <path id="comments-solid" d="M24.416,41.391C24.416,36.2,18.951,32,12.208,32S0,36.2,0,41.391a7.962,7.962,0,0,0,2.23,5.4,13.21,13.21,0,0,1-2.1,3.2.467.467,0,0,0-.088.511.46.46,0,0,0,.428.282,10.334,10.334,0,0,0,5.206-1.467,14.934,14.934,0,0,0,6.532,1.467C18.951,50.781,24.416,46.579,24.416,41.391ZM31.576,54.3a7.951,7.951,0,0,0,2.23-5.4c0-3.926-3.14-7.289-7.589-8.692a8.721,8.721,0,0,1,.076,1.18c0,6.215-6.321,11.269-14.086,11.269a17.6,17.6,0,0,1-1.861-.112c1.849,3.375,6.192,5.746,11.251,5.746a14.852,14.852,0,0,0,6.532-1.467,10.334,10.334,0,0,0,5.206,1.467.463.463,0,0,0,.428-.282.47.47,0,0,0-.088-.511A13.069,13.069,0,0,1,31.576,54.3Z" transform="translate(1326.097 -7.538)" fill="#c1ccd7"/>
                        </g>
                    </svg>
                }
                
            </Layout.Col>
            <Layout.Col span="24">
                <div className="header-icon-text">{props.text}</div>
            </Layout.Col>
        </Layout.Row>
    </div>
    /*<div className="flex-column">
        <Layout.Row type="flex" justify="center" align="stretch">
            <Layout.Col span="24"><div className="grid-content bg-purple-dark">X</div></Layout.Col>
            <Layout.Col span="24"><div className="grid-content bg-purple-dark">Y</div></Layout.Col>
        </Layout.Row>
    </div>*/
    
    
  )
}

export default MessagesIcon;