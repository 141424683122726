import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { API, graphqlOperation } from 'aws-amplify'
import { updateChat } from '../graphql/mutations';

import { Layout, Input, Button } from "element-react"
import { FcApproval, FcRating } from "react-icons/fc";
import { BiEdit, BiX } from "react-icons/bi";

export default function ChatTitle({ chat }) {
    const {t} = useTranslation();
    const [[isAliasEditEnabled, setIsAliasEditEnabled], [isAliasLabelHover, setIsAliasLabelHover], [editAliasInput, setEditAliasInput]] = [useState(false), useState(false), useState(null)];
    const username = chat.chat_type ==="S" ? chat.owner_name : chat.company_user_name;
    const memberSince = chat.chat_type ==="S" ? chat.buyer_since : chat.seller_since;
    const contactPhone = chat.chat_type ==="S" ? chat.buyer_phone : null;

    const buildAvatar = () => username.match(/\b(\w)/g).join('').toUpperCase().substring(0,2)

    useEffect(() => {
        setIsAliasEditEnabled(false)
        setEditAliasInput(chat.chat_type ==="S" ? chat.alias_owner_name : chat.alias_company_user_name)
    }, [chat])

    const editAlias = async () => {
        const updateChatInput= { }
        updateChatInput["id"]= chat.id
        updateChatInput[chat.chat_type === "S" ? "alias_owner_name" : "alias_company_user_name"] = editAliasInput
        await API.graphql(graphqlOperation(updateChat, { input: updateChatInput }));
        setIsAliasEditEnabled(false)
    }

    return (
        <Layout.Row className="m-t-none p-t-sm p-b-sm chat-messages-title">
            <Layout.Col span="3">
                <div className="avatar-md p-l-sm">
                    <span className="avatar-title rounded-circle">{buildAvatar()}</span>
                </div>
            </Layout.Col>
            <Layout.Col span="15">
                <div>
                    { 
                        isAliasEditEnabled ?
                            <div className="inline-input p-b-sm">
                                <Input placeholder="Alias" defaultValue={editAliasInput} onChange={(e) => setEditAliasInput(e)} size="mini" icon="edit" /> 
                                <Button className="cursor primary-button m-l-none m-r-xs" size="mini" onClick={editAlias}>{t('chat.chat-header.update')}</Button> 
                                <Button className="cursor primary-button m-l-none" size="mini" onClick={() => setIsAliasEditEnabled(false)}>{t('chat.chat-header.cancel')}</Button>
                            </div>
                        : 
                            <>
                                <label 
                                    className="chat-item-title"
                                    onMouseEnter={() => setIsAliasLabelHover(true) } 
                                    onMouseLeave={() => setIsAliasLabelHover(false)}>
                                        {username}
                                </label>
                                <label 
                                    className="chat-item-alias" 
                                    onMouseEnter={() => setIsAliasLabelHover(true) } 
                                    onMouseLeave={() => setIsAliasLabelHover(false) }> 
                                        - {editAliasInput}
                                        {isAliasLabelHover ? <BiEdit className="m-l-sm cursor" size={18} onClick={() => setIsAliasEditEnabled(true)} /> : <></>}
                                </label>
                            </>
                    }
                </div>
                {contactPhone ? <label className="chat-item-category m-t-xxs m-b-xxs">{contactPhone}</label> : <></> }
                <div className="chat-item-description">
                    <FcApproval className="m-l-none m-r-xxs" size={18} /> <label className="m-l-none">TBD: {t('chat.chat-header.verified-account')}</label>
                    <FcRating className="m-l-md m-r-xxs" size={18} /> <label className="m-l-none">TBD: {t('chat.chat-header.gold-supplier')}</label>
                </div>
            </Layout.Col>
            <Layout.Col span="6" className="align-right p-r-md p-t-xs">
                <label className="chat-messages-title-label-sm"><b>{t('chat.chat-header.member-since')}:</b> {memberSince}</label>
                <label className="chat-messages-title-label-sm"><b>{t('chat.chat-header.transactions')}:</b> -</label>
                <label className="chat-messages-title-label-sm"><b>{t('chat.chat-header.last-online')}:</b> {t('chat.chat-header.last-online-today')}</label>
            </Layout.Col>
        </Layout.Row>
    )
}
