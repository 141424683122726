import create from 'zustand';
import { persist } from 'zustand/middleware';
import { listCategorys } from '../../graphql/queries';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import { API } from 'aws-amplify';
//@ts-ignore
const [categoryStore] = create(persist(set => ({
  categories: [],
  setCategories: async () => {
    console.log('setting categories catalog');
    set({
        categories : await getCategories()
    });
  },
  cleanCategories : () => set({categories : []})
})), {version : 2});


const getCategoriesFromService = async () =>{
    //@ts-ignore
    const { data } = await API.graphql({
      query: listCategorys,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM
    });
    const categories = data.listCategorys.items;
    categories.sort(compare);
    console.log({categories});
    return categories;
}

function compare( a, b ) {
  if ( a.name.toLowerCase() < b.name.toLowerCase() ){
    return -1;
  }
  if ( a.name.toLowerCase() > b.name.toLowerCase() ){
    return 1;
  }
  return 0;
}

function compareTags( a, b ) {
  if ( a.toLowerCase() < b.toLowerCase() ){
    return -1;
  }
  if ( a.toLowerCase() > b.toLowerCase() ){
    return 1;
  }
  return 0;
}



const getCategories = async () =>{
      let categories = await getCategoriesFromService();
      let options = [];
      categories.forEach(element => {
        console.log(element);
        let _options = []
        //setting category All
        _options.push({
            value : `${element.id}|All`, //TODO get from Translation
            label : `${element.name} - All`
        })
        //settings tags as options inside the category
        element.tags.sort(compareTags);
        element.tags.forEach(tag => {
            console.log(tag);
            _options.push({
                value : `${element.id}|${tag}`,
                label : tag
            })
        });
        //Adding Category Group with its options
        let category = {
            label : element.name,
            options : _options
        }
        options.push(category);
      });

      return options;
}

export default categoryStore;