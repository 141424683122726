import PrivacyPolicyText from '../components/PrivacyPolicyText';

function BainubPrivacyPolicy(props){
    return(
       
        <PrivacyPolicyText/>
        
    )
}

export default PrivacyPolicyText;