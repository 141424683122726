import React from 'react';
import { Layout } from 'element-react';
const BainubIcon = (props) => {
  return (
      <Layout.Row style={{display: '-webkit-inline-box'}}>
        <Layout.Col xs="6" sm="6" md="6" lg="6">
          <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 77.396 58.06">
              <g id="Group_1409" data-name="Group 1409" transform="translate(-827.85 -275.29)">
                <g id="Group_14439" data-name="Group 14439">
                  <g id="Group_1397" data-name="Group 1397" transform="translate(827.699 274.627)">
                    <g id="Group_1395" data-name="Group 1395" transform="matrix(1, 0.017, -0.017, 1, 19.045, 16.657)">
                      <rect id="Rectangle_3866" data-name="Rectangle 3866" width="11.518" height="31.366" transform="translate(0 17.951) rotate(-41)" fill="#FF6D73"/>
                      <rect id="Rectangle_3867" data-name="Rectangle 3867" width="11.518" height="50.327" transform="translate(51.107 0) rotate(49)" fill="#FF6D73"/>
                    </g>
                    <g id="Group_1396" data-name="Group 1396" transform="translate(58.655 42.728) rotate(-179)">
                      <rect id="Rectangle_3866-2" data-name="Rectangle 3866" width="11.518" height="31.366" transform="translate(0 17.951) rotate(-41)" fill="#FF6D73"/>
                      <rect id="Rectangle_3867-2" data-name="Rectangle 3867" width="11.518" height="50.327" transform="translate(51.107 0) rotate(49)" fill="#FF6D73"/>
                    </g>
                  </g>
                  <path id="Path_176" data-name="Path 176" d="M0,6.585l11.486,14.2,8.135-6.59L15.089,8.706l-2.972-.144L12.8,5.792,8.135,0Z" transform="matrix(1, -0.017, 0.017, 1, 856.517, 294.131)" fill="#FF6D73"/>
                </g>
              </g>
            </svg>

 
        </Layout.Col>
        <Layout.Col style={{fontSize : '28px', paddingLeft: '4px'}} className="main-logo-text">
          {props.text}
        </Layout.Col>
            
      </Layout.Row>
            
  )
}

export default BainubIcon;