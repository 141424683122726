import React from 'react';
import { Layout } from 'element-react';
const StoreIcon = (props) => {
  return (
    <div className="flex-column">
        <Layout.Row type="flex" justify="center" align="middle">
            <Layout.Col style={{width: '60%'}} offset="6" span="24">
                {
                <svg xmlns="http://www.w3.org/2000/svg" width="26.773" height="20.421" viewBox="0 0 31.773 25.421">
                    <path d="M15.937,19.066H6.4V11.122H3.226v12.71a1.587,1.587,0,0,0,1.589,1.589h12.71a1.587,1.587,0,0,0,1.589-1.589V11.122H15.937ZM31.557,7.06,27.321.705A1.6,1.6,0,0,0,26,0H5.878A1.582,1.582,0,0,0,4.557.705L.322,7.06A1.589,1.589,0,0,0,1.642,9.533H30.236A1.592,1.592,0,0,0,31.557,7.06ZM25.469,24.626a.8.8,0,0,0,.794.794h1.589a.8.8,0,0,0,.794-.794v-13.5H25.469Z" transform="translate(-2.151 0)" fill="#c1ccd7"/>
                </svg>
                }
            </Layout.Col>
            <Layout.Col span="24">
                <div className="header-icon-text">{props.text}</div>
            </Layout.Col>
        </Layout.Row>
    </div>
  )
}

export default StoreIcon;