import { API, graphqlOperation } from 'aws-amplify'
import { updateChat } from '../graphql/mutations';
import { useEffect, useState } from 'react';
import { Card, Layout } from "element-react"


export default function ChatItem( { chat, onClick, isActiveChat }) {
    const username = chat.chat_type ==="S" ? chat.owner_name : chat.company_user_name;
    const aliasUsername = chat.chat_type ==="S" ? chat.alias_owner_name : chat.alias_company_user_name;
    const [isMessageUnread, setIsMessageUnread] = useState(false)
    
    let lastMessage = chat.last_message.substring(0, 29);
    lastMessage = lastMessage.length == 29 ? lastMessage = lastMessage.concat("...") : lastMessage
    const lastMessageDate = chat.updatedAt.substring(0, 10).replaceAll("-", "/")
    
    const buildAvatar = () => username.match(/\b(\w)/g).join('').toUpperCase().substring(0,2)

    useEffect(() => {
        setIsMessageUnread((chat.chat_type ==="S" && chat.company_user_last_message_read) || (chat.chat_type ==="B" && chat.owner_last_message_read))
    }, [])

    async function onClickHandler(chatId) {
        const isChatUnread = chat[chat.chat_type === "S" ? "company_user_last_message_read" : "owner_last_message_read"];
        if(isChatUnread) {
            const updateChatInput= { }
            updateChatInput["id"]= chat.id
            updateChatInput[chat.chat_type === "S" ? "company_user_last_message_read" : "owner_last_message_read"]= 0
            await API.graphql(graphqlOperation(updateChat, { input: updateChatInput }));
            setIsMessageUnread(false);
        }
        if(onClick)
            onClick(chatId)
    }

    return (
        <div onClick={() => onClickHandler(chat.id)}>
            <Card bodyStyle={{padding: "10px"}} className={'m-t-sm card-box-shadow-none cursor '+(isActiveChat ? ' active-chat-item ':'') + (chat.chat_type === "S" ? ' seller-chat ' : '')}>
                <Layout.Row glutter="5">
                    <Layout.Col span="4">
                        <div className="avatar-md" >
                            <span className="avatar-title rounded-circle cursor">{buildAvatar()}</span>
                        </div>
                    </Layout.Col>
                    <Layout.Col span="20">
                        <div>
                            <label className="chat-item-title cursor" >{username}</label> 
                            { username === aliasUsername ? <></> : <label className="chat-item-alias cursor" > - {aliasUsername}</label> }
                        </div>
                        <label className="chat-item-last-message cursor" >{lastMessage}</label>
                    </Layout.Col>
                    <div className="chat-item-date cursor">
                        {lastMessageDate}
                    </div>
                    <div className={'chat-item-unread-flag cursor '+(!isMessageUnread ? "display-none" : "")} >
                        
                    </div>
                </Layout.Row>
            </Card>
        </div>
    )
}
