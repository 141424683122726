import React, {useState, useRef, useEffect} from 'react';
import { MessageBox, Layout, Button, Card, Notification, Form, Input, Loading, DatePicker, Select, Tag, Radio, Cascader, Checkbox, Dialog, Slider, Tooltip } from 'element-react';
import { useTranslation } from 'react-i18next';
import { addRfqRules } from '../utils/AddRfqRules';
import { Auth, API, graphqlOperation, Storage} from 'aws-amplify';
import {listCategorys} from '../graphql/queries';
import aws_exports from "../aws-exports";
import { blobToBase64 } from '../utils/FormatUtils';
import { FILES_URL } from '../utils/Constants';

function Rfq() {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [contentType, setContentType] = useState([]);
    const [fileSizeError, setFileSizeError] = useState('');
    const [options2, setOptions2] = useState([]);
    const form0 = useRef(null);
    const uploadFileRef = useRef(null);

    const units = [
        'Bag','Barrel','Basket','Block','Bottle','BOU','Box','Carat','Carton','Case','Centimeter ','Cubic Centimeter ','Cubic Foot ','Cubic Inch ','Cubic Meter ','Cubic Yard ','Dozen','Feet','Fluid Ounce ','Gallon (American)','Gallon (British)','Gram','Gross','Group','Hectare','Inch','Kilogram','Kilometre','Liter','Lot','Meter','Mile','Milligram','Milliliter','Ounce','Pack','Pair','Pallet','Parcel','Part','Piece','Pound','Roll','Set','Sheet','Square Centimeter','Square Feet','Square Inch','Square Meter','Square Mile','Square Yard','Tank','Ton (metric)','Tray','Unit','Yard'
    ]
    const requencyOptions = [
        'Once','Daily','Weekly','Monthly','Trimester','Semester','Year'
    ]
    const incotermsOptions = [
        'EXW','FOB','FCA','FAS','CIF','CPT','CIP','CFR','DPU','DAP','DDP'
    ]
    const expireInOptions = [
        '1 week','2 weeks','3 weeks','1 month','2 month','3 month','4 month','5 month','6 month'
    ]
    const shippingMethodOptions = [
        'Land','Air','Sea','Express'
    ]

    // Validar el tipo de archivo
    const allowedTypes = [
        'image/png',
        'image/jpeg',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
        'application/msword', // Older Word format
        'application/vnd.ms-excel' // Older Excel format
      ];
    const [formInputs, setFormInputs] = useState(
        {
            productName: '',
            category: [],
            requirements: '',
            certification1: '',
            certification2: '',
            quantity: '',
            unit: '',
            buyingFrequency: '',
            incoterm: '',
            requiredDate: '',
            expiredIn: '',
            destination: '',
            shippingMethod: ''
        });
    const { t } = useTranslation();
    const [rules0, setRules0] = useState(null);
    const submitForm = async(e) =>{
        e.preventDefault();
        
        console.log("Submit Form");
        console.log({formInputs});

        form0.current.validate(async(valid) => {
            console.log('validator :: ' + valid)
            if(fileSizeError !== '') alert('Select a valid file type (PNG, JPG, PDF, Excel, Word) and file size not exceed 10MB');
            if(!valid) return;
            saveRfq();
        });
        //searchProductCategories();
    }

    const handleFileChange = (e) => {
        if(e.target.files.length === 0) return;
        const selectedFile = e.target.files[0];
        const maxSizeInBytes = 10 * 1024 * 1024; // 5 MB
        // Validar el tipo de archivo
        const allowedTypes = [
          'image/png',
          'image/jpeg',
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
          'application/msword', // Older Word format
          'application/vnd.ms-excel' // Older Excel format
        ];
        if (selectedFile.size > maxSizeInBytes) {
            setFileSizeError('File size exceeds 10MB');
            setFile(null);
            setFileName('');
            return;
        }
        if (selectedFile && allowedTypes.includes(selectedFile.type)) {
            console.log('File type is valid :: ' + selectedFile.name);
            setFile(selectedFile);
            setFileName(selectedFile.name);
            setContentType(selectedFile.type);
            setFileSizeError('');
        } else {
          setFileSizeError('Please select a valid file type (PNG, JPG, PDF, Excel, Word)');
          setFile(null);
          setFileName('');
        }
    }

    const loadSubCategories = (value) => {
        console.log('loadSubCategories ==> ' + value[0]);
        console.log('loadSubCategories ==> ' + value[1]);
        setFormInputs({...formInputs, category: value })
    }

    const saveRfq = async () => {
        console.log("Saving RFQ");
        setLoading(true);
        
        try {
            const user = await Auth.currentAuthenticatedUser();
            //let uploadedFile = null;
            let s3FileKey = null;
            if(fileName !== '' && file !== null) {
                s3FileKey = '/' + user.username + '/' + fileName;
                /*
                uploadedFile = await Storage.put('/' + user.username + '/' + fileName, file, {
                    contentType: contentType,
                    level: 'public',
                    progressCallback: progress => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                    const percentUploaded = Math.round(
                        (progress.loaded / progress.total) * 100
                    );
                    console.log(percentUploaded);//Aqui podemos mostrar esto en pantalla de algun modo (en un LOADER...)
                    }
                });
                */
                const session = await Auth.currentSession();
                const token = session.getIdToken().getJwtToken();
                const base64Data = await blobToBase64(file);
                const base64Content = base64Data.split(',')[1];
    
                const response = await fetch(FILES_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify(
                        {
                        fileName: s3FileKey,
                        fileType: contentType,
                        file: base64Content
                    })
                });
    
                const data = await response.json();
                        console.log(data);
            }
            
            
            const result = await API.post('NotificationServiceLambda', '/rfq/save', {
                body: {
                    product_name : formInputs.productName, 
                    category_id : formInputs.category[0], 
                    category_name : formInputs.category[1], 
                    product_description : formInputs.requirements, 
                    certification : formInputs.certification1 + '|' + formInputs.certification2, 
                    quantity : formInputs.quantity, 
                    unit : formInputs.unit, 
                    buying_frequency : formInputs.buyingFrequency, 
                    file_bucket : aws_exports.aws_user_files_s3_bucket, 
                    file_key : s3FileKey == null ? '' : s3FileKey, 
                    file_region: aws_exports.aws_project_region, 
                    incoterm : formInputs.incoterm, 
                    required_by_date: formInputs.requiredDate == '' ? null : formInputs.requiredDate, 
                    expire_in : formInputs.expiredIn, 
                    created_by : user.attributes.email,
                    shipping_method : formInputs.shippingMethod,
                    destination : formInputs.destination
                }
            })
            console.log(result)
        } catch(error) {
            console.error(error)
        }finally{
            setLoading(false);
            Notification({
                title: 'Success',
                message: 'RFQ Submitted Successfully',
                type: 'success',
                duration: 0
            });
            setFormInputs({
                productName: '',
                category: [],
                requirements: '',
                certification1: '',
                certification2: '',
                quantity: '',
                unit: '',
                buyingFrequency: '',
                incoterm: '',
                requiredDate: '',
                expiredIn: '',
                destination: '',
                shippingMethod: ''
            });
        }
    }

    useEffect(() => {
        console.log("Request for Quotation page loaded");
        setTimeout(function(){
            setRules0(addRfqRules(t));
        }, 1000);

        (async () => {
            try{
                const result = await API.graphql({ query: listCategorys});
                console.log({result});
                const newData = [];
                const _listCatgorys = result.data.listCategorys.items;
                _listCatgorys.sort(compare);
                _listCatgorys.forEach((element, index) => {
                    console.log(element);
                    const parent = {
                        label: element.name,
                        value: element.id,
                        children: []
                    }
                    const _tags = element.tags;
                    _tags.sort(compareTags)
                    _tags.forEach(child =>{
                        const _child = 
                            {
                                value: child,
                                label: child
                            }
                        parent.children.push(_child);
                    });
                    
                    newData.push(parent);
                });
                setOptions2(newData);
            }catch(e){
                console.error(e);
            } 
        })();

    }, []);
    
    return (
        <div className="product-wizard-div">
            {
                loading && <Loading fullscreen={loading} />
            }
            <div>
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="20">
                                <h2>Request For Quotation</h2>
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="20">
                                <label className="label-custom"> Submit a Bainub Direct RFQ to get multiple quotations from North American verified suppliers, reduce costs with factory direct sourcing, and simplify your supply chain challenges connecting with productive businesses in your own region. </label>
                            </Layout.Col>
                </Layout.Row>
                <br/>
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="20">
                                <Card className="box-card">
                                    <Form ref={form0} model={formInputs} className="en-US" rules={rules0} label-position="top" onSubmit={submitForm}>
                                    <Layout.Row type="flex">
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="9">
                                            <Form.Item label={'Product Name:'} prop="productName">
                                                    <Input
                                                        maxlength={75}
                                                        value={formInputs.productName}
                                                        onChange={(v) => {setFormInputs({...formInputs, productName: v })}}
                                                    />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="20" offset="2" xs="20" sm="20" lg="9">
                                            <Form.Item label={'Product Category:'} prop="category">
                                                    {/*<Select
                                                        value={formInputs.category}
                                                        onChange={(v) => {setFormInputs({...formInputs, category: v })}}
                                                    >
                                                        <Select.Option label={'TEST'} value="S"/>
                                                    </Select>*/}
                                                    <Cascader className="cascader-add-product"
                                                                                options={options2}
                                                                                value={formInputs.category} 
                                                                                onChange={ (v) => {loadSubCategories(v)}}
                                                                            />
                                            </Form.Item>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex">
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="20">
                                            <Form.Item label={'Product Requirements:'} prop="requirements">
                                                    <Input
                                                        type="textarea"
                                                        autosize={{ minRows: 10, maxRows: 15}}
                                                        placeholder="An accurate description of your product will help suppliers understand your requirements and increase the
                                                        quality of the quotations sent to you."
                                                        maxlength={1500}
                                                        value={formInputs.requirements}
                                                        onChange={(v) => {setFormInputs({...formInputs, requirements: v })}}
                                                    />
                                            </Form.Item>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex">
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="9">
                                            <Form.Item label={'Certification:'} prop="certification1">
                                                    <Input
                                                        maxlength={55}
                                                        value={formInputs.certification1}
                                                        onChange={(v) => {setFormInputs({...formInputs, certification1: v })}}
                                                    />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="20" offset="2" xs="40" sm="20" lg="9">
                                            <Form.Item label={'Certification:'} prop="certification2">
                                                <Input
                                                        maxlength={55}
                                                        value={formInputs.certification2}
                                                        onChange={(v) => {setFormInputs({...formInputs, certification2: v })}}
                                                    />
                                            </Form.Item>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex">
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Quantity:'} prop="quantity">
                                                    <Input
                                                        maxlength={11}
                                                        type="number"
                                                        value={formInputs.quantity}
                                                        onChange={(v) => {setFormInputs({...formInputs, quantity: v })}}
                                                    />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Unit:'} prop="unit">
                                                <Select
                                                        value={formInputs.unit}
                                                        onChange={(v) => {setFormInputs({...formInputs, unit: v })}}
                                                >
                                                        {
                                                            units.map(el => {
                                                            return <Select.Option key={el} label={el} value={el} />
                                                            })
                                                        }
                                                </Select>
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Buying Frequency:'} prop="buyingFrequency">
                                                <Select
                                                        value={formInputs.buyingFrequency}
                                                        onChange={(v) => {setFormInputs({...formInputs, buyingFrequency: v })}}
                                                >
                                                        {
                                                            requencyOptions.map(el => {
                                                            return <Select.Option key={el} label={el} value={el} />
                                                            })
                                                        }
                                                </Select>
                                            </Form.Item>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <br/>
                                    <Layout.Row type="flex">
                                        <Layout.Col span="20" offset="0" xs="20" sm="20" lg="3">
                                            &nbsp;        
                                        </Layout.Col>
                                        
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="12">
                                            <Form.Item>
                                            <label className="custom-file-upload">
                                            <input
                                                type="file"
                                                accept=".png,.jpg,.jpeg,.pdf,.xls,.xlsx,.doc,.docx"
                                                onChange={handleFileChange}
                                            />
                                                Upload File
                                            </label>
                                            {fileName && <p>Selected file: {fileName}</p>}
                                            {fileSizeError && <p style={{ color: 'red' }}>{fileSizeError}</p>}
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="3">
                                            &nbsp;        
                                        </Layout.Col>
                                    </Layout.Row>
                                    <br/>
                                    <Layout.Row type="flex">
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Incoterm:'} prop="incoterm">
                                                    <Select
                                                        maxlength={11}
                                                        value={formInputs.incoterm}
                                                        onChange={(v) => {setFormInputs({...formInputs, incoterm: v })}}
                                                    >
                                                        {
                                                            incotermsOptions.map(el => {
                                                            return <Select.Option key={el} label={el} value={el} />
                                                            })
                                                        }
                                                    </Select>
                                            </Form.Item>
                                        </Layout.Col>
                                        
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Port of Destination/entry:'} prop="destination">
                                                    <Input
                                                        maxlength={50}
                                                        value={formInputs.destination}
                                                        onChange={(v) => {setFormInputs({...formInputs, destination: v })}}
                                                    />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Shipping Method:'} prop="shippingMethod">
                                            
                                                <Select
                                                        
                                                        value={formInputs.shippingMethod}
                                                        onChange={(v) => {setFormInputs({...formInputs, shippingMethod: v })}}
                                                    >
                                                        {
                                                            shippingMethodOptions.map(el => {
                                                            return <Select.Option key={el} label={el} value={el} />
                                                            })
                                                        }
                                                    </Select>
                                            </Form.Item>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex">
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Required by Date:'} prop="requiredDate">
                                                <DatePicker
                                                    value={formInputs.requiredDate}
                                                    placeholder="Pick a day"
                                                    onChange={date=>{
                                                        console.debug('DatePicker1 changed: ', date)
                                                        setFormInputs({...formInputs, requiredDate: date })
                                                    }}
                                                    disabledDate={time=>time.getTime() < Date.now() - 8.64e7}
                                                />
                                            </Form.Item>
                                        </Layout.Col>
                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="6">
                                            <Form.Item label={'Expire in:'} prop="expiredIn">
                                            
                                                <Select
                                                        
                                                        value={formInputs.expiredIn}
                                                        onChange={(v) => {setFormInputs({...formInputs, expiredIn: v })}}
                                                    >
                                                        {
                                                            expireInOptions.map(el => {
                                                            return <Select.Option key={el} label={el} value={el} />
                                                            })
                                                        }
                                                    </Select>
                                            </Form.Item>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row type="flex" justify="end">
                                        <Layout.Col tag="form-button" span="10" xs="20" sm="20" lg="10">
                                            
                                                <Button type="primary" disabled={formInputs.subCategoryId == '' ? true: false} onClick={submitForm}>
                                                        Submit RFQ
                                                </Button>
                                            
                                        </Layout.Col>
                                    </Layout.Row>       
                                                            
                                    </Form>
                                </Card>
                            </Layout.Col>
                </Layout.Row>

                
            </div>
        </div>
    )

    function compare( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      }
      
      function compareTags( a, b ) {
        if ( a.toLowerCase() < b.toLowerCase() ){
          return -1;
        }
        if ( a.toLowerCase() > b.toLowerCase() ){
          return 1;
        }
        return 0;
      }
}

export default Rfq;