const ThumbPreview = (props) =>{
    return(
        <div className="thumb-preview">
            <img src={props.src} alt={props.name} style={{height: props.height, width: props.width}}>
            </img>
            <div
                className="delete"
                type="button"
                onClick={props.handleImageDelete}
              >
                X
              </div>
        </div>
    )
}

export default ThumbPreview;