const addProductRules = (t) => {
    return  ({
        product: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        quantity: [
            { required: true, message: t('my-store.create.form.validator.number'), trigger: 'blur', validator: (rule, value) => 
                {
                    if ((value === "" || value%1) != 0) 
                    {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        unit: [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur' }
        ],
        unitPrice: [
            { required: true, message: t('my-store.create.form.validator.number-decimal'), trigger: 'blur', validator: (rule, value) => 
                {
                    //var numbers = /[0-9]+\.?[0-9]*/;
                    value = value + "";
                    var numbers = /^\d{1,8}(\.\d{0,4})?$/;
                    if (!value.match(numbers)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],

        
        
    })
}

const addFeeRules = (t) => {
    return  ({
        fee: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        quantity: [
            { required: true, message: t('my-store.create.form.validator.number'), trigger: 'blur', validator: (rule, value) => 
                {
                    if ((value === "" || value%1) != 0) 
                    {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        unit: [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur' }
        ],
        unitPrice: [
            { required: true, message: t('my-store.create.form.validator.number-decimal'), trigger: 'blur', validator: (rule, value) => 
                {
                    //var numbers = /[0-9]+\.?[0-9]*/;
                    value = value + "";
                    var numbers = /^\d{1,8}(\.\d{0,4})?$/;
                    if (!value.match(numbers)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
    })
}

const addPaymentRules = (t) => {
    return  ({
        date: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        amount: [
            { required: true, message: t('my-store.create.form.validator.number-decimal'), trigger: 'blur', validator: (rule, value) => 
                {
                    //var numbers = /[0-9]+\.?[0-9]*/;
                    value = value + "";
                    var numbers = /^\d{1,8}(\.\d{0,4})?$/;
                    if (!value.match(numbers)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        method: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ]
    })
}

const generalFormRules = (t) =>{
    return ({
        buyerEmail: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ],
        currency: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ],
        paymentMethod: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ],
        deliveryDate: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ],
        guarantee: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ],
        guarantee: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ],
        paymentCost: [
            { required: false, message: t('my-store.create.form.validator.number-decimal'), trigger: 'blur', validator: (rule, value) => 
                {
                    console.log("paymentCost validator :: " + value )
                    if (value == "" || value == null) return true;
                    //var numbers = /[0-9]+\.?[0-9]*/;
                    value = value + "";
                    if(value == "") return true;
                    var numbers = /^\d{1,8}(\.\d{0,4})?$/;
                    if (!value.match(numbers)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
    })
}

const historyFormRules = (t) =>{
    return ({
        process_status: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ],
        date: [
            { required: true, message: t('my-store.create.form.validator.generic-issue')}
        ]
    })
}

const personalDataFormRules = (t) =>{
    return ({
        phoneNumber: [
            { required: true, message: t('my-account.form.validator.generic')}
        ]
    })
}

const passwdFormRules = (t, refPassword) =>{
    return ({
        passwd: [
            { required: true, message: t('my-account.form.validator.generic')}
        ],
        newPassword: [
            { required: true, message: t('register-form.validate-passwd') , validator: (rule, value) =>
                {
                    if(value.length < 8) return false;
                    return true;
                }
            }
        ],
        confirmPassword: [
            { required: true, message: t('register-form.validate-confirmpasswd'), validator: (rule, value) => {
                console.log('refPassword.current.props.value :: ' + refPassword.current.props.value);
                console.log('value :: ' + value);
                return value === refPassword.current.props.value;
            }}
        ]
    })
}

export { addProductRules, addFeeRules, addPaymentRules, 
    generalFormRules, historyFormRules, personalDataFormRules, passwdFormRules }

