import { API } from 'aws-amplify'

async function saveCompany ({ id, owner, name, logoA, logoB, logoC, currentUser }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/company/save', {
            body: {
                id, 
                owner, 
                name, 
                logoA, 
                logoB, 
                logoC, 
                currentUser
            }
        })
        console.log(result)
        return result;
    } catch(error) {
        console.error(error)
    }
}

async function saveCompanyPhoto ({id, logoA, logoB, logoC}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/company/updatePhoto', {
            body: {
                id,
                logoA,
                logoB,
                logoC
            }
        });
        return result;
    }catch(error) {
        console.error(error);
    }
}

async function updateCompanyData ({id, name}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/company/updateData', {
            body: {
                id,
                name
            }
        });
        return result;
    }catch(error) {
        console.error(error);
    }
}

export  { saveCompany, saveCompanyPhoto, updateCompanyData };