import { Layout } from 'element-react';

import React from 'react';

const TermsOfUseText = (props) => {
  return (

    <Layout.Row type="flex">
         <Layout.Col className="text-left p-4" span="24" xs="20" sm="20" lg="24">
            <Layout.Row className="privacy-padding">
        
            <>
            <div className="header privacy-policy-title">Terms of use</div>

<div className="header privacy-policy-title">General Terms</div>
<div className="content privacy-policy-content">
    Bainub is established in the Mexico and has been designed to comply with the legal framework of
    the United Mexican States.
    Each user is responsible for verifying the Website’s compatibility with the local laws of the
    place in which they are using it and
    refrain from using it if it is not.<br/><br/>
    The User declares and guarantees that he has the legal capacity, the right and the capacity to
    accept these Terms of Service and
    comply with them. User agrees to use the Services only for lawful purposes and in accordance
    with these Terms, and all
    applicable laws, rules, codes, directives, guidelines, policies, and regulations. The User
    agrees to use the Platform and the
    Services strictly for the purposes for which they were conceived in a serious, responsible
    manner and agrees that they must
    strictly comply with any instructions or indications contained in the Site or provided by
    authorized personnel of Bainub regarding
    the use of the Site.<br/><br/>
    By accessing and placing an order with Bainub, you confirm that you are in agreement with and
    bound by the terms of service
    contained in the Terms & Conditions outlined below. These terms apply to the entire website and
    any email or other type of
    communication between you and Bainub.
    Under no circumstances shall Bainub team be liable for any direct, indirect, special, incidental
    or consequential damages,
    including, but not limited to, loss of data or profit, arising out of the use, or the inability
    to use, the materials on this site, even
    if Bainub team or an authorized representative has been advised of the possibility of such
    damages. If your use of materials
    from this site results in the need for servicing, repair or correction of equipment or data, you
    assume any costs thereof.
    Bainub will not be responsible for any outcome that may occur during the course of usage of our
    resources. We reserve the
    rights to change prices and revise the resources usage policy in any moment.
</div>


<div className="header privacy-policy-title">Services</div>
<div className="content privacy-policy-content">
    Bainub's service is to provide and manage an electronic platform with tools for the exchange of
    information between users for
    the purpose of buying and selling products or services between them. This platform offers the
    user the tools to perform:
    <li>Creation of a user account.</li>
    <li>Consultation of user information.</li>
    <li>Publishing user’s company information.</li>
    <li>Publishing user’s products and/or user services.</li>
    <li>Communication between company and customer, website and users.</li>
    <li>Administration of the information of the user operations.</li>
    <li>Storage of communication and operations history.</li>
    <li>Alerts and Notifications System.</li>
    <br/><br/>
    Bainub reserves the right to make changes, add or remove services, in order to improve the
    experience of use and management
    of the platform without the need to notify or require acceptance of Site Users.
</div>

<div className="header privacy-policy-title">License</div>
<div className="content privacy-policy-content">
    Bainub grants you a revocable, non-exclusive, non-transferable, limited license to download,
    install and use the website
    strictly in accordance with the terms of this Agreement.<br/>
    These Terms & Conditions are a contract between you and Bainub (referred to in these Terms &
    Conditions as "Bainub", "us",
    "we" or "our"), the provider of the Bainub website and the services accessible from the Bainub
    website (which are collectively
    referred to in these Terms & Conditions as the "Bainub Service").<br/>
    You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms &
    Conditions, please do not
    use the Bainub Service. In these Terms & Conditions, "you" refers both to you as an individual
    and to the entity you represent.
    If you violate any of these Terms & Conditions, we reserve the right to cancel your account or
    block access to your account
    without notice.
</div>

<div className="header privacy-policy-title">Meanings</div>
<div className="content privacy-policy-content">
    For this Terms & Conditions:
    <li><b>Cookie:</b> small amount of data generated by a website and saved by your web browser. It
        is used to identify your browser,
        provide analytics, remember information about you such as your language preference or login
        information.</li>
    <li><b>Company:</b> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to
        Bainub S.A. de C.V., (Prolongación las
        Torres 138 Regio Parque Industrial Santa Catarina, Nuevo Leon 66367, Mexico) that is
        responsible for your information under
        this Terms & Conditions.
    </li>
    <li><b>Country:</b> where Bainub or the owners/founders of Bainub are based, in this case is
        México
    </li>
    <li><b>Device:</b> any internet connected device such as a phone, tablet, computer or any other
        device that can be used to visit
        Bainub and use the services.
    </li>
    <li><b>Service:</b> refers to the service provided by Bainub as described in the relative terms
        (if available) and on this platform.
    </li>
    <li><b>Third-party service:</b> refers to advertisers, contest sponsors, promotional and
        marketing partners, and others who provide
        our content or whose products or services we think may interest you.
    </li>
    <li><b>Website or Site:</b> Bainub"’s" site, which can be accessed via this URL: www.bainub.com
    </li>
    <li><b>You or User:</b> a person or entity that is registered with Bainub to use the Services.
    </li>
</div>

<div className="header privacy-policy-title">Account and registration</div>
<div className="content privacy-policy-content">
    Bainub has exclusive services for registered users. To use these Services, the User requiresto
    make a Bainub account by selecting
    a unique and non-transferable User Name, a password, and to provide basic personal, and contact
    information. You agree to
    verify that your Account information is correct and up-to-date, (b) keep your password
    confidential, (c) ensure that you log out
    of your account at the end of each session on the Site, (d) notify Bainub of any unauthorized
    use of your Username and/or
    password, (e) that you are fully responsible for all activities that occur under your Username
    and Account, even if such activities
    were not committed by you . Bainub will not be liable for any loss or damage arising from the
    unauthorized use of your password.
</div>

<div className="header privacy-policy-title">Website Usage</div>
<div className="content privacy-policy-content">
    Bainub is a platform with tools to assist in the purchase – sale process between two parties and
    does not participate in
    the negotiation, communication, contract, arbitration between the parties. In no case can Bainub
    be considered a supplier
    of any product or service in these exchanges, nor can the User be considered a consumer for the
    purposes of the Federal
    Consumer Protection Law.<br/><br/>
    The User agrees that, if required, they must present documentation proving Ownership and/or
    Legal Power of a legally
    registered company to use the company name as a Company on the Site. Bainub reserves the power
    to unilaterally cancel
    the registration of a Company on the Platform or request changes to make it compatible with the
    Terms and Conditions.<br/><br/>
    For its operation, Bainub requires information of the User's Company and the Products/Services
    offered through that
    Company. The User accepts that in no case, in any section of the Platform, the User will publish
    any content that is illegal,
    harmful, malicious, libelous, defamatory, obscene, invasive of the privacy of another person,
    threatening, harassing,
    abusive, hateful, racist, infringing, pornographic, violent, or otherwise objectionable or
    inappropriate as determined by
    Bainub; that you will not post content that contains personal information about any person,
    violates the privacy/publicity
    of another person or entity, or anything that you are contractually obligated to keep private or
    confidential; that you will
    not impersonate any person or organization or misrepresent an affiliation with any person or
    organization; You will not
    post any content that contains viruses, corrupted files, or any other similar software or
    programs that may adversely affect
    the operation of the Site or its Users.<br/><br/>
    The Content published by the User must be relevant to the Platform, specialized in wholesale
    trade between producers -
    consumers of products and services related to this activity. The publication of advertisements
    that are not related to the
    main function of the platform is expressly prohibited.<br/><br/>
    Users must ensure that the Content to be published complies with the regulations of the
    countries in which it is intended
    to be marketed. The User understands and agrees to be solely responsible and releases Bainub
    from any legal
    responsibility for advertising, promoting, marketing, sending, trafficking, sharing, delivering
    any type of product,
    information, service, images, digital files, software, substances, or any concept that fails to
    comply with the legal
    framework of the countries where said activity is carried out.<br/><br/>
    Users are solely responsible for the content shared using the different tools of the platform.
    Bainub has no influence or
    responsibility to review the information that Users share. Consequently, the User guarantees and
    will always be
    responsible for the legitimacy, accuracy, integrity, validity, and authenticity of the data
    published through pages,
    publications, messages, advertisements, or any form of information presentation on the Site.
    Bainub is not responsible
    for the legitimacy, certainty, accuracy, validity, authenticity, integrity of the information
    provided by Users.
    User understands that by using the Site, User may be exposed to Content that you may find
    unreliable, offensive,
    indecent, or objectionable. Bainub shall not be liable for any Content, any errors or omissions
    in any Content, or any loss
    or damage of any kind incurred as a result of the use of, or reliance on, any Content posted,
    emailed, transmitted or
    otherwise made available by Users of the Platform.<br/><br/>
    Bainub offers tools to promote Purchase-Sale (Trading) agreements between Users (Parties). The
    Trade Agreement is
    direct between the Buying User and the Selling User. Bainub is not part of, does not participate
    in, organizes, or offers
    arbitration to any contract between Buyer and Seller and, accepts no obligations in connection
    with such agreement.
    The Parties in said transaction, will be fully responsible for the sales contract between them,
    the publication of
    specifications and characteristics, guarantees, forms of payment, delivery times and any other
    disclosed or undisclosed
    part of the transaction. Bainub offers a Product Advertising and Transaction Management
    Platform, but does not
    participate in any way in reviewing the compliance of the information veracity, nor does it
    offer arbitration for
    compliance of trading operations.<br/><br/>
    The agreements between Buyer and Seller Users are independent of the Service that Bainub offers,
    so they are not
    affected in case of cancellation or suspension of the Site account, withdrawal of services by
    the Site, failure or
    interruption of the Services on the Site, temporary or permanent closure of the Site Services or
    any similar event. The
    only ones with the power to declare the change of status of an agreement between the Parties are
    the Parties
    themselves that originated said agreement, releasing Bainub from any responsibility that affects
    any of the parties.
</div>

<div className="header privacy-policy-title">Restrictions</div>
<div className="content privacy-policy-content">
    You agree not to, and you will not permit others to:
    <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or
        otherwise commercially exploit the website
        or make the platform available to any third party.</li>
    <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer
        any part of the website.
    </li>
    <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or
        trademark) of Bainub or its affiliates,
        partners, suppliers or the licensors of the website.
    </li>
    <li>Use mass marketing techniques on the platform. That is, sending mass messages by any means
        or publishing mass
        advertisements, either manually or with the help of special software.
    </li>
</div>

<div className="header privacy-policy-title">Return and Refund Policy</div>
<div className="content privacy-policy-content">
    Thanks for shopping at Bainub. We appreciate the fact that you like to buy the stuff we build.
    We also want to make sure you
    have a rewarding experience while you’re exploring, evaluating, and purchasing our products.
    As with any shopping experience, there are terms and conditions that apply to transactions at
    Bainub. We’ll be as brief as our
    attorneys will allow. The main thing to remember is that by placing an order or making a
    purchase at Bainub, you agree to the
    terms along with Bainub."’s" Privacy Policy.<br/>
    If, for any reason, You are not completely satisfied with any good or service that we provide,
    don't hesitate to contact us and
    we will discuss any of the issues you are going through with our product.
</div>

<div className="header privacy-policy-title">Your Suggestions</div>
<div className="content privacy-policy-content">
    Any feedback, comments, ideas, improvements, or suggestions (collectively, "Suggestions")
    provided by you to Bainub with
    respect to the website shall remain the sole and exclusive property of Bainub.<br/>
    Bainub shall be free to use, copy, modify, publish, or redistribute the Suggestions for any
    purpose and in any way without any
    credit or any compensation to you.
</div>

<div className="header privacy-policy-title">Your Consent</div>
<div className="content privacy-policy-content">
    We've updated our Terms & Conditions to provide you with complete transparency into what is
    being set when you visit our
    site and how it's being used. By using our website, registering an account, or making a
    purchase, you hereby consent to our
    Terms & Conditions.
</div>

<div className="header privacy-policy-title">Links to Other Websites</div>
<div className="content privacy-policy-content">
    This Terms & Conditions applies only to the Services. The Services may contain links to other
    websites not operated or
    controlled by Bainub. We are not responsible for the content, accuracy or opinions expressed in
    such websites, and such
    websites are not investigated, monitored or checked for accuracy or completeness by us. Please
    remember that when you use
    a link to go from the Services to another website, our Terms & Conditions are no longer in
    effect. Your browsing and
    interaction on any other website, including those that have a link on our platform, is subject
    to that website’s own rules and
    policies. Such third parties may use their own cookies or other methods to collect information
    about you.
</div>

<div className="header privacy-policy-title">Cookies</div>
<div className="content privacy-policy-content">
    Bainub uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a
    small piece of data stored on
    your computer or mobile device by your web browser. We use Cookies to enhance the performance
    and functionality of our
    website but are non-essential to their use. However, without these cookies, certain
    functionality like videos may become
    unavailable or you would be required to enter your login details every time you visit the
    website as we would not be able to
    remember that you had logged in previously. Most web browsers can be set to disable the use of
    Cookies. However, if you
    disable Cookies, you may not be able to access functionality on our website correctly or at all.
    We never place Personally
    Identifiable Information in Cookies.
</div>

<div className="header privacy-policy-title">Changes To Our Terms & Conditions</div>
<div className="content privacy-policy-content">
    You acknowledge and agree that Bainub may stop (permanently or temporarily) providing the
    Service (or any features within
    the Service) to you or to users generally at Bainub’s sole discretion, without prior notice to
    you. You may stop using the
    Service at any time. You do not need to specifically inform Bainub when you stop using the
    Service. You acknowledge and
    agree that if Bainub disables access to your account, you may be prevented from accessing the
    Service, your account details or
    any files or other materials which is contained in your account.<br/>
    If we decide to change our Terms & Conditions, we will post those changes on this page, and/or
    update the Terms &
    Conditions modification date below.
</div>

<div className="header privacy-policy-title">Modifications to Our website</div>
<div className="content privacy-policy-content">
    Bainub reserves the right to modify, suspend or discontinue, temporarily or permanently, the
    website or any service to which
    it connects, with or without notice and without liability to you.
</div>

<div className="header privacy-policy-title">Updates to Our website</div>
<div className="content privacy-policy-content">
    Bainub may from time to time provide enhancements or improvements to the features/ functionality
    of the website, which
    may include patches, bug fixes, updates, upgrades and other modifications ("Updates").<br/>
    Updates may modify or delete certain features and/or functionalities of the website. You agree
    that Bainub has no obligation
    to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or
    functionalities of the website to
    you.<br/>
    You further agree that all Updates will be (i) deemed to constitute an integral part of the
    website, and (ii) subject to the terms
    and conditions of this Agreement.
</div>

<div className="header privacy-policy-title">Third-Party Services</div>
<div className="content privacy-policy-content">
    We may display, include or make available third-party content (including data, information,
    applications and other products
    services) or provide links to third-party websites or services ("Third- Party Services").<br/>
    You acknowledge and agree that Bainub shall not be responsible for any Third-Party Services,
    including their accuracy,
    completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
    other aspect thereof. Bainub does
    not assume and shall not have any liability or responsibility to you or any other person or
    entity for any Third-Party Services.
    Third-Party Services and links thereto are provided solely as a convenience to you and you
    access and use them entirely at
    your own risk and subject to such third parties' terms and conditions.
</div>

<div className="header privacy-policy-title">Term and Termination</div>
<div className="content privacy-policy-content">
    This Agreement shall remain in effect until terminated by you or Bainub.
    Bainub may, in its sole discretion, at any time and for any or no reason, suspend or terminate
    this Agreement with or without
    prior notice.<br/>
    This Agreement will terminate immediately, without prior notice from Bainub, in the event that
    you fail to comply with any
    provision of this Agreement. You may also terminate this Agreement by deleting the website and
    all copies thereof from your
    computer.<br/>
    Upon termination of this Agreement, you shall cease all use of the website and delete all copies
    of the website from your
    computer.<br/>
    Termination of this Agreement will not limit any of Bainub's rights or remedies at law or in
    equity in case of breach by you
    (during the term of this Agreement) of any of your obligations under the present Agreement.<br/><br/>
    In case of termination, for any reason, motive or cause, the User remains responsible for any
    transaction in process like
    shipment of the product, pending payment, active guarantee, service and/or any agreement with
    its buyer/seller counterpart
    with which negotiations have been established through the Platform (whether it began before or
    after said termination). Since
    the agreements are between the Parties, the Parties are the only ones who can change them by
    mutual agreement, and, the
    termination of the relationship with the Site and their Services do not alter them in any way.
    Bainub will not be responsible for
    any infringement of Agreement between the Parties, even being responsible for the suspension or
    cancellation of the User
    rights of any of the Parties within the Platform.<br/>
    In the event of account termination, the User may not continue to use the Services directly or
    indirectly. Bainub reserves the
    right to Cancel alternative accounts or future accounts of a previously canceled account User.
</div>

<div className="header privacy-policy-title">Content Rights</div>
<div className="content privacy-policy-content">
    The User declares and guarantees to be the owner of the Content that the User publishes on the
    Site and in any case has the
    right to grant the license established herein and has the license to display or publish any
    content on the Site. Declares and
    guarantees that the information that the User publish on the Platform does not, and will not,
    infringe privacy rights, publicity
    rights, copyrights, trademark rights, patents, contract rights or any other intellectual or
    industrial property rights, or other
    rights of any third person or entity.<br/>
    The User expressly accepts that the information provided to Bainub will be stored, kept, used,
    transmitted, and reproduced by
    Bainub in order to provide Services to this and other users. Bainub shall have the non-binding
    right to reject, suspend, remove,
    or adjust any Content that any User has made available on the Site. User agrees that Bainub has
    the right to discontinue the
    delivery of communication (including but not limited to status updates, posts, messages and/or
    chats), to or from the Services
    as part of our effort to protect our Services, our Users, and to enforce the fulfillment of
    these Terms and Conditions.<br/><br/>
    As the information is used to generate purchases, sales, quotes, electronic communication, with
    one or more members of the
    platform, the User expressly accepts that the information provided to Bainub is stored, kept,
    used, transmitted and
    reproduced by Bainub even after of termination of the Services, so that the members with whom
    the User established
    agreements, can keep a record of the operations, even when their counterparty has terminated the
    relationship with Bainub.<br/><br/>
    The User grants Bainub a non-exclusive, sub-licensable, global, royalty-free license to use,
    modify, publicly represent, publicly
    display, reproduce, and distribute the User Content in any and all media now known, or hereafter
    developed, free of charge
    and, without the need to require the permission of the original User or any third party. This
    license includes the right to host,
    index, cache, distribute, and tag any User Content, as well as the right to sub-license User
    Content to third parties, including
    other users, for use in other media or platforms known or hereafter developed, for the purpose
    of providing current Services
    or offering new Services. You will continue to have all ownership rights to your User Content in
    the manner you choose,
    subject to these Terms and the license described herein.<br/><br/>
    If you are a copyright owner or such owner’s agent and believe any material on our website
    constitutes an infringement on
    your copyright, please contact us setting forth the following information: (a) a physical or
    electronic signature of the copyright
    owner or a person authorized to act on his behalf; (b) identification of the material that is
    claimed to be infringing; (c) your
    contact information, including your address, telephone number, and an email; (d) a statement by
    you that you have a good
    faith belief that use of the material is not authorized by the copyright owners; and (e) the a
    statement that the information in
    the notification is accurate, and, under penalty of perjury you are authorized to act on behalf
    of the owner.
</div>

<div className="header privacy-policy-title">Indemnification</div>
<div className="content privacy-policy-content">
    You agree to indemnify and hold Bainub and its parents, subsidiaries, affiliates, officers,
    employees, agents, partners and
    licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due
    to or arising out of your: (a)
    use of the website; (b) violation of this Agreement or any law or regulation; or (c) violation
    of any right of a third party; or (d)
    information, or information arising from the content that the user includes, presents, announces
    or transmits through the
    Service.
</div>

<div className="header privacy-policy-title">No Warranties</div>
<div className="content privacy-policy-content">
    The website is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects
    without warranty of any kind. To
    the maximum extent permitted under applicable law, Bainub, on its own behalf and on behalf of
    its affiliates and its and their
    respective licensors and service providers, expressly disclaims all warranties, whether express,
    implied, statutory or otherwise,
    with respect to the website, including all implied warranties of merchantability, fitness for a
    particular purpose, title and noninfringement, and warranties that may arise out of course of
    dealing, course of performance, usage or trade practice. Without
    limitation to the foregoing, Bainub provides no warranty or undertaking, and makes no
    representation of any kind that the
    website will meet your requirements, achieve any intended results, be compatible or work with
    any other software, , systems
    or services, operate without interruption, meet any performance or reliability standards or be
    error free or that any errors or
    defects can or will be corrected.<br/><br/>
    Under no circumstances, including negligence, shall Bainub or its associates be liable for any
    direct, indirect, incidental,
    special, punitive, or consequential damages resulting from the use of or inability to use the
    Site, nor shall they be liable for any
    damages resulting from errors, omissions, interruptions, file deletions, mistakes, defects,
    vices, delays in operation or
    transmission, or any failure or performance whether or not caused by events beyond the
    reasonable control of the company,
    including, but not limited to causes of force majeure, communication line failures, theft,
    destruction, or unauthorized access
    to site records, programs, or services, or intentional damage by third parties.<br/>
    Without limiting the foregoing, neither Bainub nor any Bainub's provider makes any
    representation or warranty of any kind,
    express or implied: (i) as to the operation or availability of the website, or the information,
    content, and materials or products
    included thereon; (ii) that the website will be uninterrupted or error-free; (iii) as to the
    accuracy, reliability, or currency of any
    information or content provided through the website; or (iv) that the website, its servers, the
    content, or e-mails sent from or
    on behalf of Bainub are free of viruses, scripts, trojan horses, worms, malware, timebombs or
    other harmful components.
    Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the
    limitations on the applicable
    statutory rights of a consumer, so some or all of the above exclusions and limitations may not
    apply to you.
</div>

<div className="header privacy-policy-title">Limitation of Liability</div>
<div className="content privacy-policy-content">
    Notwithstanding any damages that you might incur, the entire liability of Bainub and any of its
    suppliers under any provision
    of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the
    amount actually paid by you for
    the website.<br/>
    To the maximum extent permitted by applicable law, in no event shall Bainub or its suppliers be
    liable for any special,
    incidental, indirect, or consequential damages whatsoever (including, but not limited to,
    damages for loss of profits, for loss of
    data or other information, for business interruption, for personal injury, for loss of privacy
    arising out of or in any way related
    to the use of or inability to use the website, third-party software and/or third-party hardware
    used with the website, or
    otherwise in connection with any provision of this Agreement), even if Bainub or any supplier
    has been advised of the
    possibility of such damages and even if the remedy fails of its essential purpose.<br/>
    Some states/jurisdictions do not allow the exclusion or limitation of incidental or
    consequential damages, so the above
    limitation or exclusion may not apply to you.
</div>

<div className="header privacy-policy-title">Severability</div>
<div className="content privacy-policy-content">
    If any provision of this Agreement is held to be unenforceable or invalid, such provision will
    be changed and interpreted to
    accomplish the objectives of such provision to the greatest extent possible under applicable law
    and the remaining provisions
    will continue in full force and effect.<br/>
    This Agreement, together with the Privacy Policy and any other legal notices published by Bainub
    on the Services, shall
    constitute the entire agreement between you and Bainub concerning the Services. If any provision
    of this Agreement is
    deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not
    affect the validity of the
    remaining provisions of this Agreement, which shall remain in full force and effect. No waiver
    of any term of this Agreement
    shall be deemed a further or continuing waiver of such term or any other term, and Bainub."’s"
    failure to assert any right or
    provision under this Agreement shall not constitute a waiver of such right or provision. YOU AND
    Bainub AGREE THAT ANY
    CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR
    AFTER THE
    CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
</div>

<div className="header privacy-policy-title">Waiver</div>
<div className="content privacy-policy-content">
    Except as provided herein, the failure to exercise a right or to require performance of an
    obligation under this Agreement shall
    not effect a party's ability to exercise such right or require such performance at any time
    thereafter nor shall be the waiver of
    a breach constitute waiver of any subsequent breach.<br/>
    No failure to exercise, and no delay in exercising, on the part of either party, any right or
    any power under this Agreement
    shall operate as a waiver of that right or power. Nor shall any single or partial exercise of
    any right or power under this
    Agreement preclude further exercise of that or any other right granted herein. In the event of a
    conflict between this
    Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.
</div>

<div className="header privacy-policy-title">Amendments to this Agreement</div>
<div className="content privacy-policy-content">
    Bainub reserves the right, at its sole discretion, to modify or replace this Agreement at any
    time. If a revision is material we
    will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a
    material change will be
    determined at our sole discretion.<br/>
    By continuing to access or use our website after any revisions become effective, you agree to be
    bound by the revised terms.
    If you do not agree to the new terms, you are no longer authorized to use Bainub.
</div>

<div className="header privacy-policy-title">Entire Agreement</div>
<div className="content privacy-policy-content">
    The Agreement constitutes the entire agreement between you and Bainub regarding your use of the
    website and supersedes
    all prior and contemporaneous written or oral agreements between you and Bainub.
    You may be subject to additional terms and conditions that apply when you use or purchase other
    Bainub’s services, which
    Bainub will provide to you at the time of such use or purchase.
</div>

<div className="header privacy-policy-title">Updates to Our Terms</div>
<div className="content privacy-policy-content">
    We may change our Service and policies, and we may need to make changes to these Terms so that
    they accurately reflect our
    Service and policies. Unless otherwise required by law, we will notify you (for example, through
    our Service) before we make
    changes to these Terms and give you an opportunity to review them before they go into effect.
    Then, if you continue to use
    the Service, you will be bound by the updated Terms. If you do not want to agree to these or any
    updated Terms, you can
    delete your account.
</div>

<div className="header privacy-policy-title">Intellectual Property</div>
<div className="content privacy-policy-content">
    The website and its entire contents, features and functionality (including but not limited to
    all information, software, text,
    displays, images, video and audio, and the design, selection and arrangement thereof), are owned
    by Bainub, its licensors or
    other providers of such material and are protected by México and international copyright,
    trademark, patent, trade secret and
    other intellectual property or proprietary rights laws. The material may not be copied,
    modified, reproduced, downloaded or
    distributed in any way, in whole or in part, without the express prior written permission of
    Bainub, unless and except as is
    expressly provided in these Terms & Conditions. Any unauthorized use of the material is
    prohibited.
</div>

<div className="header privacy-policy-title">Agreement to Arbitrate</div>
<div className="content privacy-policy-content">
    This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS FOR
    INJUNCTIVE OR
    EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR Bainub."’s" INTELLECTUAL
    PROPERTY RIGHTS.
    The term “dispute” means any dispute, action, or other controversy between you and Bainub
    concerning the Services or this
    agreement, whether in contract, warranty, tort, statute, regulation, ordinance, or any other
    legal or equitable basis. “Dispute”
    will be given the broadest possible meaning allowable under law.
</div>

<div className="header privacy-policy-title">Notice of Dispute</div>
<div className="content privacy-policy-content">
    In the event of a dispute, you or Bainub must give the other a Notice of Dispute, which is a
    written statement that sets forth
    the name, address, and contact information of the party giving it, the facts giving rise to the
    dispute, and the relief requested.
    You must send any Notice of Dispute via email to: customerservice@bainub.com. Bainub will send
    any Notice of Dispute to
    you by mail to your address if we have it, or otherwise to your email address. You and Bainub
    will attempt to resolve any
    dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute
    is sent. After sixty (60) days,
    you or Bainub may commence arbitration.
</div>

<div className="header privacy-policy-title">Binding Arbitration</div>
<div className="content privacy-policy-content">
    If you and Bainub don’t resolve any dispute by informal negotiation, any other effort to resolve
    the dispute will be conducted
    exclusively by binding arbitration as described in this section. You are giving up the right to
    litigate (or participate in as a party
    or class member) all disputes in court before a judge or jury. The dispute shall be settled by
    binding arbitration in accordance
    with the commercial arbitration rules of the American Arbitration Association. Either party may
    seek any interim or
    preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect
    the party’s rights or property
    pending the completion of arbitration. Any and all legal, accounting, and other costs, fees, and
    expenses incurred by the
    prevailing party shall be borne by the non-prevailing party.
</div>

<div className="header privacy-policy-title">Submissions and Privacy</div>
<div className="content privacy-policy-content">
    In the event that you submit or post any ideas, creative suggestions, designs, photographs,
    information, advertisements, data
    or proposals, including ideas for new or improved products, services, features, technologies or
    promotions, you expressly
    agree that such submissions will automatically be treated as non-confidential and
    non-proprietary and will become the sole
    property of Bainub without any compensation or credit to you whatsoever. Bainub and its
    affiliates shall have no obligations
    with respect to such submissions or posts and may use the ideas contained in such submissions or
    posts for any purposes in
    any medium in perpetuity, including, but not limited to, developing, manufacturing, and
    marketing products and services
    using such ideas.
</div>

<div className="header privacy-policy-title">Promotions</div>
<div className="content privacy-policy-content">
    Bainub may, from time to time, include contests, promotions, sweepstakes, or other activities
    (“Promotions”) that require you
    to submit material or information concerning yourself. Please note that all Promotions may be
    governed by separate rules
    that may contain certain eligibility requirements, such as restrictions as to age and geographic
    location. You are responsible to
    read all Promotions rules to determine whether or not you are eligible to participate. If you
    enter any Promotion, you agree to
    abide by and to comply with all Promotions Rules.<br/>
    Additional terms and conditions may apply to purchases of goods or services on or through the
    Services, which terms and
    conditions are made a part of this Agreement by this reference.
</div>

<div className="header privacy-policy-title">Typographical Errors</div>
<div className="content privacy-policy-content">
    In the event a product and/or service is listed at an incorrect price or with incorrect
    information due to typographical error,
    we shall have the right to refuse or cancel any orders placed for the product and/or service
    listed at the incorrect price. We
    shall have the right to refuse or cancel any such order whether or not the order has been
    confirmed and your credit card
    charged. If your credit card has already been charged for the purchase and your order is
    canceled, we shall immediately issue
    a credit to your credit card account or other payment account in the amount of the charge.
</div>

<div className="header privacy-policy-title">Miscellaneous</div>
<div className="content privacy-policy-content">
    If for any reason a court of competent jurisdiction finds any provision or portion of these
    Terms & Conditions to be
    unenforceable, the remainder of these Terms & Conditions will continue in full force and effect.
    Any waiver of any provision of
    these Terms & Conditions will be effective only if in writing and signed by an authorized
    representative of Bainub. Bainub will
    be entitled to injunctive or other equitable relief (without the obligations of posting any bond
    or surety) in the event of any
    breach or anticipatory breach by you. Bainub operates and controls the Bainub Service from its
    offices in México. The Service
    is not intended for distribution to or use by any person or entity in any jurisdiction or
    country where such distribution or use
    would be contrary to law or regulation. Accordingly, those persons who choose to access the
    Bainub Service from other
    locations do so on their own initiative and are solely responsible for compliance with local
    laws, if and to the extent local laws
    are applicable. These Terms & Conditions (which include and incorporate the Bainub Privacy
    Policy) contains the entire
    understanding, and supersedes all prior understandings, between you and Bainub concerning its
    subject matter, and cannot
    be changed or modified by you. The section headings used in this Agreement are for convenience
    only and will not be given
    any legal import.
</div>

<div className="header privacy-policy-title">Disclaimer</div>
<div className="content privacy-policy-content">
    Bainub is not responsible for any content, code or any other imprecision.
    Bainub does not provide warranties or guarantees.<br/>
    In no event shall Bainub be liable for any special, direct, indirect, consequential, or
    incidental damages or any damages
    whatsoever, whether in an action of contract, negligence or other tort, arising out of or in
    connection with the use of the
    Service or the contents of the Service. Bainub reserves the right to make additions, deletions,
    or modifications to the contents
    on the Service at any time without prior notice.<br/>
    The Bainub Service and its contents are provided "as is" and "as available" without any warranty
    or representations of any
    kind, whether express or implied. Bainub is a distributor and not a publisher of the content
    supplied by third parties; as such,
    Bainub exercises no editorial control over such content and makes no warranty or representation
    as to the accuracy, reliability
    or currency of any information, content, service or merchandise provided through or accessible
    via the Bainub Service.
    Without limiting the foregoing, Bainub specifically disclaims all warranties and representations
    in any content transmitted on
    or in connection with the Bainub Service or on sites that may appear as links on the Bainub
    Service, or in the products
    provided as a part of, or otherwise in connection with, the Bainub Service, including without
    limitation any warranties of
    merchantability, fitness for a particular purpose or non-infringement of third party rights. No
    oral advice or written
    information given by Bainub or any of its affiliates, employees, officers, directors, agents, or
    the like will create a warranty.
    Price and availability information is subject to change without notice. Without limiting the
    foregoing, Bainub does not warrant
    that the Bainub Service will be uninterrupted, uncorrupted, timely, or error-free.<br/>
    The User understands and accepts that any material downloaded or obtained by any means available
    on the Site, is done at
    the User’s own discretion and risk, and the User will be fully responsible for any damage to
    their computer system or loss of
    data that results from the download of any of these materials.
</div>

<div className="header privacy-policy-title">Contact Us</div>
<div className="content privacy-policy-content">
    Don't hesitate to contact us if you have any questions.
    <li>Via Email: customerservice@bainub.com</li>
</div>
            </>
            </Layout.Row>
        
         </Layout.Col>
    </Layout.Row>
  )
}

export default TermsOfUseText;