import React from 'react';
const ProductIcon = (props) => {
  return (
            <svg onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.coords ? props.coords : "0 0 41 41"}>
            <g id="Group_1383" data-name="Group 1383" transform="translate(-371 -1386)">
              <g id="Ellipse_54" data-name="Ellipse 54" transform="translate(371 1386)" fill="#fff" stroke="#929498" stroke-width="2">
                <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                <circle cx="20.5" cy="20.5" r="19.5" fill="none"/>
              </g>
              <path id="box-solid" d="M2.182,33.141A2.064,2.064,0,0,1,4.028,32H8.945v5.5H0Zm8.14,4.364V32h4.916a2.071,2.071,0,0,1,1.849,1.141l2.18,4.364Zm8.945,11.01a2.755,2.755,0,0,1-2.752,2.752H2.752A2.754,2.754,0,0,1,0,48.515V38.881H19.267Z" transform="translate(382 1365)" fill={props.color ? props.color : "#929498"}/>
            </g>
          </svg>
  )
}

export default ProductIcon;