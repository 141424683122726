import {Layout} from 'element-react';
import {Link} from 'react-router-dom';
import React, {useState} from 'react';
import Popup from 'reactjs-popup';
import TermsOfUse from '../pages/TermsOfUse'
import PrivacyPolicy from "../pages/PrivacyPolicy";

import {useTranslation} from 'react-i18next';

export default function Footer() {
    const {t} = useTranslation();
    const language = t('language')
    const [visible, setVisible] = useState(false)
    const [isDialogVisible, setIsDialogVisible] = useState(false)
    const [isPrivacyPolicyVisible, setIsPrivacyPolicyVisible] = useState(false);

    const showTemrs = () => {
        setVisible(true)
    }

    const showPrivacyPolicy = () => {
        setIsPrivacyPolicyVisible(true)
    }


    return (
        <div className="footer">
            <TermsOfUse language={language} visible={visible} setVisible={setVisible}/>
            <PrivacyPolicy language={language} isPrivacyPolicyVisible={isPrivacyPolicyVisible}
                           setIsPrivacyPolicyVisible={setIsPrivacyPolicyVisible}/>
            <Layout.Row>
                <Layout.Col xs="24" md="9" className="footer-label">
                    BAINUB
                </Layout.Col>
                <Layout.Col xs="24" md="3" className="pt-1">
                <Link to="/terms-of-use" target="_blank">{t('footer.terms-of-use')}</Link>
                </Layout.Col>
                <Layout.Col xs="24" md="3" className="pt-1">
                <Link to="/privacy-policy" target="_blank">{t('footer.privacy-policy')}</Link>
                </Layout.Col>
                <Layout.Col xs="24" md="3" className="pt-1">
                <Link to="/about-us" target="_blank">About Us</Link>
                </Layout.Col>
                <Layout.Col xs="24" md="3" className="pt-1">
                <Link to="/help-support" target="_blank" style={{marginRight: "3rem"}}>{t('footer.help-and-support')}</Link>
                </Layout.Col>
                <Layout.Col xs="24" md="2" className="pt-1">
                <Link to="/contact-us" target="_blank">{t('footer.contact-us')}</Link>
                </Layout.Col>
                
            </Layout.Row>
        </div>
    )
}
