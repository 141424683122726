import { Card, Layout } from "element-react";
import { Link, useHistory } from "react-router-dom";
import aboutUsImg from "../assets/imgs/about-us-default.png";
import EyeIcon from "./EyeIcon";
import StarFilledIcon from "./StarFilledIcon";
import LineSeparator from "./LineSeparator";
import { toCurrency } from "../utils/FormatUtils";
import ChatIcon from "./ChatIcon";
import { S3Image } from "../components/S3Image";
import {countryOptions} from '../utils/Catalogs';
import ProductIconClean from './order/ProductIconClean';

const ResultCard = (props) => {
  const prd = props.product;
  const company = props.company;
  const favorites = props.favorites;
  const t = props.t;
  const addToFavorites = props.addToFavorites;
  const removeFromFavorites = props.removeFromFavorites;
  const isFav = () => {
    let result = favorites.filter((obj) => {
      let isFav = obj.product.id === prd.id;
      console.log(isFav);
      return isFav;
      //return 0;
    });
    console.log({ result });
    return result.length > 0 ? result[0] : undefined;
  };
  return (
    <>
      <Card className="result-card-style">
        <Layout.Row>
          <Link  id="ga_view_product_picture" target="_blank" to={"/product-detail/" + prd.id}>
            <Layout.Col className="center-card-img-prd" sm="24" md="10" lg="5">
              {/*
                                    <img className="special-container__image" src={aboutUsImg} style={{ width: "120px", height: "120px" }}/>
                                    */}
              {prd && prd.photo && prd.photo.length > 0 ? (
                <S3Image
                  className="special-container"
                  imgKey={prd.photo[0].key}
                  theme={{
                    photoImg: { width: "84%" },
                  }}
                  level="public"
                />
              ) : (
                <img
                  className="special-container__image"
                  src={aboutUsImg}
                  style={{ width: "200px", height: "200px" }}
                />
              )}
            </Layout.Col>
          </Link>
          <Layout.Col sm="24" md="10" lg="19">
            <Layout.Row>
              <Link
                id="ga_view_product_link"
                className="result-product-name"
                target="_blank"
                to={"/product-detail/" + prd.id}
              >
                {prd.name}
              </Link>
            </Layout.Row>
            <Layout.Row type="flex">
              <Layout.Col>
                <b className="result-by">{t("search.by")}:</b>{" "}
                <Link
                    className="result-company"
                    target="_blank"
                    to={"/company-store/" + prd.company_id}
                >
                  {prd.company_name}
                </Link>
              </Layout.Col>
            </Layout.Row>
            <Layout.Row type="flex">
              <Layout.Col>
                <span className="result-category">
                  {prd.category?.category_name + ' > ' + prd.category?.name}
                </span>
              </Layout.Col>
            </Layout.Row>
              <Layout.Row type="flex">
                  <Layout.Col>
                      <div className="result-info">
                      {
                        prd.company_country && <span className="result-country">
                        {
                          countryOptions.options.find(country => country.value === prd?.company_country)?.label + ", "
                        }
                        </span>
                      }
                      {prd.verified === 1 && <span className="result-verified">Verified &nbsp;&nbsp;</span>}
                      {prd.bainub_account_year && <span className="result-bainub-account-year">{prd.bainub_account_year}Y in Bainub&nbsp;&nbsp;</span>}
                      {prd.supplier_rating && <span className="result-supplier-rating">Gold Supplier&nbsp;&nbsp;</span>}
                      {prd.customizable === "true" && <span className="result-customizable">Customization Available&nbsp;&nbsp;</span>}
                      </div>
                      {/*<span className="result-country">{prd.company_country ? `${prd.company_country} `: ""}</span>*/}
                      {/*<span className="result-verified">Verified </span>*/}
                      {/*<span className="result-bainub-account-year">{prd.bainub_account_year ? `${prd.bainub_account_year}Y in Bainub `: ""}</span>*/}
                      {/*<span className="result-supplier-rating">{prd.supplier_rating ? `${prd.supplier_rating} `: ""}</span>*/}
                      {/*<span className="result-supplier-rating">Gold Supplier  </span>*/}
                      {/*<span className="result-customizable">{prd.customizable === "true" ? "Customization Available" : ""}</span>*/}
                  </Layout.Col>
              </Layout.Row>
              <Layout.Row type="flex">
                  <Layout.Col className="result-by">
                      <b>{t("search.price-range")}</b>
                      {": "} <span>{toCurrency(prd.min_price)}</span> - <span>{toCurrency(prd.max_price)}</span> USD/
                      <span>{prd.measurement}</span>
                  </Layout.Col>
              </Layout.Row>
              <Layout.Row type="flex">
                  <Layout.Col className="result-by">
                      <b>{t("search.packing")}:</b> {prd.package_type} {prd.quantity_per_package && <span>- {prd.quantity_per_package} {prd.measurement}s</span>}
                  </Layout.Col>
              </Layout.Row>

             <Layout.Row type="flex">
                 <Layout.Col className="result-by">
                      <b>{t("search.min-order")}:</b> <span>{prd.min_order} {prd.measurement}</span>
                  </Layout.Col>
              </Layout.Row>
            <hr></hr>
            <Layout.Row
              className="result-card actions-result p-t-md"
              type="flex"
            >
              <Layout.Col lg="5">
                <div
                  style={{
                    display: "inline-flex",
                    cursor: "pointer",
                    paddingTop: "8px",
                  }}
                >
                  <Link style={{
                    display: "inline-flex",
                    cursor: "pointer",
                    //paddingTop: "8px",
                  }} target="_blank" to={"/product-detail/" + prd.id}>
                  <ProductIconClean color="#FF6D73" width={24} height={24} />

                  
                  <div
                    style={{ paddingLeft: "8px" }}
                    className="link-text"
                    onClick={() => {
                      
                    }}
                  >
                    {'View Product Details'}{" "}
                  </div>
                  </Link>
                </div>
              </Layout.Col>
              <Layout.Col lg="5">
                <div
                  style={{
                    display: "inline-flex",
                    cursor: "pointer",
                    paddingTop: "8px",
                  }}
                >
                  <ChatIcon color="#FF6D73" width={18} height={18} />
                  <div
                  id="ga_contact_supplier"
                    style={{ paddingLeft: "8px" }}
                    className="link-text"
                    onClick={() => {
                      props.handleChatWithUs(prd.owner, prd);
                    }}
                  >
                    {" "}
                    {t("search.contact-supplier")}{" "}
                  </div>
                </div>
              </Layout.Col>
              <Layout.Col
                className="cursor"
                style={{ textAlign: "left" }}
                lg="8"
              >
                <div id="ga_add_favorites" style={{ display: "inline-flex", paddingTop: "8px" }}>
                  <StarFilledIcon width={18} height={18} />
                  {isFav() ? (
                    <div
                      className="link-text"
                      onClick={() => {
                        removeFromFavorites(isFav());
                      }}
                    >
                      {" "}
                      {t("search.remove-to-list")}{" "}
                    </div>
                  ) : (
                    <div
                      className="link-text"
                      onClick={() => {
                        addToFavorites(prd.id);
                      }}
                    >
                      {" "}
                      {t("search.add-to-list")}{" "}
                    </div>
                  )}
                </div>
              </Layout.Col>
            </Layout.Row>
          </Layout.Col>
        </Layout.Row>
      </Card>
    </>
  );
};

{
}

export default ResultCard;
