import {useEffect, useState, useRef} from 'react';
import { Layout, Button, Card, Form, Loading, Dropdown} from 'element-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from "react-router-dom";
import Summary from '../components/order/Summary';
import SellerInfo from '../components/order/SellerInfo';
import BuyerInfo from '../components/order/BuyerInfo';
import ProductInfo from '../components/order/ProductInfo';
import { getOrder, listCounterInfoTables } from '../graphql/queries';
import { updateOrder, updateCounterInfoTable } from '../graphql/mutations';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import TermsSection from '../components/order/TermsSection';
import FeeSection from '../components/order/FeeSection';
import PaymentSection from '../components/order/PaymentSection';
import { formatDate, stringToDate } from '../utils/FormatUtils';
import { generalFormRules } from '../utils/OrderFormRules';
import { sellerStatus } from '../services/OrderService';
import HistorySection from '../components/order/HistorySection';
import { pad } from '../utils/FormatUtils';
import BuyerBanner from '../components/order/BuyerBanner';
import sendEmailTemplate from '../graphql/lambdaInvokes';
import * as Constans from '../../src/utils/Constants';
import userStore from '../store/user';
import { listChats } from '../graphql/queries';

function OrderWizard(){
    const { user } = userStore();
    const [loading, setLoading] = useState(false);
    const [orderOwner, setOrderOwner] = useState(null);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const form1 = useRef(null);
    const buyerEmailRef = useRef();
    const [formInputs, setFormInputs] = useState({
        name : '',
        orderNumber: 0,
        companyName: '',
        products: [],
        fees: [],
        payments: [],
        sellerStatus: 0,
        total: 0
    });
    const [rules1, setRules1] = useState(null);
    let { id } = useParams();

    useEffect(() => {
        console.log('OrderWizard');
        //setFormInputs({...formInputs, orderNumber: id })
        loadOrder();
        setRules1(generalFormRules(t));
    },[t]);

    const loadOrder = async() =>{
        setLoading(true);
        console.log('loadOrder')
        try{
            let input = {
                id: id
            }
            const result  = await API.graphql({
                query: getOrder,
                variables: input
              });
            console.log("================ ORDER ================")
            console.log({result})
            const _odersDB = result.data.getOrder;
            setOrderOwner(_odersDB.owner);
            let products = [];
            _odersDB.products?.forEach((element, index) => { 
                products.push({
                    index : index,
                    product: {
                        index : index,
                        name: element.name,
                        id: element.id,
                        photo: [element.photo]
                    },
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotal: element.subtotalPrice,
                    description: element.comments
                })
            });

            let fees = [];
            _odersDB.additionalFees?.forEach((element, index) => { 
                fees.push({
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotal: element.subtotal,
                    fee: element.fee,
                    description: element.description
                })
            });
            
            let payments = [];
            _odersDB.payments?.forEach((element, index) => { 
                payments.push({
                    date: stringToDate(element.date),
                    amount: element.amount,
                    method: element.method,
                    notes: element.notes,
                })
            });

            let history = [];
            _odersDB.history?.forEach((element, index) => { 
                history.push({
                    date: stringToDate(element.date),
                    seller_status: element.seller_status,
                    buyer_status: element.buyer_status,
                    process_status: element.process_status,
                    type: element.type,
                    note: element.note
                })
            });

            setFormInputs({
                id: id,
                name : '',
                sellerStatus : _odersDB.seller_status,
                buyerStatus : _odersDB.buyer_status,
                trackingNumber: _odersDB.tracking_number,
                orderNumber : _odersDB.orderNumber,
                companyName : _odersDB.company_name,
                companyContactName: _odersDB.company_contact_name,
                companyEmail: _odersDB.company_email,
                companyAddress: _odersDB.company_address,
                //3. BuyerInfo
                buyerEmail: _odersDB.buyer_email ? _odersDB.buyer_email : '',
                buyerName: _odersDB.buyer_name ? _odersDB.buyer_name : '',
                buyerPhone: _odersDB.buyer_phone ? _odersDB.buyer_phone : '',
                buyer: '',
                street: _odersDB.billingAddress?.street,
                city: _odersDB.billingAddress?.city,
                state: _odersDB.billingAddress?.state,
                country: _odersDB.billingAddress?.country,
                zipCode: _odersDB.billingAddress?.zipCode,
                products: products,
                currency: _odersDB.terms?.currency ? _odersDB.terms?.currency : '' ,
                paymentMethod: _odersDB.terms?.paymentMethod,
                deliveryDate: stringToDate(_odersDB.terms?.deliveryDate),
                guarantee: _odersDB.terms?.guarantee,
                incoterm: _odersDB.terms?.incoterm,
                //6. Shipping
                shippingMethod: _odersDB.shipping?.method,
                paymentCost: _odersDB.shipping?.cost,
                shippingStatus: _odersDB.shipping?.status,
                originStreet: _odersDB.shipping?.originAddress.street,
                originCity: _odersDB.shipping?.originAddress.city,
                originState: _odersDB.shipping?.originAddress.state,
                originCountry: _odersDB.shipping?.originAddress.country,
                originZip: _odersDB.shipping?.originAddress.zipCode,
                originPhone: _odersDB.shipping?.originAddress.phone,
                originContact: _odersDB.shipping?.originAddress.contact,
                destinationStreet: _odersDB.shipping?.destinationAddress.street,
                destinationCity: _odersDB.shipping?.destinationAddress.city,
                destinationState: _odersDB.shipping?.destinationAddress.state,
                destinationCountry: _odersDB.shipping?.destinationAddress.country,
                destinationZip: _odersDB.shipping?.destinationAddress.zipCode,
                destinationPhone: _odersDB.shipping?.destinationAddress.phone,
                destinationContact: _odersDB.shipping?.destinationAddress.contact,
                shippingSpecialInfo: _odersDB.shipping?.specialInstructions,
                //7. Fees
                fees: fees,
                //8. Payments
                payments: payments,
                total: _odersDB.total,
                history: history
            })
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
        }
    }

    const updateOrderFromForm = async (sellerStatus, buyerStatus, historyParam) =>{
        console.log('updating order');
        console.log(`buyerStatus :: ${buyerStatus}`);
        console.log(`historyParam :: ${historyParam}`);
        setLoading(true);
        try{
            let billingAddress = {
                street: formInputs.street,
                city: formInputs.city,
                state: formInputs.state,
                country: formInputs.country,
                zipCode: formInputs.zipCode
            }
    
            let products = [];
            formInputs.products.forEach((element, index) => {
                products.push({
                    //index: index,
                    name: element.product.name,
                    id: element.product.id,
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotalPrice: element.subtotal,
                    comments: element.description,
                    photo: element.product.photo ? element.product.photo[0] : null
                })
            })

            let fees = [];
            formInputs.fees.forEach((element, index) => {
                fees.push({
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotal: element.subtotal,
                    description: element.description,
                    fee: element.fee
                })
            });

            let payments = [];
            formInputs.payments.forEach((element, index) => {
                payments.push({
                    date: formatDate(element.date),
                    amount: element.amount,
                    method: element.method,
                    notes: element.notes
                })
            });

            let history = [];
            let __history = historyParam?.length > 0 ? historyParam : formInputs.history;
            __history.forEach((element, index) => {
                history.push({
                    date: formatDate(element.date),
                    seller_status: element.seller_status,
                    buyer_status: element.buyer_status,
                    process_status: element.process_status,
                    type: element.type,
                    note: element.note
                })
            });

            console.log(`formInputs.deliveryDate :: ${formInputs.deliveryDate}`);
            let terms = {
                currency: formInputs.currency ? formInputs.currency : '',
                paymentMethod: formInputs.paymentMethod ? formInputs.paymentMethod : '',
                deliveryDate: formInputs.deliveryDate && formInputs.deliveryDate != 'Invalid Date' ? formatDate(formInputs.deliveryDate) :  '1900-01-01',
                guarantee: formInputs.guarantee ? formInputs.guarantee : '',
                incoterm: formInputs.incoterm ? formInputs.incoterm : ''
            }

            let shipping = {
                method: formInputs.shippingMethod,
                cost: formInputs.paymentCost,
                status: formInputs.shippingStatus,
                originAddress: {
                    street: formInputs.originStreet,
                    city: formInputs.originCity,
                    state: formInputs.originState,
                    country: formInputs.originCountry,
                    zipCode: formInputs.originZip,
                    phone: formInputs.originPhone,
                    contact: formInputs.originContact
                },
                destinationAddress: {
                    street: formInputs.destinationStreet,
                    city: formInputs.destinationCity,
                    state: formInputs.destinationState,
                    country: formInputs.destinationCountry,
                    zipCode: formInputs.destinationZip,
                    phone: formInputs.destinationPhone,
                    contact: formInputs.destinationContact
                },
                specialInstructions: formInputs.shippingSpecialInfo,
            }
    
            const input = {
                id: id,
                orderNumber : formInputs.orderNumber,
                company_name : formInputs.companyName,
                company_contact_name: formInputs.companyContactName,
                company_email: formInputs.companyEmail,
                company_address: formInputs.companyAddress,
                buyer_email: formInputs.buyerEmail,
                buyer_name: formInputs.buyerName,
                buyer_phone: formInputs.buyerPhone,
                billingAddress: billingAddress,
                products: products,
                terms: terms,
                shipping : shipping,
                additionalFees: fees,
                payments: payments,
                tracking_number : formInputs.trackingNumber,
                seller_status : sellerStatus !== -1 ? sellerStatus : formInputs.sellerStatus,
                buyer_status : buyerStatus,
                total: formInputs.total,
                history: history
            }
            const result = await API.graphql(
                graphqlOperation(updateOrder, { input })
              );
            console.log("Order updated", result);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
        
    }

    const LogicStatusLabel = () =>{
        return sellerStatus(formInputs.sellerStatus, formInputs.buyerStatus);
    }

    const cancelOrder = async () =>{
        setFormInputs({...formInputs, sellerStatus: 5 });
        await updateOrderFromForm(5);
    }

    const updateProductCounter = async() =>{
        try{
            const user = await Auth.currentAuthenticatedUser();
            const result = await API.graphql({ query: listCounterInfoTables, variables: 
                { 
                    filter: 
                        { 
                            owner: { eq: user.username } 
                        } 
                } 
            });
            console.log('NEW PRODUCT :: updateProductCounter');
            console.log({result});
            if(result && result.data && result.data?.listCounterInfoTables?.items.length > 0){
                const input = {
                    id: result.data.listCounterInfoTables.items[0].id,
                    active_orders_total: result.data.listCounterInfoTables.items[0].active_orders_total - 1,
                    completed_orders_total: result.data.listCounterInfoTables.items[0].completed_orders_total + 1
                };
                const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, { input }));
                console.log({resultUpdate});
            }else{
                console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
            }
        }catch(e){
            console.error(e);
        }
    }

    const finishOrder = async () =>{
        setFormInputs({...formInputs, sellerStatus: 3 });
        await updateOrderFromForm(3);
        await updateProductCounter();
    }
    
    const updateOrderStatus = (newStatus) =>{
        try{
            setLoading(true);
            setFormInputs({...formInputs, buyerStatus: newStatus });
            /*let newHistoryItem = {
                process_status: 0,
                date: new Date(),
                type: 0,
            }
            let _prevHist = formInputs.history ? formInputs.history: [];*/

            if(newStatus == 1){
                //Send approved email
                console.log('mail to :: ' + formInputs.companyEmail);
                sendEmailTemplate({
                    templateName: "orderAcceptedSeller", 
                    subject: i18n.language == "en" ? "Your Order has been Accepted" : "Tu Orden ha sido Aceptada en Bainub",
                    emailFrom: Constans.FROM_EMAIL,
                    recipients: Constans.USE_PRD_EMAIL ?  [formInputs.companyEmail] : ["victor_calva@mincovac.com", "eskasu@gmail.com"], 
                    templateVars: {
                        ordernumber: pad(formInputs.orderNumber, 10)
                    },
                    templateLanguage: i18n.language  == "en" ? "en" : "es"
                });
            }

            updateOrderFromForm(-1, newStatus, null);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
    }
    const goToChat = async () =>{
        setLoading(true);
        let filter = {
            or: [
                { 'company_user': { eq: user.cognitoId } },
                { 'owner': { eq: user.cognitoId } }
            ]
        }

        // filter[user.type === "S" ? "company_user" : "owner"]= { eq: user.cognitoId }
        const listChatsResult = await API.graphql({ 
            query: listChats,
            variables: { filter: filter }
        })
        let resultChats = listChatsResult.data.listChats.items
        console.log({resultChats});
        setLoading(false);
        if(resultChats.length > 0){
            console.log('orderOwner :: ', orderOwner);
            const _chat = resultChats.find((chat) => chat.company_user === orderOwner);
            console.log('chat found :: ', _chat);
            openChat(_chat.id);
        }else{
            openChat(0);
        }
        
    }

    const openChat = (id) =>{
        const isFirefox = typeof InstallTrigger !== 'undefined';
        console.log('isFirefox ' , isFirefox);
        if(isFirefox){
            history.push("/my-chats/" + id);
        }else{
            const win = window.open("/my-chats/" + id, "_blank");
        win.focus();
        }
    }

    const acceptCancelation = () =>{
        updateOrderStatus(4);
    }

    const BuyerBannerLogic = () =>{
        if(formInputs.buyerStatus == 0 && formInputs.sellerStatus == 1)
            return(
                <BuyerBanner t={t} 
                            textTitle={t('orders.buyers.warning-title1')} 
                            textSubtext={t('orders.buyers.warning-subtitle1')}
                            backColor={'#00B5BE'/*'#FF6D73'*/} showButtons={false} 
                />
            )
        else{
            if(formInputs.buyerStatus != 4 && formInputs.buyerStatus != 5  && formInputs.sellerStatus == 5){
                return (
                    <BuyerBanner t={t} 
                            goToChat={goToChat}
                            acceptCancelation={acceptCancelation}
                            textTitle={t('orders.buyers.warning-title2')} 
                            textSubtext={t('orders.buyers.warning-subtitle2')}
                            backColor={'#FF6D73'} showButtons={true} 
                    />
                )
            }else
                return <></>
        }
    }

    return(
        <div className="order-wizard-div">
            {
                loading && <Loading fullscreen={loading} />
            }

            <Layout.Row gutter="20">
                <Layout.Col offset="1" span="20">
                    &nbsp;
                </Layout.Col>
            </Layout.Row>
            <Card className="box-card"
                header={
                    <>
                    <Layout.Row gutter="10">
                        <Layout.Col lg="3">
                            <Link to="/my-orders">{t('orders.all')}</Link>&gt;
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="10">
                            <Layout.Col lg="3">
                                <h2 className='order-form-color' style={{borderRight: '1px solid #c1ccd7'}}>
                                    {pad(formInputs.orderNumber, 10)}
                                </h2>
                            </Layout.Col>
                            <Layout.Col lg="2">
                                <h2>
                                    <LogicStatusLabel />
                                </h2>
                            </Layout.Col>
                            <Layout.Col lg="19">
                                <Layout.Row type='flex' justify="end">
                                    <Layout.Col ></Layout.Col>
                                    <Layout.Col lg="4" >
                                        <form-button-inverse class="left-space">
                                                <Button type="primary" onClick={()=>history.push('/my-orders')}>
                                                    {t('orders.form.back-btn')}
                                                </Button>
                                        </form-button-inverse>
                                    </Layout.Col>
                                    {
                                        formInputs.sellerStatus === 1 && formInputs.buyerStatus === 0
                                        ?
                                            <Layout.Col lg="4">
                                                <div className="left-space">
                                                    <Button className='reject-order-btn' type="primary" onClick={()=>updateOrderStatus(2)}>
                                                        {t('orders.buyers.reject-btn')}
                                                    </Button>
                                                </div>
                                            </Layout.Col>
                                        :
                                            <></>
                                        
                                    }
                                    
                                    {
                                       formInputs.sellerStatus === 1 && formInputs.buyerStatus === 0
                                       ?
                                           <Layout.Col lg="4">
                                               <div className="left-space">
                                                   <Button className='approve-order-btn' type="primary" onClick={()=>updateOrderStatus(1)}>
                                                       {t('orders.buyers.approve-btn')}
                                                   </Button>
                                               </div>
                                           </Layout.Col>
                                       :
                                           <></>
                                    }
                                        
                                    <Layout.Col lg="2">
                                        
                                        <form-button-inverse class="left-space">
                                        <Dropdown trigger="click" menu={(
                                            <Dropdown.Menu>
                                                {
                                                        formInputs.buyerStatus === 1 && formInputs.sellerStatus === 2
                                                        ?
                                                        
                                                        <Dropdown.Item>
                                                            <div onClick={()=>{finishOrder(formInputs.id)}}>{t('orders.actions.finish-btn')}</div>
                                                        </Dropdown.Item>
                                                        :
                                                            <></>
                                                }
                                            </Dropdown.Menu>
                                        )}>
                                                <Button style={{'width': '50px'}} type="primary">
                                                        ...
                                                </Button>
                                        </Dropdown>
                                        </form-button-inverse>
                                    </Layout.Col>
                                </Layout.Row>
                            </Layout.Col>
                           
                    </Layout.Row>
                    </>
                }
                >
                <Layout.Row gutter="20">
                    <BuyerBannerLogic/>
                </Layout.Row>
                <Form ref={form1} model={formInputs} className="en-US" rules={rules1} label-position="top">
                    <a name="top"></a>
                    <Summary readOnly={true} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    {
                        formInputs.sellerStatus >= 1
                        ?
                            <HistorySection readOnly={true} setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                        :
                            <></>
                    }
                    
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <SellerInfo readOnly={true} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <BuyerInfo readOnly={true} buyerEmailRef={buyerEmailRef} setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <ProductInfo readOnly={true} setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <FeeSection readOnly={true} setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <TermsSection readOnly={true} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <PaymentSection readOnly={true} setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <Layout.Row justify="center" align="middle" type="flex" gutter="20">
                            <Link to="#" onClick={
                                ()=>{
                                    window.scroll({
                                        top: 0, 
                                        left: 0, 
                                        behavior: 'smooth'
                                      });
                                }
                            } ><b>{t('orders.form.back-link')}</b></Link>
                    </Layout.Row>
                </Form>
                
            </Card>

                               
        </div>
    )
}

export default OrderWizard;
