import { Link, useHistory} from "react-router-dom";
import { Layout, Button, Form, Input, Loading, MessageBox, Notification } from 'element-react';
import { useTranslation } from 'react-i18next';
import {useState, useRef, useEffect} from 'react';
import { Auth } from 'aws-amplify';
import Schema from 'async-validator';
import loginImage from "../assets/imgs/login-image.png";

function RecoveryPassword(){
    const { t } = useTranslation();
    let history = useHistory();
    const form = useRef(null);
    const refUsername = useRef(null);
    const confirmPassInputRef = useRef();
    const [active, setActive] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInputs] = useState(
        {
            username : '',
            code: '',
            newpassword : '',
            newpasswordConfirmation : ''
        }
    )
    const [rules, setRules] = useState(null);
    const [step1Rules] = useState({
        username: [
            { required: true, message: t('register-form.validate-generic'), type: 'email' }
        ]
    });
    
    useEffect(() => {
        setRules(
            {
                username: [
                    { required: true, message: t('register-form.validate-generic'), type: 'email' }
                  ],
                code: [
                    { required: true, message: t('register-form.validate-generic') }
                  ],
                newpassword: [
                    { required: true, message: t('register-form.validate-passwd'), trigger: 'blur' , validator: (rule, value) =>
                        {
                            if(value.length < 8) return false;
                            return true;
                        }
                    }
                ],
                newpasswordConfirmation: [
                    { required: true, message: t('register-form.validate-confirmpasswd'), trigger: 'blur' , validator: (rule, value) =>
                        {
                            return value === confirmPassInputRef.current.props.value;
                        }
                    }
                ]
            }
        )
    }, [t])

    const sendConfirmationMsgToUser = (username) =>{
        setLoading(true);
        Auth.forgotPassword(username)
        .then(data => {
            console.log(data);
            Notification({
                title: t('confirm-register-form.resend-code-msg-title'),
                message: t('confirm-register-form.resend-code-msg-body'),
                type: 'success',
                offset: 100
            });
            setActive(active + 1);
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            MessageBox.alert(
                t('recovery-pwd-form.error-general'), 'Error',
                {
                type: 'error'
                }
            );
            setLoading(false);
        });
    }

    const handleNext = () =>{
        form.current.validate((valid) => {
        });
        const validator = new Schema(step1Rules);
        console.log('next button');
        if(active === 0){
            // @ts-ignore
            validator.validate({username : formInputs.username}, (errors, fields) => {
                console.log({errors})
                console.log({fields})
                if (fields == null || !fields.username) {
                    //TODO ****DEJAR* esta parte
                    sendConfirmationMsgToUser(formInputs.username);
                    //TODO ****QUITAR esta parte
                    //setActive(active + 1);
                }
            });    
        }

    }

    const preHandleOnSubmit = (e) =>{
        e.preventDefault();
        handleOnSubmit();
    }

    const handleOnSubmit = () =>{
        if(active === 1){
            if(formInputs.newpassword !== formInputs.newpasswordConfirmation) {
                MessageBox.alert(t('register-form.password-doesnt-match'), 'Error', { type: 'error' });
                return;
            }
            form.current.validate((valid) => {
                if(valid){
                    setLoading(true);
                    Auth.forgotPasswordSubmit(formInputs.username, formInputs.code, formInputs.newpassword)
                    .then(data => {
                        console.log(data);
                        Notification({
                            title: t('recovery-pwd-form.password-changed-title'),
                            message: t('recovery-pwd-form.password-changed-msg-body'),
                            type: 'success',
                            offset: 100
                        });
                        setActive(active + 1);
                        setTimeout(function(){ 
                            history.push("/login");
                        }, 1500);
                    })
                    .catch(err => {
                        console.log({err})
                        //errr.__type === 'LimitExceededException'
                        //TODO enviar al login... y mencionar el error para que esperen... 
                        MessageBox.alert(
                            t('recovery-pwd-form.error-general'), 'Error',
                            {
                            type: 'error'
                            }
                        );
                        setLoading(false);
                    });     
                }
            });
        }else{
            handleNext();
        }
    }
    
    //TODO: PASSWORD RULES
    function showPasswordRules() { MessageBox.alert(t("general.password-rules"), t("general.password-rules-title")) }

    return(
        <div>
            {
                loading && <Loading loading={loading} fullscreen={true} />
            }
            <div className="login-card el-card">
                <div className="login-card el-card__body">
                    <Layout.Row  type="flex" justify="center" align="middle">
                        <Layout.Col xs="0" sm="0" md="0" lg="12" className="login-card-image-col" style={{backgroundImage: `url(${loginImage})`}}>
                            <Layout.Row className="login-card-content">
                                <Layout.Col xs="24" sm="24" md="24" lg="24">
                                    <label className="login-card-title">{t('login-form.info-title')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-sales-comission')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-purchase-comission')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-promotions')}</label>
                                    <label className="login-card-item"><b>&#x2713;</b> {t('login-form.info-customer-service')}</label>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="24" lg="24" className="login-card-button-col">
                                    <a className="white-primary-button" href="/register">
                                        {t('login-form.sign-up')}
                                    </a>
                                </Layout.Col>
                                <Layout.Col xs="24" sm="24" md="24" lg="24">
                                    
                                </Layout.Col>
                            </Layout.Row>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="24" md="12" lg="12">
                            <Layout.Row className="roooooow">
                                <Layout.Col xs="18" sm="14" md="18" lg="14" offset="5">
                                    <label className="form-title">
                                        {t('recovery-pwd-form.title')}
                                    </label>
                                    
                                    <Form onSubmit={preHandleOnSubmit} ref={form} model={formInputs} className="en-US" rules={rules} label-position="top">
                            
                                        {active === 0 ? (
                                            <Form.Item label={t('recovery-pwd-form.username')} prop="username">
                                                <span class="result-category">(Email used for registration)</span>
                                            <Input 
                                                value={formInputs.username} 
                                                onChange={(v) => {setFormInputs({...formInputs, username: v })}} 
                                            />
                                            </Form.Item>
                                        ) : (<div></div>)}
                                        
                                        {active === 1 ? (
                                            <>
                                                <Form.Item label={t('recovery-pwd-form.username')} prop="username">
                                                    <Input 
                                                        value={formInputs.username} 
                                                        ref={refUsername}
                                                        readOnly="true"
                                                        disabled="true"
                                                    />
                                                </Form.Item>
                                                <Form.Item label={t('recovery-pwd-form.code')} prop="code">
                                                    <Input 
                                                        value={formInputs.code} 
                                                        onChange={(v) => {setFormInputs({...formInputs, code: v })}} 
                                                        trim={true}
                                                        placeholder={t('confirm-register-form.code-placheholder')}/>
                                                </Form.Item>
                                                <Form.Item label={t('recovery-pwd-form.newpassword')} prop="newpassword">
                                                    <Input
                                                        type="password" 
                                                        value={formInputs.newpassword} 
                                                        ref={confirmPassInputRef}
                                                        onChange={(v) => {setFormInputs({...formInputs, newpassword: v })}} 
                                                    />
                                                </Form.Item>
                                                <Form.Item label={t('recovery-pwd-form.newpassword-confirmation')} prop="newpasswordConfirmation">
                                                    <Input
                                                        type="password" 
                                                        value={formInputs.newpasswordConfirmation} 
                                                        onChange={(v) => {setFormInputs({...formInputs, newpasswordConfirmation: v })}} 
                                                    />
                                                </Form.Item>
                                            </>
                                        ) : <></>}

                                        <Form.Item>
                                    
                                            <Link to="/">
                                                <Button className="white-primary-button">
                                                    {t('button-cancel')}
                                                </Button>
                                            </Link>
                                            &nbsp;&nbsp;&nbsp;
                                            {   active === 1 ? 
                                                    (<Button onClick={handleOnSubmit} type="primary" loading={loading} className="primary-button" >
                                                        {t('button-ok')}
                                                    </Button>) : 
                                                    (<Button onClick={handleNext} type="primary" loading={loading} className="primary-button" >
                                                        {t('recovery-pwd-form.button-next')}
                                                    </Button>)
                                            }

                                        </Form.Item>
                                    </Form>
                                </Layout.Col>
                            </Layout.Row>
                        </Layout.Col>
                    </Layout.Row>
                </div>
            </div>
        </div>
        
    )
}

export default RecoveryPassword;