import Header from '../Header';
import { useState, useEffect } from 'react'
import { Layout, Dialog } from 'element-react';
import userStore from '../../store/user';
import MyPreferencesWizard from './MyPreferencesWizard';

export default function NonMenuLayout({ children, footerComponent, showMyPreferencesWizard, ...props}) {
    const {user, setPendingSetupBusinessPreferences} = userStore();
    const [myPreferencesWizardEnabled, setMyPreferencesWizardEnabled] = useState(showMyPreferencesWizard)
    const onWizardComplete = () => { 
        console.log("on wizard complete function");
        setMyPreferencesWizardEnabled(false);
        setPendingSetupBusinessPreferences({...user }, false)
    }

    useEffect(function() {
        setMyPreferencesWizardEnabled(showMyPreferencesWizard);
    }, [])

    return (
        <div>
            <Header hide={true} loadCategories={props.loadCategories}/>
            <div className="app-container">
                { myPreferencesWizardEnabled ?
                    <Dialog visible={myPreferencesWizardEnabled} showClose={false} >
                        <Dialog.Body className="m-t-none p-t-none">
                            <MyPreferencesWizard onWizardComplete={onWizardComplete}/>
                        </Dialog.Body>
                    </Dialog> : <></>
                }
                <Layout.Row gutter="24" type="flex" justify="center">
                    <Layout.Col span="22">
                        <div className="all-but-footer">
                            {children}
                        </div>
                    </Layout.Col>
                </Layout.Row>
            </div>
            <div className="footer">
                {footerComponent}
            </div>
        </div>
    )
}