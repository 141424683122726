import create from 'zustand'
import { persist } from 'zustand/middleware'
//@ts-ignore
const [prevNavStore] = create(persist(set => ({
  path: null,
  setPath: (_path) => {
    console.log('setting path to :: ' + _path);
    set({ path: _path });
  },
  clearPath : () => set({path : null})
})
,{
    name: "prevNavStore",
    getStorage: () => sessionStorage // (optional) by default the 'localStorage' is used
}))

export default prevNavStore;