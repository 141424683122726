import React from 'react';
const SummaryIcon = (props) => {
  return (
            <svg onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.coords ? props.coords : "0 0 41 41"}>
            <g id="Group_1381" data-name="Group 1381" transform="translate(-370 -282.155)">
              <g id="Ellipse_51" data-name="Ellipse 51" transform="translate(370 282.155)" fill="#fff" stroke="#929498" stroke-width="2">
                <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                <circle cx="20.5" cy="20.5" r="19.5" fill="none"/>
              </g>
              <path id="file-invoice-solid_1_" data-name="file-invoice-solid (1)" d="M11.974,0V5.987h5.987Zm1.5,11.974H4.49v2.994h8.981ZM10.477,5.987V0H2.245A2.245,2.245,0,0,0,0,2.245V21.7a2.245,2.245,0,0,0,2.245,2.245H15.716A2.245,2.245,0,0,0,17.961,21.7V7.484H12.016A1.5,1.5,0,0,1,10.477,5.987ZM2.994,3.368a.377.377,0,0,1,.374-.374H7.11a.376.376,0,0,1,.374.374v.748a.376.376,0,0,1-.374.374H3.368a.377.377,0,0,1-.374-.374Zm0,2.994a.376.376,0,0,1,.374-.374H7.11a.375.375,0,0,1,.374.374V7.11a.375.375,0,0,1-.374.374H3.368a.376.376,0,0,1-.374-.374ZM14.968,20.581a.377.377,0,0,1-.374.374H10.852a.375.375,0,0,1-.374-.374v-.748a.377.377,0,0,1,.374-.374h3.742a.377.377,0,0,1,.374.374Zm0-9.355v4.49a.746.746,0,0,1-.748.748H3.742a.746.746,0,0,1-.748-.748v-4.49a.746.746,0,0,1,.748-.748H14.219A.745.745,0,0,1,14.968,11.226Z" transform="translate(381.519 290.68)" fill={props.color ? props.color : "#929498"}/>
            </g>
            </svg>
  )
}

export default SummaryIcon;