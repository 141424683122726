import { Layout, Card, Form, 
    Input, Select, DatePicker
} from 'element-react';
import TermsIcon from './TermsIcon';
import { currencyOptions, guaranteeOptions } from '../../utils/Catalogs';
import { formatDate, toCurrency } from '../../utils/FormatUtils';
import ShippingIcon from './ShippingIcon';

function TermsSection(props){
    const t = props.t;
    if(props.readOnly){
        return(
            <>
                <Layout.Row gutter="20">
                    <Layout.Col span="20" style={{display: 'inline-flex'}}>
                        <ShippingIcon width={41} height={41}/> <div className="order-step-form">{t('orders.viewer.shipping-section')}</div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                            &nbsp;
                </Layout.Row>
                <Layout.Row gutter="20">
                    <Layout.Col span="24" xs="20" sm="20" lg="23">
                        <Card className='order-card'>
                            <Layout.Row gutter="20">
                            &nbsp;
                            </Layout.Row>
                            <Layout.Row gutter="20">
                                <Layout.Col lg={10}>
                                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                                        {`INCOTERM :`}&nbsp;{props.formInputs.incoterm}
                                    </div>     
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row gutter="20">
                                <Layout.Col lg={10}>
                                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                                        {t('orders.form.term-delivery-date')}:&nbsp;{formatDate(props.formInputs.deliveryDate ? props.formInputs.deliveryDate : new Date())}
                                    </div>     
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row gutter="20">
                                <Layout.Col lg={10}>
                                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                                    {t('orders.form.shipping-method')}:&nbsp;{props.formInputs.shippingMethod}
                                    </div>     
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row gutter="20">
                                <Layout.Col lg={10}>
                                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                                    <div className="X">{t('orders.form.shipping-cost')}:&nbsp;{toCurrency(Number.parseFloat(props.formInputs.paymentCost)) + ' ' + props.formInputs.currency}</div>
                                    </div>     
                                </Layout.Col>
                            </Layout.Row>
                            <Layout.Row gutter="20">
                                <Layout.Col lg={10}>
                                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                                        {t('orders.form.tracking-number')}:&nbsp;{props.formInputs.trackingNumber}
                                    </div>     
                                </Layout.Col>
                            </Layout.Row>
                            <br/>
                            <Layout.Row gutter="20">
                                <Layout.Col lg={10}>
                                    <Layout.Row gutter="20">
                                        <Layout.Col lg={20}>
                                            <div className='order-form-color' style={{'color' : '#48576a'}}>
                                                {t('orders.form.shipping-origin-adds')}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                                                    {props.formInputs.companyName}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                    {props.formInputs.originStreet},{props.formInputs.originCity},{props.formInputs.originState}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                    {props.formInputs.originZip},{props.formInputs.originCountry}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                {t('orders.form.shipping-phone')}:&nbsp;{props.formInputs.originPhone}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                {t('orders.form.shipping-contact')}:&nbsp;{props.formInputs.originContact}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                                <Layout.Col lg={10}>
                                    <Layout.Row gutter="20">
                                        <Layout.Col lg={20}>
                                            <div className='order-form-color' style={{'color' : '#48576a'}}>
                                                {t('orders.form.shipping-destination-adds')}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                                                    {props.formInputs.buyerEmail}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                    {props.formInputs.destinationStreet},{props.formInputs.destinationCity},{props.formInputs.destinationState}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                    {props.formInputs.destinationZip},{props.formInputs.destinationCountry}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                {t('orders.form.shipping-phone')}:&nbsp;{props.formInputs.destinationPhone}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row gutter="20">
                                        <Layout.Col offset={1} lg={20}>
                                            <div className='shipping-label'>
                                                {t('orders.form.shipping-contact')}:&nbsp;{props.formInputs.destinationContact}
                                            </div>     
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                            </Layout.Row>
                            
                        </Card>
                    </Layout.Col>
                </Layout.Row>
                
                <Layout.Row gutter="20">
                    &nbsp;
                </Layout.Row>
            </>
        )
    }
    return(
        <>
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <TermsIcon width={41} height={41}/> <div className="order-step-form">{t('orders.form.terms-section')}</div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.term-currency')} prop="currency">
                        <Select 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            filterable={false} 
                            value={props.formInputs.currency}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, currency: v })}} 
                        >
                                {
                                    currencyOptions.options.map(el => {
                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                        </Select>
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.term-pay-method')} prop="paymentMethod">
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.paymentMethod}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, paymentMethod: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
            <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.term-delivery-date')} prop="deliveryDate">
                        {
                            props.formInputs.sellerStatus !== 0 
                            ?
                                <div>
                                    <br/>
                                    {formatDate(props.formInputs.deliveryDate)}
                                </div>
                            : 
                                <DatePicker 
                                    disabledDate={time=>time.getTime() < Date.now() - 8.64e7}
                                    value={props.formInputs.deliveryDate == 'Invalid Date' ? new Date() : props.formInputs.deliveryDate}
                                    format="yyyy-MM-dd"
                                    onChange={(v) => {props.setFormInputs({...props.formInputs, deliveryDate: v })}} 
                        />
                        }
                        
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.term-guarantee')} prop="guarantee">
                        <Select 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            filterable={false} 
                            value={props.formInputs.guarantee}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, guarantee: v })}} 
                        >
                               {
                                    guaranteeOptions.options.map(el => {
                                    return <Select.Option key={el.value} label={el.label} value={el.value} />
                                    })
                                }
                        </Select>
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.term-incoterm')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.incoterm}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, incoterm: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
        </>
    )
}

export default TermsSection;