/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat {
    onCreateChat {
      id
      owner
      owner_name
      alias_owner_name
      owner_last_message_read
      company_user
      company_user_name
      alias_company_user_name
      company_user_last_message_read
      company_name
      last_message
      buyer_phone
      seller_since
      buyer_since
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat {
    onUpdateChat {
      id
      owner
      owner_name
      alias_owner_name
      owner_last_message_read
      company_user
      company_user_name
      alias_company_user_name
      company_user_last_message_read
      company_name
      last_message
      buyer_phone
      seller_since
      buyer_since
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat {
    onDeleteChat {
      id
      owner
      owner_name
      alias_owner_name
      owner_last_message_read
      company_user
      company_user_name
      alias_company_user_name
      company_user_last_message_read
      company_name
      last_message
      buyer_phone
      seller_since
      buyer_since
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      chat_id
      chat {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      from
      to
      message
      message_type
      file {
        bucket
        region
        key
        
      }
      product_id
      product_company_id
      product_company_name
      product_name
      product_image {
        bucket
        region
        key
        
      }
      createdAt
      updatedAt
      owner
      
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      chat_id
      chat {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      from
      to
      message
      message_type
      file {
        bucket
        region
        key
        
      }
      product_id
      product_company_id
      product_company_name
      product_name
      product_image {
        bucket
        region
        key
        
      }
      createdAt
      updatedAt
      owner
      
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      chat_id
      chat {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      from
      to
      message
      message_type
      file {
        bucket
        region
        key
        
      }
      product_id
      product_company_id
      product_company_name
      product_name
      product_image {
        bucket
        region
        key
        
      }
      createdAt
      updatedAt
      owner
      
    }
  }
`;
export const onCreateCounterInfoTable = /* GraphQL */ `
  subscription OnCreateCounterInfoTable($owner: String!) {
    onCreateCounterInfoTable(owner: $owner) {
      id
      owner
      products_total
      active_orders_total
      completed_orders_total
      chats_total
      extra1_total
      extra2_total
      extra3_total
      extra4_total
      extra5_total
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateCounterInfoTable = /* GraphQL */ `
  subscription OnUpdateCounterInfoTable($owner: String!) {
    onUpdateCounterInfoTable(owner: $owner) {
      id
      owner
      products_total
      active_orders_total
      completed_orders_total
      chats_total
      extra1_total
      extra2_total
      extra3_total
      extra4_total
      extra5_total
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteCounterInfoTable = /* GraphQL */ `
  subscription OnDeleteCounterInfoTable($owner: String!) {
    onDeleteCounterInfoTable(owner: $owner) {
      id
      owner
      products_total
      active_orders_total
      completed_orders_total
      chats_total
      extra1_total
      extra2_total
      extra3_total
      extra4_total
      extra5_total
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      contact_type
      address_line1
      address_state
      address_zip
      cognito_id
      orders {
        items {
          id
          createdAt
          updatedAt
          owner
          owner_name
          buyer
          buyer_name
          buyer_phone
          buyer_email
          orderNumber
          company_name
          company_id
          company_contact_name
          company_email
          company_address
          seller_status
          buyer_status
          total
          tracking_number
          
        }
        nextToken
        
      }
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      contact_type
      address_line1
      address_state
      address_zip
      cognito_id
      orders {
        items {
          id
          createdAt
          updatedAt
          owner
          owner_name
          buyer
          buyer_name
          buyer_phone
          buyer_email
          orderNumber
          company_name
          company_id
          company_contact_name
          company_email
          company_address
          seller_status
          buyer_status
          total
          tracking_number
          
        }
        nextToken
        
      }
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      contact_type
      address_line1
      address_state
      address_zip
      cognito_id
      orders {
        items {
          id
          createdAt
          updatedAt
          owner
          owner_name
          buyer
          buyer_name
          buyer_phone
          buyer_email
          orderNumber
          company_name
          company_id
          company_contact_name
          company_email
          company_address
          seller_status
          buyer_status
          total
          tracking_number
          
        }
        nextToken
        
      }
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateUserFavorites = /* GraphQL */ `
  subscription OnCreateUserFavorites($owner: String!) {
    onCreateUserFavorites(owner: $owner) {
      id
      owner
      createdAt
      updatedAt
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const onUpdateUserFavorites = /* GraphQL */ `
  subscription OnUpdateUserFavorites($owner: String!) {
    onUpdateUserFavorites(owner: $owner) {
      id
      owner
      createdAt
      updatedAt
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const onDeleteUserFavorites = /* GraphQL */ `
  subscription OnDeleteUserFavorites($owner: String!) {
    onDeleteUserFavorites(owner: $owner) {
      id
      owner
      createdAt
      updatedAt
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      user {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        orders {
          nextToken
          
        }
        createdAt
        updatedAt
        
      }
      createdAt
      updatedAt
      owner
      owner_name
      buyer
      buyer_name
      buyer_phone
      buyer_email
      products {
        name
        id
        quantity
        unit
        unitPrice
        subtotalPrice
        comments
        photo {
          bucket
          region
          key
          
        }
        
      }
      orderNumber
      company_name
      company_id
      company_contact_name
      company_email
      company_address
      billingAddress {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      terms {
        currency
        paymentMethod
        deliveryDate
        guarantee
        incoterm
        
      }
      shipping {
        method
        cost
        status
        originAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        destinationAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        specialInstructions
        
      }
      additionalFees {
        fee
        quantity
        unit
        unitPrice
        subtotal
        description
        
      }
      seller_status
      buyer_status
      history {
        seller_status
        buyer_status
        process_status
        type
        date
        note
        
      }
      total
      payments {
        date
        amount
        currentBalance
        order
        method
        notes
        
      }
      tracking_number
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      user {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        orders {
          nextToken
          
        }
        createdAt
        updatedAt
        
      }
      createdAt
      updatedAt
      owner
      owner_name
      buyer
      buyer_name
      buyer_phone
      buyer_email
      products {
        name
        id
        quantity
        unit
        unitPrice
        subtotalPrice
        comments
        photo {
          bucket
          region
          key
          
        }
        
      }
      orderNumber
      company_name
      company_id
      company_contact_name
      company_email
      company_address
      billingAddress {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      terms {
        currency
        paymentMethod
        deliveryDate
        guarantee
        incoterm
        
      }
      shipping {
        method
        cost
        status
        originAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        destinationAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        specialInstructions
        
      }
      additionalFees {
        fee
        quantity
        unit
        unitPrice
        subtotal
        description
        
      }
      seller_status
      buyer_status
      history {
        seller_status
        buyer_status
        process_status
        type
        date
        note
        
      }
      total
      payments {
        date
        amount
        currentBalance
        order
        method
        notes
        
      }
      tracking_number
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      user {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        orders {
          nextToken
          
        }
        createdAt
        updatedAt
        
      }
      createdAt
      updatedAt
      owner
      owner_name
      buyer
      buyer_name
      buyer_phone
      buyer_email
      products {
        name
        id
        quantity
        unit
        unitPrice
        subtotalPrice
        comments
        photo {
          bucket
          region
          key
          
        }
        
      }
      orderNumber
      company_name
      company_id
      company_contact_name
      company_email
      company_address
      billingAddress {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      terms {
        currency
        paymentMethod
        deliveryDate
        guarantee
        incoterm
        
      }
      shipping {
        method
        cost
        status
        originAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        destinationAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        specialInstructions
        
      }
      additionalFees {
        fee
        quantity
        unit
        unitPrice
        subtotal
        description
        
      }
      seller_status
      buyer_status
      history {
        seller_status
        buyer_status
        process_status
        type
        date
        note
        
      }
      total
      payments {
        date
        amount
        currentBalance
        order
        method
        notes
        
      }
      tracking_number
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      name
      description
      tags
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      name
      description
      tags
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      name
      description
      tags
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateSubcategory = /* GraphQL */ `
  subscription OnCreateSubcategory {
    onCreateSubcategory {
      id
      name
      description
      tags
      category_id
      category_name
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          big_description
          min_price
          max_price
          measurement
          min_order
          package_type
          customizable
          package_custom
          monthly_capacity
          client_service
          quantity_per_package
          package_dimensions
          package_weight
          warranty
          warranty_time
          tags
          owner
          brand
          int_ext
          size
          special_functions
          color
          material
          titles
          voltage
          power
          protection
          life_time
          createdAt
          status
          titles2
          search_field
          featured
          company_name
          company_id
          category_id
          subcategory_id
          subcategory_name
          key
          company_country
          verified
          bainub_account_year
          supplier_rating
          updatedAt
          views
          
        }
        nextToken
        
      }
      
    }
  }
`;
export const onUpdateSubcategory = /* GraphQL */ `
  subscription OnUpdateSubcategory {
    onUpdateSubcategory {
      id
      name
      description
      tags
      category_id
      category_name
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          big_description
          min_price
          max_price
          measurement
          min_order
          package_type
          customizable
          package_custom
          monthly_capacity
          client_service
          quantity_per_package
          package_dimensions
          package_weight
          warranty
          warranty_time
          tags
          owner
          brand
          int_ext
          size
          special_functions
          color
          material
          titles
          voltage
          power
          protection
          life_time
          createdAt
          status
          titles2
          search_field
          featured
          company_name
          company_id
          category_id
          subcategory_id
          subcategory_name
          key
          company_country
          verified
          bainub_account_year
          supplier_rating
          updatedAt
          views
          
        }
        nextToken
        
      }
      
    }
  }
`;
export const onDeleteSubcategory = /* GraphQL */ `
  subscription OnDeleteSubcategory {
    onDeleteSubcategory {
      id
      name
      description
      tags
      category_id
      category_name
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          big_description
          min_price
          max_price
          measurement
          min_order
          package_type
          customizable
          package_custom
          monthly_capacity
          client_service
          quantity_per_package
          package_dimensions
          package_weight
          warranty
          warranty_time
          tags
          owner
          brand
          int_ext
          size
          special_functions
          color
          material
          titles
          voltage
          power
          protection
          life_time
          createdAt
          status
          titles2
          search_field
          featured
          company_name
          company_id
          category_id
          subcategory_id
          subcategory_name
          key
          company_country
          verified
          bainub_account_year
          supplier_rating
          updatedAt
          views
          
        }
        nextToken
        
      }
      
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      name
      description
      big_description
      photo {
        bucket
        region
        key
        
      }
      file {
        bucket
        region
        key
        
      }
      addtional_photo {
        bucket
        region
        key
        
      }
      min_price
      max_price
      measurement
      min_order
      package_type
      customizable
      package_custom
      monthly_capacity
      client_service
      quantity_per_package
      package_dimensions
      package_weight
      warranty
      warranty_time
      tags
      owner
      brand
      int_ext
      size
      special_functions
      color
      material
      titles
      voltage
      power
      protection
      life_time
      createdAt
      status
      titles2
      search_field
      featured
      company_name
      company_id
      category_id
      subcategory_id
      subcategory_name
      key
      company_country
      verified
      bainub_account_year
      supplier_rating
      updatedAt
      category {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        products {
          nextToken
          
        }
        
      }
      views
      
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      name
      description
      big_description
      photo {
        bucket
        region
        key
        
      }
      file {
        bucket
        region
        key
        
      }
      addtional_photo {
        bucket
        region
        key
        
      }
      min_price
      max_price
      measurement
      min_order
      package_type
      customizable
      package_custom
      monthly_capacity
      client_service
      quantity_per_package
      package_dimensions
      package_weight
      warranty
      warranty_time
      tags
      owner
      brand
      int_ext
      size
      special_functions
      color
      material
      titles
      voltage
      power
      protection
      life_time
      createdAt
      status
      titles2
      search_field
      featured
      company_name
      company_id
      category_id
      subcategory_id
      subcategory_name
      key
      company_country
      verified
      bainub_account_year
      supplier_rating
      updatedAt
      category {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        products {
          nextToken
          
        }
        
      }
      views
      
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      name
      description
      big_description
      photo {
        bucket
        region
        key
        
      }
      file {
        bucket
        region
        key
        
      }
      addtional_photo {
        bucket
        region
        key
        
      }
      min_price
      max_price
      measurement
      min_order
      package_type
      customizable
      package_custom
      monthly_capacity
      client_service
      quantity_per_package
      package_dimensions
      package_weight
      warranty
      warranty_time
      tags
      owner
      brand
      int_ext
      size
      special_functions
      color
      material
      titles
      voltage
      power
      protection
      life_time
      createdAt
      status
      titles2
      search_field
      featured
      company_name
      company_id
      category_id
      subcategory_id
      subcategory_name
      key
      company_country
      verified
      bainub_account_year
      supplier_rating
      updatedAt
      category {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        products {
          nextToken
          
        }
        
      }
      views
      
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      type
      owner
      age
      established_years
      anual_revenue
      description
      region
      featured_category
      cover_picture {
        bucket
        region
        key
        
      }
      company_logo {
        bucket
        region
        key
        
      }
      titles
      employees_qty
      production_lines_qty
      main_market
      additional_titles1
      additional_titles2
      additional_titles3
      additional_titles4
      allow_custom_product
      slogan
      address_str
      address {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      bainub_account_year
      verified
      supplier_rating
      hidden
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      type
      owner
      age
      established_years
      anual_revenue
      description
      region
      featured_category
      cover_picture {
        bucket
        region
        key
        
      }
      company_logo {
        bucket
        region
        key
        
      }
      titles
      employees_qty
      production_lines_qty
      main_market
      additional_titles1
      additional_titles2
      additional_titles3
      additional_titles4
      allow_custom_product
      slogan
      address_str
      address {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      bainub_account_year
      verified
      supplier_rating
      hidden
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      type
      owner
      age
      established_years
      anual_revenue
      description
      region
      featured_category
      cover_picture {
        bucket
        region
        key
        
      }
      company_logo {
        bucket
        region
        key
        
      }
      titles
      employees_qty
      production_lines_qty
      main_market
      additional_titles1
      additional_titles2
      additional_titles3
      additional_titles4
      allow_custom_product
      slogan
      address_str
      address {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      bainub_account_year
      verified
      supplier_rating
      hidden
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateBusinessPreference = /* GraphQL */ `
  subscription OnCreateBusinessPreference {
    onCreateBusinessPreference {
      id
      business_preferences {
        category
        subcategories
        
      }
      business_definition
      owner
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateBusinessPreference = /* GraphQL */ `
  subscription OnUpdateBusinessPreference {
    onUpdateBusinessPreference {
      id
      business_preferences {
        category
        subcategories
        
      }
      business_definition
      owner
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteBusinessPreference = /* GraphQL */ `
  subscription OnDeleteBusinessPreference {
    onDeleteBusinessPreference {
      id
      business_preferences {
        category
        subcategories
        
      }
      business_definition
      owner
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateSubcategoryFormField = /* GraphQL */ `
  subscription OnCreateSubcategoryFormField {
    onCreateSubcategoryFormField {
      id
      subcategory_id
      form_fields
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateSubcategoryFormField = /* GraphQL */ `
  subscription OnUpdateSubcategoryFormField {
    onUpdateSubcategoryFormField {
      id
      subcategory_id
      form_fields
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteSubcategoryFormField = /* GraphQL */ `
  subscription OnDeleteSubcategoryFormField {
    onDeleteSubcategoryFormField {
      id
      subcategory_id
      form_fields
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateContactUsFormFill = /* GraphQL */ `
  subscription OnCreateContactUsFormFill {
    onCreateContactUsFormFill {
      id
      contact_reason
      full_name
      email
      business_name
      phone
      message
      is_bainub_user
      contacted_by
      contacted_at
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateContactUsFormFill = /* GraphQL */ `
  subscription OnUpdateContactUsFormFill {
    onUpdateContactUsFormFill {
      id
      contact_reason
      full_name
      email
      business_name
      phone
      message
      is_bainub_user
      contacted_by
      contacted_at
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteContactUsFormFill = /* GraphQL */ `
  subscription OnDeleteContactUsFormFill {
    onDeleteContactUsFormFill {
      id
      contact_reason
      full_name
      email
      business_name
      phone
      message
      is_bainub_user
      contacted_by
      contacted_at
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateUnsuscribedEmails = /* GraphQL */ `
  subscription OnCreateUnsuscribedEmails {
    onCreateUnsuscribedEmails {
      id
      owner
      email
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateUnsuscribedEmails = /* GraphQL */ `
  subscription OnUpdateUnsuscribedEmails {
    onUpdateUnsuscribedEmails {
      id
      owner
      email
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteUnsuscribedEmails = /* GraphQL */ `
  subscription OnDeleteUnsuscribedEmails {
    onDeleteUnsuscribedEmails {
      id
      owner
      email
      createdAt
      updatedAt
      
    }
  }
`;
export const onCreateUserNotificationSent = /* GraphQL */ `
  subscription OnCreateUserNotificationSent {
    onCreateUserNotificationSent {
      id
      email
      count
      createdAt
      updatedAt
      
    }
  }
`;
export const onUpdateUserNotificationSent = /* GraphQL */ `
  subscription OnUpdateUserNotificationSent {
    onUpdateUserNotificationSent {
      id
      email
      count
      createdAt
      updatedAt
      
    }
  }
`;
export const onDeleteUserNotificationSent = /* GraphQL */ `
  subscription OnDeleteUserNotificationSent {
    onDeleteUserNotificationSent {
      id
      email
      count
      createdAt
      updatedAt
      
    }
  }
`;
