import React from 'react';
import { Layout, Button} from 'element-react';
import {Link} from 'react-router-dom';
import about_us01 from "../assets/imgs/about_us01.png";
import about_us02 from "../assets/imgs/about_us02.png";
import about_us03 from "../assets/imgs/about_us03.png";
import about_us04 from "../assets/imgs/about_us04.png";
import logo from "../assets/imgs/bainub_logo_white.png";


function NosotrosComponent() {
    const activeName = "0";
    return (
        <div className='AboutUsPage'>
            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="1" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">Acerca de Nosotros</h1>    
            <p>Bainub es una empresa mexicana establecida en Monterrey, México.
            Iniciamos operaciones en 2020 con el objetivo de construir una
            herramienta optimizada que ayuda a conectar empresas de la
            región de América del Norte: México, EE. UU. y Canadá, para facilitar
            la cooperación entre proveedores y compradores de distintos países
            por medio de un canal de comunicación innovador mediante el
            cual pueden encontrarse, comunicarse directamente, establecer
            cooperación y llevar registro de sus operaciones de intercambio
            comercial.</p>
            </Layout.Col>
            <Layout.Col className="hidde-mobile img-center" xs="24" sm="24" md="11" lg="9">
            <img src={about_us01} alt="Bainub" />
            </Layout.Col>
            <Layout.Col className="hidde-mobile" xs="1" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" lg="24" className='section02 hidden-mobile'>
            <Layout.Col xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="18" lg="18">
                <h1>Nuestra Meta</h1>
                <p>Bainub se lanzó el último cuarto de 2023 con el enfoque y el objetivo de construir nuevas colaboraciones con todo tipo de
                organizaciones comerciales e industriales para potenciar nuestra red de proveeduría en el mercado local y el extranjero.</p>
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="3" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" className="img-center" sm="24" md="11" lg="9">
            <img src={about_us02} className="hidde-mobile" alt="Bainub" />
            <img src={about_us03} className="hidde-desktop" alt="Bainub" />
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">Nuestra Plataforma</h1>    
            Bainub es una plataforma online accesible desde cualquier lugar, donde
podrás conocer proveedores y clientes potenciales, con un objetivo en
mente: hacer tu negocio cada día más fuerte.
            </Layout.Col>
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">Nuestro Equipo</h1>    
            Nuestro equipo tiene amplia experiencia en comercio internacional,
desarrollo de productos, ventas y logística. Desarrollamos Bainub incorporando
esta experiencia y optimizando las herramientas para simplificar
los desafíos que enfrentan los equipos de compras y ventas en las
empresas manufactureras.
            </Layout.Col>
            <Layout.Col className="hidde-mobile img-center" xs="24" sm="24" md="11" lg="9">
            <img src={about_us03} alt="Bainub" />
            </Layout.Col>
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="40" className="section01">
            <Layout.Col className="hidde-mobile" xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            <Layout.Col className="hidde-mobile img-center" xs="24" sm="24" md="11" lg="9">
            <img src={about_us04} alt="Bainub" />
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="11" lg="9">
            <h1 className="form-title">Nuestro Propósito</h1>    
            Aprovechemos el momento del Nearshoring.
Bainub tiene como objetivo mejorar la agilidad y eficiencia de la cadena
de suministro de productos desarrollados y comercializados en
América del Norte. Esto se traduce en beneficios tangibles en términos
de costos, tiempos de entrega y consideraciones medioambientales, al
mismo tiempo que fortalece los lazos entre las comunidades
Norteamericanas.
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="1" lg="3" ><br></br></Layout.Col>
            </Layout.Row>
            <div className='TalkContainer'>
            <div className='TalktoUs'>
                <Layout.Row>
                    <Layout.Col sm="24" md="16" lg="16">
                        <h2>Habla con Nosotros</h2>
                        <p>Si tienes dudas acerca de nuestro equipo, valores o cultura, contáctanos.
                        ¡Estamos para apoyarte!</p>
                    </Layout.Col>
                    <Layout.Col sm="24" md="8" lg="8">
                        <div className='responsiveSpacer'></div>
                    <Link to="/contact-us" target="_blank">
                        <div className='button'>
                            Más Información
                        </div>
                    </Link>
                    </Layout.Col>
                </Layout.Row>
            </div>
            </div>

            <Layout.Row gutter="40" lg="24" className='section03'>
            <Layout.Col xs="24" sm="24" md="3" lg="3" ><br></br></Layout.Col>
            <Layout.Col xs="24" sm="24" md="18" lg="18">
                <div className="responsiveSpacer2"></div>
                <h3>¿Listo para crecer? ¡Regístrate ahora!</h3>
                <img src={logo}></img>
            </Layout.Col>
            <Layout.Col xs="24" sm="24" md="3" lg="3" ><br></br></Layout.Col>
            </Layout.Row>


            </div>
    );
}

export default NosotrosComponent;
