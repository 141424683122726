import { Layout, Loading, Pagination, Message } from 'element-react';
import {useHistory} from "react-router-dom";
import mainSearchStore from '../store/main-search';
import {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import {listUsers, listProducts, listProductsOrderByMinPrice, listUserFavoritess} from '../graphql/queries';
import {API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import ResultCard from '../components/ResultCard';
import { useTranslation } from 'react-i18next';
import {engPropositions, spaPropositions} from '../utils/Prepositions';
import FilterHeader from '../components/FilterHeader';
import filterSearchStore from '../store/filter-search';
import { Auth } from 'aws-amplify';
import {createUserFavorites, deleteUserFavorites} from '../graphql/mutations';
import userStore from '../store/user';
import {generateProductChat} from '../services/ChatService';
import {CHAT_PRODUCT_MESSAGE} from '../utils/ChatConstants';
import PagerQuery from '../utils/PagerQuery';
import Survey from "./Survey";
import surveyInfo from "../store/surveyStatus";
import ProductBanner from "../components/ProductBanner";
import SearchSubCategoryDescription from "../components/SearchSubCategoryDescription";

const listProductsCOUNTER = `
query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }

`;
let tokenArray = [];
function SearchResult(){
    let history = useHistory();
    const { t } = useTranslation();
    const {filterInfo, setFilterInfo} = filterSearchStore();
    const {mainInfo} = mainSearchStore();
    let { query , tag} = useParams();
    const [favorites, setFavorites] = useState([]);
    const [loading , setLoading] = useState(false); 
    const [productsState, setProductsState ] = useState();
    const [total, setTotal] = useState(0);
    const [filterCerts, setFilterCerts] = useState("");
    const {user} = userStore();
    const [page, setPage] = useState(1);
    //const [size, setSize] = useState(5);
    const [sortBy, setSortBy] = useState('')
    const [viewModalUser, setViewModalUser] = useState(false);
    const {surveyStatus, sessionSurveyStatus} = surveyInfo();
    const [surveyVisible, setSurveyVisible] = useState(false);
    const [viewSubCategoryDescription, setViewSubCategoryDescription] = useState(false);
    const [titleAndDescription, setTitleAndDescription] = useState({title: '', description: ''});

    useEffect(() => {
        console.log('Searching for...');
        console.log({mainInfo});
        //let _array = [];
        //setTokenArray(_array);
        tokenArray = [];
        setPage(1);
        console.log('query ::: ' + query);    
        getActiveProducts(0);
        searchUserFavorites();
        
        return function cleanup() {
            console.log('============ cleanup ============');
            //setFilterInfo( 25,  0,  10000000,  false,  false,  false);
            //clearFilterInfo();
        };
    }, [query , tag, sortBy, 
        filterInfo.minPrice, filterInfo.pageSize, filterInfo.maxPrice, 
        filterInfo.customAllowed, filterInfo.minBuy, filterInfo.warranty,
        filterCerts
    ]);


    const searchUserFavorites = async () =>{
        try{
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
            let filter = {
                owner: {eq: u.username}
            };
            const result = await API.graphql({ query: listUserFavoritess, variables: {filter}});
            console.log({result});
            setFavorites(result.data.listUserFavoritess.items);
        }catch(e){
            console.error(e);
        }
        
    }

    const addToFavorites = async (productId) =>{
        console.log('addToFavorites');
        try{
            
                setLoading(true);
                //setFavoriteLoading(true);
                const u = await Auth.currentAuthenticatedUser();
                console.log({u});
                console.log('Adding to favorites product :: ' + productId);
                const input = {
                    userFavoritesProductId : productId
                }
                const result = await API.graphql(
                    graphqlOperation(createUserFavorites, { input })
                );
                console.log(result);
                //setFavorite(true);
                //@ts-ignore
                let favs = favorites;
                favs.push(result.data.createUserFavorites);
                console.log("favs :: ", {favs});
                setFavorites(favs);
                //setFavoriteId(result.data.createUserFavorites.id);
                setLoading(false);
                
            
        }catch(e){
            console.error(e);
            setLoading(false);
            if(e === 'The user is not authenticated'){
                console.log('Alert to user');
                /*Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });*/
                setViewModalUser(true);
            }
        }
        
    }

    const removeFromFavorites = async(fav) =>{
        console.log('removeFromFavorites');
        console.log({fav});
        try{
            setLoading(true);
            const input = {
                id : fav.id
            }
            const result = await API.graphql(
                graphqlOperation(deleteUserFavorites, { input })
            );
            console.log(result);
             
            let favs = favorites.filter(function( obj ) {
                return obj.id !== fav.id;
            });
            console.log("favs :: ", {favs});
            setFavorites(favs);
            setLoading(false);
            
        }catch(e){
            console.error(e);
            setLoading(false);
        }
    }

    const getActiveProducts = async(_page) =>{
        setLoading(true);
        console.log('TAG :: ' + tag);
        if(tag === 'ee7cf8a8-99a8-4e37-9933-e7363d11d979|Plastic & Rubber'){
            console.log('SHOW COMPONENT WITH DESCRIPTION OF PLASTIC & RUBBER');
            setTitleAndDescription({title: 'Chemicals, Materials & Energy > Plastic & Rubber', description: 'Discover Plastic and Rubber manufacturers near you. Find suppliers of raw material, plastic parts & components, molds & tooling design, injection molding, and all plastic related services for your manufacturing needs. Connect with suppliers in Mexico, USA and Canada and simplify your sourcing experience with Bainub - B2B supplier platform.'});
            setViewSubCategoryDescription(true);
        }else
            if(tag === '31febfbf-abc8-462e-a0c3-b26b208f8f5c|Tools & Equipment'){
                console.log('SHOW COMPONENT WITH DESCRIPTION OF Tools & Equipment');
                setTitleAndDescription({title: 'Industrial Equipment > Tools & Equipment', description: 'Find manufacturers of Tools and Equipment for every industry. Explore a wide variety of manual or automated tools, machinery suppliers, and custom designed industrial equipment manufacturers. Enhance your production efficiency connecting with verified suppliers in Mexico, USA and Canada and simplify your sourcing experience with Bainub - B2B supplier platform.'});
                setViewSubCategoryDescription(true);
            }else
                if(tag === '31febfbf-abc8-462e-a0c3-b26b208f8f5c|Machinery'){
                    console.log('SHOW COMPONENT WITH DESCRIPTION OF Machinery');
                    setTitleAndDescription({title: 'Industrial Equipment > Machinery', description: 'Find a wide variety of Machinery for every industry. Discover machinery suppliers and source manual or automated machines, custom designed industrial equipment, and automated work stations . Enhance your production efficiency connecting with verified suppliers in Mexico, USA and Canada and simplify your sourcing experience with Bainub - B2B supplier platform.'});
                    setViewSubCategoryDescription(true);
                }else{
                    setViewSubCategoryDescription(false);
                }

        console.log({filterInfo});
        console.log(tokenArray);
        try{
            let input = {
                filter: {
                    and: {
                        status: {eq: 1},
                        or: [],
                        min_price : {between: [filterInfo.minPrice, filterInfo.maxPrice]},
                        customizable: {eq : filterInfo.customAllowed ? 'true' : 'false'},
                        min_order: {gt: "0"},
                        warranty_time: {contains : "Year"},
                        and:{
                            or: [{titles: {contains : filterCerts.toUpperCase()}}, {titles2: {contains : filterCerts.toUpperCase()}}]
                        }
                    }
                }
            }
            if(filterCerts.trim() === ""){
                delete input.filter.and.and;
            }
            if(filterInfo.customAllowed === false){
                delete input.filter.and.customizable;
            }
            if(filterInfo.minBuy === false){
                delete input.filter.and.min_order;
            }
            if(filterInfo.warranty === false){
                delete input.filter.and.warranty_time;
            }
            let querySeparated = query.toLowerCase().split(" ");

            console.log('querySeparated :: ' + querySeparated.length);
            for (var i=0; i < querySeparated.length; i++) {
                console.log('Searching by word :: ' + querySeparated[i]);
                if(!engPropositions.includes(querySeparated[i]) && !spaPropositions.includes(querySeparated[i])){
                    input.filter.and.or.push(
                        {search_field: {contains: querySeparated[i]}}
                    );
                    input.filter.and.or.push(
                        {tags: {contains: querySeparated[i]}}
                    );
                }
            }
            console.log('TAG :: ' + tag);
            if(tag != 'All' && tag != 'all'){
                if(tag.endsWith('All')){
                    input.filter.and = {
                        status : input.filter.and.status,
                        or : input.filter.and.or,
                        category_id : {eq: tag.split("|")[0]},
                        min_price : input.filter.and.min_price
                    }
                }else{
                    input.filter.and = {
                        status : input.filter.and.status,
                        or : input.filter.and.or,
                        min_price : input.filter.and.min_price,
                        category_id : {eq: tag.split("|")[0]} ,
                        subcategory_name: {eq: tag.split("|")[1]}
                    }
                }
            }

            if(query === "$EMPTY$"){
                delete input.filter.and.or;
            }

            console.log({input});
            
            let resultCounter = null;
            if(_page === 0){
                console.log('COUNTING TOTAL');
                /*resultCounter = await API.graphql({
                    query: listProductsCOUNTER,
                    variables: input,
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                });*/
                const pagerQueryCounter = new PagerQuery(listProductsCOUNTER, input, Object.keys({ listProducts }));
                const resultCounter = await pagerQueryCounter.searchPublicData();
                console.log({resultCounter});
                
                console.log('TOTAL :: ' + resultCounter.data.listProducts.items.length);
                setTotal(resultCounter.data.listProducts.items.length);
                
            }
            
            input = {
                ...input,
                //limit : filterInfo.pageSize + 1,
                limit : filterInfo.pageSize,
                nextToken : _page == 0 ? null : tokenArray[_page-1]
            }
            console.log({input});
            
            if(sortBy === ''){
                /*const result  = await API.graphql({
                    query: listProducts,
                    variables: input,
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                });*/
                console.log({listProducts});
                const pagerQuery = new PagerQuery(listProducts, input, Object.keys({ listProducts }));
                const result = await pagerQuery.searchPublicData();
                console.log({result});
                //let _tokenArray = tokenArray;
                if(result.data.listProducts.nextToken)
                    tokenArray.push(result.data.listProducts.nextToken);
                //setTokenArray(_tokenArray);
                //@ts-ignore
                setProductsState(result.data.listProducts.items);
                if (!surveyStatus && !sessionSurveyStatus &&  result.data.listProducts.items.length === 0) {
                    setTimeout(() => {
                        setSurveyVisible(true)
                    }, 2000);
                }
            }else{
                delete input.filter.and.min_price
                input = {
                    ...input,
                    sortDirection: sortBy === 'min-price' ? 'ASC' : 'DESC',
                    min_price : {between: [filterInfo.minPrice, filterInfo.maxPrice]},
                    key: 'all'
                }
                console.log('listProductsOrderByMinPrice', JSON.stringify(input));
                /*const result  = await API.graphql({
                    query: listProductsOrderByMinPrice,
                    variables: input,
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                });*/
                const _pagerQuery = new PagerQuery(listProductsOrderByMinPrice, input, Object.keys({ listProductsOrderByMinPrice }));
                const result = await _pagerQuery.searchPublicData();

                console.log({result});
                tokenArray.push(result.data.listProductsOrderByMinPrice.nextToken);
                //@ts-ignore
                setProductsState(result.data.listProductsOrderByMinPrice.items);
            }
            
            
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
            setProductsState();
        }
        
    }

    const changePage = (currentPage) =>{
        console.log(currentPage);
        setPage(currentPage);
        getActiveProducts(currentPage-1);
    }

    const changeTableSize = (newSize) =>{
        //setSize(newSize);
        tokenArray = [];
        setPage(1);
        setFilterInfo(newSize, filterInfo.minPrice, filterInfo.maxPrice, filterInfo.customAllowed, filterInfo.warranty, filterInfo.minBuy);
        //setTimeout(function(){
        //    getActiveProducts(0);
        //}, 1000);
        
    }

    const handleChatWithUs = async(productOwner, productPage) =>{
        console.log('handletChatWithUs');
        setLoading(true);
        try{
            const u = await Auth.currentAuthenticatedUser();
            //console.log({user})
            const filter = {
                cognito_id : {eq : productOwner}
            }
            console.log({filter});
            //const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter}});
            const pager = new PagerQuery(listUsers, { filter }, Object.keys({ listUsers }));
            const resultCompanyUser = await pager.searchPrivateData();

            console.log({resultCompanyUser});
            const chatResult  = await generateProductChat(CHAT_PRODUCT_MESSAGE, productPage, t, user, resultCompanyUser.data.listUsers.items[0]);
            if(chatResult === true){
                history.push("/my-chats/1");
            }
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
            if(e === 'The user is not authenticated'){
                console.log('Alert to user');
                /*Message({
                    message: t('product.create.preview.no-session'),
                    type: 'error'
                });*/
                setViewModalUser(true);
            }
        }
        
    } 

    if(loading)
        return  <Loading fullscreen={loading} />;
    if(total === 0)
        return <>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                    <FilterHeader filterCerts={filterCerts} setFilterCerts={setFilterCerts} sortBy={sortBy} setSortBy={setSortBy} t={t}/>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row className="mt-5" >
                <Layout.Col offset="1"  xs="15" sm="15" md="15" lg="15">
                    <Survey surveyTitle={t('product.search-result.no-results-title')} />
                </Layout.Col>
            </Layout.Row>
        </>;
    return(
        <>
            {
                viewSubCategoryDescription 
                ? 
                <Layout.Row style={{marginTop : '10px'}} type="flex" span="24">
                    <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                        <SearchSubCategoryDescription title={titleAndDescription.title} description={titleAndDescription.description}/> 
                    </Layout.Col>
                </Layout.Row>
                : <></>
            }
            <ProductBanner setViewModalUser={setViewModalUser} modalVisible={viewModalUser} title={''}/>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                    <FilterHeader filterCerts={filterCerts} setFilterCerts={setFilterCerts} sortBy={sortBy} setSortBy={setSortBy} t={t}/>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                {
                    productsState && productsState.map((element, i) =>{
                        return( 
                            <>                              
                                <ResultCard handleChatWithUs={handleChatWithUs} removeFromFavorites={removeFromFavorites} addToFavorites={addToFavorites} favorites={favorites} t={t} key={element.id} product={element} />
                                <br/>
                            </>
                        )
                    })
                }
                </Layout.Col>
            </Layout.Row>

            <Layout.Row type="flex" span="24">
                <Layout.Col  style={{textAlign : 'center'}}  xs="21" sm="22" md="22" lg="22">
                    {
                        <div id="old-search">
                            <Pagination 
                            layout="sizes, prev, pager, next" 
                            total={total} 
                            pageSizes={[5, 25, 50, 100]}
                            onCurrentChange={changePage}
                            onSizeChange={changeTableSize}
                            currentPage={page}
                            pageSize={filterInfo.pageSize}/>
                        </div>
                    }
                </Layout.Col> 
            </Layout.Row>
            <Layout.Row type="flex" span="24">
                <Layout.Col offset="1"  xs="24" sm="22" md="22" lg="22">
                    {
                    //filterInfo.pageSize
                    }
                </Layout.Col>
            </Layout.Row>
        </>
    );
}

export default SearchResult;