import { Layout, Menu, Input, Select, Button } from 'element-react';
import notFoundImg from '../assets/imgs/not_found_icon.png';
import {Link} from "react-router-dom";

function NotFound(){
    return(
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
        }}>
            <Layout.Col>
                <Layout.Row justify="center" gutter={1} type={'flex'}>
                    <img height={'200'} src={notFoundImg}></img>
                </Layout.Row>
                <Layout.Row justify="center" gutter={1} type={'flex'}>
                    <h2>Page Not Found</h2>
                </Layout.Row>
                <Layout.Row justify="center" gutter={1} type={'flex'}>
                    <p>We are sorry but the requested page was not found.</p>
                </Layout.Row>
                <Layout.Row justify="center" gutter={1} type={'flex'}>
                    <Link  to={"/"}>Go back to the home page</Link>
                </Layout.Row>
            </Layout.Col>
        </div>
    )
}

export default NotFound;