
const toCurrency = (original) =>{
    if(original){
        return original.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }else{
        return '';
    }
    
}

const yearsFromToday =  (pastYear) =>{
    if(pastYear){
        var x = new Date(pastYear);
        var d = new Date();
        var year = d.getFullYear();
        return year - x.getFullYear();
    }else{
        return 0;
    }    
}

const yearFromDate =  (d) =>{
    if(d){
        var x = new Date(d);
        return x.getFullYear();
    }else{
        return 0;
    }    
}

const getLadaInfo = (country) =>{
    console.log('getLadaInfo, country :: ' + country);
    if(country === 'MEX' ){
        return '+52';
    }else{
        if(country === 'USA' ){
            return '+1';
        }else{
            if(country === 'CAN' ){
                return '+1';
            }   
        }    
    }
    return '';
}

const pad = (num, size) => {
    var s = "000000000" + num;
    return s.substring(s.length-size);
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function formatDate(date) {
    //console.log(`formatDate(${date})`)
    if(date != 'Invalid Date')
        return [
            date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
        ].join('-');
  }

  function stringToDate(str){
    console.debug(`stringToDate(${str})`)
    const date = new Date(str + 'T12:00:00Z')
    console.debug(`result :: ${date}`)
    return date;
  }

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

export {
    toCurrency,
    yearsFromToday,
    yearFromDate,
    getLadaInfo,
    pad,
    formatDate,
    stringToDate,
    blobToBase64
};