import { Layout, Button, Card, Form, Input, Notification, 
    Dialog, MessageBox, Table, DatePicker} 
    from 'element-react';
import PaymentIcon from './PaymentIcon';
import { currencyOptions, guaranteeOptions } from '../../utils/Catalogs';
import DeleteIcon from '../../components/DeleteIcon';
import { useState, useRef, useEffect } from 'react';
import { addPaymentRules } from '../../utils/OrderFormRules';
import { formatDate, toCurrency }  from '../../utils/FormatUtils';
import { totalProducts, totalFees, paymentCurrentBalance } from '../../services/OrderService';
import NotesIcon from '../NotesIcon';

function PaymentSection(props){
    const t = props.t;
    const [showDialog, setShowDialog] = useState(false);
    const [warningColor, setWarningColor] = useState('black');
    const form = useRef(null);
    const [rules, setRules] = useState(null);
    const [paymentForm, setPaymentForm] = useState(
        {
            date: null,
            amount: null,
            method: '',
            notes: ''
        });
    useEffect(() => {
        setRules(addPaymentRules(t));
    }, [t]);

    //props.formInputs.total - paymentCurrentBalance(5000, props.formInputs.payments)
    useEffect(() => {
        //setRules(addPaymentRules(t));
        let total = props.formInputs.total - paymentCurrentBalance(5000, props.formInputs.payments);
        console.log('props.formInputs.payments ::: ' + total)
        if(total < 0){
            //Notification()
            Notification({
                title: 'Warning',
                message: t('orders.warning-payments'),
                type: 'error'
            });
            setWarningColor('red');
        }else{
            setWarningColor('black');
        }
    }, [props.formInputs.total, props.formInputs.payments]);

    const columns = 
    [
        {
          label: t('orders.form.payment-table.1'),
          prop: "date",
          width: 120,
          sortable: true,
          render: (row, column, index)=>{
            return(
                formatDate(row.date)
            ) 
          }
        },
        {
            label: t('orders.form.payment-table.2'),
            //width: 120,
            //sortable: true,
            align: 'right',
            prop: 'amount',
            render: (row, column, index)=>{
              return(
                toCurrency(parseFloat(row.amount)) + ' ' + props.formInputs.currency
              ) 
            }
        },
        {
            label: t('orders.form.payment-table.3'),
            //width: 120,
            //sortable: true,
            align: 'center',
            render: (row, column, index)=>{
              return(
                    row.method
              ) 
            }
        },
        {
            label: t('orders.form.payment-table.4'),
            // width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                toCurrency(props.formInputs.total - paymentCurrentBalance(index, props.formInputs.payments)) + ' ' + props.formInputs.currency
              ) 
            }
        },
        {
            //label: t('orders.form.payment-table.5'),
             width: props.readOnly ? 1 : 120,
            //sortable: true,
            render: (row, column, index)=>{
                if(props.formInputs.sellerStatus === 3){
                    return(
                        <div className="text-center inline-row" style={{marginTop: '15px'}}>
                                <NotesIcon color='#FF6D73'  handleClick={()=>{MessageBox.alert(row.notes, 'Info Extra');}} width={30} height={20}/>
                        </div>
                    ) 
                }
                if(props.readOnly)
                return(
                    <>
                    </>
                )
                else
                    return(
                            <div className="text-center inline-row" style={{marginTop: '15px'}}>
                                    <DeleteIcon handleClick={()=>{deleteItem(index)}} width={30} height={30}/>
                                    <NotesIcon color='#FF6D73'  handleClick={()=>{MessageBox.alert(row.notes, 'Info Extra');}} width={30} height={20}/>
                            </div>
                        ) 
            }
        }
    ]
    const deleteItem = (tableIndex) =>{
        console.log(`deleting ${tableIndex}`);
        props.setLoading(true);
        let _prevPayments = props.formInputs.payments.filter(function( obj, index ) {
            return index !== tableIndex;
        });
        //let _prevProducts = props.formInputs.products ? props.formInputs.products: [];
        props.setFormInputs({...props.formInputs, payments: [..._prevPayments] })
        props.setLoading(false);
    }

    const addPayment = () =>{
        form.current.validate((valid) => {
            console.log('validator :: ' + valid);
            if (valid) {
                console.log({paymentForm});
                let _prevPayments = props.formInputs.payments ? props.formInputs.payments: [];
                props.setFormInputs({...props.formInputs, payments: [..._prevPayments, paymentForm] })
                setShowDialog(false);
            } else {
                console.log('invalid form');
            }
        })
        
    }

    const initForm = async() => {
        props.setLoading(true);
        setPaymentForm({
            date: null,
            amount: null,
            method: '',
            notes: ''
        })
        setShowDialog(true);
        props.setLoading(false);
        
    }

    return(
        <>
            <Dialog size="small"
                    visible={showDialog}
                    onCancel={ () => { 
                        console.log('cancel Diaglog');
                        setShowDialog(false);
                    } }
                    title={t('orders.form.payment-dialog-title')}
                >
                <Dialog.Body>
                    <Form ref={form} model={paymentForm} className="en-US" rules={rules} label-position="top">
                        <Layout.Row gutter="24">
                            <Layout.Col lg={7} md={8} xs={24} sm={24}>
                                <Form.Item label={t('orders.form.payment-table.modal-date')} prop="date">
                                    <DatePicker 
                                        value={paymentForm.date}
                                        format="dd-MM-yyyy"
                                        onChange={(v) => {
                                            setPaymentForm({...paymentForm, date: v })
                                        }} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={7} md={8} xs={24} sm={24}>
                                <Form.Item label={t('orders.form.payment-table.modal-amount')} prop="amount">
                                    <Input 
                                        value={paymentForm.amount}
                                        onChange={v => {setPaymentForm({...paymentForm, amount: v })}} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={7} md={8} xs={24} sm={24}>
                                <Form.Item label={t('orders.form.payment-table.modal-method')} prop="method">
                                    <Input 
                                        value={paymentForm.method}
                                        onChange={(v) => {setPaymentForm({...paymentForm, method: v })}} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={21} md={14} xs={24} sm={24}>
                                <Form.Item label={t('orders.form.payment-table.modal-notes')} prop="notes" >
                                    <Input 
                                        type="textarea"
                                        autosize={{ minRows: 4, maxRows: 4}}
                                        rows={4}
                                        value={paymentForm.notes}
                                        onChange={(v) => {setPaymentForm({...paymentForm, notes: v })}} 
                                    />
                                    
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <Layout.Row type="flex">
                                    <Layout.Col span="24" xs="20" sm="20" lg="24">
                                        <Layout.Row type="flex" justify="end">
                                            <Layout.Col tag="form-button">
                                                <div style={{display: 'inline-flex'}}>
                                                    <form-button-inverse class="left-space">
                                                    <Button className="left-separator" type="primary" onClick={() => setShowDialog(false)}>
                                                            {t('orders.form.cancel-prd-btn')}
                                                    </Button>
                                                    </form-button-inverse>
                                                </div>
                                            </Layout.Col>
                                            <Layout.Col tag="form-button">
                                                    <Button className="left-separator" type="primary" onClick={() => addPayment()}>
                                                            {t('orders.form.payment-table.add-pay-btn')}
                                                    </Button>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Layout.Col>
                    </Layout.Row>
                </Dialog.Footer>
            </Dialog>        
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <PaymentIcon width={41} height={41}/> 
                        <div className="order-step-form">
                            {
                                props.readOnly
                                ?
                                    t('orders.viewer.payment-section')
                                :
                                    t('orders.form.payment-section')
                            }
                        </div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp; 
            </Layout.Row>
            <Layout.Row type="flex">
                    <Layout.Col span="24" xs="20" sm="20" lg="23">
                        <Layout.Row type="flex" justify="end">
                            <Layout.Col tag="form-button">
                                {
                                    props.readOnly
                                    ?
                                        <></>
                                    :
                                        <div style={{display: 'inline-flex'}}>
                                            <form-button-inverse class="left-space">
                                                {
                                                    props.formInputs.sellerStatus === 3
                                                    ?
                                                        <></>
                                                    :
                                                        <Button className="left-separator" type="primary" onClick={() => initForm()}>
                                                            {t('orders.form.payment-table.add-pay-btn')}
                                                        </Button>
                                                }
                                            </form-button-inverse>
                                        </div>
                                }
                                
                            </Layout.Col>
                        </Layout.Row>
                    </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20" justify="end" type="flex" >
                    <Layout.Col offset="1" lg="10">
                        &nbsp;
                    </Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="20">
                    <Layout.Col span="23">
                        
                        <Table
                            className="products-table box-card"
                            data={props.formInputs.payments}
                            columns={columns}  
                            fit={true}
                            sumText='Amount'
                            showSummary={true}
                            defaultSort= {{
                                'order': 'ascending',
                                'prop': 'date'
                                }
                            }
                            summaryMethod={(columns, data)=>{
                                const dataList = [];
                                for(var i=0; i < columns.length; i++){
                                    let total = 0;
                                    if(i === 0){
                                        dataList[i] = 'Subtotal:';
                                        continue;
                                    }
                                    for(let j=0; j < data.length; j++){
                                        let value = data[j][columns[i]['property']];
                                        
                                        if(isNaN(value)){
                                            total = ''
                                            break;
                                        }else{
                                            total += parseFloat(value);
                                        }
                                    }
                                    
                                    dataList[i] = toCurrency(total) + (i === 1 ? ' ' + props.formInputs.currency : '');
                                }
                                return dataList;
                            }}
                            emptyText={<>
                                <div><b>{t('orders.form.payment-table.no-data-1')}</b></div>
                                <br/>
                                {
                                    props.readOnly ?
                                        <div></div>
                                    :
                                        <div>{t('orders.form.payment-table.no-data-2')}</div>
                                }
                            </>}
                            //onRowClick={dotsClick}
                        />
                    </Layout.Col>
                </Layout.Row>

            <Layout.Row gutter="20" justify="end" type="flex" >
                <Layout.Col offset="1" lg="10">
                    &nbsp;
                </Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="20" >
                <Layout.Col span="24" xs="20" sm="20" lg="23">
                    <Card className="box-card order-card">
                        <Layout.Row gutter="20" >
                            <Layout.Col >
                                <Layout.Row gutter="20" type='flex' justify="start">
                                    <Layout.Col className='order-total-label' span="4" xs="4" sm="4" lg="4">
                                        <div>{t('orders.total.products')}</div>
                                    </Layout.Col>
                                        <div className="X">{toCurrency(totalProducts(props.formInputs.products)) + ' ' + props.formInputs.currency}</div>
                                    
                                </Layout.Row>
                                <Layout.Row gutter="20">
                                    <Layout.Col offset="1" lg="10">
                                    &nbsp;
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row gutter="20" type='flex' justify="start">
                                    <Layout.Col className='order-total-label' span="4" xs="4" sm="4" lg="4">
                                        <div>{t('orders.total.fees')}</div>
                                    </Layout.Col>
                                    <div className="X">{toCurrency(totalFees(props.formInputs.fees)) + ' ' + props.formInputs.currency}</div>
                                </Layout.Row>
                                <Layout.Row gutter="20">
                                    <Layout.Col offset="1" lg="10">
                                    &nbsp;
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row gutter="20" type='flex' justify="start">
                                    <Layout.Col className='order-total-label' span="4" xs="4" sm="4" lg="4">
                                        <div>{t('orders.total.ship')}</div>
                                    </Layout.Col>
                                    
                                        <div className="X">{toCurrency(Number.parseFloat(props.formInputs.paymentCost)) + ' ' + props.formInputs.currency}</div>
                                    
                                </Layout.Row>
                            </Layout.Col>
                            <Layout.Col span="10">
                                
                            </Layout.Col>
                        </Layout.Row>
     
                        <Layout.Row gutter="20">
                            <Layout.Col offset="1" lg="10">
                            &nbsp;
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <div className="form-separator"/>
                        </Layout.Row>
                        <Layout.Row gutter="20" type='flex' justify="start">
                            <Layout.Col className='order-total-label' span="4" xs="4" sm="4" lg="4">
                                <div>{t('orders.total.subtotal')}</div>
                            </Layout.Col>
                            <div className="text item">{toCurrency(props.formInputs.total) + ' ' + props.formInputs.currency}</div>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col offset="1" lg="10">
                            &nbsp;
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20" type='flex' justify="start">
                            <Layout.Col className='order-total-label' span="4" xs="4" sm="4" lg="4">
                                <div>{t('orders.total.paid')}</div>
                            </Layout.Col>
                            <div className="text item">{toCurrency(paymentCurrentBalance(5000, props.formInputs.payments)) + ' ' + props.formInputs.currency}</div>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col offset="1" lg="10">
                            &nbsp;
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <div className="form-separator"/>
                        </Layout.Row>
                        <Layout.Row gutter="20" type='flex' justify="start">
                            <Layout.Col className='order-total-label' span="4" xs="4" sm="4" lg="4">
                                <div>{t('orders.total.total')}</div>
                            </Layout.Col>
                            <div className="text item" style={{color : warningColor}}>{toCurrency(props.formInputs.total - paymentCurrentBalance(5000, props.formInputs.payments)) + ' ' + props.formInputs.currency}</div>
                        </Layout.Row>
                        
                    </Card>
                </Layout.Col>
                
            </Layout.Row>
            
            
        </>
    )
}

export default PaymentSection;