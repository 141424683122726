import {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';

import {Auth, API, graphqlOperation} from 'aws-amplify';
import {listCompanys, listProducts} from '../graphql/queries';
import {updateCompany} from '../graphql/mutations';
import PagerQuery from '../utils/PagerQuery';


import {Layout, Button, Card, Form, Input, Loading, Dialog, InputNumber} from 'element-react';
import MyPage from '../components/MyPage';

import 'react-image-crop/dist/ReactCrop.css';

//@ts-ignore
import createImg from '../assets/imgs/create-company.png';
import MyStoreCropImage from './MyStoreCropImage';

function MyStore() {
    let history = useHistory();
    const {t} = useTranslation();

    const [formInputs, setFormInputs] = useState({
        file1: null, urlFile1: null, file2: null, urlFile2: null
    });
    const [loading, setLoading] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [imageDialogVisible, setImageDialogVisible] = useState(false);
    const [companyPage, setCompanyPage] = useState(null);
    const [featuresProduct, setFeaturesProduct] = useState([]);
    const [fieldToUpdate, setFieldToUpdate] = useState('');
    const [showNoCompany, setShowNoCompany] = useState(false);
    const [showCompanyPreview, setShowCompanyPreview] = useState(false);

    useEffect(() => {
        console.log('MyStore');
        searchCompanyData();
    }, [])

    const searchCompanyData = async () => {
        console.log('searchCompanyData... from BD')
        setLoading(true);
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log('for user :: ' + user.username);
            //const result = await API.graphql(graphqlOperation(listCompanys, {filter: {owner: {eq: user.username}}}))
            const pagerQuery = new PagerQuery(listCompanys, {filter: {owner: {eq: user.username}}}, Object.keys({listCompanys}));
            const result = await pagerQuery.searchPrivateData();
            console.log({result})
            {
                (result.data.listCompanys.items.length > 0) ? setCompanyPage(result.data.listCompanys.items[0])
                    : toCreateStore();
            }
            {

                (result.data.listCompanys.items.length > 0) ? setShowCompanyPreview(true) : setShowCompanyPreview(false)
            }

            //@ts-ignore
            const pagerQueryProducts = new PagerQuery(listProducts, {filter: {and: {owner: {eq: result.data.listCompanys.items[0].owner}, status: {eq: 1}}}}, Object.keys({listProducts}));
            const resultProducts = await pagerQueryProducts.searchPrivateData();
            /*const resultProducts = await API.graphql({
                query: listProducts, variables: {
                    filter: {
                        and: {
                            owner: {eq: result.data.listCompanys.items[0].owner}, featured: {eq: 1}, status: {eq: 1}
                        }
                    }
                }
            });*/
            console.log({resultProducts});
            setFeaturesProduct(resultProducts.data.listProducts.items);
            
            
        } catch (e) {
            console.error(e);
            setLoading(false);
        } finally {
            setLoading(false);

        }

    }

    const toCreateStore = () => {
        history.push("/my-store-wizard/0");
    }

    const editCompanyText = (field) => {
        console.log("editCompanyText: ", field)
        setDialogVisible(true);
        setFieldToUpdate(field);
    }

    const editCompanyImage = (field) => {
        console.log("editCompanyImage: ", field)
        setImageDialogVisible(true);
        setFieldToUpdate(field);
    }

    const updateCompanyData = async () => {
        console.log('Update company');
        setLoading(true);
        try {
            const input = {
                id: companyPage.id,
                name: companyPage.name,
                type: companyPage.type,
                age: companyPage.age,
                description: companyPage.description,
                employees_qty: companyPage.employees_qty,
                titles: companyPage.titles,
                production_lines_qty: companyPage.production_lines_qty
            };
            const result = await API.graphql(graphqlOperation(updateCompany, {input}));
            console.log("Updated company", result);
            //@ts-ignore
            setCompanyPage(result.data.updateCompany);

        } catch (e) {
        } finally {
            setDialogVisible(false);
            setLoading(false);
        }

    }

    const cancelEditCompanyData = () => {
        setFormInputs({...formInputs, urlFile1: null})
        setDialogVisible(false);
        setImageDialogVisible(false);
        searchCompanyData();
    }

    const goToEditMyStore = () => {
        history.push('/my-store-wizard/' + companyPage.id);
    }

    if (loading) return <Loading fullscreen={loading}/>

    return <div>

        {imageDialogVisible ? <MyStoreCropImage
            visible={imageDialogVisible}
            onCancelDialogCallback={() => {
                console.log("onCancelDialogCallback callback!");
                setImageDialogVisible(false);
            }}
            onSaveImageCallback={(companyPageUpdated) => {
                console.log("onSaveImageCallback callback!");
                setImageDialogVisible(false);
                setCompanyPage(companyPageUpdated)
            }}
            fieldToUpdate={fieldToUpdate}
            s3KeyPrefix={`/${companyPage.name}/${fieldToUpdate}`}
            companyPageId={companyPage.id}
        /> : <></>

        }

        <Dialog size="tiny"
                visible={dialogVisible}
                onCancel={() => cancelEditCompanyData()}
        >
            <Dialog.Body>
                <Form label-position="top">
                    <Form.Item>
                        {fieldToUpdate === 'employees_qty' || fieldToUpdate === 'production_lines_qty' ?
                            <InputNumber
                                defaultValue={companyPage?.[fieldToUpdate]}
                                onChange={(v) => {
                                    setCompanyPage({...companyPage, [fieldToUpdate]: v})
                                }}
                            /> : <Input
                                type={fieldToUpdate === 'description' ? 'textarea' : 'text'}

                                rows={5}

                                value={companyPage?.[fieldToUpdate]}
                                onChange={(v) => {
                                    setCompanyPage({...companyPage, [fieldToUpdate]: v})
                                }}
                            />}
                    </Form.Item>
                </Form>
            </Dialog.Body>
            <Dialog.Footer className="dialog-footer">
                <form-button-inverse style={{'padding-right': '10px'}}>
                    <Button onClick={() => cancelEditCompanyData()}>Cancelar</Button>
                </form-button-inverse>
                <form-button>
                    <Button type="primary" onClick={() => updateCompanyData()}>Actualizar</Button>
                </form-button>
            </Dialog.Footer>
        </Dialog>


        {showCompanyPreview ? <Layout.Row type="flex">
            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                {<Card className="box-card warning-card">
                    <Layout.Row type="flex">
                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                            <span>&nbsp;</span>
                            <h2 className="white-text reduce-top-space">{t('my-store.warning1')}</h2>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row className="bottom-separator rigth-separator" type="flex" justify="end">
                        <Layout.Col tag="form-button-warning" span="24" offset="1" xs="20" sm="20" lg="20">
                            <Button type="primary" onClick={goToEditMyStore}>
                                {t('my-store.button-to-edit')}
                            </Button>
                        </Layout.Col>
                    </Layout.Row>

                </Card>}
            </Layout.Col>
        </Layout.Row> : <></>}

        {companyPage !== null ? <MyPage featuresProduct={featuresProduct} editCompanyText={editCompanyText}
                                        editCompanyImage={editCompanyImage} owner={true}
                                        companyPage={companyPage}/> : <></>}
    </div>
}

export default MyStore;