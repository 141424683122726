import create from 'zustand'
const [menuStore] = create(set => ({
  menuIndex: '',
  setMenuIndex: (index) => {
    console.log('new menu index :: ' + index);
    set({ menuIndex: index });
  },
  cleanMenuIndex : () => set({menuIndex: ''})
}))

export default menuStore;