import {API} from 'aws-amplify'

async function saveSurvey({name, mail, phone, product_request, created_by, form_type, is_seller, company_name, country}) {
    try {
        const result = await API.post('NotificationServiceLambda', '/survey/save', {
            body: {name, mail, phone, product_request, created_by, form_type, is_seller, company_name, country}
        })
        console.log(result);
        return result;
    } catch (error) {
        console.error(error);
    }
}

async function selectSurvey({month, year}) {
    try {
        const result = await API.post('NotificationServiceLambda', '/survey/select', {
            body: {month, year}
        });
        console.log(result);
        return result;
    } catch (error) {
        console.error(error);
    }
}


export {saveSurvey, selectSurvey};