import React from 'react';
const SellerInfoIcon = (props) => {
  return (
            <svg onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.coords ? props.coords : "0 0 41 41"}>
            <g id="Group_1382" data-name="Group 1382" transform="translate(-370 -595)">
              <g id="Ellipse_52" data-name="Ellipse 52" transform="translate(370 595)" fill="#fff" stroke="#929498" stroke-width="2">
                <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
                <circle cx="20.5" cy="20.5" r="19.5" fill="none"/>
              </g>
              <path id="id-card-clip-solid" d="M9.124,4.562h2.281a1.141,1.141,0,0,0,1.141-1.141V1.141A1.141,1.141,0,0,0,11.406,0H9.124A1.14,1.14,0,0,0,7.984,1.141V3.422A1.139,1.139,0,0,0,9.124,4.562Zm9.695-2.281H13.687V3.992A1.711,1.711,0,0,1,11.976,5.7H8.554A1.711,1.711,0,0,1,6.843,3.992V2.281H1.711A1.711,1.711,0,0,0,0,3.992V16.538a1.711,1.711,0,0,0,1.711,1.711H18.819a1.711,1.711,0,0,0,1.711-1.711V3.992A1.711,1.711,0,0,0,18.819,2.281Zm-8.554,5.7a2.281,2.281,0,1,1-2.281,2.281A2.281,2.281,0,0,1,10.265,7.984Zm3.422,7.984H6.843a.57.57,0,0,1-.57-.57,1.711,1.711,0,0,1,1.711-1.711h4.562A1.711,1.711,0,0,1,14.257,15.4.572.572,0,0,1,13.687,15.968Z" transform="translate(380.235 606.375)" fill={props.color ? props.color : "#929498"}/>
            </g>
          </svg>
  )
}

export default SellerInfoIcon;