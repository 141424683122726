
export default function ChatTextMessage({ messageSendBy, message }) {
    const lastMessageDate = message.updatedAt.substring(0, 10).replaceAll("-", "/")
    const convertISODateStringToTimeZoneDate = (d) =>{
        let originalDate = new Date(d);        
        const local = new Date();
        originalDate = originalDate.getTime() - (local.getTimezoneOffset() / 60);
        originalDate = new Date(originalDate);
        return originalDate.toLocaleTimeString();
    }
    return (
        <div style={{ display: "block"}} className="m-none m-b-md">
            {/* own-message or other-message class */}
            <div className={"chat-text-message p-sm "+(messageSendBy === "own" ? "own-message": "other-message")}> 
                <div className="chat-text-message-body cursor">
                    {message.message}
                </div>
            </div>
            {/* align-left or align-right class */}
            <div className={"message-datetime m-l-sm m-r-sm m-t-xxs "+(messageSendBy === "own" ? "align-right": "align-left")}>
                {lastMessageDate} {convertISODateStringToTimeZoneDate(message.createdAt)}
            </div>
        </div>
    )
}
