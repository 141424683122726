import { Layout, Card, Loading } from 'element-react';
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

export default function AboutUsSwiper({ slidesPerView, spaceBetween, suppliers, loading }) {
    return (
      <>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="AboutUsSwiper"

        >
          <SwiperSlide>
            <p className='about-us-swiper-text'>
                BAINUB is a <b>North American B2B Network </b>where buying and selling specialized products is made simple. 
                Discover certified suppliers to purchase products or components for your manufacturing needs. <br></br><br></br>
                Stay connected with potential buyers and suppliers through our Message Center with real time chat and offline messaging. 
                Discover the power of our nearshoring model, facilitating direct connections with potential customers in North America for 
                seamless and efficient business transactions.</p>
          </SwiperSlide>
          <SwiperSlide>
            <p className='about-us-swiper-text'>
            We offer a comprehensive online trading plataform designed to simplify buying and selling products between specialized companies in 
            North America. Our platform provides all the necessary functionalities to buyers and sellers, enabling them to develop and 
            amplify the voice of their business while expanding their reach across borders.</p>
          </SwiperSlide>
          <SwiperSlide>
            <p className='about-us-swiper-text'>
            With our user-friendly interface, you can easily navigate through our platform to find microsites of certified suppliers who offer 
            a wide range of products for your company's manufacturing needs. <br></br><br></br>Whether you're looking to source components, materials, or finished products, 
            our platform has got you covered. And if you're a seller, you can showcase and sell your own products to a diverse audience of potential buyers.</p>
          </SwiperSlide>
          <SwiperSlide>
            <p className='about-us-swiper-text'>
            Don't waste any more time looking for suppliers individually. Join us and discover the most efficient way to find the right products or components 
            for your company. Unlock growth for your business. Subscribe now! Don't miss this chance to take your company to the next level!</p>
          </SwiperSlide>
        </Swiper>
      </>
    );
  }
