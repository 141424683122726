import {Card, Layout, Select, Input, Button} from 'element-react';
import {useState, useEffect} from 'react';
function FilterHeader(props){
    const t = props.t;
    
    const setFilterCerts = props.setFilterCerts;
    const filterCerts = props.filterCerts;
    const [localCert, setLocalCert] = useState();
    
    const handleClicked = () =>{
        console.log('handleClicked', localCert);
        setFilterCerts(localCert);
        //console.log(inputEl.current.getCheckedNodes());

        //console.log(data, node);
        /*setTimeout(function(){
            console.log(inputEl.current.getCheckedNodes());
        },
        1000)
        */
    }

    useEffect(() => {
        console.log('UseEffect FilterHeader');
        setLocalCert(filterCerts);
    }, []);

    return(
        <div className="filter-header">
            <Card className="box-card">
                <Layout.Row type="flex" className="filter-header-layout" span="24">
                    <Layout.Col className="filter-header-margin" offset="1"  xs="24" sm="22" md="22" lg="12">
                        <Layout.Row type="flex" span="24">
                            <Layout.Col className="result-price filter-spacer" xs="24" sm="22" md="22" lg="22">
                                {t('search.sort-by')}:
                            </Layout.Col>
                        </Layout.Row>    
                        <Layout.Row type="flex" span="24">
                            <Layout.Col className="filter-spacer" xs="10" sm="22" md="22" lg="22">
                                <Select value={props.sortBy} onChange={v => props.setSortBy(v)}>
                                    <Select.Option label={t('search.sort.option1')} value=""/>
                                    <Select.Option label={t('search.sort.option2')} value="min-price"/>
                                    <Select.Option label={t('search.sort.option3')} value="max-price"/>
                                </Select>
                            </Layout.Col>
                            <Layout.Col className="filter-spacer" xs="12" sm="22" md="22" lg="22">
                                {
                                    /*
                                    <Tree
                                    data={regions}
                                    isShowCheckbox={true}
                                    //onCheckChange={handleClicked}
                                    nodeKey="id"
                                    ref={inputEl}
                                    //onNodeClicked={handleClicked}
                                    //onCurrentChange={handleClicked}
                                    />   
                                    */
                                }
                            </Layout.Col>
                        </Layout.Row>
                        
                    </Layout.Col>
                    <Layout.Col className="gray-rigth-separator" offset="1"  xs="24" sm="2" md="2" lg="4">
                        
                    </Layout.Col>
                    <Layout.Col  className="filter-header-margin" offset="1"  xs="24" sm="22" md="22" lg="10">
                        <Layout.Row type="flex" span="24">
                            <Layout.Col className="result-min-order filter-spacer" xs="24" sm="22" md="22" lg="22">
                            {t('search.filter-message')}:
                            </Layout.Col>
                        </Layout.Row>    
                        <Layout.Row type="flex" span="24">
                            <Layout.Col tag="form-button-inverse" className="filter-spacer" xs="24" sm="22" md="22" lg="22">
                                <div style={{display: 'inline-flex'}} className="filter-spacer">
                                <Input value={localCert} onChange={v=>setLocalCert(v)} className="filter-spacer"/>
                                <Button onClick={handleClicked} style={{width: '160px', height: '40px', marginTop: '3px'}} type="primary">{t('search.button-filter')}</Button>
                                </div>
                            </Layout.Col>
                        </Layout.Row>    
                        
                        
                    </Layout.Col>
                </Layout.Row>
            </Card>
        </div>
    )
}

export default FilterHeader;