import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import userStore from './../../store/user';

export default function AnonRoute({ layout: Layout, component: Component, menuComponent: MenuComponent, footerComponent: FooterComponent, ...rest }) {
    const { user } = userStore();

    return (
        <Route
            {...rest}
            render={props => {
                return user.name ? 
                    <Redirect to="/" /> : 
                    (Layout ? 
                        <Layout menuComponent={<MenuComponent/>} footerComponent={<FooterComponent/>}> <Component {...props} /> </Layout> : 
                        <Component {...props} />
                    ) 
            }}
        >  
        </Route>
    )
}
