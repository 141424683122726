import { Layout, Card, Loading } from 'element-react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

import ProductSwiperItem from './ProductSwiperItem';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function ProductsSwiper({ slidesPerView, spaceBetween, products, loading }) {
    const { t } = useTranslation();

    function renderProducts() {
        return products && products.map(product => {
            return(
                <SwiperSlide className="swiper-item" key={product.id}>
                    <ProductSwiperItem product={product} />
                </SwiperSlide>
            )
        })
    }

    console.log("products swiper:")
    console.log(products)

    return (
        <Layout.Row >
            <Layout.Col span="24">
                <Card className="box-card">
                    <Layout.Row>
                        <Layout.Col span="24" >
                            { loading ? 
                                <Loading className="p-t-md p-b-xl" text={t('general.loading')} loading={true} fullscreen={false}/>
                                :
                                <Swiper 
                                    slidesPerView={slidesPerView ? slidesPerView : 5.5} 
                                    spaceBetween={spaceBetween} 
                                    slidesPerGroup={1} 
                                    navigation={true}
                                    loop={true}
                                    breakpoints={{
                                        344: {
                                          width: 344,
                                          slidesPerView: 1,
                                        },
                                        640: {
                                          width: 640,
                                          slidesPerView: 1,
                                        },
                                        768: {
                                          width: 768,
                                          slidesPerView: 2,
                                        },
                                      }}  
                                >
                                    { renderProducts() }
                                </Swiper>
                            }
                        </Layout.Col>
                    </Layout.Row>
                </Card>
            </Layout.Col>
        </Layout.Row>
    )
}
