import RegistrationMessage from '../components/RegistrationMessage';
import shop from '../assets/imgs/shop.png';


function RegistrationSuccess(props){
    return(
       
        <RegistrationMessage/>
        
    )
}

export default RegistrationMessage;