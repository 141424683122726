import {useEffect, useState} from 'react';
import { Card, Layout, Loading, Dropdown, Table, Button, Input, Tabs } from 'element-react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from "react-router-dom";
import {toCurrency} from '../utils/FormatUtils';
import EditSolidIcon from '../components/EditSolidIcon';
import CloneIcon from '../components/CloneIcon';
import DeleteIcon from '../components/DeleteIcon';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { createOrder, updateCounterInfoTable } from '../graphql/mutations';
import { listCompanys, listUsers, listOrders, listCounterInfoTables } from '../graphql/queries';
import { cloneOrder, sellerStatus, updateSellerStatusOrder } from '../services/OrderService';
import createImg from '../assets/imgs/create-company.png';
import PagerQuery from '../utils/PagerQuery';

function Orders(props){
    const [companyPage, setCompanyPage] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [type, setType] = useState(1);
    const [filterTable, setFilterTable] = useState('');
    useEffect(() => {
        console.log('Ordes table');
        loadOrders();
    },[type, filterTable]);

    const toCreateStore = () => {
        history.push("/my-store-wizard/0");
    }

    const loadOrders = async () =>{
        try{
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            console.log('for user :: ' + user.username);
            const pageQueryCompany = new PagerQuery(listCompanys, { filter: { owner: {eq: user.username } } }, Object.keys({ listCompanys }));
            const result = await pageQueryCompany.searchPrivateData();
            //const result = await API.graphql(graphqlOperation(listCompanys, { filter: { owner: {eq: user.username } } }))
            console.log({result})
            //@ts-ignore
            setCompanyPage(result.data.listCompanys.items[0]);


            let vars = {};

            if(type !== -1){
                if(type == 1){
                    //Para poder visualizar las Ordenes InProgress y OnHold
                    vars = {
                            filter: {
                                    and: [
                                    { owner: { eq: user.username } },
                                    {
                                        or: [
                                        { seller_status: { eq: type } },
                                        { seller_status: { eq: 5 } }
                                        ],
                                        buyer_status : { ne: 4 }
                                    }
                                    ]
                                }
                            } 
                }else{
                    vars = { filter: { owner: { eq: user.username }, seller_status: {eq: type}}  }
                }
                
            }else{
                vars = { filter: { owner: { eq: user.username }, seller_status: {lt: 99}}  }
            }

            const pageQuery = new PagerQuery(listOrders, vars, Object.keys({ listOrders }));
            const resultOrders = await pageQuery.searchPrivateData();
            /*const resultOrders = await API.graphql({
                query: listOrders, 
                variables: vars
                        
            });*/
            console.log({resultOrders});
            setOrders(resultOrders.data?.listOrders.items);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }   
    }
    const [orders, setOrders] = useState([]);
    const { t } = useTranslation('',{ useSuspense: true });
    const columns = 
    [
        {
          label: t('orders.table.number'),
          prop: "orderNumber",
          width: 80,
          sortable: true,
          render: (row, column, index)=>{
            return(
                <Link to={"/add-order-wizard/" + row.id}> {row.orderNumber} </Link>  
            ) 
          }
        },
        {
            filterMethod(value, row) {
                if(filterTable !== ''){
                  return row.buyer_email.toLowerCase().includes(filterTable.toLowerCase()) ;
                }else{
                    return true;
                }
              
            },
            filters: [{ text: 'Busqueda', value: filterTable }],
            filteredValue :[filterTable],
            label: t('orders.table.client'),
            //sortable: true,
            prop: "buyer_email",
            render: (row, column, index)=>{
              return(
                <Link to={"/add-order-wizard/" + row.id}> {row.buyer_email} </Link>  
              ) 
            }
        },
        {
            label: t('orders.table.products'),
            id: 'products-cell',
            //prop: 'products.name',
            //sortable: true,
            render: (row, column, index)=>{
              return(
                  <div className="table-order-products">
                      {row.products ? row.products[0]?.name : ''} {row.products?.length > 1 ? <div>&nbsp;(+{row.products.length-1}More)</div> : <></>}
                  </div>
              ) 
            }
        },
        {
            label: t('orders.table.quantity'),
            width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                row.products ? row.products[0]?.quantity : 0
              ) 
            }
        },
        {
            label: t('orders.table.unit'),
            width: 120,
            render: (row, column, index)=>{
              return(
                row.products ? row.products[0]?.unit : 0
              ) 
            }
        },
        {
            label: t('orders.table.total'),
            width: 160,
            prop: 'total',
            sortable: true,
            render: (row, column, index)=>{
              return(
                toCurrency(row.total)
              ) 
            }
        },
        {
            label: t('orders.table.due-date'),
            width: 125,
            prop: 'deliveryDate',
            sortable: true,
            render: (row, column, index)=>{
                if(row.terms?.deliveryDate.toString() == '1900-01-01')
                    return '-';
                return(
                  row.terms?.deliveryDate
                ) 
              }
        },
        {
            label: t('orders.table.status'),
            width: 110,
            prop: 'seller_status',
            sortable: true,
            render: (row, column, index)=>{
                return sellerStatus(row.seller_status, row.buyer_status);
            }
        },
        {
            label: t('orders.table.actions'),
            width: 100,
            align: 'center',
            render: (row, column, index)=>{
                return(
                    <Dropdown trigger="click" menu={(
                        <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <div className='inline-row cursor' >
                                            <EditSolidIcon newStyle={{'margin-top' : '10px'}} color="#393e46" coords="0 0 16 33" handleClick={()=>{goToEditProduct(row.id)}} width={30} height={30}/> 
                                            <div onClick={()=>{goToEditProduct(row.id)}}>Edit</div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <div className='inline-row cursor' >
                                            <CloneIcon newStyle={{'margin-top' : '10px'}} color="#393e46" coords="0 0 16 33" handleClick={()=>{cloneHandler(row.id)}} width={30} height={30}/><div onClick={()=>{cloneHandler(row.id)}}>{t('orders.actions.clone-btn')}</div>
                                        </div>
                                    </Dropdown.Item>
                                    {
                                        row.seller_status === 0 
                                        ?
                                            <Dropdown.Item>
                                                <div className='inline-row cursor' >
                                                    <DeleteIcon newStyle={{'margin-top' : '10px'}} color="#393e46" coords="0 0 16 33" handleClick={()=>{deleteHandler(row.id)}} width={30} height={30}/><div onClick={()=>{deleteHandler(row.id)}}>{t('orders.actions.delete-btn')}</div>
                                                </div>
                                            </Dropdown.Item>
                                        :
                                            <></>
                                    }
                                    
                        </Dropdown.Menu>
                    )}>
                        <div className="order-dots" /*onClick={() => alert(row.id)}*/>
                            ...
                        </div>
                    </Dropdown>
                    
                ) 
            }
        }
    ]
    const cloneHandler = async (id) =>{
        console.log('cloneHandler');
        try{
            setLoading(true);
            const orderCloned = await cloneOrder(id);
            //setOrders({...orders, orderCloned});
            //TODO mejorar como se recargar las ordenes...
            //window.location.reload(false);
            goToEditProduct(orderCloned);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
        
    }

    const deleteHandler = async (id) =>{
        console.log('deleteHandler');
        try{
            setLoading(true);
            const orderUpdated = await updateSellerStatusOrder(id, 99);
            //window.location.reload(false);
            loadOrders();
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
        
    }

    const updateProductCounter = async(user) =>{
        try{
            const result = await API.graphql({ query: listCounterInfoTables, variables: 
                { 
                    filter: 
                        { 
                            owner: { eq: user.username } 
                        } 
                } 
            });
            console.log('NEW PRODUCT :: updateProductCounter');
            console.log({result});
            if(result && result.data && result.data?.listCounterInfoTables?.items.length > 0){
                const input = {
                    id: result.data.listCounterInfoTables.items[0].id,
                    active_orders_total: result.data.listCounterInfoTables.items[0].active_orders_total + 1
                };
                const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, { input }));
                console.log({resultUpdate});
            }else{
                console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
            }
        }catch(e){
            console.error(e);
        }
    }

    const viewNotes = (id) =>{
        console.log(`View notes ${id}`);
    }

    const goToEditProduct = (id) =>{
        console.log('goToEditProduct');
        history.push('/add-order-wizard/' + id);
    }

    const dotsClick = (row, event, column) =>{
        console.log({row});
        console.log({column});
    }

    const goToAddOrder = async () =>{
        //console.log(`goToAddOrder`)
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const resultOrders = await API.graphql({ query: maxOrderNumberQuery, variables: { filter: { owner: { eq: user.username } }  }});
        console.log({resultOrders});
        let maxNumber = 10;
        if(resultOrders.data.listOrders.items && resultOrders.data.listOrders.items.length > 0){
            console.log('si hay items, recorriendo para obtener uno nuevo');
            //TODO obtener el mayor numero...
            maxNumber = Math.max.apply(Math, resultOrders.data.listOrders.items.map(function(o) { return o.orderNumber; })) + 1;
        }

        const companyResult  = await API.graphql({
            query: listCompanys,
            variables: { filter: { owner: {eq: user.username } } }
        });
        const company = companyResult.data.listCompanys.items[0];
        const filter = {
            cognito_id : {eq : company.owner}
        }
        //const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter }});
        const inputListUsers = { filter: filter}
        const pagerQuery = new PagerQuery(listUsers, inputListUsers, Object.keys({ listUsers }));
        const resultCompanyUser = await pagerQuery.searchPrivateData();
        console.log({resultCompanyUser});
        const _user = resultCompanyUser.data.listUsers.items[0]
        const input = {
            owner: user.username,
            owner_name: _user.first_name + ' ' + _user.last_name,
            buyer:'',
            buyer_name:'',
            buyer_email: '',
            orderNumber: maxNumber,
            company_name: company ? company.name : '',
            company_id: company ? company.id : '',
            company_contact_name: _user.first_name + ' ' + _user.last_name,
            company_email: _user.email,
            company_address: company ? company.address_str : '',
            seller_status: 0,
            buyer_status: 0,
            total: 0
        };
        const result = await API.graphql(
            graphqlOperation(createOrder, { input })
        );
        console.log({result});
        await updateProductCounter(user);
        setLoading(false);
        //history.push("/add-order-wizard/"+result.data.createOrder.id);
        const isFirefox = typeof InstallTrigger !== 'undefined';
        console.log('isFirefox ' , isFirefox);
            if(isFirefox){
                history.push("/add-order-wizard/"+result.data.createOrder.id);
            }else{
                const win = window.open("/add-order-wizard/"+result.data.createOrder.id, "_blank");
                win.focus(); 
            }
        
    }

    const switchTab = (tab) =>{
        if(tab === '1'){
            setType(0);
        }else{
            if(tab === '2'){
                setType(1);
            }else{
                if(tab === '3'){
                    setType(3);
                }else{
                    if(tab === '4'){
                        setType(-1);
                    }
                }
            }
        }
    }

    let maxOrderNumberQuery = /* GraphQL */ `
        query listOrders(
            $filter: ModelOrderFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                orderNumber
            }
            nextToken
            }
        }
    `;
    
    return(
        <div>
            {
                loading && <Loading fullscreen={loading} />
            }
            <div className="my-product-table">
                {
                    companyPage == null ? 
                    <>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        <h2>{t('orders.table.title')}</h2>
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        &nbsp;
                                    {
                                        /*
                                    <Button onClick={null} type="primary">Test crear empresa</Button>
                                        */
                                    }
                                    </Layout.Col>
                        </Layout.Row>


                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                            <Card className="box-card card-company">
                                                <Layout.Row type="flex" justify="start" align="top">
                                                        <Layout.Col offset="1">
                                                        </Layout.Col>
                                                </Layout.Row>
                                                <div className="center">
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                <img src={createImg}/>
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col >
                                                                &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex" justify="center" align="middle">
                                                        <Layout.Col tag="form-button">
                                                            <div className="text-center">
                                                                <Button type="primary" onClick={toCreateStore}>
                                                                                            {t('my-store.button-add-store')}
                                                                </Button>
                                                            </div>
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                </div>
                                                
                                            </Card>               
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        &nbsp;
                                    </Layout.Col>
                        </Layout.Row>
                        
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        &nbsp;
                                    </Layout.Col>
                        </Layout.Row>
                    </>
                    
                    :
                    <>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="20">
                                        <h2>{t('orders.table.title')}</h2>
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="22">        
                                    <Tabs activeName="2" onTabClick={ (tab) => switchTab(tab.props.name) }>
                                        <Tabs.Pane label={t('orders.tabs.draft')} name="1"></Tabs.Pane>
                                        <Tabs.Pane label={t('orders.tabs.active')} name="2"></Tabs.Pane>
                                        <Tabs.Pane label={t('orders.tabs.completed')} name="3"></Tabs.Pane>
                                        <Tabs.Pane label={t('orders.tabs.all')} name="4"></Tabs.Pane>
                                    </Tabs>            
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row type="flex">
                                        <Layout.Col span="24" xs="20" sm="20" lg="23">
                                            <Layout.Row type="flex" justify="end">
                                                <Layout.Col tag="form-button">
                                                    <div style={{display: 'inline-flex'}}>
                                                        <Input
                                                            icon="search"
                                                            placeholder="Search"
                                                            value={filterTable}
                                                            onChange={v=>{setFilterTable(v)}}
                                                            className="input-width-200"
                                                        />
                                                        <Button className="left-separator" type="primary" onClick={goToAddOrder}>
                                                                {t('orders.table.add-btn')}
                                                        </Button>
                                                    </div>
                                                </Layout.Col>
                                            </Layout.Row>
                                        </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20" justify="end" type="flex" >
                                    <Layout.Col offset="1" lg="10">
                                        &nbsp;
                                    </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                                    <Layout.Col offset="1" span="22">
                                        
                                        <Table
                                            className="products-table box-card"
                                            data={orders}
                                            columns={columns}  
                                            fit={true}
                                            emptyText="No orders available..."
                                            onRowClick={dotsClick}
                                        />
                                    </Layout.Col>
                        </Layout.Row>
                </>
                }
            </div>
        </div>
        
    )
}

export default Orders;