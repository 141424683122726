const addProductRules0 = (t) => {
    return  ({
        
    })
}

const addProductRules1 = (t) => {
    return  ({
        name: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        description: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        minPrice: [
            { required: true, message: t('my-store.create.form.validator.number-decimal'), trigger: 'blur', validator: (rule, value) => 
                {
                    //var numbers = /[0-9]+\.?[0-9]*/;
                    value = value + "";
                    var numbers = /^\d{1,6}(\.\d{0,4})?$/;
                    console.log('NUMBERS :: ' + value);
                    if (!value.match(numbers) || value <= 0) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        maxPrice: [
            { required: true, message: t('my-store.create.form.validator.number-decimal'), trigger: 'blur', validator: (rule, value) => 
                {
                    //var numbers = /[0-9]+\.?[0-9]*/;
                    console.log('maxPrice :: ' + value);
                    value = value + "";
                    var numbers = /^\d{1,16}(\.\d{0,4})?$/;
                    if (!value.match(numbers) || value <= 0) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        measurement: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        minOrder: [
            { required: true, message: t('my-store.create.form.validator.number2'), trigger: 'blur', validator: (rule, value) => 
                {
                    var numbers = /^[0-9]+$/;
                    if (value === "" || !value.match(numbers) || value <= 0) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        packageType: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        clientService: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        monthlyCapacity : [
            { required: false, message: t('my-store.create.form.validator.number2'), trigger: 'blur', validator: (rule, value) => 
                {
                    var numbers = /^[0-9]+$/;
                    if ( value !== "" && (!value.match(numbers) || value <= 0)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        quantityPerPackage : [
            { required: false, message: t('my-store.create.form.validator.number2'), trigger: 'blur', validator: (rule, value) => 
                {
                    var numbers = /^[0-9]+$/;
                    if ( value !== "" && (!value.match(numbers) || value <= 0)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        packageDimensions1 : [
            { required: false, message: t('my-store.create.form.validator.number2'), trigger: 'blur', validator: (rule, value) => 
                {
                    var numbers = /^[0-9]+$/;
                    if ( value !== "" && (!value.match(numbers) || value <= 0)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        packageDimensions2 : [
            { required: false, message: t('my-store.create.form.validator.number2'), trigger: 'blur', validator: (rule, value) => 
                {
                    var numbers = /^[0-9]+$/;
                    if ( value !== "" && (!value.match(numbers) || value <= 0)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        packageDimensions3 : [
            { required: false, message: t('my-store.create.form.validator.number2'), trigger: 'blur', validator: (rule, value) => 
                {
                    var numbers = /^[0-9]+$/;
                    if ( value !== "" && (!value.match(numbers) || value <= 0)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        packageWeight : [
            { required: false, message: t('my-store.create.form.validator.number-decimal2'), trigger: 'blur', validator: (rule, value) => 
                {
                    //var numbers = /[0-9]+\.?[0-9]*/;
                    var numbers = /^\d{1,6}(\.\d{0,1})?$/;
                    if (value !== "" && (!value.match(numbers) || value <= 0)) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        tags : [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur', validator: (rule, value) => 
                {
                    console.log('TAAAAGS :: ' + value);
                    //let total = value ? value.split(",") : [];
                    //console.log({total});
                    console.log(value.length);
                    if(value.length >= 3){
                        return true;
                    }else{
                        return false;
                    }
                } 
            }
        ]

    })
}

const addProductRules2 = (t) => {
    return  ({

    })
}

export {addProductRules0, addProductRules1, addProductRules2};