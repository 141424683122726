import {Layout, Loading, Select, Table, Dialog, Radio} from 'element-react';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {listCounterInfoTables, listUserFavoritess, getProduct} from '../graphql/queries';
import {updateProduct, updateCounterInfoTable} from '../graphql/mutations';
import {Auth, API, graphqlOperation} from 'aws-amplify';
import {S3Image} from "../components/S3Image";
import {useHistory, Link} from "react-router-dom";
import {statusOptions} from '../utils/Catalogs';
import * as productController from '../graphql/productLambdaInvokes';
import PagerQuery from '../utils/PagerQuery';

function MyFavorites() {
    
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation('', {useSuspense: true});
    const [products, setProducts] = useState([]);
    const [filterTable, setFilterTable] = useState('');
    const [rows, setRows] = useState([]);
    const [generalStatus, setGeneralStatus] = useState('');
    const [showVendorDialog, setShowVendorDialog] = useState(false);
    const [vendorDialogData, setVendorDialogData] = useState({
        key: '',
        productName: '',
        vendorName: ''
    });
    let history = useHistory();
    const columns =
        [
            {
                label: t('my-products.table.product'),
                filterMethod(value, row) {
                    if (filterTable !== '') {
                        return row.name.toLowerCase().includes(filterTable.toLowerCase());
                    } else {
                        return true;
                    }

                },
                render: (row, column, index) => {
                    return (
                        <span style={{display: 'inline-flex'}}>
                    {
                        row.photo && row.photo.length > 0
                            ? <S3Image imgKey={row.photo[0].key}
                                       theme={{photoImg: {maxWidth: "50px", maxHeight: "50px", marginRight: "5px"}}}
                                       level="public"/>
                            : <div style={{width: "50px", height: "50px", marginRight: "5px"}}></div>
                    }
                            &nbsp;

                            <div>
                                <Link target="_blank" to={"/product-detail/" + row.id}>{row.name}</Link>
                                <span id={'category-title'}><br/>Category: <span
                                    id={'product-category'}>{row.category.name}</span></span>
                                
                            </div>
                </span>
                    )
                }
            },
            {
                
                label: 'Company',
                render: (row, column, index) => {
                    return (
                        <span>
                            <div>
                                <Link target="_blank" to={"/company-store/" + row.company_id}>{row.company_name}</Link>
                            </div>
                        </span>
                    )
                
                }
            }
        ];

    useEffect(async () => {
        console.log('MyProducts - useEffect');
        await loadProducts();
    }, [filterTable]);


    const loadProducts = async () => {
        setLoading(true);
    
        const user = await Auth.currentAuthenticatedUser();
        console.log({user});
        
        //const resultFavorites = await API.graphql(graphqlOperation(listUserFavoritess, {filter: {owner: {eq: user.username}}}));
        const input = {filter: {owner: {eq: user.username}}};
        const pagerQuery = new PagerQuery(listUserFavoritess, input, Object.keys({ listUserFavoritess }));
        const resultFavorites = await pagerQuery.searchPrivateData();

        console.log({resultFavorites});
        let items = resultFavorites.data.listUserFavoritess.items;
        let _products = [];
        for (let i = 0; i < items.length; i++) {
            if(items[i].product.status == 1){
                const productResult = await API.graphql(graphqlOperation(getProduct, {id: items[i].product.id}));
                _products.push(productResult.data.getProduct);
            }
                
        }
        setProducts(_products);
        setLoading(false);
    }

    const updateProductCounter = async (user) => {
        try {
            const result = await API.graphql({
                query: listCounterInfoTables, variables:
                    {
                        filter:
                            {
                                owner: {eq: user.username}
                            }
                    }
            });
            console.log('NEW PRODUCT :: updateProductCounter');
            console.log({result});
            if (result && result.data && result.data?.listCounterInfoTables?.items.length > 0) {
                const input = {
                    id: result.data.listCounterInfoTables.items[0].id,
                    products_total: result.data.listCounterInfoTables.items[0].products_total - 1
                };
                const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, {input}));
                console.log({resultUpdate});
            } else {
                console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
            }
        } catch (e) {
            console.error(e);
        }
    }

    const changeStatus = async (id, newStatus) => {
        console.log(`changeStatus => ${id} - ${newStatus}`)
        setLoading(true);
        try {
            const input = {
                id: id,
                status: newStatus
            };
            const result = await API.graphql(graphqlOperation(updateProduct, {input}));
            console.log({products});
            if (products.length == 0) await loadProducts();
            let objIndex = products.findIndex((obj => obj.id == id));
            console.log(`index to replace :: ${objIndex}`)
            let _products = products;
            let productModifieds = {...result.data.updateProduct, views: _products[objIndex].views}
            _products[objIndex] = productModifieds;
            setProducts([..._products]);
            if (newStatus == 2) {
                const u = await Auth.currentAuthenticatedUser();
                await updateProductCounter(u);
            }
            productController.updateProductStatus(input);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }

    }

    const goToEditProduct = (id) => {
        console.log(`goToEditProduct => ${id}`)
        history.push("/add-product-wizard/" + id);
    }

    const changeGeneralStatus = async (newStatus) => {
        if (newStatus !== '') {
            try {
                await rows.forEach(async (element) => {
                    setLoading(true);
                    await changeStatus(element.id, newStatus);
                });
                setGeneralStatus('');
                setRows([]);
                setLoading(false);
            } catch (e) {
                console.error(e)
                setLoading(false);
            }
        }


    }


    return (
        <div>
            {
                loading && <Loading fullscreen={loading}/>
            }

            <Dialog size="tiny"
                    visible={showVendorDialog}
                    onCancel={() => {
                        console.log('cancel Diaglog');
                        setShowVendorDialog(false);
                    }}
            >
                <Dialog.Body>
                    <Layout.Row gutter="20">
                        <Layout.Col offset="1" span="7">
                            {
                                vendorDialogData.key
                                    ?
                                    <S3Image
                                        imgKey={vendorDialogData.key}
                                        theme={{
                                            photoImg: {maxWidth: "75px", maxHeight: "75px", marginRight: "15px"}
                                        }}
                                        level="public"/>
                                    :
                                    <></>
                            }

                        </Layout.Col>
                        <Layout.Col span="15">
                            {vendorDialogData.productName}
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <Layout.Col span="15">
                            &nbsp;
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row justify="center" type="flex">
                        <Layout.Col offset="1" span="6">
                            {t('my-products.table.vendors-modal')}:
                        </Layout.Col>
                        <Layout.Col offset="1" span="15">
                            <div className="radio-custom">
                                <Radio value="1" checked={true}>
                                    {vendorDialogData.vendorName}
                                </Radio>
                            </div>
                        </Layout.Col>
                    </Layout.Row>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">

                </Dialog.Footer>
            </Dialog>
            <div className="my-product-table">
                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="20">
                        <h2>Favorites</h2>
                    </Layout.Col>
                </Layout.Row>


                

                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="4">
                        {
                            rows.length > 0
                                ?
                                <Select value={generalStatus}
                                        placeholder={'Change Status'}
                                        onChange={(v) => {
                                            setGeneralStatus(v);
                                            changeGeneralStatus(v);
                                            console.log(v)
                                        }}
                                >
                                    <Select.Option key={-1} label={''} value={''}/>
                                    {
                                        statusOptions.options.map(el => {
                                            return <Select.Option key={el.value} label={el.label} value={el.value}/>
                                        })
                                    }

                                </Select>
                                :
                                <></>

                        }

                    </Layout.Col>
                </Layout.Row>


                <Layout.Row gutter="20" justify="end" type="flex">
                    <Layout.Col offset="1" lg="10">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                    <Layout.Col offset="1" span="22">

                        <Table
                            className="products-table box-card"
                            data={products}
                            columns={columns}
                            emptyText="No data..."
                            onSelectChange={(selection) => {
                                setRows(selection);
                                console.log(selection)
                            }}
                            onSelectAll={(selection) => {
                                setRows(selection);
                                console.log(selection)
                            }}
                        />
                    </Layout.Col>
                </Layout.Row>
            </div>
        </div>

    )
}

export default MyFavorites;