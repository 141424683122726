import React from 'react';
import { Collapse } from 'element-react';

function HelpAndSupportComponent({menu}) {
    const activeName = "0";
    const _className = menu ? {paddingLeft: '40px'} : {};
    return (
        <div style={_className}>
            <Collapse value={activeName} accordion>
                <Collapse.Item title="1. What kind of products can I promote in Bainub?" name="1">
                Bainub is a B2B platform for components/products with special manufacturing characteristics, customized and produced/packaged/labeled according to the buyers requirement.<br/>
                In Bainub you can advertise:<br/>
                <ul>
                    <li>Intermediate Components</li>
                    <li>Custom Manufacturing</li>
                    <li>White Label Products</li>
                    <li>Tools And Machinery</li>
                    <li>Packing And Packaging</li>
                    <li>Textile</li>
                    <li>Agriculture And Food</li>
                    <li>Molds and Tooling</li>
                    <li>Any product manufactured/produced for industry or wholesale market</li>
                </ul>
                You don't have to have the components in stock for immediate delivery. Bainub is a business platform in which you can negotiate terms, delivery time, minimum quantity, etc.<br/>
                If you have any doubt about product restrictions or related please <a href="https://www.bainub.com/contact-us" target="_blank">contact us</a>, we'll be happy to advice on your specific needs.
                </Collapse.Item>
                <Collapse.Item title="2. My company provides Services, can I use Bainub to promote Services?" name="2">
                No. Right now Bainub specializes in tangible products and components. We don't have the tools required for Service Providers at the moment.<br/>
                Please <a href="https://www.bainub.com/contact-us" target="_blank">contact us</a> here if you have any doubt about your specific situation.<br/>
                </Collapse.Item>
                <Collapse.Item title="3. I want to advertise my products in Bainub. How do I start?" name="3">
                Its easy! All the tools for you as a seller are available in the Seller Hub.<br/>
                These are the steps you need to follow:<br/>
                <ul>
                <li>1. Get a Seller Account to be able to use the Seller Hub tools.</li>
                <li>2. Go to your Seller Hub.</li>
                <li>3. In the Seller Hub's left menu, go to My Company to launch our wizard to build your Business Microsite.</li>
                <li>4. Go to My Products to create your first Product Ad in Bainub.</li>
                </ul>

                </Collapse.Item>
                <Collapse.Item title="4. Can I get in contact with Buyers directly?" name="4">
                In Bainub you create ads to attract buyers and get them interested in your manufacturing services. They contact you through our message center after evaluating your information.<br/>
                To increase the effectiveness of your ads, we recommend to build a Microsite with a full description of your company's experience and capabilities. <br/>
                Its important to build Product Ads with high-quality technical information and pictures which reflect the proffesionalism of your work.<br/>
                Explain, with example products in each ad, your capabilities as manufacturer in your specialty area and why you should be trusted with a manufacturing project.<br/>
                The process is simple:<br/>
                <ul>
                    <li>Create your Company Microsite</li>
                    <li>Create Product Ads</li>
                    <li>Buyers send you Inquiries through our Message Center</li>
                    <li>You quote the items and establish your terms</li>
                    <li>Negotiate and create agreements</li>
                    <li>Create a Trade Agreement and submit to your customer for approval</li>
                </ul>
                </Collapse.Item>

                <Collapse.Item title="5. Is there a selling fee that I have to pay each time I sell?" name="5">
                Bainub <b>won’t</b> request any fee, commission, or participation as part of your trading operations.
                </Collapse.Item>

                <Collapse.Item title="6. Is Bainub involved in my transactions?" name="6">
                No, Bainub provides the platform to connect suppliers with customers, then the traders can freely negotiate conditions and terms with each other for each of their transactions without us requiring any participation.<br/>
                You and your trade partner have full ownership and responsibility over the transaction.
                </Collapse.Item>

                <Collapse.Item title="7. Should I upload products in English or Spanish?" name="7">
                All the information in Bainub must be submitted in English.
                </Collapse.Item>

                <Collapse.Item title="8. Can I get assistance to upload my Product ads?" name="8">
                Yes! We are happy to help you to get he best experience using Bainub.<br/>	
                We can help you to upload your information through video calls and also with translations of your product information.<br/>	
                We also offer free Demo to show you how to use the Seller Tools available for you in Bainub.<br/>	
                Please <a href="https://www.bainub.com/contact-us" target="_blank">send a message</a> and we will contact you in no time.	
                </Collapse.Item>

                <Collapse.Item title="9. Bainub provides logistics/customs services?" name="9">
                No, Bainub provides the platform to connect suppliers with customers, then the terms of the delivery of the cargo is set by you and your trade partner. <br/>
                You can negotiate to deliver in your facilities in Mexico or you can offer shipping services through an express company or a forwarder. You can freely establish what is best for your project.<br/>
                We can happily recommend logistics partners and customs agents for you to talk to them and start building relations that will help to improve your services in the future. <a href="https://www.bainub.com/contact-us" target="_blank">Contact us here</a>
                </Collapse.Item>

                <Collapse.Item title="10. I have never exported my products. Do you offer mentoring for export processes?" name="10">
                Better yet. We have expert allies which might help you become a proficient exporter considering all aspects of the process: knowledge, documentation, certifications, logistics, etc.<br/>	
                <a href="https://www.bainub.com/contact-us" target="_blank">Get in touch with us</a> and we can easily arrange a meeting with  our expert allies.	
                </Collapse.Item>


            </Collapse>
           
            
        </div>
    );
}

export default HelpAndSupportComponent;
