import { API } from 'aws-amplify'


export default async function sendEmailTemplate ({ templateName, templateLanguage, emailFrom, recipients, templateVars, subject }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/newNotification/sendTemplate', {
            body: {
                templateName, 
                templateLanguage, 
                emailFrom, 
                recipients : recipients, 
                templateVars, 
                subject
            }
        })
        console.log(result)
    } catch(error) {
        console.error(error)
    }
}