import { Layout,  Form, Input } from 'element-react';
import SummaryIcon from './SummaryIcon';
import { pad } from '../../utils/FormatUtils';

function Summary(props){
    const t = props.t;

    if(props.readOnly){
        return(
            <>
                <Layout.Row gutter="20">
                    <Layout.Col span="20" style={{display: 'inline-flex'}}>
                        <SummaryIcon width={41} height={41}/> <div className="order-step-form">{t('orders.viewer.summary')}</div>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                    <Layout.Col lg={4}>
                        <Form.Item label={t('orders.form.order-number')} >
                            <br/>
                            <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                                {pad(props.formInputs.orderNumber, 10)}
                            </div>     
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col lg={4}>
                        <Form.Item label={t('orders.form.seller')} >
                            <br/>
                            <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                                {props.formInputs.companyName}
                            </div>     
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col lg={4}>
                        <Form.Item label={t('orders.form.tracking-number')} >
                            <br/>
                            <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                                {props.formInputs.trackingNumber}
                            </div>     
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>
            </>
        )
    }

    return(
        <>
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <SummaryIcon width={41} height={41}/> <div className="order-step-form">{t('orders.form.summary')}</div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4}>
                    <Form.Item label={t('orders.form.order-number')} >
                        <Input 
                            disabled
                            value={pad(props.formInputs.orderNumber, 10)}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, orderNumber: v })}} 
                            />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4}>
                    <Form.Item label={t('orders.form.seller')} >
                        <br/>
                        <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                            {props.formInputs.companyName}
                        </div>     
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4}>
                    <Form.Item label={t('orders.form.tracking-number')} >
                        <Input 
                            value={props.formInputs.trackingNumber}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, trackingNumber: v })}} 
                            />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
        </>
    )
}

export default Summary;