import { API } from 'aws-amplify'

async function saveCategory ({ id, name, description, currentUser }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/category/save', {
            body: {
                id, name, description, currentUser
            }
        })
        console.log(result)
    } catch(error) {
        console.error(error)
    }
}

async function saveCategoryTag ({name, tagName, categoryId}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/category/tags/save', {
            body: {
                tagName, categoryId
            }
        });
    }catch(error) {
        console.error(error);
    }
}

async function saveSubCategory ({ id, name, description, currentUser, categoryId }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/subcategory/save', {
            body: {
                id, name, description, currentUser, categoryId
            }
        })
        console.log(result);
        return result;
    } catch(error) {
        console.error(error)
    }
}

async function saveSubCategoryTag ({ tagName, subCategoryId}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/subcategory/tags/save', {
            body: {
                tagName, subCategoryId
            }
        });
        console.log(result);
        return result;
    }catch(error) {
        console.error(error);
    }
}

async function getSubCategoryByCategoryIdAndName({ categoryId, name }) {
    try {
        const result = await API.post('NotificationServiceLambda', `/subcategory/get/companyId/name`, {
            body: {
                categoryId, name
            }
        })
        console.log(result);
        return result;
    } catch(error) {
        console.error(error)
    }
}

async function searchSubCategory({name}){
    try {
        const result = await API.post('NotificationServiceLambda', `/category/search/subcategory`, {
            body: {
                name
            }
        })
        console.log(result);
        return result;
    } catch(error) {
        console.error(error)
    }
}

export  { saveCategory, saveCategoryTag, saveSubCategory, saveSubCategoryTag,
            getSubCategoryByCategoryIdAndName, searchSubCategory };