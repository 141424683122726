import { Layout } from 'element-react'
import React from 'react'

export default function ChatEmptyInbox() {
    return (
        <Layout.Row style={{height: '70vh'}} >
            <Layout.Col span="9" className="chat-empty-inbox-leftside">
                <Layout.Col className="m-t-md">
                    <label>Here you can communicate with your Bainub Clients and Suppliers.</label>
                    <label>You Don't have active conversations.</label>
                </Layout.Col>
            </Layout.Col>
            <Layout.Col span="15" className="chat-empty-inbox-rightside p-l-md">
                <Layout.Col className="m-t-md p-b-xl">
                    {
                        /*
                        <>
                        <label className="m-t-md"><b>¿Cuanto van a durar las conversaciones?</b></label>
                        <label className="m-t-md"><b>Gratis:</b> Purga manual de adjuntos</label>
                        <label className="m-t-md"><b>Premium:</b> Adjuntos siempre Mientras dure la membresía</label>
                        <label className="m-t-xl"><b>+ Agregar búsqueda dentro de conversaciones</b></label>
                        <label className="m-t-xl"><b>+ ¿Notificaciones por correo al proveedor / comprador?</b></label>
                        <label className="m-t-md"><b>Si</b></label>
                        <label className="m-t-xl">Limitar MB de archivos adjuntos (25 mb)</label>
                        </>
                        */
                    }
                    
                </Layout.Col>
            </Layout.Col>
        </Layout.Row>
    )
}
