import {useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import { API, Auth } from 'aws-amplify';
import { createContactUsFormFill } from '../graphql/mutations';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql"

import PrivacyPolicy from './PrivacyPolicy';

import contactUsRules from '../utils/ContactUsRules';

import { Layout, Button, Form, Input, Select, Loading, Switch, Checkbox, MessageBox} from 'element-react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import Popup from 'reactjs-popup';
import sendEmailTemplate from '../graphql/lambdaInvokes';
import * as Constans from '../../src/utils/Constants';

function ContactUs(props) {
    const { t } = useTranslation();
    const language = t('language')

    const [loading, setLoading] = useState(false);
    const [isPrivacyPolicyVisible, setIsPrivacyPolicyVisible] = useState(false);
    const form = useRef(null);
    const [rules, setRules] = useState();
    const [formInputs, setFormInputs] = useState(
        {
            phone: '',
            contactReason: '',
            fullName: '',
            email: '',
            businessName: '',
            message: '',
            captcha: '',
            isBainubUser: false,
            termsAndConditions: false
        }
    )

    const showPrivacyPolicy = () => {
        setIsPrivacyPolicyVisible(true)
    }

    const handleOnSubmit = () => {
        console.log('handle on submit');
        console.log('Terms and conditions value :: ' + formInputs.termsAndConditions);
        window.scrollTo(0, 0);
        if(validateCaptcha(formInputs.captcha) === false){
            console.log('captcha is not valid');
            setFormInputs({...formInputs, captcha: '' });
            form.current.validate();
            printFieldsWithError(form);
        }else{
            console.log('captcha is valid');
            form.current.validate((valid) => {
                console.log('validator :: ' + valid);
                if (valid) {
                    saveContactUs();
                } else {
                  console.log('error saving contact us detail');
                  printFieldsWithError(form);
                  return false;
                }
            });
        }
    }


    const printFieldsWithError = (form) => {
        let c = form.current;
        console.log({c});
        let fieldsWithError = [];
        for(let i = 0; i < c.state.fields.length; i++){
            if(c.state.fields[i].state.valid === false){
            if(c.state.fields[i].props.label != undefined){
                fieldsWithError.push(c.state.fields[i].props.label);
            }else{
                fieldsWithError.push('Your approval of the Terms of Use and Privacy Policy is required to continue.');
            }
                
            }
        }
        if(fieldsWithError.length > 0){
            let msg = '';
            msg = fieldsWithError.join(' \n');
            console.log('fieldsWithError.length :: ' + fieldsWithError.length);
            console.log('fieldsWithError :: ' + msg);
            MessageBox.alert(msg , 'Review Fields with Error: ', { type: 'error' });
        }
    }

    const saveContactUs = async () => {
        try {
            setLoading(true);
            const input = {
                phone: formInputs.phone,
                contact_reason: formInputs.contactReason,
                full_name: formInputs.fullName,
                email: formInputs.email,
                business_name: formInputs.businessName,
                message: formInputs.message,
                is_bainub_user: formInputs.isBainubUser
            }
            console.log({ input });
            
            const result = await API.graphql({
                query: createContactUsFormFill,
                variables: { input },
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
            });
            console.log("contact us result:", result);
            try{
                const user = await Auth.currentAuthenticatedUser();    
                input.user = user.username;
            } catch(e){}
            
            
            
            await sendEmailTemplate({
                templateName: "helpRequest", 
                subject: "Someone needs help!",
                emailFrom: Constans.FROM_EMAIL,
                recipients: ["czuniga@automateit.mx", "customerservice@bainub.com"], 
                templateVars: input,
                templateLanguage: "en"
            });

            setLoading(false);
            let msg = t('contact-us.form.success-message');
            MessageBox.confirm(msg , 'Success', { showCancelButton: false, showConfirmButton: true, type: 'success' });
            
        } catch (error) {
            setLoading(false);
            console.log("error saving contact us detail:", error);
            let msg = t('contact-us.form.error-message');
            loadCaptchaEnginge(6);
            MessageBox.alert(msg , 'Error', { type: 'error' });
        }
    }

    useEffect(() => {
        loadCaptchaEnginge(6);
        setRules(contactUsRules(t));
    }, [t]);
    const _className = props.menu ? {paddingLeft: '40px'} : {};
    if(loading)
        return <Loading loading={loading} fullscreen={true} />
    return(
        <div style={_className}>
            <PrivacyPolicy language={language} isPrivacyPolicyVisible={isPrivacyPolicyVisible}
                           setIsPrivacyPolicyVisible={setIsPrivacyPolicyVisible}/>
            <Layout.Row gutter="10" type="flex" className="first-title-row" >
                <Layout.Col span="24">
                    {
                        props.hideTitle == true ? <></> : <h2 className="form-title" >{t('contact-us.title')}</h2>
                    }
                    
                    <label className="el-form-item__label" >{t('general.required-fields-label-1')} <span className="red-dash">*</span> {t('general.required-fields-label-2')}</label>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row >
                <Form ref={form} model={formInputs} rules={rules} className="en-US" label-position="top">
                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="10" lg="8">
                            <Form.Item label={t('contact-us.form.contact-reason')} prop="contactReason">
                                <Select value={formInputs.contactReason} onChange={(v) => {setFormInputs({...formInputs, contactReason: v })}}>
                                    <Select.Option label={t('contact-us.form.contact-reason-sales-inquiry')} value="sales-inquiry"></Select.Option>
                                    <Select.Option label={t('contact-us.form.contact-reason-technical-support')} value="technical-support"></Select.Option>
                                    <Select.Option label={t('contact-us.form.contact-reason-general-help')} value="general-help"></Select.Option>
                                </Select>
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="10" lg="8">
                            <Form.Item label={t('contact-us.form.is-bainub-user')}><br />
                                <Switch
                                        value={formInputs.isBainubUser}
                                        allowFocus={false}
                                        onText={t('contact-us.form.is-bainub-user-yes')}
                                        offText={t('contact-us.form.is-bainub-user-no')}
                                        onChange={(v) =>{setFormInputs({...formInputs, isBainubUser: v });}}>
                                </Switch>
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="10" lg="8" >
                            <Form.Item label={t('contact-us.form.fullname')} prop="fullName">
                                <Input value={formInputs.fullName} onChange={(v) => {setFormInputs({...formInputs, fullName: v })}}></Input>
                            </Form.Item>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="24" md="10" lg="8" offset="2">
                            <Form.Item label={t('contact-us.form.email')} prop="email">
                                <Input value={formInputs.email} onChange={(v) => {setFormInputs({...formInputs, email: v })}}></Input>
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="10" lg="8" >
                            <Form.Item label={t('contact-us.form.business-name')} prop="businessName">
                                <Input value={formInputs.businessName} onChange={(v) => {setFormInputs({...formInputs, businessName: v })}}></Input>
                            </Form.Item>
                        </Layout.Col>
                        <Layout.Col xs="24" sm="24" md="10" lg="8" offset="2">
                            <Form.Item label={t('contact-us.form.phone')} prop="phone">
                                <Input value={formInputs.phone} onChange={(v) => {setFormInputs({...formInputs, phone: v })}}></Input>
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="18" lg="18" >
                            <Form.Item label={t('contact-us.form.message')} prop="message">
                                <Input
                                    type="textarea"
                                    value={formInputs.message}
                                    onChange={(v)=>{setFormInputs({...formInputs, message: v })}}
                                    autosize={{ minRows: 8, maxRows: 8}} />
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="11" lg="8">
                            <Form.Item>
                                <h2 className="form-subtitle">Captcha</h2>
                                <LoadCanvasTemplate />
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row>
                        <Layout.Col xs="24" sm="24" md="11" lg="8">
                            <Form.Item prop="captcha" label={t('contact-us.form.captcha-label')}>
                                <Input value={formInputs.captcha} onChange={(v) => {setFormInputs({...formInputs, captcha: v })}}></Input>
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row className="isolated-row">
                        <Layout.Col xs="24" sm="24" md="18" lg="18">
                            <Form.Item prop="termsAndConditions">
                                <Checkbox.Group value={formInputs.termsAndConditions} onChange={(v) => {setFormInputs({...formInputs, termsAndConditions: v })}}>
                                    <Checkbox name="termsAndConditions">
                                        
                                            <label>
                                                {t('contact-us.form.agreement-checkbox-1')} 
                                                <a href="#" onClick={showPrivacyPolicy} > {t('contact-us.form.agreement-checkbox-privacy-policy')} </a>.
                                            </label>   
                                        
                                    </Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row className="isolated-row align-center" type="flex" justify="center" align="middle">
                        <Form.Item align="middle" justify="center">
                            <Link to="/" className="secondary-button">
                                {t('button-cancel')}
                            </Link>
                            
                            <Button type="primary" onClick={handleOnSubmit} loading={loading} className="primary-button">
                                {t('button-send')}
                            </Button>
                        </Form.Item>
                    </Layout.Row>
                </Form>
            </Layout.Row>
        </div>
    );
}

export default ContactUs;