import React from 'react';
const TermsIcon = (props) => {
  return (
          <svg onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.coords ? props.coords : "0 0 41 41"}>
          <g id="Group_1384" data-name="Group 1384" transform="translate(-371 -1754)">
            <g id="Ellipse_55" data-name="Ellipse 55" transform="translate(371 1754)" fill="#fff" stroke="#919397" stroke-width="2">
              <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
              <circle cx="20.5" cy="20.5" r="19.5" fill="none"/>
            </g>
            <path id="handshake-solid" d="M0,75.215l2.394,0a1.2,1.2,0,0,0,1.2-1.2V65.654H0Zm1.8-2.387a.6.6,0,1,1-.6.6A.6.6,0,0,1,1.8,72.829Zm11.252-9.58a1.2,1.2,0,0,0-.8.312l-3.68,3.366c0,.007,0,.015-.011.015a1.488,1.488,0,0,0-.079,2.095,1.6,1.6,0,0,0,2.1.1c.006-.006.01-.006.014-.009L13.574,66.4a.6.6,0,0,1,.809.884l-.977.893,5.447,4.418a2.879,2.879,0,0,1,.295.29V65.61a8.165,8.165,0,0,0-5.776-2.39Zm-.531,5.738-1.122,1.028a2.691,2.691,0,1,1-3.64-3.965l3.06-2.8h-.247A8.182,8.182,0,0,0,4.8,65.639l-.007,8.375h.683l3.385,3.061a2.392,2.392,0,0,0,3.367-.346l.678.57a1.427,1.427,0,0,0,1.959-.2l1.174-1.444.2.168a1.2,1.2,0,0,0,1.683-.178l.357-.441a1.194,1.194,0,0,0-.176-1.681Zm7.833-3.329v8.364a1.191,1.191,0,0,0,1.163,1.2l2.428,0V65.647Zm1.8,8.364a.6.6,0,1,1,.6-.595A.6.6,0,0,1,22.144,74.022Z" transform="translate(380.102 1704.226)" fill={props.color ? props.color : "#929498"}/>
          </g>
        </svg>
  )
}

export default TermsIcon;