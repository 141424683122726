import { API } from 'aws-amplify'

export default async function logInDB ({ message, type, user }) {
    try {
        const result = await API.post('NotificationServiceLambda', '/log/save', {
            body: {
                message, type, user
            }
        })
        console.log(result)
    } catch(error) {
        console.error(error)
    }
}