import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify, {Analytics, AWSKinesisFirehoseProvider} from 'aws-amplify';
import aws_exports from './aws-exports2';
import { i18n } from 'element-react'
import locale from 'element-react/src/locale/lang/en'
import './i18n';
import 'element-theme-default';

i18n.use(locale);

Amplify.configure(aws_exports);

/*Analytics.configure({
  AWSPinpoint: {
    // Amazon Pinpoint App Client ID
    appId: 'de56fc7253994e1a9ffc5f6b0f0ee71b',
    // Amazon service region
    region: 'us-east-1',
    mandatorySignIn: false,
  },
  AWSKinesisFirehose: {

      // OPTIONAL -  Amazon Kinesis Firehose service region
      region: 'us-east-2',

      // OPTIONAL - The buffer size for events in number of items.
      bufferSize: 1000,

      // OPTIONAL - The number of events to be deleted from the buffer when flushed.
      flushSize: 100,

      // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
      flushInterval: 5000, // 5s

      // OPTIONAL - The limit for failed recording retries.
      resendLimit: 5
  } 
});

Analytics.autoTrack('session', {
  // REQUIRED, turn on/off the auto tracking
  enable: false,
  // OPTIONAL, the attributes of the event, you can either pass an object or a function 
  // which allows you to define dynamic attributes
  //attributes: {
  //    attr: 'attr'
  //},
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: 'AWSPinpoint'
});

Analytics.addPluggable(new AWSKinesisFirehoseProvider());
*/
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
