

import React from 'react'
import { Layout } from 'element-react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import shop from '../assets/imgs/shop.png';

export default function MobileWarning() {
    const { t } = useTranslation();
    return (
            <div className='minAlto'>
                <Layout.Row  justify="center" align="middle">
                    <Layout.Col offset="1" xs="22" sm="22" md="22" lg="22">
                        <h1>
                            {window.location.pathname}
                        </h1>
                        <h3>
                            This feature is Desktop exclusive!
                        </h3>
                        <h3>
                            Please visit us on a Desktop or Laptop computer.
                        </h3>
                        <h3>
                            Question? <a href="/contact-us">contact us</a>
                        </h3>
                    </Layout.Col>
                </Layout.Row>
                <div className="center">
                <Layout.Row  justify="center" align="middle">
                    <Layout.Col xs="24" sm="24" md="24" lg="24">
                        <img height={200} src={shop}/>   
                    </Layout.Col>
                </Layout.Row>
                </div>
            </div>
        
    )
}
