import { Redirect, Route, useHistory } from 'react-router-dom'
import userStore from './../../store/user';
import { Auth } from 'aws-amplify';
import DefaultMobile from '../../pages/DefaultMobile';
import {BrowserView, MobileView} from 'react-device-detect';

export default function PrivateRoute({ showLeftMenu, layout: Layout, component: Component, menuComponent: MenuComponent, footerComponent: FooterComponent, showMyPreferencesWizard: showMyPreferencesWizard, ...rest }) {
    const { user } = userStore();
    let history = useHistory();
    const verifyCurrentUser = async() =>{
        console.log('verifyCurrentUser');
        try{
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
        }catch(e){
            console.log('sending to login...');
            console.error(e);
            history.push("/login");
            return false;
        }
        return true;
    }
    const Test = () => {
        console.log('test');
        return <></>
    }

    return (
        <>
        <BrowserView>
            <Route
                {...rest}
                render={props => {
                    return user.name || verifyCurrentUser() ? 
                    (Layout ? 
                        <Layout showLeftMenu={true} showMyPreferencesWizard={false} menuComponent={<MenuComponent/>} footerComponent={<FooterComponent/>}> <Component {...props} /> </Layout> : 
                        <Component {...props} />
                    ) : 
                    <Redirect to="/login" />
                }}
            >  
            </Route>
        </BrowserView>
        <MobileView>
            <Route
                {...rest}
                
                render={props => {
                    return user.name || verifyCurrentUser() ? 
                    (Layout ? 
                        <>
                        <Layout showLeftMenu={showLeftMenu} showMyPreferencesWizard={showMyPreferencesWizard} menuComponent={<MenuComponent/>} footerComponent={<FooterComponent/>}> <DefaultMobile /> </Layout> 
                        </>
                        : 
                        <DefaultMobile />
                    ) : 
                    <Redirect to="/login" />
                }}
            >  
            </Route>
        </MobileView>
        
        </>
    )
}