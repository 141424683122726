const StatusLabel = (props) => {
    let status = props.status;
    let statusStr = 'N/A';
    let backColor = 'black';
    switch(status){
        case 0:
            statusStr = 'Draft';
            backColor = '#EFAB68';
            break;
        case 1:
            statusStr = 'New';
            backColor = '#86CAF4';
            break;
        case 2:
            statusStr = 'Active';
            backColor = '#CB95ED';
            break;
        case 3:
            statusStr = 'Completed';
            backColor = '#ACD85B';
            break;
        case 4:
            statusStr = 'On Hold';
            backColor = '#D8C05B';
            break;
        case 5:
            statusStr = 'Cancelled';
            backColor = '#D8735B';
            break;
        default:
            statusStr = 'N/A'
            break;
    };
    return(
        <div style={{'background-color': backColor, 'height' : props.height ? props.heigh : '24px'}} className="order-status-label">
            <div >{statusStr}</div>
        </div>
    )
}

export default StatusLabel;