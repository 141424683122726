import { useState, useEffect } from 'react';
import { Button, Dialog } from 'element-react';
import { useTranslation } from 'react-i18next';
export default function WarningModal(props) {
    const { t, i18n } = useTranslation();
    return(
        <>
            <Dialog
                title={props.title}
                size="tiny"
                visible={ props.modalWarningVisible }
                onCancel={ () => props.setModalWarningVisible(false) }
                lockScroll={ false }
            >
                <Dialog.Body>
                <span>{props.message}</span>
                <br/>
                <br/>
                <span>{props.message2}</span>
                <br/>
                <br/>
                <span>{props.message3}</span>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <form-button-inverse style={{'padding-right' : '10px'}}>
                        <Button onClick={ () => { 
                            props.setModalWarningVisible(false)
                        }  
                        }>
                            {
                                props.cancelButtonText 
                                    ?  
                                        props.cancelButtonText 
                                    : 
                                t('button-cancel')
                            }
                        </Button>
                    </form-button-inverse>
                    <form-button>
                        <Button type="primary" onClick={ () => {
                            props.action(); 
                            props.setModalWarningVisible(false); 
                        }}>
                            {
                                props.okButtonText 
                                    ?  
                                        props.okButtonText 
                                    : 
                                        t('button-ok')
                            }
                          
                        </Button>
                    </form-button>
                </Dialog.Footer>
            </Dialog>
        </>
    )
}