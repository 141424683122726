import React from 'react'
import {useEffect, useState} from 'react';

import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { updateCompany } from '../graphql/mutations';
import aws_exports from "../aws-exports";

import { useTranslation } from 'react-i18next';

import { Button, Form, Dialog, Layout } from 'element-react';
import { PhotoPicker } from "aws-amplify-react";
import ReactCrop from 'react-image-crop';
import * as companyController from '../graphql/companyLambdaInvokes';
import { FILES_URL } from '../utils/Constants';
import { blobToBase64 } from '../utils/FormatUtils';

import 'react-image-crop/dist/ReactCrop.css';

export default function MyStoreCropImage({ visible, onCancelDialogCallback, onSaveImageCallback, fieldToUpdate, s3KeyPrefix , companyPageId }) {
    const minImageHeight = 100
    const minImageWidth  = 100
    const { t } = useTranslation();
    const [isDialogVisible, setIsDialogVisible] = useState(visible)
    const [previewImageUrl, setPreviewImageUrl] = useState(null)
    const [image, setImage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [processStep, setProcessStep] = useState('select-file')
    // const imagePreviewRef = useRef(null)

    //crop states
    const [cropConfiguration, setCropConfiguration] = useState(null)
    const [cropImage, setCropImage] = useState(null)
    const [croppedImageBase64, setCroppedImageBase64] = useState(null)

    useEffect(function() {
        setIsDialogVisible(visible)
    }, [])

    useEffect(function() {
        if(cropImage == null) return;
        var _cropConfiguration = { 
            unit: 'px',
            x: 0,
            y: 0,
            width: 700,
            height: 350,
            aspect: 4/2
        }
        if(cropImage.height >= _cropConfiguration.height && cropImage.width >= _cropConfiguration.width) {
            _cropConfiguration = { 
                unit: 'px',
                x: 0,
                y: 0,
                width: 700,
                height: 350,
                aspect: 4/2
            }   
        }
        else if(cropImage.height < cropImage.width) {
            _cropConfiguration.height = cropImage.height
            _cropConfiguration.width =  cropImage.height * 2
            if(_cropConfiguration.width > cropImage.width) {
                _cropConfiguration.width = cropImage.width
                _cropConfiguration.height = cropImage.width / 2
            }
        } else {
            _cropConfiguration.width =  cropImage.width ;
            _cropConfiguration.height = cropImage.width / 2
            if(_cropConfiguration.height > cropImage.height) {
                _cropConfiguration.height = cropImage.height
                _cropConfiguration.width = cropImage.height * 2
            }
        }
        setCropConfiguration(_cropConfiguration)
    }, [cropImage])

    const onCancelDialog = () => {
        console.log('onCancelDialog');
        setIsDialogVisible(false);
        onCancelDialogCallback();
    }

    const onPhotoPickerPick = (file) => {
        console.log('onPhotoPicketPick');
        console.log({file});
        let reader = new FileReader();
        reader.onloadend = () => {
            console.log({reader});
            setPreviewImageUrl(reader.result);
        }
        reader.readAsDataURL(file.file);
        setImage(file);
        console.log(file)
        setProcessStep('crop-image')
    }

    const handleCropFinished = () => {
        setProcessStep('image-cropped-preview');
    }

    const updateCompanyImage = async () =>{
        try{
            setLoading(true);
            console.log(image)
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            let croppedImageData = await fetch(croppedImageBase64);
            let croppedImageBlob = await croppedImageData.blob();

            let s3FileKey = `${s3KeyPrefix}/${Date.now()}-${fieldToUpdate}-${image.name}`;
            /*const uploadedFile = await Storage.put(s3FileKey, croppedImageBlob, {
                contentType: image.type,
                level: 'public',
                progressCallback: progress => {
                  console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                  const percentUploaded = Math.round((progress.loaded / progress.total) * 100);
                  console.log(percentUploaded);
                }
            });
            console.log({uploadedFile});*/
            const base64Data = await blobToBase64(croppedImageBlob);
            const base64Content = base64Data.split(',')[1];

            const response = await fetch(FILES_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify(
                    {
                    fileName: s3FileKey,
                    fileType: image.type,
                    file: base64Content
                })
            });

            const data = await response.json();
                    console.log(data);

            const s3File = {
                //@ts-ignore
                key: s3FileKey,
                bucket: aws_exports.aws_user_files_s3_bucket,
                region: aws_exports.aws_project_region
            };

            console.log('Update company files');
            const input = fieldToUpdate === 'cover_picture' ? { id : companyPageId, cover_picture: s3File, } : { id : companyPageId, company_logo: s3File, }
            const result = await API.graphql(graphqlOperation(updateCompany, { input }));
            console.log("Updated company", result);
            await companyController.saveCompanyPhoto({ 
                id: companyPageId, 
                logoA: s3FileKey, 
                logoB: aws_exports.aws_user_files_s3_bucket, 
                logoC: aws_exports.aws_project_region
            });
            onSaveImageCallback(result.data.updateCompany);
        } catch(e){
            console.log("error updating image", e);
        } finally{
            setIsDialogVisible(false);
            setLoading(false);
        }
    }

    function getCroppedImg(imageLoaded, crop) {
        console.log(imageLoaded)
        if(imageLoaded == null) return;
        const canvas = document.createElement('canvas');
        const scaleX = imageLoaded.naturalWidth / imageLoaded.width;
        const scaleY = imageLoaded.naturalHeight / imageLoaded.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
      
        ctx.drawImage(
            imageLoaded,
            crop.x * scaleX, crop.y * scaleY,
            crop.width * scaleX, crop.height * scaleY,
            0, 0,
            crop.width, crop.height,
        );
        const base64Image = canvas.toDataURL(imageLoaded.src.startsWith('data:image/png;') ? 'image/png':'image/jpeg');
        setCroppedImageBase64(base64Image)
    }

    function onImageLoaded(imageLoaded) {
        console.log("image size: " + imageLoaded.height + "x" + imageLoaded.width);
        if(imageLoaded.height < minImageHeight || imageLoaded.width < minImageWidth) {
            setProcessStep('invalid-image-size');
        } else {
            setCropImage(imageLoaded); 
        }
    }

    return (
        <Dialog visible={isDialogVisible} title="Update company image" onCancel={onCancelDialog} size="large">
            <Dialog.Body className="center-text">
                <Layout.Row type="flex" justify="center" align="middle">
                    {processStep === 'select-file' || processStep == 'invalid-image-size' ? 
                        <Layout.Col xs="24" sm="24" md="24" lg="24" className="is-justify-center is-align-middle el-row--flex">
                            <Form label-position="top">
                                {processStep === 'invalid-image-size' ? <label><b>Invalid image size (Minimium size {minImageHeight} x {minImageWidth} px)</b></label> : <></> }
                                <Form.Item label={t('my-store.create.form.'+fieldToUpdate)}><br/>
                                    <PhotoPicker
                                        title="Click to select a file ..."
                                        onPick={onPhotoPickerPick}
                                        theme={{
                                            sectionHeader: { display: "none" },
                                            photoPlaceholder:{ display: "none" },
                                            photoPickerButton: {
                                                width: '100%',
                                                borderRadius: '27px',
                                                backgroundColor: '#FF6D73',
                                                borderColor: 'transparent',
                                                fontWeight: 'bold',
                                                boxShadow: '3px 5px 13px rgb(0 0 0 / 10%);',
                                                color: 'white',
                                                padding: '10px 15px'
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Layout.Col> : <></> 
                    }
                    { previewImageUrl && processStep === 'crop-image' ?
                        <Layout.Col xs="24" sm="24" md="24" lg="24" >
                            <ReactCrop 
                                style={{width: "100%"}}
                                // imageStyle={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                                src={previewImageUrl} 
                                crop={cropConfiguration} 
                                onImageLoaded={onImageLoaded}
                                onComplete={() => {getCroppedImg(cropImage, cropConfiguration)}}
                                onChange={newCrop => { setCropConfiguration(newCrop); console.log(newCrop)} } 
                            /> 
                        </Layout.Col> : <></>
                    }
                    { croppedImageBase64 && processStep === 'image-cropped-preview' ?
                        <Layout.Col xs="24" sm="24" md="24" lg="24" >
                            <img 
                                style={{width: "100%"}}
                                src={croppedImageBase64}
                                alt=""
                            /> 
                        </Layout.Col> : <></>
                    }
                </Layout.Row>
            </Dialog.Body>
            <Dialog.Footer className="dialog-footer">
                <form-button-inverse style={{'padding-right' : '10px'}}>
                    <Button onClick={onCancelDialog}>Cancel</Button>
                </form-button-inverse>
                <form-button>
                    {
                        processStep === 'select-file' || processStep === 'invalid-image-size' ? <></> 
                        : ( processStep === 'crop-image' ? 
                            <Button type="primary" onClick={handleCropFinished}>Cut</Button> : 
                            <Button type="primary" onClick={updateCompanyImage}>Update</Button>) 
                    }
                </form-button>
                
            </Dialog.Footer>
        </Dialog>
    )
}
