import {useState, useRef, useEffect} from 'react';
import { 
    Layout, Form, Select, Checkbox, Input, Dialog, Button,
    Notification, MessageBox
    } 
from 'element-react';
import { useTranslation } from 'react-i18next';
import { countryOptions } from '../utils/Catalogs';
import EditSolidIcon from '../components/EditSolidIcon';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listUsers, listCompanys } from '../graphql/queries';
import { updateUser } from '../graphql/mutations';
import {personalDataFormRules, passwdFormRules} from '../utils/OrderFormRules'
import userStore from '../store/user';
import MyPreferencesWizard from '../components/layouts/MyPreferencesWizard';
import { addUnsuscribeEmail, deleteUnsuscribeEmail, isEmailUnsuscribed } from '../graphql/emailLambdaInvokers';
import PagerQuery from '../utils/PagerQuery';

export default function NavigationPreferences(){
    const { t } = useTranslation();
    const [showWizard, setShowWizard] = useState(false);
    const [isEmailInDataBase, setIsEmailInDataBase] = useState(false);
    const [email, setEmail] = useState('');
    
    useEffect(() => {
        console.log('My Account');
        checkEmailUnsuscribed();
    },[]);

    const checkEmailUnsuscribed = async () => {
        const u = await Auth.currentAuthenticatedUser();
        console.log({u});
        //const userResult = await API.graphql({ query: listUsers, variables: { filter: { cognito_id: { eq: u.username } }}});
        const input = { filter: { cognito_id: { eq: u.username } }}
        const pagerQuery = new PagerQuery(listUsers, input, Object.keys({ listUsers }));
        const userResult = await pagerQuery.searchPrivateData();
        console.log({userResult});
        let _userData = userResult.data?.listUsers.items[0];
        console.log({_userData})
        setEmail(_userData.email);
        isEmailUnsuscribed({email: _userData.email}).then((res)=>{
            console.log('RESULT IS UNSUSCRIBED');
            console.log({res});
            console.log(res.data[0].count);
            if(res &&  res.data?.length > 0 && res.data[0].count > 0){
                setIsEmailInDataBase(true);
            }
        }).catch((err)=>{
            console.log('ERROR IS UNSUSCRIBED');
            console.log({err});
            setIsEmailInDataBase(false);
        });
    }

    const wizardOff = () =>{
        console.log('wizardOff');
        setShowWizard(false);
    }

    const updateUnsuscribedPreferences = async () => {
        console.log('updateUnsuscribedPreferences :: ' + isEmailInDataBase);
        
        if(!isEmailInDataBase){
            console.log('deleteUnsuscribeEmail');
            deleteUnsuscribeEmail({email}).then((res)=>{
                console.log('RESULT DELETE UNSUSCRIBED');
                console.log({res});
                Notification({
                    title: 'Success',
                    message: 'Email is Now Suscribed',
                    type: 'success'
                });
            }).catch((err)=>{
                console.log('ERROR DELETE UNSUSCRIBED');
                console.log({err});
                Notification({
                    title: 'Error',
                    message: 'Error Unsuscribed',
                    type: 'error'
                });
            });
        }
        else{
            console.log('addUnsuscribeEmail');
            addUnsuscribeEmail({email}).then((res)=>{
                console.log('RESULT ADD UNSUSCRIBED');
                console.log({res});
                Notification({
                    title: 'Success',
                    message: 'Email Successfully Unsuscribed',
                    type: 'success'
                });
            }).catch((err)=>{
                console.log('ERROR ADD UNSUSCRIBED');
                console.log({err});
                Notification({
                    title: 'Error',
                    message: 'Error Unsuscribed',
                    type: 'error'
                });
            });
        }
    }

    
    
    return(
        <>
        <Dialog
            size="large"
            visible={showWizard}
            onCancel={ () => { 
                console.log('cancel Diaglog');
                setShowWizard(false);
            } }
        >
            <Dialog.Body>
                <MyPreferencesWizard onWizardComplete={wizardOff} />
            </Dialog.Body>
            
        </Dialog>
        
        
        <Layout.Row gutter="20">
                                &nbsp;
        </Layout.Row>
       
        <Layout.Row gutter="10">
            <Layout.Col offset="1" span="20">
                <div className="order-wizard-div">
                    <Layout.Row type='flex' gutter="20" justify="space-between">
                        <Layout.Col span="14" >
                            <h2 style={{color:'#707070'}}>{t('my-account.preferences.header')}</h2>
                        </Layout.Col>
                    </Layout.Row>
                    
                    <Layout.Row gutter="10">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <Layout.Row gutter="10">
                        <Layout.Col span="20">
                            <div onClick={()=>setShowWizard(true)} className="cursor" style={{ display: 'inline-flex', paddingTop: 10}}>
                                <EditSolidIcon width={30} height={30}/> <div style={{color: '#FF6D73', paddingTop: 2}}>{t('my-account.preferences.change-link')}</div>
                            </div>
                        </Layout.Col>
                    </Layout.Row>
            </div>
            </Layout.Col>      
        </Layout.Row>
        <Layout.Row gutter="10">
            &nbsp;
        </Layout.Row>
        <Layout.Row gutter="10">
            &nbsp;
        </Layout.Row>

        <Layout.Row gutter="10">
            <Layout.Col offset="1" span="20">
                <div className="order-wizard-div">
                    <Layout.Row type='flex' gutter="20" justify="space-between">
                        <Layout.Col span="14" >
                            <h2 style={{color:'#707070'}}>{'Email Preferences'}</h2>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="10">
                        <div className="form-separator"/>
                    </Layout.Row>
                </div>
            </Layout.Col>
        </Layout.Row>
        
        <Layout.Row gutter="10">
            <Layout.Col offset="1" span="20">
                <p>
                We send notification emails to keep you informed about your account status and performance. This includes New Information Requests from customers and Project Status Notifications. 
                </p>
                <p>
                We strongly recommend keeping this functionality active to stay informed in real-time of any important updates.
                </p>
            </Layout.Col>
        </Layout.Row>
        <Layout.Row gutter="10">
            <Layout.Col offset="1" span="20">
                <Checkbox 
                onChange={(v)=>{setIsEmailInDataBase(v)}}
                checked={isEmailInDataBase} >I don't want to receive Email Notifications</Checkbox>                            
            </Layout.Col>
        </Layout.Row>
        <Layout.Row gutter="10">
            &nbsp;
        </Layout.Row>
        <Layout.Row gutter="10">
            <Layout.Col offset="1" span="20">
            <Layout.Col tag="form-button">
                    <Button className="left-separator" type="primary" onClick={() => updateUnsuscribedPreferences()}>
                            {t('my-account.form.update-btn')}
                    </Button>
            </Layout.Col>
            </Layout.Col>
        </Layout.Row>
        <Layout.Row gutter="20">
                     <div style={{paddingTop: 60}}></div>
            </Layout.Row>
       
        </>
    )
}