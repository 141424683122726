import { Layout, Form } from 'element-react';
import SellerInfoIcon from './SellerInfoIcon';

function SellerInfo(props){
    const t = props.t;

    return(
        <>
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <SellerInfoIcon width={41} height={41}/> 
                        <div className="order-step-form">
                            {
                                props.readOnly
                                ?
                                    t('orders.viewer.seller-section')
                                :
                                    t('orders.form.seller-section')
                            }
                        </div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4}>
                    <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                            {props.formInputs.companyName}
                    </div>     
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={10}>
                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                        {props.formInputs.companyAddress}
                    </div>     
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4}>
                    <Form.Item label={t('orders.form.company-contact')} >
                        <br/>
                        <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                            {props.formInputs.companyContactName}
                        </div>     
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={6}>
                    <Form.Item label={t('orders.form.email')} >
                        <br/>
                        <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                            {props.formInputs.companyEmail}
                        </div>     
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
        </>
    )
}

export default SellerInfo;