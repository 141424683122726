const contactUsRules = (t) => {
    return  ({
        contactReason: [
            { 
                required: true, 
                message: t('contact-us.form.required-field') 
            }
        ],
        fullName: [
            { 
                required: true, 
                message: t('contact-us.form.required-field') 
            }
        ],
        businessName: [
            { 
                required: true, 
                message: t('contact-us.form.required-field') 
            }
        ],
        email: [
            { 
                required: true, 
                message: t('contact-us.form.required-field'),
                type: 'email'
            }
        ],
        phone: [
            { 
                required: true, 
                message: t('contact-us.form.required-field')
            }
        ],
        message: [
            { 
                required: true, 
                message: t('contact-us.form.required-field') 
            }
        ],
        termsAndConditions: [
            { type: 'array', required: true, message: t('register-form.validate-generic'), trigger: 'blur' }
        ],
        captcha: [
            { 
                required: true, 
                message: t('contact-us.form.required-field') 
            }
        ]
    })
}

export default contactUsRules;