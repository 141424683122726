import React from 'react';
const ShippingIcon = (props) => {
  return (
        <svg onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.coords ? props.coords : "0 0 41 41"}>
        <g id="Group_1385" data-name="Group 1385" transform="translate(-371 -2070.067)">
          <g id="Ellipse_56" data-name="Ellipse 56" transform="translate(371 2070.067)" fill="#fff" stroke="#929498" stroke-width="2">
            <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
            <circle cx="20.5" cy="20.5" r="19.5" fill="none"/>
          </g>
          <path id="truck-fast-solid" d="M2.425,1.819A1.819,1.819,0,0,1,4.244,0h9.7a1.819,1.819,0,0,1,1.819,1.819V9.7h7.276v3.638a1.213,1.213,0,1,1,0,2.425H21.827a3.638,3.638,0,0,1-7.276,0H9.7a3.638,3.638,0,0,1-7.276,0v-4.85H7.882a.606.606,0,0,0,0-1.213H.606a.606.606,0,1,1,0-1.213H9.094a.606.606,0,0,0,0-1.213H1.819a.606.606,0,1,1,0-1.213h8.488a.606.606,0,1,0,0-1.213H.606a.606.606,0,1,1,0-1.213H2.425ZM6.063,17.583a1.819,1.819,0,1,0-1.819-1.819A1.819,1.819,0,0,0,6.063,17.583Zm12.126-3.638a1.819,1.819,0,1,0,1.819,1.819A1.819,1.819,0,0,0,18.189,13.945Zm-.5-7.882H15.157V3.638h2.528a2.487,2.487,0,0,1,1.717.709l2.929,2.929a2.426,2.426,0,0,1,.709,1.717v1.921H20.614V8.992Z" transform="translate(379.508 2081.067)" fill={props.color ? props.color : "#929498"}/>
        </g>
      </svg>
      
  )
}

export default ShippingIcon;