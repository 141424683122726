import { Layout, Form, 
    Input, Select } from 'element-react';
import SellerInfoIcon from './SellerInfoIcon';
import {countryOptions} from '../../utils/Catalogs';
import { useState } from 'react';

function BuyerInfo(props){
    const t = props.t;
    const [country] = useState(countryOptions);
    const renderCountries = country.options.map(el => <Select.Option key={el.value} value={el.value} label={el.label}  />)

    if(props.readOnly){
        return(
            <>
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <SellerInfoIcon width={41} height={41}/> 
                    <div className="order-step-form">
                        {
                            t('orders.viewer.buyer-section')   
                        }
                    </div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4}>
                    <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                            {props.formInputs.buyerEmail}
                    </div>     
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4}>
                    <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                            {props.formInputs.buyerName}
                    </div>     
                </Layout.Col>
                <Layout.Col lg={4}>
                    <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                            {props.formInputs.buyerPhone}
                    </div>     
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={10}>
                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                        {props.formInputs.street}
                    </div>     
                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                        {props.formInputs.city}, {props.formInputs.state}
                    </div>     
                    <div className='order-form-color' style={{'color' : '#48576a'}}>
                        {props.formInputs.zipCode}, {props.formInputs.country}
                    </div>     
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            
        </>
        )
    }

    return(
        <>
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <SellerInfoIcon width={41} height={41}/> <div className="order-step-form">{t('orders.form.buyer-section')}</div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.buyer')} prop="buyerEmail" >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            ref={props.buyerEmailRef}
                            placeholder={t('orders.form.buyer-ph')}
                            value={props.formInputs.buyerEmail}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, buyerEmail: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                
                {/*
                <Layout.Col lg={6} md={10} xs={10} sm={10}>
                    <Form.Item label={''} >
                        <br/>
                        <form-button-inverse class="left-space">
                                <Button type="primary" onClick={validateBuyer}>
                                    {t('orders.form.validate-btn')}
                                </Button>
                        </form-button-inverse>
                    </Form.Item>
                </Layout.Col>
                */}
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={20} md={10} xs={10} sm={10}>
                <span className='buyer-note'>{t('orders.form.buyer-note')}</span>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={20} md={10} xs={10} sm={10}>
                <span className='buyer-note'>&nbsp;</span>
                </Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.buyer-name')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.buyerName}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, buyerName: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.buyer-phone')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.buyerPhone}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, buyerPhone: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>

            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <h5>{t('orders.form.billing-adds')}</h5>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={8} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.street')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.street}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, street: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.city')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.city}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, city: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.state')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.state}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, state: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.country')} >
                        <Select 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            filterable={false} 
                            value={props.formInputs.country}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, country: v })}} 
                        >
                                    { renderCountries }
                        </Select>
                    </Form.Item>
                </Layout.Col>
                <Layout.Col lg={4} md={10} xs={10} sm={10}>
                    <Form.Item label={t('orders.form.zip-code')} >
                        <Input 
                            disabled={props.formInputs.sellerStatus === 0 ? false : true}
                            value={props.formInputs.zipCode}
                            onChange={(v) => {props.setFormInputs({...props.formInputs, zipCode: v })}} 
                        />
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
        </>
    )
}

export default BuyerInfo;