import {getOrder, listCounterInfoTables} from '../graphql/queries';
import {createOrder, updateOrder, updateCounterInfoTable} from '../graphql/mutations';
import {API, graphqlOperation } from 'aws-amplify';
//import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import StatusLabel from '../components/StatusLabel';
//import ProductIcon from '../components/order/ProductIcon';
//import { composeInitialProps } from 'react-i18next';

let maxOrderNumberQuery = /* GraphQL */ `
query listOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
        orderNumber
    }
    nextToken
    }
}
`;

const updateSellerStatusOrder = async (orderId, newStatus) =>{
    try{
        let input = {
            id: orderId
        }
        const result  = await API.graphql({
            query: getOrder,
            variables: input
        });
        console.log({result});
        let originalOrder = result.data.getOrder;
        const inputCreate = {
            id: originalOrder.id,
            company_name : originalOrder.company_name,
            company_contact_name: originalOrder.company_contact_name,
            company_email: originalOrder.company_email,
            company_address: originalOrder.company_address,
            buyer_email: originalOrder.buyer_email,
            billingAddress: originalOrder.billingAddress,
            products: originalOrder.products,
            terms: originalOrder.terms,
            shipping : originalOrder.shipping,
            additionalFees: originalOrder.additionalFees,
            payments: originalOrder.payments,
            tracking_number : originalOrder.tracking_number,
            seller_status : 99,
            buyer_status : originalOrder.buyer_status,
            total: originalOrder.total,
            owner_name: originalOrder.owner_name,
            owner: originalOrder.owner,
            buyer: originalOrder.buyer,
            buyer_name: originalOrder.buyer_name,
            //buyer_email: originalOrder.buyer_email,
            orderNumber: originalOrder.orderNumber,
            company_id: originalOrder.company_id
        };

        const updateOrderResult = await API.graphql(
            graphqlOperation(updateOrder, { input : inputCreate })
        );
        console.log({updateOrderResult});
    }catch(e){
        console.log(e);
    }
}

const updateProductCounter = async(ownerId) =>{
    try{
        const result = await API.graphql({ query: listCounterInfoTables, variables: 
            { 
                filter: 
                    { 
                        owner: { eq: ownerId } 
                    } 
            } 
        });
        console.log('NEW PRODUCT :: updateProductCounter');
        console.log({result});
        if(result && result.data && result.data?.listCounterInfoTables?.items.length > 0){
            const input = {
                id: result.data.listCounterInfoTables.items[0].id,
                active_orders_total: result.data.listCounterInfoTables.items[0].active_orders_total + 1
            };
            const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, { input }));
            console.log({resultUpdate});
        }else{
            console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
        }
    }catch(e){
        console.error(e);
    }
}

const cloneOrder = async (orderId) => {
    try{
        let input = {
            id: orderId
        }
        const result  = await API.graphql({
            query: getOrder,
            variables: input
        });
        console.log({result})
        //@ts-ignore
        const originalOrder = result.data.getOrder;

        const resultOrders = await API.graphql({ query: maxOrderNumberQuery, variables: { filter: { owner: { eq: originalOrder.owner } }  }});
        console.log({resultOrders});
        let maxNumber = 10;
        if(resultOrders.data.listOrders.items && resultOrders.data.listOrders.items.length > 0){
            console.log('si hay items, recorriendo para obtener uno nuevo');
            //TODO obtener el mayor numero...
            maxNumber = Math.max.apply(Math, resultOrders.data.listOrders.items.map(function(o) { return o.orderNumber; })) + 1;
        }

        const inputCreate = {
            company_name : originalOrder.company_name,
            company_contact_name: originalOrder.company_contact_name,
            company_email: originalOrder.company_email,
            company_address: originalOrder.company_address,
            buyer_email: originalOrder.buyer_email,
            billingAddress: originalOrder.billingAddress,
            products: originalOrder.products,
            terms: originalOrder.terms,
            shipping : originalOrder.shipping,
            additionalFees: originalOrder.additionalFees,
            payments: originalOrder.payments,
            tracking_number : originalOrder.tracking_number,
            seller_status : 0,
            buyer_status : 0,
            total: originalOrder.total,
            owner_name: originalOrder.owner_name,
            owner: originalOrder.owner,
            buyer: originalOrder.buyer,
            buyer_name: originalOrder.buyer_name,
            //buyer_email: originalOrder.buyer_email,
            orderNumber: maxNumber,
            company_id: originalOrder.company_id
        };
        
        console.log({inputCreate});

        const resultCreate = await API.graphql(
            graphqlOperation(createOrder, { input : inputCreate })
        );
        console.log("Order created", resultCreate);
        //@ts-ignore
        await updateProductCounter(originalOrder.owner);
        return resultCreate.data.createOrder.id;
    }catch(e){
        console.log(e);
    }
}

const sellerStatus = (sellerStatus, buyerStatus) =>{
    if(buyerStatus === 1 && sellerStatus === 1){
        return(<StatusLabel status={2}/>) 
    }else{
        if( (buyerStatus === 2 && sellerStatus === 1) || 
            (buyerStatus === 2 && sellerStatus === 2) || 
            (buyerStatus !== 4 && sellerStatus === 5)){
            return(<StatusLabel status={4}/>) 
        }else{
            if(buyerStatus === 4 && sellerStatus === 5){
                return(<StatusLabel status={5}/>) 
            }else{
                return(<StatusLabel status={sellerStatus}/>) 
            }
            
        }
    }
}

const calculateOrderTotal = (products, fees, shippingCost) =>{
    let _total = 0
    let _shippingCost = 0;
    console.log(` ::::: ${shippingCost}`)
    try{
        _shippingCost = Number.parseFloat(shippingCost);
    }catch(e){_shippingCost = 0}
    console.log(`shippingCostshippingCostshippingCost ${shippingCost}`)
    _total = _total + totalProducts(products) + (_shippingCost ? _shippingCost : 0);
    _total = _total + totalFees(fees);
    
    return _total;
}

const totalProducts = (products) =>{
    let _total = 0
    if(products){
        products.forEach((element, index) => {
            _total = _total + element.subtotal;
        })
    }
    return _total;
}
const totalFees = (fees) =>{
    let _total = 0;
    if(fees){
        fees.forEach((element, index) => {
            _total = _total + element.subtotal;
        })
    }
    return _total;
}

const paymentCurrentBalance = (index, payments) =>{
    let _total = 0;
    if(payments){
        payments.sort(function (a, b) {
            if (a.date > b.date) {
              return 1;
            }
            if (a.date < b.date) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
        payments.forEach((element, i) => {
            if(i > index)
                return _total
            _total = _total + Number.parseFloat(element.amount);
        })
    }
    return _total;
}

export { cloneOrder, sellerStatus, calculateOrderTotal, totalProducts, 
    totalFees, paymentCurrentBalance, updateSellerStatusOrder };