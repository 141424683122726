import {useEffect, useState} from 'react';
import { Layout, Loading, Table, Input, Tabs } from 'element-react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from "react-router-dom";
import {toCurrency} from '../utils/FormatUtils';
import StatusLabel from '../components/StatusLabel';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { createOrder } from '../graphql/mutations';
import { listCompanys, listUsers, listOrders } from '../graphql/queries';
import PagerQuery from '../utils/PagerQuery';

function OrdersBuyers(props){
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [type, setType] = useState(1);
    const [filterTable, setFilterTable] = useState('');
    useEffect(() => {
        console.log('Ordes table');
        loadOrders();
    },[type, filterTable]);
    const loadOrders = async () =>{
        try{
            setLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            console.log({user});

            let vars = {};

            if(type !== -1){
                if(type == 1){
                    //Para poder visualizar las Ordenes InProgress y OnHold
                    vars = {
                            filter: {
                                    and: [
                                    { buyer_email: { eq: user.attributes.email } },
                                    {
                                        or: [
                                        { seller_status: { eq: type } },
                                        { seller_status: { eq: 5 } }
                                        ],
                                        buyer_status : { ne: 4 }
                                    }
                                    ]
                                }
                            } 
                }else{
                    vars = { filter: { buyer_email: { eq: user.attributes.email }, seller_status: {eq: type}}  }
                }
                
            }else{
                vars = { filter: { buyer_email: { eq: user.attributes.email }, seller_status: { lt: 99 }, seller_status: { gt: 0 } }  }
            }

            const resultOrders = await API.graphql({
                query: listOrders, 
                variables: vars/*type !== -1 
                    ? 
                        { filter: { buyer_email: { eq: user.attributes.email }, seller_status: {eq: type} }  }
                    : 
                        { filter: { buyer_email: { eq: user.attributes.email }, seller_status: { lt: 99 }, seller_status: { gt: 0 } }  }
                    */
            });
            console.log({resultOrders});
            setOrders(resultOrders.data?.listOrders.items);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }   
    }
    const [orders, setOrders] = useState([]);
    const { t } = useTranslation('',{ useSuspense: true });
    const columns = 
    [
        {
          label: t('orders.table.number'),
          prop: "orderNumber",
          width: 80,
          sortable: true,
          render: (row, column, index)=>{
            return(
                <Link to={"/view-order/" + row.id}> {row.orderNumber} </Link>  
            ) 
          }
        },
        {
            filterMethod(value, row) {
                if(filterTable !== ''){
                  return row.company_name.toLowerCase().includes(filterTable.toLowerCase()) ;
                }else{
                    return true;
                }
              
            },
            filters: [{ text: 'Busqueda', value: filterTable }],
            filteredValue :[filterTable],
            label: t('orders.table.client'),
            sortable: true,
            prop: "buyer_name",
            render: (row, column, index)=>{
              return(
                <Link to={"/view-order/" + row.id}> {row.company_name} </Link>  
              ) 
            }
        },
        {
            label: t('orders.table.products'),
            id: 'products-cell',
            //prop: 'products.name',
            //sortable: true,
            render: (row, column, index)=>{
              return(
                  <div className="table-order-products">
                      {row.products ? row.products[0]?.name : ''} {row.products?.length > 1 ? <div>&nbsp;(+{row.products.length-1}More)</div> : <></>}
                  </div>
              ) 
            }
        },
        {
            label: t('orders.table.quantity'),
            width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                row.products ? row.products[0]?.quantity : 0
              ) 
            }
        },
        {
            label: t('orders.table.unit'),
            width: 120,
            render: (row, column, index)=>{
              return(
                row.products ? row.products[0]?.unit : 0
              ) 
            }
        },
        {
            label: t('orders.table.total'),
            width: 160,
            prop: 'total',
            sortable: true,
            render: (row, column, index)=>{
              return(
                toCurrency(row.total)
              ) 
            }
        },
        {
            label: t('orders.table.due-date'),
            width: 125,
            prop: 'deliveryDate',
            sortable: true,
            render: (row, column, index)=>{
                console.log('row.terms?.deliveryDate :: ' + row.terms?.deliveryDate.toString());
                if(row.terms?.deliveryDate.toString() == '1900-01-01')
                    return '';
                return(
                  row.terms?.deliveryDate
                ) 
              }
        },
        {
            label: t('orders.table.status'),
            width: 110,
            prop: 'seller_status',
            sortable: true,
            render: (row, column, index)=>{
                if(row.buyer_status === 1 && row.seller_status === 1){
                    return(<StatusLabel status={2}/>) 
                }else{
                    if( (row.buyer_status === 2 && row.seller_status === 1) || 
                        (row.buyer_status === 2 && row.seller_status === 2) || 
                        (row.buyer_status !== 4 && row.seller_status === 5)){
                        return(<StatusLabel status={4}/>) 
                    }else{
                        return(<StatusLabel status={row.seller_status}/>) 
                    }
                }
            }
        }
    ]

    const viewNotes = (id) =>{
        console.log(`View notes ${id}`);
    }

    const goToEditProduct = (id) =>{
        console.log('goToEditProduct');
        history.push('/add-order-wizard/' + id);
    }

    const dotsClick = (row, event, column) =>{
        console.log({row});
        console.log({column});
    }

    const goToAddOrder = async () =>{
        //console.log(`goToAddOrder`)
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const resultOrders = await API.graphql({ query: maxOrderNumberQuery, variables: { filter: { buyer_email: { eq: user.username } }  }});
        console.log({resultOrders});
        let maxNumber = 10;
        if(resultOrders.data.listOrders.items && resultOrders.data.listOrders.items.length > 0){
            console.log('si hay items, recorriendo para obtener uno nuevo');
            //TODO obtener el mayor numero...
            maxNumber = Math.max.apply(Math, resultOrders.data.listOrders.items.map(function(o) { return o.orderNumber; })) + 1;
        }

        const companyResult  = await API.graphql({
            query: listCompanys,
            variables: { filter: { owner: {eq: user.username } } }
        });
        const company = companyResult.data.listCompanys.items[0];
        const filter = {
            cognito_id : {eq : company.owner}
        }
        //const resultCompanyUser = await API.graphql({ query: listUsers, variables: { filter }});
        const inputListUsers = { filter: filter}
        const pagerQuery = new PagerQuery(listUsers, inputListUsers, Object.keys({ listUsers }));
        const resultCompanyUser = await pagerQuery.searchPrivateData();
        console.log({resultCompanyUser});
        const _user = resultCompanyUser.data.listUsers.items[0]
        const input = {
            owner: user.username,
            owner_name: _user.first_name + ' ' + _user.last_name,
            buyer:'',
            buyer_name:'',
            buyer_email: '',
            orderNumber: maxNumber,
            company_name: company ? company.name : '',
            company_id: company ? company.id : '',
            company_contact_name: _user.first_name + ' ' + _user.last_name,
            company_email: _user.email,
            company_address: company ? company.address_str : '',
            seller_status: 0,
            buyer_status: 0,
            total: 0
        };
        const result = await API.graphql(
            graphqlOperation(createOrder, { input })
        );
        console.log({result});
        setLoading(false);
        history.push("/add-order-wizard/"+result.data.createOrder.id);
    }

    const switchTab = (tab) =>{
        if(tab === '1'){
            setType(0);
        }else{
            if(tab === '2'){
                setType(1);
            }else{
                if(tab === '3'){
                    setType(3);
                }else{
                    if(tab === '4'){
                        setType(-1);
                    }
                }
            }
        }
    }

    let maxOrderNumberQuery = /* GraphQL */ `
        query listOrders(
            $filter: ModelOrderFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                orderNumber
            }
            nextToken
            }
        }
    `;
    
    return(
        <div>
            {
                loading && <Loading fullscreen={loading} />
            }
            <div className="my-product-table">
            
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="20">
                                <h2>{t('orders.table.title')}</h2>
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="22">        
                            <Tabs activeName="2" onTabClick={ (tab) => switchTab(tab.props.name) }>
                                <Tabs.Pane label={t('orders.tabs.active')} name="2"></Tabs.Pane>
                                <Tabs.Pane label={t('orders.tabs.completed')} name="3"></Tabs.Pane>
                                <Tabs.Pane label={t('orders.tabs.all')} name="4"></Tabs.Pane>
                            </Tabs>            
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Layout.Row type="flex" justify="end">
                                        <Layout.Col tag="form-button">
                                            <div style={{display: 'inline-flex'}}>
                                                <Input
                                                    icon="search"
                                                    placeholder="Search"
                                                    value={filterTable}
                                                    onChange={v=>{setFilterTable(v)}}
                                                    className="input-width-200"
                                                />
                                            </div>
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20" justify="end" type="flex" >
                            <Layout.Col offset="1" lg="10">
                                &nbsp;
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="22">
                                
                                <Table
                                    className="products-table box-card"
                                    data={orders}
                                    columns={columns}  
                                    fit={true}
                                    emptyText="No orders available..."
                                    onRowClick={dotsClick}
                                />
                            </Layout.Col>
                </Layout.Row>
            </div>
        </div>
        
    )
}

export default OrdersBuyers;