import { Route, useHistory } from 'react-router-dom'
import userStore from '../../store/user';
import { Auth } from 'aws-amplify';

export default function MixedRoute({showLeftMenu, layout: Layout, component: Component, menuComponent: MenuComponent, footerComponent: FooterComponent, showMyPreferencesWizard: showMyPreferencesWizard, ...rest }) {
    const { user } = userStore();
    const verifyCurrentUser = async() =>{
        console.log('verifyCurrentUser');
        try{
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
        }catch(e){
            //console.log('sending to login...');
            //cleanUser();
            console.error(e);
            //history.push("/login");
            return false;
        }
        return true;
    }

    if(showMyPreferencesWizard && (user.name || verifyCurrentUser())) {
        showMyPreferencesWizard = user.pendingSetupBusinessPreferences;
    }

    console.log("-------------")
    console.log(showMyPreferencesWizard)

    return (
        <Route
            {...rest}
            render={props => {
                return <Layout searchType={rest.searchType} showLeftMenu={showLeftMenu} showMyPreferencesWizard={showMyPreferencesWizard} menuComponent={<MenuComponent/>} footerComponent={<FooterComponent/>}> <Component {...props} /> </Layout>
            }}
        >  
        </Route>
    )
}