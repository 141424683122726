
import { Layout, Button, Card} from 'element-react';

function BuyerBanner(props){
    const t = props.t;
    return(
        <>
            <Layout.Row type="flex">
                    <Layout.Col span="24" xs="23" sm="23" lg="23">
                                <Card style={{'background-color' : props.backColor}} className="box-card card-order-warning">
                                    <Layout.Row type="flex">
                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                                            <h4 className="white-text reduce-top-space">
                                                {props.textTitle}<br/>
                                                {props.textSubtext}
                                            </h4>
                                        </Layout.Col>
                                    </Layout.Row>
                                        {
                                            props.showButtons
                                            ?
                                            <Layout.Row className="bottom-separator" >
                                                <Layout.Col  span="24" offset="1" xs="4" sm="4" lg="4">
                                                    <Button className="banner-chat-btn" type="primary" onClick={props.goToChat}>
                                                        {t('orders.buyers.chat-btn')}
                                                    </Button>
                                                </Layout.Col>
                                                <Layout.Col  span="24" xs="4" sm="4" lg="4">
                                                    <Button className="banner-cancel-btn" type="primary" onClick={props.acceptCancelation}>
                                                        {t('orders.buyers.accept-cancel-btn')}
                                                    </Button>
                                                </Layout.Col>
                                            </Layout.Row>
                                            :
                                                <></>
                                        }
                                        
                                   

                                </Card>    
                    </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                <Layout.Col offset="1" span="20">
                    &nbsp;
                </Layout.Col>
            </Layout.Row>
        </>
    )
}

export default BuyerBanner;
