import { Layout, Button, Card, Steps, Form, Input, Loading, Notification, Select, Switch, Tooltip, Cascader } from 'element-react';
import {useEffect, useState, useRef,} from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import {createCompany, updateCompany} from '../graphql/mutations';
import {getCompany, listCategorys} from '../graphql/queries';
import { useTranslation } from 'react-i18next';
import userStore from '../store/user';
import { useHistory, useParams } from "react-router-dom";
import {addCompanyRules0, addCompanyRules1, addCompanyRules2} from '../utils/AddCompanyRules';
import tooltopImage from '../assets/imgs/tooltip.png';
import {mexStates, usaStates, canadaStates} from '../utils/Catalogs'
import * as companyLambdaInvokes from '../graphql/companyLambdaInvokes';
function MyStoreWizard(){
    let history = useHistory();
    let { id } = useParams();
    const {user} = userStore();
    const [options2, setOptions2] = useState([]);
    let [countryOptions, setCountryOptions] = useState({
        options : []
    });
    let type = {
        options:[
            {
                label: 'Manufacturer',
                value: 'Manufacturer'
            },
            {
                label: 'Trading Company',
                value: 'Trading Company'
            }
        ]
    }
    let main_market = {
        options:[
            {
                label: 'Canada',
                value: 'Canada'
            },
            {
                label: 'México',
                value: 'México'
            },
            {
                label: 'USA',
                value: 'USA'
            }
        ]
    }
    let revenue = {
        options:[
            {
                label: '<50,000 USD',
                value: '<50,000 USD'
            },
            {
                label: '50,000-100,000 USD',
                value: '50,000-100,000 USD'
            },
            {
                label: '500,000-2,500,000 USD',
                value: '500,000-2,500,000 USD'
            },
            {
                label: '>2,500,000 USD',
                value: '>2,500,000 USD'
            }
        ]
    }

    let [yearOptions, setYearOptions] = useState({
        options: []
    });

    const form0 = useRef(null);
    const form1 = useRef(null);
    const form2 = useRef(null);
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInputs] = useState(
        {
            name: '',
            type: '',
            age: '',
            anual_revenue : '',
            description: '',
            region: '',
            featured_category: '',
            file1: null,
            urlFile1: null,
            file2: null,
            urlFile2: null,
            titles: '',
            employees_qty : '',
            production_lines_qty: '',
            additional_titles1: '',
            additional_titles2: '',
            additional_titles3: '',
            additional_titles5: '',
            file1Name: '',
            main_market: '',
            allow_custom_product: '',
            address_str: '',
            street: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
            phone: ''
        }
    )
    const [currentStep, setCurrentStep] = useState(0);
    const { t, i18n } = useTranslation();
    const [rules0, setRules0] = useState(null);
    const [rules1, setRules1] = useState(null);
    const [rules2, setRules2] = useState(null);
    useEffect(() => {
        console.log('MyStore');
        console.log('user country :: ' + user.country);
        if(user.country === 'MEX'){
            setCountryOptions(mexStates);
        }else{
            if(user.country === 'USA'){
                setCountryOptions(usaStates);
            }{
                if(user.country === 'CAN'){
                    setCountryOptions(canadaStates);
                }
            }   
        }

        (async () => {
            try{
                const result = await API.graphql({ query: listCategorys});
                console.log({result});
                const newData = [];
                const _listCatgorys = result.data.listCategorys.items;
                _listCatgorys.sort(compare);
                _listCatgorys.forEach((element, index) => {
                    console.log(element);
                    const parent = {
                        label: element.name,
                        value: element.id,
                        children: []
                    }
                    const _tags = element.tags;
                    _tags.sort(compareTags)
                    _tags.forEach(child =>{
                        const _child = 
                            {
                                value: child,
                                label: child
                            }
                        parent.children.push(_child);
                    });
                    
                    newData.push(parent);
                });
                setOptions2(newData);
            }catch(e){
                console.error(e);
            } 
        })();

        setRules0(addCompanyRules0(t));
        setRules1(addCompanyRules1(t));
        setRules2(addCompanyRules2(t));
        fillYearsOptions();

        if(id != '0'){
            getCompanyData();
        }

    },[user.country])

    function compare( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      }
      
      function compareTags( a, b ) {
        if ( a.toLowerCase() < b.toLowerCase() ){
          return -1;
        }
        if ( a.toLowerCase() > b.toLowerCase() ){
          return 1;
        }
        return 0;
      }

    const getCompanyData = async () =>{
        setLoading(true);
        console.log('looking for store id :: ' + id);
        try{
            const input = {
                id : id
            }
            const result = await API.graphql(graphqlOperation(getCompany, input));
            console.log({result})
            const _companyData = result.data.getCompany;
            const _featured_category = _companyData.featured_category.split('|');
            setFormInputs({
                name: _companyData.name,
                type: _companyData.type,
                age: _companyData.age,
                anual_revenue : _companyData.anual_revenue,
                description: _companyData.description,
                region: _companyData.region,
                featured_category: _featured_category != '' && _featured_category != null && _featured_category.length == 2 ? [_featured_category[0], _featured_category[1]] : [],
                file1: null,
                urlFile1: null,
                file2: null,
                urlFile2: null,
                titles: _companyData.titles,
                employees_qty : _companyData.employees_qty,
                production_lines_qty: _companyData.production_lines_qty,
                additional_titles1: _companyData.additional_titles1,
                additional_titles2: _companyData.additional_titles2,
                additional_titles3: _companyData.additional_titles3,
                additional_titles4: _companyData.additional_titles4,
                file1Name: '',
                main_market: _companyData.main_market,
                allow_custom_product: _companyData.allow_custom_product == 1 ? true : false,
                address_str: _companyData.address_str,
                street: _companyData.address?.street,
                city: _companyData.address?.city,
                zipCode: _companyData.address?.zipCode,
                phone: _companyData.address?.phone
            })
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
        }
        
    }
    /*
    const handleImage2Change = (file)=> {
        console.log('preview file');
        console.log({file});
        let reader = new FileReader();
        reader.onloadend = () => {
            setFormInputs({...formInputs, urlFile2: reader.result });
        }
        reader.readAsDataURL(file.file)
        image2 = file;
    }

    const handleImage1Change = (file)=> {
        console.log('preview file');
        console.log({file});
        let reader = new FileReader();
        reader.onloadend = () => {
            console.log({reader});
            setFormInputs({...formInputs, urlFile1: reader.result });
        }
        reader.readAsDataURL(file.file);
        image1 = file;
    }
    */
    const nextStep = () =>{
        console.log('next step');
        if(currentStep == 0){
            form0.current.validate((valid) => {
                console.log('validator :: ' + valid);
                if (valid) {
                    setCurrentStep(currentStep + 1);
                } else {
                    console.log('invalid form');
                }
            });
        }else{
            if(currentStep == 1){
                form1.current.validate((valid) => {
                    console.log('validator :: ' + valid);
                    if (valid) {
                        setCurrentStep(currentStep + 1);
                    } else {
                        console.log('invalid form');
                    }
                }); 
            }else{
                setCurrentStep(currentStep + 1);
            }
        }
    }

    const prevStep = () =>{
        setCurrentStep(currentStep - 1);
    }

    const updateStoreData = async() =>{
        setLoading(true);
            try{
                const user = await Auth.currentAuthenticatedUser();
                console.log("formInputs.allow_custom_product :: " + formInputs.allow_custom_product);
                const input = {
                    id: id,
                    name : formInputs.name,
                    type : formInputs.type,
                    age : formInputs.age,
                    anual_revenue: formInputs.anual_revenue,
                    region: formInputs.region,
                    featured_category:  formInputs.featured_category != null && formInputs.featured_category.length == 2 ? formInputs.featured_category[0] + '|' + formInputs.featured_category[1] : [],
                    additional_titles1: formInputs.additional_titles1,
                    additional_titles2: formInputs.additional_titles2,
                    additional_titles3: formInputs.additional_titles3,
                    additional_titles4: formInputs.additional_titles4,
                    description : formInputs.description,
                    main_market: formInputs.main_market,
                    allow_custom_product: formInputs.allow_custom_product == true ? 1 : 0,
                    titles: formInputs.titles,
                    production_lines_qty: formInputs.production_lines_qty,
                    employees_qty: formInputs.employees_qty,
                    address_str: formInputs.address_str,
                    address : {
                        street: formInputs.street,
                        city: formInputs.city,
                        zipCode: formInputs.zipCode,
                        phone: formInputs.phone
                    }
                };
                const result = await API.graphql(
                    graphqlOperation(updateCompany, { input })
                );
                console.log("Created company", result);
                await companyLambdaInvokes.updateCompanyData({
                    id : id,
                    name : formInputs.name,
                })
                setLoading(false);
                setTimeout(function(){
                    Notification({
                        title: t('my-store.create.form.success-title'),
                        message: t('my-store.create.form.success-msg'),
                        type: 'success'
                    });
                    history.push("/my-store");
                },300);
                
            }catch(e){
                console.error(e)
                setLoading(false);
                if(e === 'The user is not authenticated'){
                    history.push("/");
                }
            }
    }

    const saveMyStore = async () =>{
        if(id != '0'){
            updateStoreData();
        }else{
            setLoading(true);
            try{
                const user = await Auth.currentAuthenticatedUser();
                console.log("formInputs.allow_custom_product :: " + formInputs.allow_custom_product);
                const input = {
                    name : formInputs.name,
                    type : formInputs.type,
                    age : formInputs.age,
                    anual_revenue: formInputs.anual_revenue,
                    region: formInputs.region,
                    featured_category:  formInputs.featured_category != null && formInputs.featured_category.length == 2 ? formInputs.featured_category[0] + '|' + formInputs.featured_category[1] : [],
                    additional_titles1: formInputs.additional_titles1,
                    additional_titles2: formInputs.additional_titles2,
                    additional_titles3: formInputs.additional_titles3,
                    additional_titles4: formInputs.additional_titles4,
                    description : formInputs.description,
                    main_market: formInputs.main_market,
                    allow_custom_product: formInputs.allow_custom_product == true ? 1 : 0,
                    //cover_picture: file1,
                    //company_logo: file2,
                    titles: formInputs.titles,
                    production_lines_qty: formInputs.production_lines_qty,
                    employees_qty: formInputs.employees_qty,
                    address_str: formInputs.address_str,
                    address : {
                        street: formInputs.street,
                        city: formInputs.city,
                        zipCode: formInputs.zipCode,
                        phone: formInputs.phone
                    },
                    hidden: 0
                };
                const result = await API.graphql(
                    graphqlOperation(createCompany, { input })
                );
                console.log("Created company", result);

                await companyLambdaInvokes.saveCompany({
                    id : result.data.createCompany.id, 
                    owner : result.data.createCompany.owner, 
                    name : formInputs.name,
                    logoA : '' , 
                    logoB : '', 
                    logoC : '', 
                    currentUser: user.username
                });

                setLoading(false);
                setTimeout(function(){
                    Notification({
                        title: t('my-store.create.form.success-title'),
                        message: t('my-store.create.form.success-msg'),
                        type: 'success'
                    });
                    history.push("/my-store");
                },300);
                
            }catch(e){
                console.error(e)
                setLoading(false);
                if(e === 'The user is not authenticated'){
                    history.push("/");
                }
            }
        }
    }


    const handleSaveMyStore = () =>{
        console.log('handleSaveMyStore');
        form2.current.validate((valid) => {
            console.log('validator step2 :: ' + valid);
            if (!valid) {
                return;
            }else{
                saveMyStore();
            }
        });
        
    }

    const fillYearsOptions = ()=>{
        console.log("fillYearsOptions");
        let currentYear = new Date().getFullYear();
        console.log("currentYear :: " + currentYear);
        let options = [];
        console.log("currentYear <= 1950 :: " + (parseInt(currentYear) >= 1950));
        for(let i = parseInt(currentYear) ; i >= 1950 ; i--){
            let option = {
                label : i,
                value : i
            }
            options.push(option);
        }
        //yearOptions.options = options;
        setYearOptions({options : options});
        console.log({yearOptions});
    }

    const loadSubCategories = async (value) => {
        console.log('loadSubCategories ==> ' + value[0]);
        console.log('loadSubCategories ==> ' + value[1]);
        setFormInputs({...formInputs, featured_category: value})
        
    }

    return(
        <div>
            {
                loading && <Loading fullscreen={loading} />
            }
        <div>
            
            <div className="my-store-wizard-div">
                
                <Layout.Row gutter="10">
                            <Layout.Col offset="1" span="20">
                                <h2>{t('my-store.create.title')}</h2>
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="10">
                            <Layout.Col offset="1" span="20">
                                <div className="general-text">
                                <p>In order to publish your company site we need your help filling out the fields in the wizzard below.
                                <br></br>After completion, we will show you a preliminary look of your company’s site and you will be able to make changes before publication.
                                <br></br>Remember that you can save the information to complete the process later.</p>
                                </div>
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="10">
                            <Layout.Col offset="1" span="20">
                                &nbsp;
                               {
                               //<Button onClick={saveCompany} type="primary">Test crear empresa</Button>
                               }
                            </Layout.Col>
                </Layout.Row>


                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="20">
                                    <Card className="box-card">
                                        <Layout.Row type="flex" justify="center">
                                            <Layout.Col offset="1" lg="10" tag="creat-mystore-steps">
                                                <Steps space={120} active={currentStep} finishStatus="success">
                                                    <Steps.Step  description={t('my-store.create.step1')}></Steps.Step>
                                                    {
                                                        /*
                                                        <Steps.Step  description={t('my-store.create.step2')}></Steps.Step>
                                                        */
                                                    }
                                                    <Steps.Step  description={t('my-store.create.step3')}></Steps.Step>
                                                    
                                                </Steps>
                                            </Layout.Col>
                                        </Layout.Row>
                                        <Layout.Row gutter="20">
                                                <Layout.Col offset="1" span="20">
                                                    &nbsp;
                                                </Layout.Col>
                                        </Layout.Row>
                                        <Form ref={form0} model={formInputs} className="en-US" rules={rules0} label-position="top">
                                            {
                                                currentStep === 0 ? 
                                                        <div className="create-company-step0">
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.name')} prop="name">
                                                                        <Input 
                                                                            maxlength={120}
                                                                            value={formInputs.name} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, name: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.type')} prop="type">
                                                                        <Select value={formInputs.type}
                                                                            onChange={(v) => {setFormInputs({...formInputs, type: v })}} 
                                                                        >
                                                                        {
                                                                            type.options.map(el => {
                                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                            })
                                                                        }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.age')} prop="age">
                                                                        <Select value={formInputs.age}
                                                                            onChange={(v) => {setFormInputs({...formInputs, age: v })}} 
                                                                        >
                                                                        {   
                                                                            yearOptions.options?.map(el => {
                                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                            })
                                                                        }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.anual_revenue')} prop="anual_revenue">
                                                                        <Select value={formInputs.anual_revenue}
                                                                            onChange={(v) => {setFormInputs({...formInputs, anual_revenue: v })}} 
                                                                        >
                                                                        {   
                                                                            revenue.options?.map(el => {
                                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                            })
                                                                        }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="24" sm="24" lg="17">
                                                                    <Form.Item label={t('my-store.create.form.description')} prop="description">
                                                                        <Input 
                                                                        type="textarea"
                                                                            value={formInputs.description}
                                                                            placeholder={'Mention where and when your company was founded, your company experience, area of expertise and capabilities. Add any information that makes your company stand out!'}
                                                                            autosize={{ minRows: 3, maxRows: 5}}
                                                                            onChange={(v) => {setFormInputs({...formInputs, description: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.region')} prop="region">
                                                                        <Select value={formInputs.region}
                                                                            onChange={(v) => {setFormInputs({...formInputs, region: v })}} 
                                                                        >
                                                                        {   
                                                                            countryOptions.options?.map(el => {
                                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                            })
                                                                        }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.featured_category')} prop="featured_category">
                                                                    <Tooltip placement="top-start" content="What's the category of the main product offered by your company?" effect="light"> <img height={'20'} src={tooltopImage}></img></Tooltip>
                                                                        {
                                                                            /*
                                                                            <Input 
                                                                            maxlength={30}
                                                                            value={formInputs.featured_category} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, featured_category: v })}} 
                                                                            />
                                                                            */
                                                                        }
                                                                        {
                                                                            <Cascader className="cascader-add-product"
                                                                                options={options2}
                                                                                value={Array.isArray(formInputs.featured_category) ? formInputs.featured_category : []} 
                                                                                onChange={ (v) => {loadSubCategories(v)}}
                                                                            />
                                                                        }
                                                                        
                                                                            
                                                                    </Form.Item>                                                                  
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            {
                                                            /*
                                                                <Layout.Row type="flex">
                                                                    <Layout.Col offset="1" xs="24" sm="24" lg="17">
                                                                        <Form.Item label={t('my-store.create.form.address_str')} prop="address_str">
                                                                            <Input 
                                                                                value={formInputs.address_str}
                                                                                onChange={(v) => {setFormInputs({...formInputs, address_str: v })}} 
                                                                                />
                                                                        </Form.Item>
                                                                    </Layout.Col>
                                                                </Layout.Row>
                                                            */
                                                            }
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.street')} prop="street">
                                                                        <Input 
                                                                            value={formInputs.street}
                                                                            onChange={(v) => {setFormInputs({...formInputs, street: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.city')} prop="city">
                                                                        <Input 
                                                                            value={formInputs.city}
                                                                            onChange={(v) => {setFormInputs({...formInputs, city: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>

                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.zipCode')} prop="zipCode">
                                                                        <Input 
                                                                            value={formInputs.zipCode}
                                                                            onChange={(v) => {setFormInputs({...formInputs, zipCode: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.phone')} prop="phone">
                                                                        <Input 
                                                                            value={formInputs.phone}
                                                                            onChange={(v) => {setFormInputs({...formInputs, phone: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>

                                                            <Layout.Row type="flex" justify="end" >
                                                                <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20">
                                                                    <Form.Item>
                                                                        <Button type="primary" onClick={nextStep}>
                                                                                {t('my-store.create.form.button-next')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                        </div>
                                                : <></>
                                            }
                                            </Form>
                                            
                                            {
                                             /*
                                             <Form ref={form1} model={formInputs} className="en-US" rules={rules1} label-position="top">
                                                currentStep === 1 ? 
                                                        <div className="create-company-step1">
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.file1')} prop="urlFile1">
                                                                        <PhotoPicker
                                                                        title="Select file..."
                                                                        onPick={file => {handleImage1Change(file);}}
                                                                        theme={{
                                                                            sectionHeader: {
                                                                                display: "none"
                                                                            },
                                                                            photoPlaceholder:{
                                                                                display: "none"
                                                                            },
                                                                            photoPickerButton: {
                                                                                borderRadius: '27px',
                                                                                backgroundColor: '#FF6D73',
                                                                                borderColor: 'transparent',
                                                                                fontWeight: 'bold',
                                                                                boxShadow: '3px 5px 13px rgb(0 0 0 / 10%);',
                                                                                color: 'white',
                                                                                padding: '10px 15px'
                                                                            }
                                                                          }}
                                                                        />
                                                                        
                                                                            <img
                                                                                
                                                                                className="image-preview"
                                                                                src={(formInputs.urlFile1 != null ? formInputs.urlFile1 : previewImg)}
                                                                                alt=""
                                                                            />
                                                                        
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.file2')} prop="urlFile2">
                                                                        <PhotoPicker
                                                                            title="Select file..."
                                                                            onPick={file => handleImage2Change(file)}
                                                                            theme={{
                                                                                sectionHeader: {
                                                                                    display: "none"
                                                                                },
                                                                                photoPlaceholder:{
                                                                                    display: "none"
                                                                                },
                                                                                photoPickerButton: {
                                                                                    borderRadius: '27px',
                                                                                    backgroundColor: '#FF6D73',
                                                                                    borderColor: 'transparent',
                                                                                    fontWeight: 'bold',
                                                                                    boxShadow: '3px 5px 13px rgb(0 0 0 / 10%);',
                                                                                    color: 'white',
                                                                                    padding: '10px 15px'
                                                                                }
                                                                              }}
                                                                        />
                                                                        
                                                                        <img
                                                                            className="image-preview"
                                                                            src={(formInputs.urlFile2 != null ? formInputs.urlFile2 : previewImg)}
                                                                            alt=""
                                                                        />
                                                                        
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex" justify="end" >
                                                                <Layout.Col tag="form-button-inverse" pull="1" xs="20" sm="20" lg="20">
                                                                    <Form.Item>
                                                                        <Button type="primary" onClick={prevStep}>
                                                                                {t('my-store.create.form.button-prev')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20">
                                                                    
                                                                    <Form.Item>
                                                                        <Button type="primary" onClick={nextStep}>
                                                                                {t('my-store.create.form.button-next')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                        </div>
                                                : <></>
                                                
                                                </Form>
                                                */
                                            }
                                            
                                            <Form ref={form2} model={formInputs} className="en-US" rules={rules2} label-position="top">
                                            {
                                                currentStep === 1 ? 
                                                        <div className="create-company-step2">
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.employees_qty')} prop="employees_qty">
                                                                        <Input 
                                                                            value={formInputs.employees_qty} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, employees_qty: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.main_market')} prop="main_market">
                                                                        <Select value={formInputs.main_market}
                                                                            onChange={(v) => {setFormInputs({...formInputs, main_market: v })}} 
                                                                        >
                                                                        {
                                                                            main_market.options.map(el => {
                                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                            })
                                                                        }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.production_lines_qty')} prop="production_lines_qty">
                                                                        <Input 
                                                                            value={formInputs.production_lines_qty}
                                                                            onChange={(v) => {setFormInputs({...formInputs, production_lines_qty: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.titles')} prop="titles">
                                                                        <Input 
                                                                            maxlength={30}
                                                                            value={formInputs.titles} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, titles: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.titles')} prop="additional_titles1">
                                                                        <Input 
                                                                            maxlength={30}
                                                                            value={formInputs.additional_titles1} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, additional_titles1: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.titles')} prop="additional_titles2">
                                                                        <Input 
                                                                            maxlength={30}
                                                                            value={formInputs.additional_titles2} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, additional_titles2: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex">
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.titles')} prop="additional_titles3">
                                                                        <Input 
                                                                            maxlength={30}
                                                                            value={formInputs.additional_titles3} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, additional_titles3: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.titles')} prop="additional_titles4">
                                                                        <Input 
                                                                            maxlength={30}
                                                                            value={formInputs.additional_titles4} 
                                                                            onChange={(v) => {setFormInputs({...formInputs, additional_titles4: v })}} 
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col offset="1" xs="20" sm="20" lg="8">
                                                                    <Form.Item label={t('my-store.create.form.allow_custom_product')} prop="allow_custom_product">
                                                                        <br/>
                                                                        <Switch
                                                                            onText="YES"
                                                                            offText="NO"
                                                                            value={formInputs.allow_custom_product}
                                                                            allowFocus={false}
                                                                            onChange={(v) =>{setFormInputs({...formInputs, allow_custom_product: v }); console.log('Switch :: ' + v)}}
                                                                        >
                                                                        </Switch>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            <Layout.Row type="flex" justify="end" >
                                                                <Layout.Col tag="form-button-inverse" pull="1" xs="20" sm="20" lg="20">
                                                                    <Form.Item>
                                                                        <Button type="primary" onClick={prevStep}>
                                                                                {t('my-store.create.form.button-prev')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20">
                                                                    <Form.Item>
                                                                        <Button loading={loading} type="primary" onClick={handleSaveMyStore}>
                                                                                {t('my-store.create.form.button-finish')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                        </div>
                                                : <></>
                                            }
                                        </Form>
                                        
                                    </Card>               
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex" justify="center" align="middle">
                    <Layout.Col >
                            &nbsp;
                    </Layout.Col>
                </Layout.Row>
                
            </div>
            
        </div>
        </div>
    )
}

export default MyStoreWizard;