import { useParams } from "react-router-dom";
import { Layout, Loading, Card , Button} from 'element-react';
import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {getProduct, listCompanys} from '../graphql/queries';
import {updateProduct} from '../graphql/mutations'
import {API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import ProductView from '../components/ProductView';
import { useHistory } from "react-router-dom";
import { cloneProduct } from '../services/ProductService';

function ProductReview(){
    let { productid } = useParams();
    const [loading, setLoading] = useState(false);
    const [mainPhoto, setMainPhoto] = useState(false);
    const { t, i18n } = useTranslation();
    const [productPage, setProductPage] = useState(null);
    const [companyPage, setCompanyPage] = useState(null);
    let history = useHistory();

    useEffect(() => {
        console.log('Getting product with id :: ' + productid);
        getProductFromDB();
    }, [productid]);

    const handleChangeMainPhoto = (key)=>{
        setMainPhoto(key);
    }

    const getProductFromDB = async () =>{
        setLoading(true);
        try{
            const input = {
                id: productid
            }
            const result  = await API.graphql({
                query: getProduct,
                variables: input,
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
              });
            console.log({result})
            //@ts-ignore
            setProductPage(result.data.getProduct);

            const resultPage = await API.graphql(graphqlOperation(listCompanys, { filter: { owner: {eq: result.data.getProduct.owner } } }))
            console.log({result})
            //@ts-ignore
            setCompanyPage(resultPage.data.listCompanys.items[0]);

            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
        }
    }

    const publishProduct = async() =>{
        console.log('Chaging product status from : ' + productPage.status);
        setLoading(true);
        try{
            const input =
            {
                id : productid,
                status: productPage.status == 1 ? 0 : 1
            }
            const result = await API.graphql(
                graphqlOperation(updateProduct, { input })
            );
            console.log("Updated product", result);
            //@ts-ignore
            setProductPage(result.data.updateProduct);
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
        }
    }

    const goToEdit = ()=>{
        console.log(`goToEditProduct => ${productid}`)
        history.push("/add-product-wizard/"+productid);
    }
    const goToAllProduct = ()=>{
        console.log(`goToAllProduct`)
        history.push("/my-products");
    }
    const goToAddProduct = ()=>{
        console.log(`goToAddProduct => 0`)
        history.push("/add-product-wizard/0");
    }
    const cloneHandler = async () =>{
        console.log('cloneProduct');
        setLoading(true);
        try{
            const newId = await cloneProduct(productid);
            console.log('newId :: ' + newId);
            if(newId){
                goToEditProduct(newId);
            }
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
    }

    const goToEditProduct = (id) =>{
        console.log(`goToEditProduct => ${id}`)
        history.push("/add-product-wizard/"+id);
    }

    return(
        <div>
            {
                loading && <Loading fullscreen={loading} />
            }
            <div>
                <Layout.Row gutter="24">
                    <Layout.Col span="24">
                        {
                            /*
                            <h2>{t('product.create.title')}</h2>
                            <p className="route-sub-title">{t('product.create.preview.route')}</p>
                            */
                        }
                        
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="21">
                                <Card className="box-card warning-card">
                                    <Layout.Row type="flex">
                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                                            <h1 className="white-text">{t('product.create.preview.subtitle')}</h1>
                                            <h2 className="white-text reduce-top-space">{t('product.create.preview.warning')}</h2>
                                        </Layout.Col>
                                    </Layout.Row>
                                    <Layout.Row className="bottom-separator rigth-separator" type="flex" justify="end">
                                        <Layout.Col  tag="form-button-warning" span="24" offset="1" xs="20" sm="20" lg="20">
                                                <Button type="primary" onClick={goToEdit}>
                                                    {t('product.create.preview.button-to-edit')}
                                                </Button>
                                                <Button style={{width:'165px'}} type="primary" onClick={goToAllProduct}>
                                                    {t('product.create.preview.button-to-view-all')}
                                                </Button>
                                                <Button type="primary" style={{width:'185px'}} onClick={goToAddProduct}>
                                                    {t('product.create.preview.button-to-create')}
                                                </Button>
                                                
                                                <Button type="primary" style={{width:'210px'}} onClick={cloneHandler}>
                                                    {t('product.create.preview.button-to-clone')}
                                                </Button>
                                                
                                        </Layout.Col>
                                    </Layout.Row>

                                </Card>    
                    </Layout.Col>
                </Layout.Row>

                <ProductView setLoading={setLoading} companyPage={companyPage} mainPhoto={mainPhoto} handleChangeMainPhoto={handleChangeMainPhoto} productPage={productPage} />
                
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                
            </div>
        </div>
    );
}

export default ProductReview;
