import React, {useRef, useState, useEffect} from 'react'
import {Button, Loading, Form, Input, Layout, Notification, Select} from 'element-react';
import { useTranslation } from 'react-i18next';
import * as surveyController from "../graphql/surveyLambdaInvokes";
import surveyFormRules from '../utils/SurveyFormRules';
import {countryOptions} from '../utils/Catalogs';
import sendEmailTemplate from '../graphql/lambdaInvokes';
import * as Constans from '../../src/utils/Constants';

export default function RegisterSurvey( { surveyTitle, formType }) {
    const { t } = useTranslation();

    const form = useRef(null);
    const [loading, setLoading] = useState(false);
    const [country] = useState(countryOptions);
    const [surveyRules, setSurveyRules] = useState(null);
    const [formInputs, setFormInputs] = useState(
        {
            fullname : '',
            email: '',
            phone : '',
            products : '',
            country: '',
            company_name: '',
            is_seller: false
        }
    );

    useEffect(() => {
        setSurveyRules(surveyFormRules(t));
    }, [t]);

    const submitHandler = () => {
        form.current.validate((valid) => { 
            if (valid) {
                handleOnSubmit();
            }
        });
    }

    const handleOnSubmit = async () => {
        try {
            console.log('saving survey');
            setLoading(true);
            var saveSurveyResponse = await surveyController.saveSurvey({
                name: formInputs.fullname,
                mail: formInputs.email,
                form_type: formType,
                company_name: '',
                phone: '',
                product_request: formInputs.company_name,
                is_seller: 0,
                country: ''
            });
            console.log(saveSurveyResponse);
            await sendEmailTemplate({
                templateName: "form", 
                subject: 'FORMULARIO KNOW MORE',
                emailFrom: Constans.FROM_EMAIL,
                recipients: [ "customerservice@bainub.com" ],
                //recipients: [ "czuniga@automateit.mx" ],
                templateVars: {
                    content:  formInputs.fullname 
                    + "<br/>" + formInputs.email
                    + "<br/> null"
                    + "<br/>" + formInputs.company_name
                    + "<br/> null"
                    + "<br/> null"
                    + "<br/> null"
                    + "<br/>" + new Date().toLocaleString("en-US", {timeZone: "America/Mexico_City"})
                },
                templateLanguage: "en"
            });
            setLoading(false);
            if(saveSurveyResponse.returnCode === 0) {
                console.log("survey saved successfully");
                clearForm();
                Notification({
                    title: t('home-page.product-survey.confirmation-title'),
                    message: t('home-page.product-survey.confirmation-message'),
                    type: 'success',
                    offset: 100
                });
            } else {
                console.log("survey save failed");
                Notification({
                    title: t('home-page.product-survey.confirmation-error-title'),
                    message: t('home-page.product-survey.confirmation-error-message'),
                    type: 'error',
                    offset: 100
                });
            }
        } catch (e) {
            console.error(e)
        }
    }

    const clearForm = () => {
        setFormInputs({
            fullname : '',
            email: '',
            phone : '',
            products : '',
            country: '',
            company_name: '',
            is_seller: false
        });
    }

    const renderCountries = country.options.map(el => <Select.Option key={el.value} value={el.value} label={el.label}/>)
    
    let dialog =
    <>
        {
            loading && <Loading loading={loading} fullscreen={true} />
        }

        <Form ref={form} model={formInputs} rules={surveyRules}>
            {
                /*
                <Layout.Col span="24">
                <h2 className="mb-0 pb-0 primary-color">{surveyTitle}</h2>
                <h3 className="mt-0 pt-0 mb-0">{t('home-page.product-survey.register-survey-subtitle')}</h3>
                </Layout.Col>
                */
            }
            
            <Layout.Row className="header-rows" gutter="20" type="flex" justify="center">
                <Layout.Col xs="20" sm="20" md="4" lg="4">
                    <Form.Item prop="fullname" className="mb-0 mt-1">
                        <Input 
                            value={formInputs.fullname} 
                            placeholder={t('home-page.product-survey.fullname')}
                            onChange={(v) => {setFormInputs({...formInputs, fullname: v})}} />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col xs="20" sm="20" md="4" lg="4">
                    <Form.Item prop="email" className="mb-0 mt-1">
                        <Input 
                            value={formInputs.email}
                            placeholder={t('home-page.product-survey.email')}
                            onChange={(v) => {setFormInputs({...formInputs, email: v})}} />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col xs="20" sm="20" md="4" lg="4">
                    <Form.Item prop="company_name" className="mb-0 mt-1">
                        <Input 
                            value={formInputs.company_name}
                            placeholder={t('home-page.product-survey.products')}
                            onChange={(v) => {setFormInputs({...formInputs, company_name: v})}} />
                    </Form.Item>
                </Layout.Col>
                <Layout.Col tag="form-button" xs="20" sm="20" md="4" lg="4">
                    <Form.Item className="pt-1 m-l-lg">
                        <Button type="primary" onClick={submitHandler} loading={loading}>
                            {t('home-page.product-survey.submit-button')}
                        </Button>
                    </Form.Item>
                </Layout.Col>
            </Layout.Row>
        </Form>
    </>;
    return dialog;
}