import create from 'zustand'
import { persist } from 'zustand/middleware'
//@ts-ignore
const [userTypeStore] = create(persist(set => ({
  userType: "S",
  setNewType: (newType) => {
    console.log('setting new user type to :: ' + newType);
    set({ userType: newType });
  },
  clearUserType : () => set({path : "S"})
})
,{
    name: "userTypeStore",
    getStorage: () => sessionStorage // (optional) by default the 'localStorage' is used
}))

export default userTypeStore;