import React from 'react'
import {useState} from 'react';
import {Dialog} from 'element-react';
import TermsOfUseText from '../components/TermsOfUseText';

export default function TermsOfUse({language, visible, setVisible}) {
    //const [isDialogVisible , setIsDialogVisible] = useState(true)

    let dialog = (
        <Dialog visible={visible}
                onCancel={() => setVisible(false)}
                lockScroll={false}>

            <Dialog.Body className='dialog-body-footer'>
                <>
                    
                <TermsOfUseText/>

                </>
            </Dialog.Body>
        </Dialog>)
    

    return dialog
}