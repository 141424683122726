import { Layout, Menu, Input, Select, Button } from 'element-react';
import {useHistory} from "react-router-dom";
import userStore from '../store/user';
import categoryStore from '../store/categories';
import {useEffect, useState} from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { listUsers, listBusinessPreferences, listChats } from '../graphql/queries';
import mainSearchStore from '../store/main-search';
import { useTranslation } from 'react-i18next';
import MessagesIcon from './MessagesIcon';
import StoreIcon from './StoreIcon';
import LoginIcon from './LoginIcon';
import BuyerToolsIcon from './BuyerToolsIcon';
import ShoppingCartIcon from './ShoppingCartIcon';
import UserIcon from '../components/UserIcon';
import BainubIcon from '../components/BainubIcon';
import filterSearchStore from '../store/filter-search';
import { onUpdateChat } from '../graphql/subscriptions';
import headerChatStore from '../store/header-chat';
import menuStore from '../store/menu-selected';
import userTypeStore from '../store/userType';
import logInDB from '../graphql/logLambdaInvokes';
import * as LogType from '../utils/LogType';
import PagerQuery from '../utils/PagerQuery';
import { Link } from "react-router-dom";

function Header(props){
    let history = useHistory();
    const {user, setUserInfo, cleanUser, changeUserType} = userStore();
    const {userType, setNewType, clearUserType} = userTypeStore();
    const {chatInfo, setChatInfo} = headerChatStore()
    const {mainInfo, setMainInfo, clearMainInfo} = mainSearchStore();
    const {setFilterInfo} = filterSearchStore();
    const {categories, setCategories, cleanCategories}  = categoryStore();
    const { t, i18n } = useTranslation();
    const [languageSelect, setLanguageSelect] = useState();
    const {setMenuIndex} = menuStore();


    const changeLanguageHandler = (lng) =>
    {
      //console.log(languageSelect);
      setLanguageSelect(lng);
      i18n.changeLanguage(lng);
      //history.push(window.location.pathname + '?lng=' + languageSelect);
    }

    const handleKeyDown = (event) => {
        //console.log('A key was pressed', event.keyCode + ' ' + event.charCode);
        if (event.charCode === 13 || event.keyCode === 13) {
            event.preventDefault();
            searchProducts();
        }
    };

    useEffect(() => {
        console.log('UseEffect Header');
        console.log({i18n});
        console.log("language :: " + i18n.language);
        console.log({user});
        let subscription = undefined;
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(async (user) => {
            console.log(user);
            let filter = {
                username: {
                    eq: user.attributes.email
                }
            };
            //const result = await API.graphql({ query: listUsers, variables: { filter: filter}});
            const inputUsers = { filter: filter }
            const pagerQuery = new PagerQuery(listUsers, inputUsers, Object.keys({ listUsers }));
            const result = await pagerQuery.searchPrivateData();
            console.log("getting business preferences ...")
            const resultBusinessPreferences = await API.graphql({ query: listBusinessPreferences, variables: { filter: { owner: { eq: user.username } }}});
            console.log(resultBusinessPreferences)

            //const userDB = data.getUser;
            console.log('USER TYPE FROM STORE ::: ' + userType);
            const userDB = {
                pendingSetupBusinessPreferences: (resultBusinessPreferences.data.listBusinessPreferences.items.length === 0),
                ...result.data.listUsers.items[0] ,
                type: userType
            }

            console.log('userDB')
            console.log(userDB);
            //@ts-ignore
            /*user = {
                type: userType,
                ...user
            }*/
            setUserInfo(user, userDB);
            //changeUserType({...user }, userType);
            //TODO read initial chat status por this User...
            const input = {
                filter:{
                    or: [
                        {owner : {eq : userDB.cognito_id}},
                        {company_user : {eq : userDB.cognito_id}}
                    ]
                }
            }

            const resultChatInfo = await API.graphql({
                query: listChats,
                variables: input
            });
            console.log('LIST CHAT INFO...')
            console.log({resultChatInfo});
            const _u = await Auth.currentAuthenticatedUser();
            resultChatInfo.data.listChats.items.forEach(element => {
                if(userDB && element.company_user_last_message_read === 1 && element.company_user === _u.attributes.sub){
                    setChatInfo(true);
                }
                if(userDB && element.owner_last_message_read === 1 && element.owner === _u.attributes.sub){
                    setChatInfo(true);
                }
            });



            console.log('Subscribe to onUpdateChat');
            subscription = API.graphql(
                graphqlOperation(onUpdateChat)
            ).subscribe({
                next: async ({ provider, value }) => {
                    console.log({ provider, value });
                    try {
                        const newMessage = value.data.onUpdateChat;
                        const u = await Auth.currentAuthenticatedUser();
                        if(newMessage.owner === u.attributes.sub || newMessage.company_user === u.attributes.sub){
                            console.log({user});
                            if(user && newMessage.company_user_last_message_read === 1 && newMessage.company_user === u.attributes.sub){
                                setChatInfo(true);
                            }else{
                                if(user && newMessage.company_user_last_message_read === 0 && newMessage.company_user === u.attributes.sub){
                                    setChatInfo(false);
                                }
                            }

                            if(user && newMessage.owner_last_message_read === 1 && newMessage.owner === u.attributes.sub){
                                setChatInfo(true);
                            }else{
                                if(user && newMessage.owner_last_message_read === 0 && newMessage.owner === u.attributes.sub){
                                    setChatInfo(false);
                                }
                            }
                        }
                    } catch (error) {
                        console.error(error);
                    }
                },
                error: error => {
                    console.warn(error);
                }
            });
        })
        .catch(err => console.log(err));
        console.log('loadCategories :: ' + props.loadCategories);
        if(props.loadCategories || categories.length === 0){
            console.log('loading categories');

            setCategories();
            console.log({categories});
            //setMainInfo('', '');
            setTimeout(()=>{
                console.log('calling clean');
                clearMainInfo();
            }, 1000);
        }
        return function cleanup() {
            console.log('Cleaning Subscription');
            if(subscription != undefined){
                subscription.unsubscribe();
            }
        };
    }, []);

    const logout = async () =>{
        try {
            //setLoading(true);
            await Auth.signOut();
            cleanCategories();
            cleanUser();
            setCategories();
            clearUserType();
            //setLoading(false);
            history.push("/");
            //history.go(0);
        } catch (error) {
            //setLoading(false);
            console.log('error signing out: ', error);
        }
    }
    const closeSession = async (index) =>{
            console.log({index});

            if(index === "0"){
                clearMainInfo();
                history.push("/");
                return;
            }

            if(index === "2-1a"){
                history.push("/login");
                return;
            }
            if(index === "2-2a" || index === "-3" || index === "-4" || index === "-4.5"){
                history.push("/login");
                return;
            }

            if(index === "2-2"){
                logout();
            }
            if(index === "2-3"){
                setMenuIndex("/my-account");
                history.push("/my-account");
                return;
            }

            if(index === "2-4"){
                changeUserType({...user }, user.type === 'S' ? 'B' : 'S')
                setNewType(user.type === 'S' ? 'B' : 'S');
                //window.location.reload(false);
                return;
            }

            if(index === "3"){
                setChatInfo(false);
                const win = window.open("/my-chats/0", "_blank");
                win.focus();
                return;
            }

            if(index === "4"){
                if(user.type === "S"){
                    setMenuIndex("/dashboard");
                    const win = window.open("/dashboard", "_blank");
                    win.focus();
                }else{
                    setMenuIndex("/dashboard");
                    const win = window.open("/dashboard", "_blank");
                    win.focus();
                }

                return;
            }

            if(index === "4.1"){
                setMenuIndex("/tools/dashboard");
                const win = window.open("/tools/dashboard", "_blank");
                win.focus();
            }

            if(index === "4.5"){
                    setMenuIndex("/my-orders");
                    //history.push("/my-orders");
                    const win = window.open("/my-orders", "_blank");
                    win.focus();
            }

    }

    const searchProducts = () =>{
        //window["sendEvent"]("search", mainInfo.searchQuery );
        logInDB({ message: 'search term :: ' + mainInfo.searchQuery, type: LogType.SEARCH_TYPE, user: user!= null && user.name != null ? user.name : 'anonymous' });
        setFilterInfo( 25,  0,  10000000,  false,  false,  false);
        setTimeout(function(){
            history.push(`/search-result-ng/${mainInfo.searchQuery === ""? "$EMPTY$" : mainInfo.searchQuery }/${mainInfo.selectIndex === "" ? "All" : mainInfo.selectIndex}?query=${mainInfo.searchQuery === ""? "$EMPTY$" : mainInfo.searchQuery}`);
        },500);
    }

    const goToLogin = () =>{
        history.push('/login');
    }

    return(
            <Menu theme="dark" onSelect={(index) =>{closeSession(index)}}  defaultActive="1" className="el-menu-demo header-color top-menu" mode="horizontal">
                {
                                    props.hide ? <></>
                                    :
                                <>

                        <Layout.Row xs="24" className="top-banner-header" gutter="20" type="flex">
                                <Layout.Col xs="12" sm="12" md="12" lg="12">
                                   Welcome to Bainub
                                </Layout.Col>
                                <Layout.Col className="text-right" xs="12" sm="12" md="12" lg="12">
                                <a href="https://blog.bainub.com/" target="_blank">Blog</a> | <Link to="/about-us" target="_blank">About Bainub</Link> |  <Link to="/contact-us" target="_blank">Contact</Link>
                                </Layout.Col>
                        </Layout.Row>

                                </>
                                
                                }

                        <Layout.Row xs="24" className="header-rows" gutter="20" type="flex" justify="center">
                                <Layout.Col xs="24" sm="24" md="3" lg="3">
                                    <Menu.Item className="main-logo" index="0"><BainubIcon width={36} height={36} text="BAINUB"/></Menu.Item>
                                </Layout.Col>
                                {
                                    props.hide ? <></>
                                    :
                                <>

                                <Layout.Col xs="24" sm="24" md="10" lg="10">
                                        <Layout.Row>
                                            <Layout.Col xs="24" sm="24" md="18" lg="18" className="search-input-content">
                                            <Input onKeyPress={handleKeyDown} placeholder={'Search for products'} value={mainInfo.searchQuery} onChange={(v) => { setMainInfo( mainInfo.selectIndex , v ); }} id="main-search-input" prepend={
                                                        <Select id="main-select-category" className="select-category" value={mainInfo.selectIndex} onChange={(v) => { setMainInfo( v , mainInfo.searchQuery ) }}
                                                                placeholder={t('header.categories')}>
                                                            <Select.Option label={t('header.all')} value="All">

                                                            </Select.Option>
                                                            {
                                                                categories.map(group => {
                                                                return (
                                                                    <Select.OptionGroup key={group.label} label={group.label}>
                                                                    {
                                                                        group.options.map(el => {
                                                                        return (
                                                                            <Select.Option key={el.value} label={el.label} value={el.value}>
                                                                                <span style={{float: 'left'}}>{el.label}</span>
                                                                            </Select.Option>
                                                                        )
                                                                        })
                                                                    }
                                                                    </Select.OptionGroup>
                                                                )
                                                                })
                                                            }
                                                    </Select>
                                                    }  />
                                            </Layout.Col>

                                            <Layout.Col xs="24" sm="24" md="6" lg="6" className="search-input-content text-left">
                                            <div id="main-search-button">
                                                        <Button type="primary" onClick={searchProducts} icon="search">{t('header.search')}</Button>
                                                    </div>
                                            </Layout.Col>
                                        </Layout.Row>
                                
                                </Layout.Col>

                                <Layout.Col xs="24" sm="24" md="11" lg="11">
                                        {user.name === undefined ?
                                                <div id="sub-menu-single-without-session">
                                                    <Menu.Item index="-3"><MessagesIcon text={t('header.messages')}/></Menu.Item>
                                                    <Menu.Item index="-4"><StoreIcon text={t('header.mystore')} /></Menu.Item>
                                                    <Menu.Item index="4.1"><BuyerToolsIcon text={'Buyer Tools'} /></Menu.Item>
                                                </div>
                                            :
                                                <div id="sub-menu-single-without-session">
                                                    <Menu.Item index="3">
                                                        <MessagesIcon hasMessage={chatInfo} text={t('header.messages')}/>
                                                    </Menu.Item>
                                                    <Menu.Item index="4"><StoreIcon text={t('header.mystore')} /></Menu.Item>
                                                    <Menu.Item index="4.1"><BuyerToolsIcon text={'Buyer Tools'} /></Menu.Item>
                                                    {/* 
                                                        <Menu.Item index="4.5"><ShoppingCartIcon text={t('header.mylist')} /></Menu.Item> 
                                                    */}

                                                </div>
                                        }

                                        <div className="search-input-content">
                                                {user.name === undefined ?
                                                    <div id="main-login-button">
                                                        <Button type="primary" onClick={goToLogin} icon="login" className='float-right'> <LoginIcon text={t('header.login')}/></Button>
                                                    </div>
                                                    :
                                                    <div className='float-right' id="sub-menu-without-session">
                                                        <Menu.SubMenu index="2" title={<UserIcon width="30" height="30"
                                                                                                 grettings={t('header.greetings')}
                                                                                                 text={user.firstName}
                                                                                                 mail={user.name}/>
                                                    }>
                                                            {
                                                                //<Menu.Item index="2-1">{t('header.profile')}</Menu.Item>
                                                            }
                                                            {
                                                                user && user.originalType === "XYZ"
                                                                ?
                                                                    <Menu.Item index="2-4"> {user.type === 'S' ? t('header.sellertobuyer') : t('header.buyertoseller')} </Menu.Item>
                                                                :
                                                                    <></>
                                                            }
                                                            <Menu.Item index="2-3">{t('header.myaccount')}</Menu.Item>
                                                            <Menu.Item index="2-2">{t('header.logout')}</Menu.Item>
                                                        </Menu.SubMenu>
                                                    </div>
                                                }
                                        </div>

                                </Layout.Col>
                                </>
                                }
                        </Layout.Row>

            </Menu>
    );
}

export default Header;