import React from 'react';
import ContactUs from './ContactUs';
import HelpAndSupportComponent from '../components/HelpAndSupportComponent';

function HelpAndSupport({menu}) {
    return (
        <>
        <h1 className="form-title">Bainub Help Center</h1>
            <br/>
            <h3 className="form-subtitle">Please browse the Bainub Help Topics.</h3>
            <h2 className='text-center'>FAQ</h2>
            <br/>
            <HelpAndSupportComponent menu={menu}/>
            <br/>
            <h3 className='form-subtitle'>
                Can’t find what you are looking for?
            </h3>
            <h3 className='form-subtitle'>
                Just fill the form below and we will do our best to help.
            </h3>
            <ContactUs menu={menu} hideTitle={true}/>
        </>
    );
}

export default HelpAndSupport;
