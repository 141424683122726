import TermsOfUseText from '../components/TermsOfUseText';

function BainubTermsOfUse(props){
    return(
       
        <TermsOfUseText/>
        
    )
}

export default BainubTermsOfUse;