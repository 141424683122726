import Header from './../Header';
import { useState, useEffect } from 'react'
import { Layout, Dialog } from 'element-react';
import userStore from '../../store/user';
import MyPreferencesWizard from './MyPreferencesWizard';
import {isDesktop} from 'react-device-detect';

export default function AppLayout({ children, showLeftMenu , footerComponent, menuComponent, showMyPreferencesWizard, ...props}) {
    const {user, setPendingSetupBusinessPreferences} = userStore();
    const [myPreferencesWizardEnabled, setMyPreferencesWizardEnabled] = useState(showMyPreferencesWizard)
    const onWizardComplete = () => { 
        console.log("on wizard complete function");
        setMyPreferencesWizardEnabled(false);
        setPendingSetupBusinessPreferences({...user }, false)
    }

    useEffect(function() {
        setMyPreferencesWizardEnabled(showMyPreferencesWizard);
    }, [])

    return (
        <div>
            <Header loadCategories={props.loadCategories}/>
            <div className="app-container">
                { /*myPreferencesWizardEnabled && isDesktop ?
                    <Dialog className="dialog-width-1000" visible={myPreferencesWizardEnabled} showClose={false} >
                        <Dialog.Body className="m-t-none p-t-none">
                            <MyPreferencesWizard onWizardComplete={onWizardComplete}/>
                        </Dialog.Body>
                    </Dialog> : <></>*/
                }
                <Layout.Row gutter="10" type="flex">
                    {
                    showLeftMenu === true ?
                       <>
                            <Layout.Col className={props.searchType ? '' : 'leftBack'} xs="1" sm="4" md="4" lg="4">
                                {menuComponent}
                            </Layout.Col>
                       </>
                       :
                       <>
                            <Layout.Col xs="1" sm="1" md="1" lg="1">
                                &nbsp;
                            </Layout.Col>
                       </>
                    }

                <Layout.Col className="no-gutter" xs={showLeftMenu? "24" : "23"} sm={showLeftMenu === true? "20" : "23"} md={showLeftMenu? "20" : "23"} lg={showLeftMenu? "20" : "23"}>
                    <div>
                        {children}
                    </div>
                </Layout.Col>
                    
                    
                </Layout.Row>
            </div>
            <div className="footer">
                {footerComponent}
            </div>
        </div>
    )
}