import {listChats, listCounterInfoTables} from '../graphql/queries';
import {createChat, createMessage, updateChat, updateCounterInfoTable} from '../graphql/mutations';
import {API, graphqlOperation } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { Message } from 'element-react';
import {getLadaInfo} from '../utils/FormatUtils';
import PagerQuery from '../utils/PagerQuery';


const updateChatCompanyNotReaded = async(chatId, lastMessage, checkStatus, user) =>{
    let input = {
        id: chatId, 
        last_message: lastMessage,
        company_user_last_message_read: 1,
        buyer_phone: ''
    }
    console.log('checkStatus :: ' + checkStatus);
    console.log({user});
    if(checkStatus){
        input = {...input, buyer_phone: getLadaInfo(user.country) + ' ' + user.phoneNumber}
    }
    console.log({input});
    console.log('updating chat readed messaged')
    const result = await API.graphql(
        graphqlOperation(updateChat, { input })
    );
    console.log({result})
}

const updateProductCounter = async(user) =>{
    try{
        const result = await API.graphql({ query: listCounterInfoTables, variables: 
            { 
                filter: 
                    { 
                        owner: { eq: user.username } 
                    } 
            } 
        });
        console.log('NEW PRODUCT :: updateProductCounter');
        console.log({result});
        if(result && result.data && result.data?.listCounterInfoTables?.items.length > 0){
            const input = {
                id: result.data.listCounterInfoTables.items[0].id,
                chats_total: result.data.listCounterInfoTables.items[0].chats_total + 1
            };
            const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, { input }));
            console.log({resultUpdate});
        }else{
            console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
        }
    }catch(e){
        console.error(e);
    }
}

const generateProductChateFromInput = async(_message, productPage, t, sessionUser, companyUser, checkStatus) =>{
    console.log('generateProductChateFromInput');
    try{
        console.log({sessionUser})
        console.log({companyUser})
        const u = await Auth.currentAuthenticatedUser();
        console.log({u});
        console.log('productPage Owner :: ' + productPage.owner);
        console.log('current User :: ' + u.attributes.sub);
        if(productPage.owner === u.attributes.sub){
            Message({
                message: t('product.create.preview.error-owner'),
                type: 'error'
            });
            return false;
        }else{
            let filter = {
                and : {
                    owner: {eq: u.attributes.sub},
                    company_user: {eq: productPage.owner}
                }
            };
            const pager = new PagerQuery(listChats, { filter }, Object.keys({ listChats }));
            const resultChats = await pager.searchPrivateData();//await API.graphql({ query: listChats, variables: { filter }});
            console.log({resultChats});
            let chatId = null
            if(resultChats.data.listChats.items.length == 0){
                //Create new chat
                const input = {
                    owner: u.attributes.sub,
                    owner_name: sessionUser?.firstName,
                    alias_owner_name: sessionUser?.firstName,
                    company_user: productPage.owner,
                    company_user_name: companyUser.first_name + ' ' + companyUser.last_name,
                    alias_company_user_name: companyUser.first_name + ' ' + companyUser.last_name,
                    last_message: _message,
                    owner_last_message_read: 0,
                    company_user_last_message_read: 1,
                    seller_since: companyUser.createdAt.substring(0,4),
                    buyer_since: sessionUser.createdAt.substring(0,4),
                    buyer_phone: checkStatus ? getLadaInfo(sessionUser.country) + ' ' + sessionUser.phoneNumber : ''
                }
                const result = await API.graphql(
                    graphqlOperation(createChat, { input })
                );
                console.log({result})
                chatId = result.data.createChat.id;
                await updateProductCounter(u);
            }else{
                chatId = resultChats.data.listChats.items[0].id;
                await updateChatCompanyNotReaded(chatId, _message, checkStatus, sessionUser);
            }
            console.log('CHAT ID ::: ' + chatId);
    
            console.log({productPage});
            //create new message
            
            
            const inputMessage = {
                chat_id : chatId,
                messageChatId: chatId,
                from: u.attributes.sub,
                to: productPage.owner,
                message: checkStatus ? getLadaInfo(sessionUser.country) + ' ' + sessionUser.phoneNumber : _message,
                message_type: checkStatus ?  "product-question-contact-phone" : "product-question",
                product_id: productPage.id,
                product_company_id: productPage.company_id,
                product_company_name: productPage.company_name,
                product_name: productPage.name,
                product_image: (productPage.photo != null && productPage.photo.length > 0) ? productPage.photo[0] : null
            }
            const resultNewInputMessage = await API.graphql(
                    graphqlOperation(createMessage, { input: inputMessage })
            );
    
            console.log({resultNewInputMessage})
            
            if(checkStatus){
                const input = {
                    chat_id : chatId,
                    messageChatId: chatId,
                    from: u.attributes.sub,
                    to: productPage.owner,
                    message: _message,
                    message_type: "message",
                    product_id: productPage.id,
                    product_company_id: productPage.company_id,
                    product_company_name: productPage.company_name,
                    product_name: productPage.name,
                    product_image: (productPage.photo != null && productPage.photo.length > 0) ? productPage.photo[0] : null
                }
                const resultNewMessage = await API.graphql(
                        graphqlOperation(createMessage, { input })
                );
        
                console.log({resultNewMessage})
            }
            
            return true;
        }
    }catch(e){
        console.error(e);
        if(e === 'The user is not authenticated'){
            console.log('Alert to user');
            Message({
                message: t('product.create.preview.no-session'),
                type: 'error'
            });
        }
        return false;
    }
}


const generateCompanyChat = async(_message, companyPage, t, sessionUser, companyUser, checkStatus) =>{
    console.log('generateProductChat');
    try{
        console.log({sessionUser})
        const u = await Auth.currentAuthenticatedUser();
        console.log({u});
        console.log('Company Owner :: ' + companyPage.owner);
        console.log('current User :: ' + u.attributes.sub);

        if(companyPage.owner === u.attributes.sub){
            Message({
                message: t('product.create.preview.error-owner'),
                type: 'error'
            });
            return false;
        }else{
            console.log('continue witout errors');
            let filter = {
                and : {
                    owner: {eq: u.attributes.sub},
                    company_user: {eq: companyPage.owner}
                }
            };
            const pager = new PagerQuery(listChats, { filter }, Object.keys({ listChats }));
            const resultChats = await pager.searchPrivateData();//await API.graphql({ query: listChats, variables: { filter }});
            console.log({resultChats});
            let chatId = null
            if(resultChats.data.listChats.items.length == 0){
                //Create new chat
                const input = {
                    owner: u.attributes.sub,
                    owner_name: sessionUser?.firstName,
                    alias_owner_name: sessionUser?.firstName,
                    company_user: companyPage.owner,
                    company_user_name: companyUser.first_name + ' ' + companyUser.last_name,
                    alias_company_user_name: companyUser.first_name + ' ' + companyUser.last_name,
                    last_message: _message,
                    seller_since: companyUser.createdAt.substring(0,4),
                    buyer_since: sessionUser.createdAt.substring(0,4),
                    owner_last_message_read: 0,
                    company_user_last_message_read: 1,
                    buyer_phone: checkStatus ? getLadaInfo(sessionUser.country) + ' ' + sessionUser.phoneNumber : ''
                }
                const result = await API.graphql(
                    graphqlOperation(createChat, { input })
                );
                console.log({result})
                chatId = result.data.createChat.id;
                await updateProductCounter(u);
            }else{
                chatId = resultChats.data.listChats.items[0].id;
                await updateChatCompanyNotReaded(chatId, _message, checkStatus, sessionUser);
            }
            console.log('CHAT ID ::: ' + chatId);
    
            console.log({companyPage});
            //create new message
            const input = {
                chat_id : chatId,
                messageChatId: chatId,
                from: u.attributes.sub,
                to: companyPage.owner,
                product_company_id: companyPage.id,
                product_company_name: companyPage.name,
                message: checkStatus ? getLadaInfo(sessionUser.country) + ' ' + sessionUser.phoneNumber : _message,
                message_type: checkStatus ?  "company-question-contact-phone" : "company-question",
            }
            const resultNewMessage = await API.graphql(
                    graphqlOperation(createMessage, { input })
            );
            console.log({resultNewMessage});
            return true;
        }
    }catch(e){
        console.error(e);
        if(e === 'The user is not authenticated'){
            console.log('Alert to user');
            Message({
                message: t('product.create.preview.no-session'),
                type: 'error'
            });
        }
        return false;
    }
}

const generateProductChat = async(_message, productPage, t, sessionUser, companyUser) =>{
    console.log('generateProductChat');
    try{
        console.log({sessionUser})
        const u = await Auth.currentAuthenticatedUser();
        console.log({u});
        console.log('productPage Owner :: ' + productPage.owner);
        console.log('current User :: ' + u.attributes.sub);

        if(productPage.owner === u.attributes.sub){
            Message({
                message: t('product.create.preview.error-owner'),
                type: 'error'
            });
            return false;
        }else{
            console.log('continue witout errors');
            let filter = {
                and : {
                    owner: {eq: u.attributes.sub},
                    company_user: {eq: productPage.owner}
                }
            };
            const pager = new PagerQuery(listChats, { filter }, Object.keys({ listChats }));
            const resultChats = await pager.searchPrivateData();//await API.graphql({ query: listChats, variables: { filter }});
            console.log({resultChats});
            let chatId = null
            if(resultChats.data.listChats.items.length == 0){
                //Create new chat
                const input = {
                    owner: u.attributes.sub,
                    owner_name: sessionUser?.firstName,
                    alias_owner_name: sessionUser?.firstName,
                    company_user: productPage.owner,
                    company_user_name: companyUser.first_name + ' ' + companyUser.last_name,
                    alias_company_user_name: companyUser.first_name + ' ' + companyUser.last_name,
                    last_message: _message,
                    seller_since: companyUser.createdAt.substring(0,4),
                    buyer_since: sessionUser.createdAt.substring(0,4),
                    owner_last_message_read: 0,
                    company_user_last_message_read: 1
                }
                const result = await API.graphql(
                    graphqlOperation(createChat, { input })
                );
                console.log({result})
                chatId = result.data.createChat.id;
                await updateProductCounter(u);
            }else{
                chatId = resultChats.data.listChats.items[0].id;
                await updateChatCompanyNotReaded(chatId, productPage.name, false, sessionUser);
            }
            console.log('CHAT ID ::: ' + chatId);
    
            console.log({productPage});
            //create new message
            const input = {
                chat_id : chatId,
                messageChatId: chatId,
                from: u.attributes.sub,
                to: productPage.owner,
                message: _message,
                message_type: "lets-chat",
                product_id: productPage.id,
                product_company_id: productPage.company_id,
                product_company_name: productPage.company_name,
                product_name: productPage.name,
                product_image: (productPage.photo != null && productPage.photo.length > 0) ? productPage.photo[0] : null
            }
            const resultNewMessage = await API.graphql(
                    graphqlOperation(createMessage, { input })
            );
            console.log({resultNewMessage});
            await updateProductCounter(u);
            return true;
        }
    }catch(e){
        console.error(e);
        if(e === 'The user is not authenticated'){
            console.log('Alert to user');
            Message({
                message: t('product.create.preview.no-session'),
                type: 'error'
            });
        }
        return false;
    }
}

export {
    generateProductChat, 
    generateProductChateFromInput,
    generateCompanyChat
};