/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "NotificationServiceLambda",
            "endpoint": "https://apirest.bainub.com",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://graphql.bainub.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:5630d12f-0238-48c2-91f8-5f52b9b64f91",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Txcr3UYpO",
    "aws_user_pools_web_client_id": "391f30j6b8i8ctghvo4g5jpbrf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "marketplace-20210505173231-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://marketplace-20210505173231-hostingbucket-dev.s3-website.us-east-2.amazonaws.com",
    "aws_user_files_s3_bucket": "marketplace19950c81227542e0b3222b702ca325c8141408-dev",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_firehose_name": "stream-kinesis-markeplace"
};


export default awsmobile;
