import { Layout, Button, Form, 
    Input,  Dialog, Table, MessageBox
} from 'element-react';
import FeeIcon from './FeeIcon';
import DeleteIcon from '../../components/DeleteIcon';
import { toCurrency } from '../../utils/FormatUtils';
import { useState, useRef, useEffect } from 'react';
import { addFeeRules } from '../../utils/OrderFormRules';
import { calculateOrderTotal } from '../../services/OrderService';
import NotesIcon from '../NotesIcon';

function FeeSection(props){
    const t = props.t;
    const [showDialog, setShowDialog] = useState(false);
    const form = useRef(null);
    const [rules, setRules] = useState(null);
    const [feeForm, setFeeForm] = useState(
        {
            fee: '',
            quantity: null,
            unit: '',
            unitPrice: null,
            description: '',
            subtotal: null
        });
    useEffect(() => {
        setRules(addFeeRules(t));
    }, [t]);
    const columns = 
    [
        {
          label: t('orders.form.fee-table.1'),
          prop: "orderNumber",
          width: 120,
          //sortable: true,
          render: (row, column, index)=>{
            return(
                index + 1
            ) 
          }
        },
        {
            label: t('orders.form.fee-table.2'),
            //width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                    row.fee
              ) 
            }
        },
        {
            label: t('orders.form.fee-table.3'),
            width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                row.quantity
              ) 
            }
        },
        {
            label: t('orders.form.fee-table.4'),
            width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                row.unit
              ) 
            }
        },
        {
            label: t('orders.form.fee-table.5'),
            width: 150,
            //sortable: true,
            align: 'right',
            render: (row, column, index)=>{
              return(
                toCurrency(Number(row.unitPrice)) + ' ' + props.formInputs.currency
              ) 
            }
        },
        {
            label: t('orders.form.fee-table.6'),
            width: 150,
            prop: 'subtotal',
            align: 'right',
            sortable: true,
            render: (row, column, index)=>{
              return(
                toCurrency(row.subtotal) + ' ' + props.formInputs.currency
              ) 
            }
        },
        {
            //label: t('orders.form.product-table.7'),
            width: 80,
            //sortable: true,
            render: (row, column, index)=>{
                if(props.readOnly){
                    return <></>
                }
                if(props.formInputs.sellerStatus !== 0){
                    return(
                        <>
                            <NotesIcon color='#FF6D73'  handleClick={()=>{MessageBox.alert(row.description, 'Info Extra');}} width={30} height={20}/>
                        </>
                    )
                }
                return(
                    <div className="text-center inline-row" style={{marginTop: '15px'}}>
                            <DeleteIcon handleClick={()=>{deleteItem(index)}} width={30} height={30}/>
                            <NotesIcon color='#FF6D73'  handleClick={()=>{MessageBox.alert(row.description, 'Info Extra');}} width={30} height={20}/>
                    </div>
                ) 
            }
        },
    ]
    const deleteItem = (tableIndex) =>{
        console.log(`deleting ${tableIndex}`);
        props.setLoading(true);
        let _prevFees = props.formInputs.fees.filter(function( obj, index ) {
            return index !== tableIndex;
        });
        //let _prevProducts = props.formInputs.products ? props.formInputs.products: [];
        let _total = calculateOrderTotal(props.formInputs.products, [..._prevFees], props.formInputs.paymentCost)
        props.setFormInputs({...props.formInputs, fees: [..._prevFees], total : _total })
        props.setLoading(false);
    }

    const addFee = () =>{
        form.current.validate((valid) => {
            console.log('validator :: ' + valid);
            if (valid) {
                console.log({feeForm});
                let _prevFees = props.formInputs.fees ? props.formInputs.fees: [];
                let _total = calculateOrderTotal(props.formInputs.products, [..._prevFees, feeForm], props.formInputs.paymentCost);
                props.setFormInputs({...props.formInputs, fees: [..._prevFees, feeForm],total: _total });
                setShowDialog(false);
            } else {
                console.log('invalid form');
            }
        })
        
    }

    const initForm = async() => {
        props.setLoading(true);
        /*const user = await Auth.currentAuthenticatedUser();
        console.log({user});
        const resultProducts = await API.graphql({ query: listProducts, variables: { filter: { owner: { eq: user.username }, status: { eq: 1 } }  }});
        console.log({resultProducts});
        let optionsResult = []
        resultProducts.data.listProducts.items.forEach(element => {
            optionsResult.push(
                {
                    value: element,
                    label: element.name
                }
            )
        })
        setProductOptions(optionsResult);*/
        setFeeForm({
            product: '',
            quantity: null,
            unit: '',
            unitPrice: null,
            description: '',
            subtotal: null
        })
        setShowDialog(true);
        props.setLoading(false);
        
    }

    return(
        <>
            <Dialog size="small"
                    visible={showDialog}
                    onCancel={ () => { 
                        console.log('cancel Diaglog');
                        setShowDialog(false);
                    } }
                    title={t('orders.form.fee-dialog-title')}
                >
                <Dialog.Body>
                    <Form ref={form} model={feeForm} className="en-US" rules={rules} label-position="top">
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.fee-table.modal-fee')} prop="fee">
                                <Input 
                                        value={feeForm.fee}
                                        onChange={(v) => {
                                            setFeeForm({...feeForm, fee: v })
                                        }} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={5} md={7} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.fee-table.modal-quantity')} prop="quantity">
                                    <Input 
                                        value={feeForm.quantity}
                                        onChange={(v) => {
                                            setFeeForm({...feeForm, quantity: v, subtotal: (v * feeForm.unitPrice) })
                                        }} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={5} md={7} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.fee-table.modal-unit')} prop="unit">
                                    <Input 
                                        value={feeForm.unit}
                                        onChange={(v) => {setFeeForm({...feeForm, unit: v })}} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={5} md={7} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.fee-table.modal-unit-price')} prop="unitPrice">
                                    <Input 
                                        value={feeForm.unitPrice}
                                        onChange={(v) => {setFeeForm({...feeForm, unitPrice: v, subtotal: (v * feeForm.quantity) })}} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                    <b>{t('orders.form.fee-table.modal-subtotal') + ': ' } {toCurrency(feeForm.subtotal)}</b>
                                
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.fee-table.modal-description')} prop="description" >
                                    <Input 
                                        type="textarea"
                                        autosize={{ minRows: 4, maxRows: 4}}
                                        value={feeForm.description}
                                        onChange={(v) => {setFeeForm({...feeForm, description: v })}} 
                                    />
                                    
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <Layout.Row type="flex">
                                    <Layout.Col span="24" xs="20" sm="20" lg="23">
                                        <Layout.Row type="flex" justify="end">
                                            <Layout.Col tag="form-button">
                                                <div style={{display: 'inline-flex'}}>
                                                    <form-button-inverse class="left-space">
                                                    <Button className="left-separator" type="primary" onClick={() => setShowDialog(false)}>
                                                            {t('orders.form.cancel-prd-btn')}
                                                    </Button>
                                                    </form-button-inverse>
                                                </div>
                                            </Layout.Col>
                                            <Layout.Col tag="form-button">
                                                    <Button className="left-separator" type="primary" onClick={() => addFee()}>
                                                            {t('orders.form.fee-table.add-fee-btn')}
                                                    </Button>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Layout.Col>
                    </Layout.Row>
                </Dialog.Footer>
            </Dialog>        
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <FeeIcon width={41} height={41}/> 
                        <div className="order-step-form">
                            {
                                props.readOnly
                                ?
                                    t('orders.viewer.fee-section')
                                :
                                    t('orders.form.fee-section')
                            }
                        </div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                &nbsp;
            </Layout.Row>
            <Layout.Row type="flex">
                    <Layout.Col span="24" xs="20" sm="20" lg="23">
                        <Layout.Row type="flex" justify="end">
                            <Layout.Col tag="form-button">
                                <div style={{display: 'inline-flex'}}>
                                    <form-button-inverse class="left-space">
                                        {
                                            props.formInputs.sellerStatus !== 0 || props.readOnly
                                            ?
                                                <></>
                                            :
                                                <Button className="left-separator" type="primary" onClick={() => initForm()}>
                                                {t('orders.form.fee-table.add-fee-btn')}
                                                </Button>
                                        }
                                    </form-button-inverse>
                                </div>
                            </Layout.Col>
                        </Layout.Row>
                    </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20" justify="end" type="flex" >
                    <Layout.Col offset="1" lg="10">
                        &nbsp;
                    </Layout.Col>
            </Layout.Row>
            <Layout.Row gutter="20">
                    <Layout.Col span="23">
                        
                        <Table
                            className="products-table box-card"
                            data={props.formInputs.fees}
                            columns={columns}  
                            fit={true}
                            sumText='Total'
                            showSummary={true}
                            summaryMethod={(columns, data)=>{
                                const dataList = [];
                                for(var i=0; i < columns.length; i++){
                                    let total = 0;
                                    if(i === 4){
                                        dataList[i] = 'Subtotal:';
                                        continue;
                                    }
                                    for(let j=0; j < data.length; j++){
                                        let value = data[j][columns[i]['property']];
                                        
                                        if(isNaN(value)){
                                            total = ''
                                            break;
                                        }else{
                                            total += parseFloat(value);
                                        }
                                    }
                                    
                                    dataList[i] = toCurrency(total) + ( i === 5 ?  ' ' + props.formInputs.currency : '');
                                }
                                return dataList;
                            }}
                            emptyText={<>
                                <div><b>{t('orders.form.fee-table.no-data-1')}</b></div>
                                <br/>
                                {
                                    props.readOnly ?
                                        <div></div>
                                    :
                                        <div>{t('orders.form.fee-table.no-data-2')}</div>
                                }
                                
                            </>}
                            //onRowClick={dotsClick}
                        />
                    </Layout.Col>
                </Layout.Row>
        </>
    )
}

export default FeeSection;