//import { toCurrency } from "./FormatUtils";

const addCompanyRules0 = (t) => {
    return  ({
        name: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        type: [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur' }
        ],
        description: [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur' }
        ],
        region: [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur' }
        ],
        age: [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur', validator: (rule, value) => 
                {
                    //var numbers = /^[0-9]+$/;
                    //if (!value.match(numbers) || value.length == 0) {
                    if (value.length == 0) {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ]
    })
}

const addCompanyRules1 = (t) => {
    return  ({
        urlFile1: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ],
        urlFile2: [
            { required: true, message: t('my-store.create.form.validator.generic') }
        ]
    })
}

const addCompanyRules2 = (t) => {
    return  ({
        main_market: [
            { required: true, message: t('my-store.create.form.validator.generic'), trigger: 'blur' }
        ],
        production_lines_qty: [
            { required: true, message: t('my-store.create.form.validator.number'), trigger: 'blur', validator: (rule, value) => 
                {
                    if ((value === "" || value%1) != 0) 
                    {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ],
        employees_qty: [
            { required: true, message: t('my-store.create.form.validator.number'), trigger: 'blur', validator: (rule, value) => 
                {
                    if ((value === "" || value%1) != 0) 
                    {
                        return false;
                    }else{
                        return true;
                    }
                } 
            }
        ]
    })
}

export {addCompanyRules0, addCompanyRules1, addCompanyRules2};