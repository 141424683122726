import { API } from 'aws-amplify'

async function saveProduct ({id, name, brand, description, owner, minPrice, maxPrice,
    photoA, photoB, photoC, companyId, status, warranty, customizable,
    minOrder, cert1, cert2, cert3, productOrder, subCategoryId,
    featured, currentUser, company_country, bainub_account_year}) {
    try {
        const result = await API.post('NotificationServiceLambda', '/product/save', {
            body: {
                id, name, brand, description, owner, minPrice, maxPrice,
                photoA, photoB, photoC, companyId, status, warranty, customizable,
                minOrder, cert1, cert2, cert3, productOrder, subCategoryId,
                featured, currentUser, company_country, bainub_account_year
            }
        })
        console.log(result);
        return result;
    } catch(error) {
        console.error(error);
    }
}

async function updateProduct ({id, name, brand, description, owner, minPrice, maxPrice,
    photoA, photoB, photoC, companyId, status, warranty, customizable,
    minOrder, cert1, cert2, cert3, subCategoryId, company_country, bainub_account_year}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/product/update', {
            body: {
                id, name, brand, description, owner, minPrice, maxPrice,
                photoA, photoB, photoC, companyId, status, warranty, customizable,
                minOrder, cert1, cert2, cert3, subCategoryId, company_country, bainub_account_year
            }
        });
        console.log(result);
        return result;
    }catch(error) {
        console.error(error);
    }
}

async function updateProductStatus ({id, status}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/product/status/update', {
            body: {
                id, status
            }
        });
        console.log(result);
        return result;
    }catch(error) {
        console.error(error);
    }
}

async function saveProductTag ({tagName, productId}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/product/tags/save', {
            body: {
                tagName, productId
            }
        });
        console.log(result);
        return result;
    }catch(error) {
        console.error(error);
    }
}

async function deleteAllProductTags ({productId}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/product/tags/delete', {
            body: {
                productId
            }
        });
        console.log(result);
        return result;
    }catch(error) {
        console.error(error);
    }
}

async function getNumberOrProductsByCompanyId({companyId}) {
    try{
        const result = await API.post('NotificationServiceLambda', '/product/bycompany/count', {
            body: {
                companyId
            }
        });
        console.log(result);
        return result;
    }catch(error) {
        console.error(error);
    }
}

async function searchProducts({searchTerm}){
    try{
        const result = await API.post('NotificationServiceLambda', '/product/search', {
            body: {
                searchTerm
            }
        });
        console.log(result);
        return result;
    }catch(error) {
        console.error(error);
    }
}

export { saveProduct, updateProduct, updateProductStatus, saveProductTag,
            deleteAllProductTags, getNumberOrProductsByCompanyId, searchProducts };