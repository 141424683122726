import React from 'react'
import {useState} from 'react';
import {Dialog} from 'element-react';

export default function PrivacyPolicy({language, isPrivacyPolicyVisible, setIsPrivacyPolicyVisible}){

    let dialog = (
        <Dialog visible={isPrivacyPolicyVisible}
                onCancel={() => setIsPrivacyPolicyVisible(false)}
                lockScroll={false}>
            <Dialog.Body className='dialog-body-footer'>
                <>
                    <div className="header privacy-policy-title">Politica de Privacidad</div>
                    <div className="content privacy-policy-content">
                        Bainub ("nosotros", "nuestro" o "nos") se compromete a proteger su privacidad. Esta Política de
                        privacidad explica cómo Bainub recopila, usa y divulga su información personal.
                        Esta Política de privacidad se aplica a nuestro sitio web, www.bainub.com y sus subdominios
                        asociados (colectivamente, nuestro "Servicio") junto con nuestra aplicación, Bainub. Al acceder
                        o
                        utilizar nuestro Servicio, usted indica que ha leído, comprendido y aceptado nuestra
                        recopilación,
                        almacenamiento, uso y divulgación de su información personal como se describe en esta Política
                        de
                        privacidad y en nuestros Términos de servicio.
                    </div>

                    <div className="header privacy-policy-title">Definiciones y Términos Clave</div>
                    <div className="content privacy-policy-content">
                        Para ayudar a explicar las cosas de la manera más clara posible en esta Política de privacidad,
                        cada
                        vez que se hace referencia a cualquiera de estos términos, se definen estrictamente
                        como:<br/><br/>
                        <li><b>Cookie:</b> pequeña cantidad de datos generados por un sitio web y guardados por su
                            navegador
                            web. Se utiliza para identificar su navegador, proporcionar análisis, recordar información
                            sobre
                            usted, como su preferencia de idioma o información de inicio de sesión.
                        </li>
                        <li><b>Compañía:</b> cuando esta política menciona "Compañía", "nosotros", "nos" o "nuestro", se
                            refiere a Bainub S.A. de C.V. (Prol. las Torres No.138 Regio Parque Industrial, Santa
                            Catarina,
                            N.L., México, 66367), que es responsable de su información en virtud de esta Política de
                            privacidad.
                        </li>
                        <li><b>Plataforma:</b> sitio web de Internet, aplicación web o aplicación digital de cara al
                            público
                            de Bainub.
                        </li>
                        <li><b>País:</b> donde se encuentra Bainub o los propietarios / fundadores de Bainub, en este
                            caso
                            es México.
                        </li>
                        <li><b>Cliente:</b> se refiere a la empresa, organización o persona que se registra para
                            utilizar el
                            Servicio Bainub para gestionar las relaciones con sus consumidores o usuarios del servicio.
                        </li>
                        <li><b>Dispositivo:</b> cualquier dispositivo conectado a Internet, como un teléfono, tablet,
                            computadora o cualquier otro dispositivo que se pueda usar para visitar Bainub y usar los
                            servicios.
                        </li>
                        <li><b>Dirección IP:</b> a cada dispositivo conectado a Internet se le asigna un número conocido
                            como dirección de protocolo de Internet (IP). Estos números generalmente se asignan en
                            bloques
                            geográficos. A menudo, se puede utilizar una dirección IP para identificar la ubicación
                            desde la
                            que un dispositivo se conecta a Internet.
                        </li>
                        <li><b>Personal:</b> se refiere a aquellas personas que son empleadas por Bainub o están bajo
                            contrato para realizar un servicio en nombre de una de las partes.
                        </li>
                        <li><b>Datos personales:</b> cualquier información que directa, indirectamente o en conexión con
                            otra información, incluido un número de identificación personal, permita la identificación
                            de
                            una persona física.
                        </li>
                        <li><b>Servicio:</b> se refiere al servicio brindado por Bainub como se describe en los términos
                            relativos (si están disponibles) y en esta plataforma.
                        </li>
                        <li><b>Terceros:</b> se refiere a anunciantes, patrocinadores de concursos, socios promocionales
                            y
                            de marketing, y otros que brindan nuestro contenido o cuyos productos o servicios que
                            creemos
                            que pueden interesarle.
                        </li>
                        <li><b>Sitio web:</b> el sitio de Bainub, al que se puede acceder a través de esta URL:
                            www.bainub.com
                        </li>
                        <li><b>Usted:</b> una persona o entidad que está registrada con Bainub para utilizar los
                            Servicios.
                        </li>
                    </div>

                    <div className="header privacy-policy-title">¿Qué información recopilamos?</div>
                    <div className="content privacy-policy-content">
                        Recopilamos información suya cuando visita nuestra plataforma, se registra en nuestro sitio,
                        realiza
                        un pedido, se suscribe a nuestro boletín, responde a una encuesta o completa un
                        formulario.<br/><br/>
                        <li>Nombre / nombre de usuario</li>
                        <li>Números de teléfono</li>
                        <li>Correos electrónicos</li>
                        <li>Direcciones de correo</li>
                        <li>Títulos de trabajo</li>
                        <li>Direcciones de facturación</li>
                        <li>Edad</li>
                        <li>Contraseña</li>
                        <br/><br/>
                        También recopilamos información de dispositivos móviles para una mejor experiencia de usuario,
                        aunque estas funciones son completamente opcionales:<br/><br/>
                        <li>Ubicación (GPS): los datos de ubicación ayudan a crear una representación precisa de sus
                            intereses, y esto se puede utilizar para llevar anuncios más específicos y relevantes a los
                            clientes potenciales.
                        </li>
                    </div>

                    <div className="header privacy-policy-title">¿Cómo usamos la información que recopilamos?</div>
                    <div className="content privacy-policy-content">
                        Cualquiera de la información que recopilamos de usted puede usarse de una de las siguientes
                        maneras:<br/><br/>
                        <li>Para personalizar su experiencia (su información nos ayuda a responder mejor a sus
                            necesidades
                            individuales)
                        </li>
                        <li>Para mejorar nuestra plataforma (nos esforzamos continuamente por mejorar lo que ofrece
                            nuestra
                            plataforma en función de la información y los comentarios que recibimos de usted)
                        </li>
                        <li>Para mejorar el servicio al cliente (su información nos ayuda a responder de manera más
                            efectiva
                            a sus solicitudes de servicio al cliente y necesidades de soporte)
                        </li>
                        <li>Para procesar transacciones</li>
                        <li>Para administrar un concurso, promoción, encuesta u otra característica del sitio</li>
                        <li>Para enviar correos electrónicos periódicos</li>
                    </div>

                    <div className="header privacy-policy-title">¿Cuándo usa Bainub la información del usuario final de
                        terceros?
                    </div>
                    <div className="content privacy-policy-content">
                        Bainub recopilará los datos del usuario final necesarios para proporcionar los servicios de
                        Bainub a
                        nuestros clientes.
                        Los usuarios finales pueden proporcionarnos voluntariamente la información que han puesto a
                        disposición en los sitios web de las
                        redes sociales. Si nos proporciona dicha información, podemos recopilar información disponible
                        públicamente de los sitios web de
                        redes sociales que ha indicado. Puede controlar la cantidad de información que los sitios web de
                        redes sociales hacen pública visitando
                        estos sitios web y cambiando su configuración de privacidad.
                    </div>

                    <div className="header privacy-policy-title">¿Cuándo usa Bainub la información del cliente de
                        terceros?
                    </div>
                    <div className="content privacy-policy-content">
                        Recibimos información de terceros cuando se comunica con nosotros. Por ejemplo, cuando nos envía
                        su
                        dirección de correo electrónico para mostrar interés en convertirse en cliente de Bainub,
                        recibimos
                        información de un tercero que brinda servicios automáticos de detección de fraude a Bainub.
                        Ocasionalmente, también recopilamos información que se pone a disposición del público en los
                        sitios
                        web de redes sociales. Puede controlar la cantidad de información que los sitios web de redes
                        sociales hacen pública visitando estos sitios web y cambiando su configuración de privacidad.
                    </div>

                    <div className="header privacy-policy-title">¿Compartimos la información que recopilamos con
                        terceros?
                    </div>
                    <div className="content privacy-policy-content">
                        Podemos compartir la información que recopilamos, tanto personal como no personal, con terceros
                        como
                        anunciantes, patrocinadores de concursos, socios promocionales y de marketing, y otros que
                        proporcionan nuestro contenido o cuyos productos o servicios creemos que pueden interesarle.
                        También
                        podemos compartirlo con nuestras compañías afiliadas y socios comerciales
                        actuales y futuros, y si estamos involucrados en una fusión, venta de activos u otra
                        reorganización
                        comercial, también podemos compartir o transferir su información personal y no personal a
                        nuestros
                        sucesores en interés.
                        Podemos contratar proveedores de servicios de terceros de confianza para que realicen funciones
                        y
                        nos brinden servicios, como el alojamiento y el mantenimiento de nuestros servidores y la
                        plataforma, almacenamiento y administración de bases de datos, administración de correo
                        electrónico,
                        marketing de almacenamiento, procesamiento de tarjetas de crédito, servicio y cumplimiento de
                        pedidos de productos y servicios que puede comprar a través de la plataforma. Es probable que
                        compartamos su información
                        personal, y posiblemente alguna información no personal, con estos terceros para permitirles
                        realizar estos servicios para nosotros y para usted.
                        Podemos compartir partes de los datos de nuestro archivo de registro, incluidas las direcciones
                        IP,
                        con fines analíticos con terceros, como socios de análisis web, desarrolladores de aplicaciones
                        y
                        redes publicitarias. Si se comparte su dirección IP, se puede utilizar para estimar la ubicación
                        general y otros datos tecnológicos, como la velocidad de conexión, si ha visitado la plataforma
                        en
                        una ubicación compartida y el tipo de dispositivo utilizado para visitar la plataforma. Pueden
                        agregar información sobre nuestra publicidad y lo que ve en la plataforma y luego proporcionar
                        auditorías, investigaciones e informes para nosotros y nuestros anunciantes.
                        También podemos divulgar información personal y no personal sobre usted al gobierno, a
                        funcionarios
                        encargados de hacer cumplir la ley o a terceros privados, según consideremos, a nuestro
                        exclusivo
                        criterio, necesario o apropiado para responder a reclamos, procesos legales (incluidas
                        citaciones),
                        para proteger nuestra derechos e intereses o los de un tercero, la seguridad del público o de
                        cualquier persona, para prevenir o detener cualquier actividad ilegal, poco ética o legalmente
                        procesable, o para cumplir con las órdenes judiciales, leyes, reglas y regulaciones aplicables.
                    </div>

                    <div className="header privacy-policy-title">¿Dónde y cuándo se recopila la información de los
                        clientes
                        y usuarios finales?
                    </div>
                    <div className="content privacy-policy-content">
                        Bainub recopilará la información personal que nos envíe. También podemos recibir información
                        personal sobre usted de terceros como se describe anteriormente.
                    </div>

                    <div className="header privacy-policy-title">¿Cómo utilizamos su dirección de correo electrónico?
                    </div>
                    <div className="content privacy-policy-content">
                        Al enviar su dirección de correo electrónico en esta plataforma, acepta recibir nuestros correos
                        electrónicos. Puede cancelar su
                        participación en cualquiera de estas listas de correo electrónico en cualquier momento haciendo
                        clic
                        en el enlace de exclusión
                        voluntaria u otra opción para cancelar la suscripción que se incluye en el correo electrónico
                        respectivo. Solo enviamos correos
                        electrónicos a personas que nos han autorizado a contactarlos, ya sea directamente o a través de
                        un
                        tercero. No enviamos correos
                        electrónicos comerciales no solicitados, porque odiamos el spam tanto como usted. Al enviar su
                        dirección de correo electrónico,
                        también acepta permitirnos usar su dirección de correo electrónico para la orientación de la
                        audiencia del cliente en sitios como
                        Facebook, donde mostramos publicidad personalizada a personas específicas que han optado por
                        recibir
                        nuestras comunicaciones.
                        Las direcciones de correo electrónico enviadas solo a través de la página de procesamiento de
                        pedidos se utilizarán con el único
                        propósito de enviarle información y actualizaciones relacionadas con su pedido. Sin embargo, si
                        nos
                        ha proporcionado el mismo correo
                        electrónico a través de otro método, podemos usarlo para cualquiera de los fines establecidos en
                        esta Política. Nota: Si en algún
                        momento desea cancelar la suscripción para no recibir correos electrónicos futuros, incluimos
                        instrucciones detalladas para cancelar
                        la suscripción en la parte inferior de cada correo electrónico.
                    </div>

                    <div className="header privacy-policy-title">¿Cuánto tiempo conservamos su información?</div>
                    <div className="content privacy-policy-content">
                        Conservamos su información solo mientras la necesitemos para proporcionarle Bainub y cumplir con
                        los
                        propósitos descritos en esta
                        política. Este también es el caso de cualquier persona con la que compartamos su información y
                        que
                        lleve a cabo servicios en nuestro
                        nombre. Cuando ya no necesitemos usar su información y no sea necesario que la conservemos para
                        cumplir con nuestras obligaciones
                        legales o reglamentarias, la eliminaremos de nuestros sistemas o la despersonalizaremos para que
                        no
                        podamos identificarlo.
                    </div>

                    <div className="header privacy-policy-title">¿Cómo protegemos su información?</div>
                    <div className="content privacy-policy-content">
                        Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información
                        personal cuando realiza un
                        pedido, ingresa, envía o accede a su información personal. Ofrecemos el uso de un servidor
                        seguro.
                        Toda la información confidencial
                        / crediticia suministrada se transmite a través de la tecnología Secure Socket Layer (SSL) y
                        luego
                        se encripta en nuestra base de datos
                        de proveedores de pasarela de pago solo para que sea accesible por aquellos autorizados con
                        derechos
                        especiales de acceso a dichos
                        sistemas, y deben mantener la información confidencial. Después de una transacción, su
                        información
                        privada (tarjetas de crédito,
                        números de seguro social, finanzas, etc.) nunca se archiva. Sin embargo, no podemos garantizar
                        la
                        seguridad absoluta de la información
                        que transmita a Bainub ni garantizar que su información en el servicio no sea accedida,
                        divulgada,
                        alterada o destruida por una
                        infracción de cualquiera de nuestras condiciones físicas, salvaguardias técnicas o de gestión.
                    </div>

                    <div className="header privacy-policy-title">¿Podría transferirse mi información a otros países?
                    </div>
                    <div className="content privacy-policy-content">
                        Bainub está incorporada en México. La información recopilada a través de nuestro sitio web, a
                        través
                        de interacciones directas con
                        usted o del uso de nuestros servicios de ayuda puede transferirse de vez en cuando a nuestras
                        oficinas o personal, o a terceros,
                        ubicados en todo el mundo, y puede verse y alojarse en cualquier lugar del mundo, incluidos los
                        países que pueden no tener leyes de
                        aplicación general que regulen el uso y la transferencia de dichos datos. En la mayor medida
                        permitida por la ley aplicable, al utilizar
                        cualquiera de los anteriores, usted acepta voluntariamente la transferencia transfronteriza y el
                        alojamiento de dicha información.
                    </div>

                    <div className="header privacy-policy-title">¿La información recopilada a través del Servicio Bainub
                        es
                        segura?
                    </div>
                    <div className="content privacy-policy-content">
                        Tomamos precauciones para proteger la seguridad de su información. Contamos con procedimientos
                        físicos, electrónicos y
                        administrativos para ayudar a salvaguardar, prevenir el acceso no autorizado, mantener la
                        seguridad
                        de los datos y usar correctamente
                        su información. Sin embargo, ni las personas ni los sistemas de seguridad son infalibles,
                        incluidos
                        los sistemas de cifrado. Además, las
                        personas pueden cometer delitos intencionales, cometer errores o no seguir las políticas. Por lo
                        tanto, aunque hacemos todos los
                        esfuerzos razonables para proteger su información personal, no podemos garantizar su seguridad
                        absoluta. Si la ley aplicable impone
                        algún deber irrenunciable de proteger su información personal, usted acepta que la mala conducta
                        intencional serán los estándares
                        utilizados para medir nuestro cumplimiento con ese deber.
                    </div>

                    <div className="header privacy-policy-title">¿Puedo actualizar o corregir mi información?</div>
                    <div className="content privacy-policy-content">
                        Los derechos que tienes para solicitar actualizaciones o correcciones de la información que
                        recopila
                        Bainub dependen de tu relación
                        con Bainub. El personal puede actualizar o corregir su información según se detalla en nuestras
                        políticas de empleo internas de la
                        empresa.
                        Los clientes tienen derecho a solicitar la restricción de ciertos usos y divulgaciones de
                        información de identificación personal de la
                        siguiente manera. Puede comunicarse con nosotros para (1) actualizar o corregir su información
                        de
                        identificación personal, (2) cambiar
                        sus preferencias con respecto a las comunicaciones y otra información que recibe de nosotros, o
                        (3)
                        eliminar la información de
                        identificación personal que se mantiene sobre usted en nuestro sistema (sujeto al siguiente
                        párrafo), cancelando su cuenta. Dichas
                        actualizaciones, correcciones, cambios y eliminaciones no tendrán ningún efecto sobre otra
                        información que mantenemos o
                        información que hayamos proporcionado a terceros de acuerdo con esta Política de privacidad
                        antes de
                        dicha actualización,
                        corrección, cambio o eliminación. Para proteger su privacidad y seguridad, podemos tomar medidas
                        razonables (como solicitar una
                        contraseña única) para verificar su identidad antes de otorgarle acceso a su perfil o hacer
                        correcciones. Usted es responsable de
                        mantener en secreto su contraseña única y la información de su cuenta en todo momento.
                        Debe tener en cuenta que tecnológicamente no es posible eliminar todos y cada uno de los
                        registros
                        de la información que nos ha
                        proporcionado de nuestro sistema. La necesidad de realizar copias de seguridad de nuestros
                        sistemas
                        para proteger la información de
                        pérdidas involuntarias significa que puede existir una copia de su información en una forma que
                        no
                        se pueda borrar y que será difícil
                        o imposible de localizar para nosotros. Inmediatamente después de recibir su solicitud, toda la
                        información personal almacenada en
                        las bases de datos que usamos activamente y otros medios fácilmente buscables se actualizará,
                        corregirá, cambiará o eliminará, según
                        corresponda, tan pronto como y en la medida en que sea razonable y técnicamente posible.
                        Si es un usuario final y desea actualizar, eliminar o recibir cualquier información que tengamos
                        sobre usted, puede hacerlo poniéndose
                        en contacto con la organización de la que es cliente.
                    </div>

                    <div className="header privacy-policy-title">Personal</div>
                    <div className="content privacy-policy-content">
                        Si es un trabajador o solicitante de Bainub, recopilamos la información que nos proporciona
                        voluntariamente. Usamos la información
                        recopilada con fines de recursos humanos para administrar los beneficios a los trabajadores y
                        seleccionar a los solicitantes.
                        Puede comunicarse con nosotros para (1) actualizar o corregir su información, (2) cambiar sus
                        preferencias con respecto a las
                        comunicaciones y otra información que reciba de nosotros, o (3) recibir un registro de la
                        información que tenemos relacionada con
                        usted. Dichas actualizaciones, correcciones, cambios y eliminaciones no tendrán ningún efecto
                        sobre
                        otra información que
                        mantenemos o información que hayamos proporcionado a terceros de acuerdo con esta Política de
                        privacidad antes de dicha
                        actualización, corrección, cambio o eliminación.
                    </div>

                    <div className="header privacy-policy-title">Venta de Negocio</div>
                    <div className="content privacy-policy-content">
                        Nos reservamos el derecho de transferir información a un tercero en el caso de una venta, fusión
                        u
                        otra transferencia de todos o
                        sustancialmente todos los activos de Bainub o cualquiera de sus afiliadas corporativas (como se
                        define en este documento), o la
                        porción de Bainub o cualquiera de sus Afiliadas corporativas con las que se relaciona el
                        Servicio, o
                        en el caso de que discontinuemos
                        nuestro negocio o presentemos una petición o hayamos presentado una petición contra nosotros en
                        caso
                        de quiebra, reorganización
                        o procedimiento similar, siempre que el el tercero acepte adherirse a los términos de esta
                        Política
                        de privacidad.
                    </div>

                    <div className="header privacy-policy-title">Afiliados</div>
                    <div className="content privacy-policy-content">
                        Podemos divulgar información (incluida la información personal) sobre usted a nuestros afiliados
                        corporativos. Para los propósitos de
                        esta Política de Privacidad, "Afiliado Corporativo" significa cualquier persona o entidad que
                        directa o indirectamente controla, está
                        controlada por o está bajo control común con Bainub, ya sea por propiedad o de otra manera.
                        Cualquier información relacionada con
                        usted que proporcionemos a nuestros afiliados corporativos será tratada por dichos afiliados
                        corporativos de acuerdo con los términos
                        de esta política de privacidad.
                    </div>

                    <div className="header privacy-policy-title">Ley que Rige</div>
                    <div className="content privacy-policy-content">
                        Esta Política de privacidad se rige por las leyes de México sin tener en cuenta su disposición
                        sobre
                        conflicto de leyes. Usted acepta la
                        jurisdicción exclusiva de los tribunales en relación con cualquier acción o disputa que surja
                        entre
                        las partes en virtud de esta Política
                        de privacidad o en relación con ella, excepto aquellas personas que puedan tener derecho a
                        presentar
                        reclamaciones en virtud del
                        Escudo de privacidad o el marco suizo-estadounidense.
                        Las leyes de México, excluyendo sus conflictos de leyes, regirán este Acuerdo y su uso de la
                        plataforma. Su uso de la plataforma
                        también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales.
                        Al usar Bainub o comunicarse con nosotros directamente, significa que acepta esta Política de
                        privacidad. Si no está de acuerdo con
                        esta Política de privacidad, no debe interactuar con nuestro sitio web ni utilizar nuestros
                        servicios. El uso continuo del sitio web, la
                        interacción directa con nosotros o después de la publicación de cambios en esta Política de
                        privacidad que no afecten
                        significativamente el uso o divulgación de su información personal significará que acepta esos
                        cambios.
                    </div>

                    <div className="header privacy-policy-title">Tu consentimiento</div>
                    <div className="content privacy-policy-content">
                        Hemos actualizado nuestra Política de privacidad para brindarle total transparencia sobre lo que
                        se
                        establece cuando visita nuestro
                        sitio y cómo se utiliza. Al utilizar nuestra plataforma, registrar una cuenta o realizar una
                        compra,
                        por la presente acepta nuestra Política
                        de privacidad y acepta sus términos.
                    </div>

                    <div className="header privacy-policy-title">Enlaces a otros Sitios Web</div>
                    <div className="content privacy-policy-content">
                        Esta Política de privacidad se aplica solo a los Servicios. Los Servicios pueden contener
                        enlaces a
                        otros sitios web que Bainub no opera
                        ni controla. No somos responsables por el contenido, la precisión o las opiniones expresadas en
                        dichos sitios web, y dichos sitios web
                        no son investigados, monitoreados o verificados por nuestra precisión o integridad. Recuerde que
                        cuando utiliza un enlace para ir
                        desde los Servicios a otro sitio web, nuestra Política de privacidad deja de estar en vigor. Su
                        navegación e interacción en cualquier otro
                        sitio web, incluidos aquellos que tienen un enlace en nuestra plataforma, están sujetos a las
                        propias reglas y políticas de ese sitio web.
                        Dichos terceros pueden utilizar sus propias cookies u otros métodos para recopilar información
                        sobre
                        usted.
                    </div>

                    <div className="header privacy-policy-title">Publicidad</div>
                    <div className="content privacy-policy-content">
                        Esta plataforma puede contener anuncios de terceros y enlaces a sitios de terceros. Bainub no
                        hace
                        ninguna representación en cuanto
                        a la exactitud o idoneidad de la información contenida en esos anuncios o sitios y no acepta
                        ninguna
                        responsabilidad u obligación por
                        la conducta o el contenido de esos anuncios y sitios y las ofertas realizadas por los terceros.
                        La publicidad mantiene a Bainub y muchos de los sitios web y servicios que usted utiliza de
                        forma
                        gratuita. Trabajamos arduamente
                        para asegurarnos de que los anuncios sean seguros, discretos y lo más relevantes posible.
                        Los anuncios de terceros y los enlaces a otros sitios donde se anuncian bienes o servicios no
                        son
                        respaldos ni recomendaciones de
                        Bainub de los sitios, bienes o servicios de terceros. Bainub no asume ninguna responsabilidad
                        por el
                        contenido de los anuncios, las
                        promesas hechas o la calidad / confiabilidad de los productos o servicios ofrecidos en todos los
                        anuncios.
                    </div>

                    <div className="header privacy-policy-title">Cookies para Publicidad</div>
                    <div className="content privacy-policy-content">
                        Estas cookies recopilan información a lo largo del tiempo sobre su actividad en línea en la
                        plataforma y otros servicios en línea para
                        hacer que los anuncios en línea sean más relevantes y efectivos para usted. Esto se conoce como
                        publicidad basada en intereses.
                        También realizan funciones como evitar que el mismo anuncio vuelva a aparecer continuamente y
                        garantizar que los anuncios se
                        muestren correctamente a los anunciantes. Sin cookies, es muy difícil para un anunciante llegar
                        a su
                        audiencia o saber cuántos
                        anuncios se mostraron y cuántos clics recibieron.
                    </div>

                    <div className="header privacy-policy-title">Cookies</div>
                    <div className="content privacy-policy-content">
                        Bainub utiliza "cookies" para identificar las áreas de nuestro sitio web que ha visitado. Una
                        cookie
                        es una pequeña porción de datos
                        que su navegador web almacena en su computadora o dispositivo móvil. Usamos cookies para mejorar
                        el
                        rendimiento y la
                        funcionalidad de nuestra plataforma, pero no son esenciales para su uso. Sin embargo, sin estas
                        cookies, es posible que ciertas
                        funciones, como los videos, no estén disponibles o se le solicitará que ingrese sus datos de
                        inicio
                        de sesión cada vez que visite la
                        plataforma, ya que no podríamos recordar que había iniciado sesión anteriormente. La mayoría de
                        los
                        navegadores web se pueden
                        configurar para desactivar el uso de cookies. Sin embargo, si desactiva las cookies, es posible
                        que
                        no pueda acceder a la funcionalidad
                        de nuestro sitio web correctamente o en absoluto. Nunca colocamos información de identificación
                        personal en cookies.
                    </div>

                    <div className="header privacy-policy-title">Bloquear y deshabilitar Cookies y tecnologías similares
                    </div>
                    <div className="content privacy-policy-content">
                        Donde sea que se encuentre, también puede configurar su navegador para bloquear cookies y
                        tecnologías similares, pero esta acción
                        puede bloquear nuestras cookies esenciales e impedir que nuestro sitio web funcione
                        correctamente, y
                        es posible que no pueda
                        utilizar todas sus funciones y servicios por completo. También debe tener en cuenta que también
                        puede perder información guardada
                        (por ejemplo, detalles de inicio de sesión guardados, preferencias del sitio) si bloquea las
                        cookies
                        en su navegador. Los distintos
                        navegadores ponen a su disposición distintos controles. Deshabilitar una cookie o una categoría
                        de
                        cookie no elimina la cookie de su
                        navegador, deberá hacerlo usted mismo desde su navegador, debe visitar el menú de ayuda de su
                        navegador para obtener más
                        información.
                    </div>

                    <div className="header privacy-policy-title">Servicios de Remarketing</div>
                    <div className="content privacy-policy-content">
                        Usamos servicios de remarketing. ¿Qué es el remarketing? En marketing digital, el remarketing (o
                        retargeting) es la práctica de publicar
                        anuncios en Internet a personas que ya han visitado su sitio web. Permite que su empresa parezca
                        que
                        está "siguiendo" a las personas
                        en Internet mediante la publicación de anuncios en los sitios web y las plataformas que más
                        utilizan.
                    </div>

                    <div className="header privacy-policy-title">Privacidad de los Niños</div>
                    <div className="content privacy-policy-content">
                        No nos dirigimos a ninguna persona menor de 13 años. No recopilamos información de
                        identificación
                        personal de ninguna persona
                        menor de 13 años. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado Datos
                        personales, comuníquese con Nos. Si nos
                        damos cuenta de que hemos recopilado datos personales de cualquier persona menor de 13 años sin
                        la
                        verificación del consentimiento
                        de los padres, tomamos medidas para eliminar esa información de nuestros servidores.
                    </div>

                    <div className="header privacy-policy-title">Cambios en nuestra Política de Privacidad</div>
                    <div className="content privacy-policy-content">
                        Podemos cambiar nuestro Servicio y nuestras políticas, y es posible que debamos realizar cambios
                        en
                        esta Política de privacidad para
                        que reflejen con precisión nuestro Servicio y nuestras políticas. A menos que la ley exija lo
                        contrario, le notificaremos (por ejemplo, a
                        través de nuestro Servicio) antes de realizar cambios en esta Política de privacidad y le
                        daremos la
                        oportunidad de revisarlos antes de
                        que entren en vigencia. Luego, si continúa utilizando el Servicio, estará sujeto a la Política
                        de
                        privacidad actualizada. Si no desea aceptar
                        esta o cualquier Política de privacidad actualizada, puede eliminar su cuenta.
                    </div>

                    <div className="header privacy-policy-title">Servicios de terceros</div>
                    <div className="content privacy-policy-content">
                        Podemos mostrar, incluir o poner a disposición contenido de terceros (incluidos datos,
                        información,
                        aplicaciones y otros servicios de
                        productos) o proporcionar enlaces a sitios web o servicios de terceros ("Servicios de
                        terceros").
                        Usted reconoce y acepta que Bainub no será responsable de ningún Servicio de terceros, incluida
                        su
                        precisión, integridad, puntualidad,
                        validez, cumplimiento de los derechos de autor, legalidad, decencia, calidad o cualquier otro
                        aspecto de los mismos. Bainub no asume
                        ni tendrá ninguna obligación o responsabilidad ante usted o cualquier otra persona o entidad por
                        los
                        Servicios de terceros.
                        Los Servicios de terceros y los enlaces a los mismos se brindan únicamente para su conveniencia
                        y
                        usted accede a ellos y los usa
                        completamente bajo su propio riesgo y sujeto a los términos y condiciones de dichos terceros.
                    </div>

                    <div className="header privacy-policy-title">Tecnologías de Seguimiento</div>
                    <div className="content privacy-policy-content">
                        <b>API de Google Maps: </b>
                        La API de Google Maps es una herramienta sólida que se puede utilizar para crear un mapa
                        personalizado, un mapa de búsqueda,
                        funciones de registro, mostrar la sincronización de datos en vivo con la ubicación, planificar
                        rutas
                        o crear un mashup, solo por nombrar
                        algunos.
                        La API de Google Maps puede recopilar información suya y de su dispositivo por motivos de
                        seguridad.
                        La API de Google Maps recopila información que se mantiene de acuerdo con su Política de
                        privacidad. <br/>
                        <b>Cookies: </b>
                        Usamos Cookies para mejorar el rendimiento y la funcionalidad de nuestra plataforma, pero no son
                        esenciales para su uso. Sin
                        embargo, sin estas cookies, es posible que ciertas funciones, como los videos, no estén
                        disponibles
                        o se le solicitará que ingrese sus
                        datos de inicio de sesión cada vez que visite la plataforma, ya que no podríamos recordar que
                        había
                        iniciado sesión anteriormente.<br/>
                        <b>Sesiones: </b>
                        Bainub utiliza "Sesiones" para identificar las áreas de nuestro sitio web que ha visitado. Una
                        sesión es un pequeño fragmento de
                        datos que su navegador web almacena en su computadora o dispositivo móvil.
                    </div>

                    <div className="header privacy-policy-title">Residentes de California</div>
                    <div className="content privacy-policy-content">
                        La Ley de Privacidad del Consumidor de California (CCPA) requiere que divulguemos las categorías
                        de
                        Información personal que
                        recopilamos y cómo la usamos, las categorías de fuentes de las que recopilamos Información
                        personal
                        y los terceros con quienes la
                        compartimos, que hemos explicado anteriormente.
                        También estamos obligados a comunicar información sobre los derechos que tienen los residentes
                        de
                        California según la ley de
                        California. Puede ejercer los siguientes derechos:
                        <li>Derecho a conocer y acceder. Puede enviar una solicitud verificable de información con
                            respecto
                            a: (1) categorías de Información
                            personal que recopilamos, usamos o compartimos; (2) fines para los que recopilamos o
                            utilizamos
                            categorías de información personal;
                            (3) categorías de fuentes de las que recopilamos información personal; y (4) piezas
                            específicas
                            de información personal que hemos
                            recopilado sobre usted.</li>
                        <li>Derecho a la igualdad de servicio. No lo discriminaremos si ejerce sus derechos de
                            privacidad.
                        </li>
                        <li>Derecho a eliminar. Puede enviar una solicitud verificable para cerrar su cuenta y
                            eliminaremos
                            la Información personal sobre usted
                            que hayamos recopilado.
                        </li>
                        <li>Solicite que una empresa que vende los datos personales de un consumidor, no venda los datos
                            personales del consumidor.
                        </li>
                        Si realiza una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos
                        derechos, comuníquese con nosotros.
                        No vendemos la información personal de nuestros usuarios.
                        Para obtener más información sobre estos derechos, comuníquese con nosotros.
                    </div>

                    <div className="header privacy-policy-title">Contáctenos</div>
                    <div className="content privacy-policy-content">
                        No dude en contactarnos si tiene alguna pregunta.
                        <li>A través de correo electrónico: <b>customerservice@bainub.com</b></li>
                    </div>
                </>
            </Dialog.Body>
        </Dialog>)

    if (language === "en") {
        dialog = (
            <Dialog visible={isPrivacyPolicyVisible}
                    onCancel={() => setIsPrivacyPolicyVisible(false)}
                    lockScroll={false}>

                <Dialog.Body className='dialog-body-footer'>
                    <>
                        <div className="header privacy-policy-title">Privacy Policy</div>
                        <div className="content privacy-policy-content">
                            Bainub (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy
                            explains how your personal information is collected, used, and disclosed by Bainub.<br/>
                            This Privacy Policy applies to our website, and its associated subdomains (collectively, our
                            “Service”) alongside our application, Bainub. By accessing or using our Service, you signify
                            that you have read, understood, and agree to our collection, storage, use, and disclosure of
                            your personal information as described in this Privacy Policy and our Terms of Service.
                        </div>

                        <div className="header privacy-policy-title">Definitions and key terms</div>
                        <div className="content privacy-policy-content">
                            To help explain things as clearly as possible in this Privacy Policy, every time any of
                            these
                            terms are referenced, are strictly
                            defined as:
                            <li><b>Cookie:</b> small amount of data generated by a website and saved by your web
                                browser. It
                                is used to identify your browser,
                                provide analytics, remember information about you such as your language preference or
                                login
                                information.</li>
                            <li><b>Company:</b> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to
                                Bainub S.A. de C.V., (Prolongación las Torres
                                138 Regio Parque Industrial Santa Catarina, Nuevo Leon 66367, Mexico) that is
                                responsible
                                for your information under this
                                Privacy Policy.
                            </li>
                            <li><b>Country:</b> where Bainub or the owners/founders of Bainub are based, in this case is
                                México
                            </li>
                            <li><b>Customer:</b> refers to the company, organization or person that signs up to use the
                                Bainub Service to manage the relationships
                                with your consumers or service users.
                            </li>
                            <li><b>Device:</b> any internet connected device such as a phone, tablet, computer or any
                                other
                                device that can be used to visit Bainub
                                and use the services.
                            </li>
                            <li><b>IP address:</b> Every device connected to the Internet is assigned a number known as
                                an
                                Internet protocol (IP) address. These
                                numbers are usually assigned in geographic blocks. An IP address can often be used to
                                identify the location from which a device
                                is connecting to the Internet.
                            </li>
                            <li><b>Personnel:</b> refers to those individuals who are employed by Bainub or are under
                                contract to perform a service on behalf of
                                one of the parties.
                            </li>
                            <li><b>Personal Data:</b> any information that directly, indirectly, or in connection with
                                other
                                information — including a personal
                                identification number — allows for the identification or identifiability of a natural
                                person.
                            </li>
                            <li><b>Service:</b> refers to the service provided by Bainub as described in the relative
                                terms
                                (if available) and on this platform.
                            </li>
                            <li><b>Third-party service:</b> refers to advertisers, contest sponsors, promotional and
                                marketing partners, and others who provide our
                                content or whose products or services we think may interest you.
                            </li>
                            <li><b>Website:</b> Bainub."’s" site, which can be accessed via this URL: www.bainub.com
                            </li>
                            <li><b>You:</b> a person or entity that is registered with Bainub to use the Services.</li>
                        </div>

                        <div className="header privacy-policy-title">What Information Do We Collect?</div>
                        <div className="content privacy-policy-content">
                            We collect information from you when you visit our website, register on our site, place an
                            order, subscribe to our newsletter,
                            respond to a survey or fill out a form.
                            <li>Name / Username</li>
                            <li>Phone Numbers</li>
                            <li>Email Addresses</li>
                            <li>Mailing Addresses</li>
                            <li>Job Titles</li>
                            <li>Billing Addresses</li>
                            <li>Age</li>
                            <li>Password</li>
                            <br/><br/>
                            We also collect information from mobile devices for a better user experience, although these
                            features are completely optional:
                            <li>Location (GPS): Location data helps create an accurate representation of your interests,
                                and
                                this can be used to deliver
                                more targeted and relevant ads to potential customers.</li>
                        </div>

                        <div className="header privacy-policy-title">How Do We Use The Information We Collect?</div>
                        <div className="content privacy-policy-content">
                            Any of the information we collect from you may be used in one of the following ways:
                            <li>To personalize your experience (your information helps us to better respond to your
                                individual needs)</li>
                            <li>To improve our website (we continually strive to improve our website offerings based on
                                the
                                information and feedback we
                                receive from you)
                            </li>
                            <li>To improve customer service (your information helps us to more effectively respond to
                                your
                                customer service requests and
                                support needs)
                            </li>
                            <li>To process transactions</li>
                            <li>To administer a contest, promotion, survey or other site feature</li>
                            <li>To send periodic emails</li>
                        </div>

                        <div className="header privacy-policy-title">When does Bainub use end user information from
                            third
                            parties?
                        </div>
                        <div className="content privacy-policy-content">
                            Bainub will collect End User Data necessary to provide the Bainub services to our
                            customers.<br/>
                            End users may voluntarily provide us with information they have made available on social
                            media
                            websites. If you provide us
                            with any such information, we may collect publicly available information from the social
                            media
                            websites you have indicated.<br/>
                            You can control how much of your information social media websites make public by visiting
                            these
                            websites and changing your
                            privacy settings.
                        </div>

                        <div className="header privacy-policy-title">When does Bainub use customer information from
                            third
                            parties?
                        </div>
                        <div className="content privacy-policy-content">
                            We receive some information from the third parties when you contact us. For example, when
                            you
                            submit your email address to
                            us to show interest in becoming a Bainub customer, we receive information from a third party
                            that provides automated fraud
                            detection services to Bainub. We also occasionally collect information that is made publicly
                            available on social media websites.<br/>
                            You can control how much of your information social media websites make public by visiting
                            these
                            websites and changing your
                            privacy settings.
                        </div>

                        <div className="header privacy-policy-title">Do we share the information we collect with third
                            parties?
                        </div>
                        <div className="content privacy-policy-content">
                            We may share the information that we collect, both personal and non-personal, with third
                            parties
                            such as advertisers, contest
                            sponsors, promotional and marketing partners, and others who provide our content or whose
                            products or services we think
                            may interest you. We may also share it with our current and future affiliated companies and
                            business partners, and if we are
                            involved in a merger, asset sale or other business reorganization, we may also share or
                            transfer
                            your personal and non-personal
                            information to our successors-in-interest.<br/><br/>
                            We may engage trusted third party service providers to perform functions and provide
                            services to
                            us, such as hosting and
                            maintaining our servers and the website, database storage and management, e-mail management,
                            storage marketing, credit
                            card processing, customer service and fulfilling orders for products and services you may
                            purchase through the website. We will
                            likely share your personal information, and possibly some non-personal information, with
                            these
                            third parties to enable them to
                            perform these services for us and for you.<br/><br/>
                            We may share portions of our log file data, including IP addresses, for analytics purposes
                            with
                            third parties such as web analytics
                            partners, application developers, and ad networks. If your IP address is shared, it may be
                            used
                            to estimate general location and
                            other technographics such as connection speed, whether you have visited the website in a
                            shared
                            location, and type of the
                            device used to visit the website. They may aggregate information about our advertising and
                            what
                            you see on the website and
                            then provide auditing, research and reporting for us and our advertisers.<br/><br/>
                            We may also disclose personal and non-personal information about you to government or law
                            enforcement officials or private
                            parties as we, in our sole discretion, believe necessary or appropriate in order to respond
                            to
                            claims, legal process (including
                            subpoenas), to protect our rights and interests or those of a third party, the safety of the
                            public or any person, to prevent or
                            stop any illegal, unethical, or legally actionable activity, or to otherwise comply with
                            applicable court orders, laws, rules and
                            regulations.
                        </div>

                        <div className="header privacy-policy-title">Where and when is information collected from
                            customers
                            and end users?
                        </div>
                        <div className="content privacy-policy-content">
                            Bainub will collect personal information that you submit to us. We may also receive personal
                            information about you from third
                            parties as described above.
                        </div>

                        <div className="header privacy-policy-title">How Do We Use Your Email Address?</div>
                        <div className="content privacy-policy-content">
                            By submitting your email address on this website, you agree to receive emails from us. You
                            can
                            cancel your participation in any
                            of these email lists at any time by clicking on the opt-out link or other unsubscribe option
                            that is included in the respective
                            email. We only send emails to people who have authorized us to contact them, either
                            directly, or
                            through a third party. We do
                            not send unsolicited commercial emails, because we hate spam as much as you do. By
                            submitting
                            your email address, you also
                            agree to allow us to use your email address for customer audience targeting on sites like
                            Facebook, where we display custom
                            advertising to specific people who have opted-in to receive communications from us. Email
                            addresses submitted only through
                            the order processing page will be used for the sole purpose of sending you information and
                            updates pertaining to your order.
                            If, however, you have provided the same email to us through another method, we may use it
                            for
                            any of the purposes stated in
                            this Policy. Note: If at any time you would like to unsubscribe from receiving future
                            emails, we
                            include detailed unsubscribe
                            instructions at the bottom of each email.
                        </div>

                        <div className="header privacy-policy-title">How Long Do We Keep Your Information?</div>
                        <div className="content privacy-policy-content">
                            We keep your information only so long as we need it to provide Bainub to you and fulfill the
                            purposes described in this policy.<br/>
                            This is also the case for anyone that we share your information with and who carries out
                            services on our behalf. When we no
                            longer need to use your information and there is no need for us to keep it to comply with
                            our
                            legal or regulatory obligations,
                            we’ll either remove it from our systems or depersonalize it so that we can't identify you.
                        </div>

                        <div className="header privacy-policy-title">How Do We Protect Your Information?</div>
                        <div className="content privacy-policy-content">
                            We implement a variety of security measures to maintain the safety of your personal
                            information
                            when you place an order or
                            enter, submit, or access your personal information. We offer the use of a secure server. All
                            supplied sensitive/credit information
                            is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment
                            gateway providers database only
                            to be accessible by those authorized with special access rights to such systems, and are
                            required to keep the information
                            confidential. After a transaction, your private information (credit cards, social security
                            numbers, financials, etc.) is never kept
                            on file. We cannot, however, ensure or warrant the absolute security of any information you
                            transmit to Bainub or guarantee
                            that your information on the Service may not be accessed, disclosed, altered, or destroyed
                            by a
                            breach of any of our physical,
                            technical, or managerial safeguards.
                        </div>

                        <div className="header privacy-policy-title">Could my information be transferred to other
                            countries?
                        </div>
                        <div className="content privacy-policy-content">
                            Bainub is incorporated in México. Information collected via our website, through direct
                            interactions with you, or from use of
                            our help services may be transferred from time to time to our offices or personnel, or to
                            third
                            parties, located throughout the
                            world, and may be viewed and hosted anywhere in the world, including countries that may not
                            have
                            laws of general applicability
                            regulating the use and transfer of such data. To the fullest extent allowed by applicable
                            law,
                            by using any of the above, you
                            voluntarily consent to the trans-border transfer and hosting of such information.
                        </div>

                        <div className="header privacy-policy-title">Is the information collected through the Bainub
                            Service
                            secure?
                        </div>
                        <div className="content privacy-policy-content">
                            We take precautions to protect the security of your information. We have physical,
                            electronic,
                            and managerial procedures to
                            help safeguard, prevent unauthorized access, maintain data security, and correctly use your
                            information. However, neither
                            people nor security systems are foolproof, including encryption systems. In addition, people
                            can
                            commit intentional crimes,
                            make mistakes or fail to follow policies. Therefore, while we use reasonable efforts to
                            protect
                            your personal information, we
                            cannot guarantee its absolute security. If applicable law imposes any non-disclaimable duty
                            to
                            protect your personal
                            information, you agree that intentional misconduct will be the standards used to measure our
                            compliance with that duty.
                        </div>

                        <div className="header privacy-policy-title">Can I update or correct my information?</div>
                        <div className="content privacy-policy-content">
                            The rights you have to request updates or corrections to the information Bainub collects
                            depend
                            on your relationship with
                            Bainub. Personnel may update or correct their information as detailed in our internal
                            company
                            employment policies.<br/>
                            Customers have the right to request the restriction of certain uses and disclosures of
                            personally identifiable information as
                            follows. You can contact us in order to (1) update or correct your personally identifiable
                            information, (2) change your
                            preferences with respect to communications and other information you receive from us, or (3)
                            delete the personally identifiable
                            information maintained about you on our systems (subject to the following paragraph), by
                            cancelling your account. Such
                            updates, corrections, changes and deletions will have no effect on other information that we
                            maintain, or information that we
                            have provided to third parties in accordance with this Privacy Policy prior to such update,
                            correction, change or deletion. To
                            protect your privacy and security, we may take reasonable steps (such as requesting a unique
                            password) to verify your identity
                            before granting you profile access or making corrections. You are responsible for
                            maintaining
                            the secrecy of your unique
                            password and account information at all times.<br/><br/>
                            You should be aware that it is not technologically possible to remove each and every record
                            of
                            the information you have
                            provided to us from our system. The need to back up our systems to protect information from
                            inadvertent loss means that a
                            copy of your information may exist in a non-erasable form that will be difficult or
                            impossible
                            for us to locate. Promptly after
                            receiving your request, all personal information stored in databases we actively use, and
                            other
                            readily searchable media will be
                            updated, corrected, changed or deleted, as appropriate, as soon as and to the extent
                            reasonably
                            and technically practicable.<br/>
                            If you are an end user and wish to update, delete, or receive any information we have about
                            you,
                            you may do so by contacting
                            the organization of which you are a customer.
                        </div>

                        <div className="header privacy-policy-title">Personnel</div>
                        <div className="content privacy-policy-content">
                            If you are a Bainub worker or applicant, we collect information you voluntarily provide to
                            us.
                            We use the information collected
                            for Human Resources purposes in order to administer benefits to workers and screen
                            applicants.<br/><br/>
                            You may contact us in order to (1) update or correct your information, (2) change your
                            preferences with respect to
                            communications and other information you receive from us, or (3) receive a record of the
                            information we have relating to you.<br/>
                            Such updates, corrections, changes and deletions will have no effect on other information
                            that
                            we maintain, or information that
                            we have provided to third parties in accordance with this Privacy Policy prior to such
                            update,
                            correction, change or deletion.
                        </div>

                        <div className="header privacy-policy-title">Sale of Business</div>
                        <div className="content privacy-policy-content">
                            We reserve the right to transfer information to a third party in the event of a sale, merger
                            or
                            other transfer of all or substantially
                            all of the assets of Bainub or any of its Corporate Affiliates (as defined herein), or that
                            portion of Bainub or any of its Corporate
                            Affiliates to which the Service relates, or in the event that we discontinue our business or
                            file a petition or have filed against us
                            a petition in bankruptcy, reorganization or similar proceeding, provided that the third
                            party
                            agrees to adhere to the terms of
                            this Privacy Policy.
                        </div>

                        <div className="header privacy-policy-title">Affiliates</div>
                        <div className="content privacy-policy-content">
                            We may disclose information (including personal information) about you to our Corporate
                            Affiliates. For purposes of this Privacy
                            Policy, "Corporate Affiliate" means any person or entity which directly or indirectly
                            controls,
                            is controlled by or is under common
                            control with Bainub, whether by ownership or otherwise. Any information relating to you that
                            we
                            provide to our Corporate
                            Affiliates will be treated by those Corporate Affiliates in accordance with the terms of
                            this
                            Privacy Policy.
                        </div>

                        <div className="header privacy-policy-title">Governing Law</div>
                        <div className="content privacy-policy-content">
                            This Privacy Policy is governed by the laws of México without regard to its conflict of laws
                            provision. You consent to the exclusive
                            jurisdiction of the courts in connection with any action or dispute arising between the
                            parties
                            under or in connection with this
                            Privacy Policy except for those individuals who may have rights to make claims under Privacy
                            Shield, or the Swiss-US framework.
                            The laws of México, excluding its conflicts of law rules, shall govern this Agreement and
                            your
                            use of the website. Your use of the
                            website may also be subject to other local, state, national, or international
                            laws.<br/><br/>
                            By using Bainub or contacting us directly, you signify your acceptance of this Privacy
                            Policy.
                            If you do not agree to this Privacy
                            Policy, you should not engage with our website, or use our services. Continued use of the
                            website, direct engagement with us,
                            or following the posting of changes to this Privacy Policy that do not significantly affect
                            the
                            use or disclosure of your personal
                            information will mean that you accept those changes.
                        </div>

                        <div className="header privacy-policy-title">Your Consent</div>
                        <div className="content privacy-policy-content">
                            We've updated our Privacy Policy to provide you with complete transparency into what is
                            being
                            set when you visit our site and
                            how it's being used. By using our website, registering an account, or making a purchase, you
                            hereby consent to our Privacy
                            Policy and agree to its terms.
                        </div>

                        <div className="header privacy-policy-title">Links to Other Websites</div>
                        <div className="content privacy-policy-content">
                            This Privacy Policy applies only to the Services. The Services may contain links to other
                            websites not operated or controlled by
                            Bainub. We are not responsible for the content, accuracy or opinions expressed in such
                            websites,
                            and such websites are not
                            investigated, monitored or checked for accuracy or completeness by us. Please remember that
                            when
                            you use a link to go from
                            the Services to another website, our Privacy Policy is no longer in effect. Your browsing
                            and
                            interaction on any other website,
                            including those that have a link on our platform, is subject to that website’s own rules and
                            policies. Such third parties may use
                            their own cookies or other methods to collect information about you.
                        </div>

                        <div className="header privacy-policy-title">Advertising</div>
                        <div className="content privacy-policy-content">
                            This website may contain third party advertisements and links to third party sites. Bainub
                            does
                            not make any representation as
                            to the accuracy or suitability of any of the information contained in those advertisements
                            or
                            sites and does not accept any
                            responsibility or liability for the conduct or content of those advertisements and sites and
                            the
                            offerings made by the third
                            parties.<br/><br/>
                            Advertising keeps Bainub and many of the websites and services you use free of charge. We
                            work
                            hard to make sure that ads
                            are safe, unobtrusive, and as relevant as possible.<br/>
                            Third party advertisements and links to other sites where goods or services are advertised
                            are
                            not endorsements or
                            recommendations by Bainub of the third party sites, goods or services. Bainub takes no
                            responsibility for the content of any of
                            the ads, promises made, or the quality/reliability of the products or services offered in
                            all
                            advertisements.
                        </div>

                        <div className="header privacy-policy-title">Cookies for Advertising</div>
                        <div className="content privacy-policy-content">
                            These cookies collect information over time about your online activity on the website and
                            other
                            online services to make online
                            advertisements more relevant and effective to you. This is known as interest-based
                            advertising.
                            They also perform functions
                            like preventing the same ad from continuously reappearing and ensuring that ads are properly
                            displayed for advertisers. Without
                            cookies, it’s really hard for an advertiser to reach its audience, or to know how many ads
                            were
                            shown and how many clicks they
                            received.
                        </div>

                        <div className="header privacy-policy-title">Cookies</div>
                        <div className="content privacy-policy-content">
                            Bainub uses "Cookies" to identify the areas of our website that you have visited. A Cookie
                            is a
                            small piece of data stored on your
                            computer or mobile device by your web browser. We use Cookies to enhance the performance and
                            functionality of our website
                            but are non-essential to their use. However, without these cookies, certain functionality
                            like
                            videos may become unavailable or
                            you would be required to enter your login details every time you visit the website as we
                            would
                            not be able to remember that
                            you had logged in previously. Most web browsers can be set to disable the use of Cookies.
                            However, if you disable Cookies, you
                            may not be able to access functionality on our website correctly or at all. We never place
                            Personally Identifiable Information in
                            Cookies.
                        </div>

                        <div className="header privacy-policy-title">Blocking and disabling cookies and similar
                            technologies
                        </div>
                        <div className="content privacy-policy-content">
                            Wherever you're located you may also set your browser to block cookies and similar
                            technologies,
                            but this action may block our
                            essential cookies and prevent our website from functioning properly, and you may not be able
                            to
                            fully utilize all of its features
                            and services. You should also be aware that you may also lose some saved information (e.g.
                            saved
                            login details, site preferences)
                            if you block cookies on your browser. Different browsers make different controls available
                            to
                            you. Disabling a cookie or category
                            of cookie does not delete the cookie from your browser, you will need to do this yourself
                            from
                            within your browser, you should
                            visit your browser's help menu for more information.
                        </div>

                        <div className="header privacy-policy-title">Remarketing Services</div>
                        <div className="content privacy-policy-content">
                            We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or
                            retargeting) is the practice of serving
                            ads across the internet to people who have already visited your website. It allows your
                            company
                            to seem like they're “following”
                            people around the internet by serving ads on the websites and platforms they use most.
                            Kids' Privacy.<br/><br/>
                            We do not address anyone under the age of 13. We do not knowingly collect personally
                            identifiable information from anyone
                            under the age of 13. If You are a parent or guardian and You are aware that Your child has
                            provided Us with Personal Data,
                            please contact Us. If We become aware that We have collected Personal Data from anyone under
                            the
                            age of 13 without
                            verification of parental consent, We take steps to remove that information from Our servers.
                        </div>

                        <div className="header privacy-policy-title">Changes To Our Privacy Policy</div>
                        <div className="content privacy-policy-content">
                            We may change our Service and policies, and we may need to make changes to this Privacy
                            Policy
                            so that they accurately reflect
                            our Service and policies. Unless otherwise required by law, we will notify you (for example,
                            through our Service) before we make
                            changes to this Privacy Policy and give you an opportunity to review them before they go
                            into
                            effect. Then, if you continue to
                            use the Service, you will be bound by the updated Privacy Policy. If you do not want to
                            agree to
                            this or any updated Privacy
                            Policy, you can delete your account.
                        </div>

                        <div className="header privacy-policy-title">Third-Party Services</div>
                        <div className="content privacy-policy-content">
                            We may display, include or make available third-party content (including data, information,
                            applications and other products
                            services) or provide links to third-party websites or services ("Third- Party
                            Services").<br/><br/>
                            You acknowledge and agree that Bainub shall not be responsible for any Third-Party Services,
                            including their accuracy,
                            completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
                            other aspect thereof. Bainub does not
                            assume and shall not have any liability or responsibility to you or any other person or
                            entity
                            for any Third-Party Services.<br/>
                            Third-Party Services and links thereto are provided solely as a convenience to you and you
                            access and use them entirely at your
                            own risk and subject to such third parties' terms and conditions.
                        </div>

                        <div className="header privacy-policy-title">Tracking Technologies</div>
                        <div className="content privacy-policy-content">
                            <li><b>Google Maps API</b></li>
                            Google Maps API is a robust tool that can be used to create a custom map, a searchable map,
                            check-in functions, display live
                            data synching with location, plan routes, or create a mashup just to name a few.
                            Google Maps API may collect information from You and from Your Device for security purposes.
                            Google Maps API collects information that is held in accordance with its Privacy Policy
                            <li><b>Cookies</b></li>
                            We use Cookies to enhance the performance and functionality of our website but are
                            non-essential
                            to their use. However,
                            without these cookies, certain functionality like videos may become unavailable or you would
                            be
                            required to enter your login
                            details every time you visit the website as we would not be able to remember that you had
                            logged
                            in previously.
                            <li><b>Local Storage</b></li>
                            Local Storage sometimes known as DOM storage, provides web apps with methods and protocols
                            for
                            storing client-side data.
                            Web storage supports persistent data storage, similar to cookies but with a greatly enhanced
                            capacity and no information stored
                            in the HTTP request header.
                            <li><b>Sessions</b></li>
                            Bainub uses "Sessions" to identify the areas of our website that you have visited. A Session
                            is
                            a small piece of data stored on
                            your computer or mobile device by your web browser.
                        </div>

                        <div className="header privacy-policy-title">California Residents</div>
                        <div className="content privacy-policy-content">
                            The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal
                            Information we collect and how we
                            use it, the categories of sources from whom we collect Personal Information, and the third
                            parties with whom we share it, which
                            we have explained above.<br/><br/>
                            We are also required to communicate information about rights California residents have under
                            California law. You may exercise
                            the following rights:
                            <li>Right to Know and Access. You may submit a verifiable request for information regarding
                                the:
                                (1) categories of Personal
                                Information we collect, use, or share; (2) purposes for which categories of Personal
                                Information are collected or used by us; (3)
                                categories of sources from which we collect Personal Information; and (4) specific
                                pieces of
                                Personal Information we have
                                collected about you.</li>
                            <li>Right to Equal Service. We will not discriminate against you if you exercise your
                                privacy
                                rights.
                            </li>
                            <li>Right to Delete. You may submit a verifiable request to close your account and we will
                                delete Personal Information about you
                                that we have collected.
                            </li>
                            <li>Request that a business that sells a consumer's personal data, not sell the consumer's
                                personal data.<br/><br/>
                                If you make a request, we have one month to respond to you. If you would like to
                                exercise
                                any of these rights, please contact
                                us.
                            </li>
                            <br/>
                            We do not sell the Personal Information of our users.
                            For more information about these rights, please contact us.
                        </div>

                        <div className="header privacy-policy-title">Contact Us</div>
                        <div className="content privacy-policy-content">
                            Don't hesitate to contact us if you have any questions.
                            <li>Via Email: customerservice@bainub.com</li>
                        </div>
                    </>
                </Dialog.Body>
            </Dialog>)
    }

    return dialog
}