import { Layout, Button, Form, 
    Input, Select, Dialog, MessageBox, Table
} from 'element-react';
import ProductIcon from './ProductIcon';
import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { addProductRules } from '../../utils/OrderFormRules';
import { listProducts } from '../../graphql/queries';
import { Auth, API } from 'aws-amplify';
import { S3Image } from "../../components/S3Image";
import DeleteIcon from '../../components/DeleteIcon';
import { toCurrency } from '../../utils/FormatUtils';
import { calculateOrderTotal } from '../../services/OrderService';
import NotesIcon from '../NotesIcon';
function ProductInfo(props){
    const t = props.t;
    const [showDialog, setShowDialog] = useState(false);
    const form = useRef(null);
    const [rules, setRules] = useState(null);
    let [productOptions, setProductOptions] = useState([]);
    const [productForm, setProductForm] = useState(
        {
            index: 0,
            product: null,
            quantity: null,
            unit: '',
            unitPrice: null,
            description: '',
            subtotal: null
        });
    useEffect(() => {
        setRules(addProductRules(t));
    }, [t]);

    const columns = 
    [
        {
          label: t('orders.form.product-table.1'),
          prop: "orderNumber",
          width: 120,
          //sortable: true,
          render: (row, column, index)=>{
            return(
                index + 1
            ) 
          }
        },
        {
            label: t('orders.form.product-table.2'),
            //width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                
                <span style={{display: 'inline-flex'}}>
                    {
                        row.product.photo && row.product.photo[0] != null
                        ?
                            <S3Image
                            imgKey={row.product.photo[0].key}
                            theme={{
                                photoImg: { maxWidth: "50px", maxHeight: "50px", marginRight: "5px"}
                            }}
                            level="public"/>
                        :
                        <div style={{ width: "50px", height: "50px", marginRight: "5px"}}></div>
                    }
                    &nbsp;
                    <Link target="_blank" to={"/product-detail/" + row.product.id}> {row.product.name} </Link>
                </span>
              ) 
            }
        },
        {
            label: t('orders.form.product-table.3'),
            width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                row.quantity
              ) 
            }
        },
        {
            label: t('orders.form.product-table.4'),
            width: 120,
            //sortable: true,
            render: (row, column, index)=>{
              return(
                row.unit
              ) 
            }
        },
        {
            label: t('orders.form.product-table.5'),
            width: 150,
            //sortable: true,
            align: 'right',
            render: (row, column, index)=>{
              return(
                toCurrency(Number(row.unitPrice)) + ' ' + props.formInputs.currency
              ) 
            }
        },
        {
            label: t('orders.form.product-table.6'),
            width: 150,
            prop: 'subtotal',
            align: 'right',
            sortable: true,
            render: (row, column, index)=>{
              return(
                toCurrency(row.subtotal) + ' ' + props.formInputs.currency
              ) 
            }
        },
        {
            //label: t('orders.form.product-table.7'),
            width: 80,
            //sortable: true,
            render: (row, column, index)=>{
                if(props.readOnly){
                    return <></>
                }
                if(props.formInputs.sellerStatus !== 0){
                    return(
                        <>
                            <NotesIcon color='#FF6D73'  handleClick={()=>{MessageBox.alert(row.description, 'Info Extra');}} width={30} height={20}/>
                        </>
                    )
                }else{
                    return(
                        <div className="text-center inline-row" style={{marginTop: '15px'}}>
                                <DeleteIcon handleClick={()=>{deleteItem(row.product.id, row.index)}} width={30} height={30}/>
                                <NotesIcon color='#FF6D73' handleClick={()=>{MessageBox.alert(row.description, 'Info Extra');}} width={30} height={20}/>
                        </div>
                        
                    ) 
                }
              
            }
        },
    ]

    const deleteItem = (productId, _index) =>{
        console.log(`deleting ${productId}, index: ${_index}`);
        
        props.setLoading(true);
        console.log('props.formInputs.products', props.formInputs.products);
        let _prevProducts = props.formInputs.products.filter(function( obj ) {
            console.log(obj.product.id, productId, obj.index, _index);
            //console.log(obj.product.id !== productId, obj.index != _index);
            console.log(obj.product.id == productId && obj.index == _index);
            if(obj.product.id == productId && obj.index == _index)
                return false;
            else 
                return true;

        });
        console.log({_prevProducts});
        //let _prevProducts = props.formInputs.products ? props.formInputs.products: [];
        let _total = calculateOrderTotal([..._prevProducts], props.formInputs.fees, props.formInputs.paymentCost)
        props.setFormInputs({...props.formInputs, products: [..._prevProducts], total: _total })
        props.setLoading(false);
    }

    const initForm = async() => {
        props.setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        console.log({user});
        const resultProducts = await API.graphql({ query: listProducts, variables: { filter: { owner: { eq: user.username }, status: { eq: 1 } }  }});
        console.log({resultProducts});
        let optionsResult = []
        resultProducts.data.listProducts.items.forEach(element => {
            optionsResult.push(
                {
                    id: element.id,
                    value: element,
                    label: element.name
                }
            )
        })
        setProductOptions(optionsResult);
        setProductForm({
            index: 0,
            product: '',
            quantity: null,
            unit: '',
            unitPrice: null,
            description: '',
            subtotal: null
        })
        setShowDialog(true);
        props.setLoading(false);
    }

    const addProduct = () => {
        form.current.validate((valid) => {
            console.log('validator :: ' + valid);
            if (valid) {
                console.log({productForm});
                //TODO add the product to the props.FormInput table
                //_products.push(productForm);
                let _prevProducts = props.formInputs.products ? props.formInputs.products: [];
                let _total = calculateOrderTotal([..._prevProducts, productForm], props.formInputs.fees, props.formInputs.paymentCost)
                props.setFormInputs({...props.formInputs, products: [..._prevProducts, productForm], total: _total })
                setShowDialog(false);
            } else {
                console.log('invalid form');
            }
        });
    }

    return(
        <>
            <Dialog size="small"
                    visible={showDialog}
                    onCancel={ () => { 
                        console.log('cancel Diaglog');
                        setShowDialog(false);
                    } }
                    title={t('orders.form.product-dialog-title')}
                >
                <Dialog.Body>
                    <Form ref={form} model={productForm} className="en-US" rules={rules} label-position="top">
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.product-table.modal-product')} prop="product">
                                    <Select 
                                        filterable={false} 
                                        value={productForm.product}
                                        onChange={(v) => {
                                            console.log('INDEX a USAR ::  ' + (props.formInputs.products ? props.formInputs.products.length : 0));
                                            setProductForm({...productForm, product: v, index : (props.formInputs.products ? props.formInputs.products.length : 0) })}
                                        } 
                                    >
                                        {   
                                            productOptions?.map(el => {
                                            return <Select.Option key={el.id} label={el.label} value={el.value} />
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={5} md={7} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.product-table.modal-quantity')} prop="quantity">
                                    <Input 
                                        value={productForm.quantity}
                                        onChange={(v) => {
                                            setProductForm({...productForm, quantity: v, subtotal: (v * productForm.unitPrice) })
                                        }} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={5} md={7} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.product-table.modal-unit')} prop="unit">
                                    <Input 
                                        value={productForm.unit}
                                        onChange={(v) => {setProductForm({...productForm, unit: v })}} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                            <Layout.Col lg={5} md={7} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.product-table.modal-unit-price')} prop="unitPrice">
                                    <Input 
                                        value={productForm.unitPrice}
                                        onChange={(v) => {setProductForm({...productForm, unitPrice: v, subtotal: (v * productForm.quantity) })}} 
                                    />
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                    <b>{t('orders.form.product-table.modal-subtotal') + ': ' } {toCurrency(productForm.subtotal)}</b>
                                
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                <Form.Item label={t('orders.form.product-table.modal-description')} prop="description" >
                                    <Input 
                                        type="textarea"
                                        autosize={{ minRows: 4, maxRows: 4}}
                                        value={productForm.description}
                                        onChange={(v) => {setProductForm({...productForm, description: v })}} 
                                    />
                                    
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <Layout.Row type="flex">
                                    <Layout.Col span="24" xs="20" sm="20" lg="23">
                                        <Layout.Row type="flex" justify="end">
                                            <Layout.Col tag="form-button">
                                                <div style={{display: 'inline-flex'}}>
                                                    <form-button-inverse class="left-space">
                                                    <Button className="left-separator" type="primary" onClick={() => setShowDialog(false)}>
                                                            {t('orders.form.cancel-prd-btn')}
                                                    </Button>
                                                    </form-button-inverse>
                                                </div>
                                            </Layout.Col>
                                            <Layout.Col tag="form-button">
                                                    <Button className="left-separator" type="primary" onClick={() => addProduct()}>
                                                            {t('orders.form.add-prd-btn')}
                                                    </Button>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Layout.Col>
                    </Layout.Row>
                </Dialog.Footer>
            </Dialog>
            <Layout.Row gutter="20">
                <Layout.Col span="20" style={{display: 'inline-flex'}}>
                    <ProductIcon width={41} height={41}/> 
                        <div className="order-step-form">
                            {
                                props.readOnly
                                ?
                                    t('orders.viewer.product-section')
                                :
                                    t('orders.form.product-section')
                            }
                        </div>
                </Layout.Col>
            </Layout.Row>
            <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Layout.Row type="flex" justify="end">
                                        <Layout.Col tag="form-button">
                                            <div style={{display: 'inline-flex'}}>
                                                <form-button-inverse class="left-space">
                                                {
                                                    props.formInputs.sellerStatus !== 0 || props.readOnly
                                                    ?
                                                        <></>
                                                    :
                                                        <Button className="left-separator" type="primary" onClick={() => initForm()}>
                                                        {t('orders.form.add-prd-btn')}
                                                        </Button>
                                                }
                                                
                                                
                                                </form-button-inverse>
                                            </div>
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20" justify="end" type="flex" >
                            <Layout.Col offset="1" lg="10">
                                &nbsp;
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                            <Layout.Col span="23">
                                
                                <Table
                                    className="products-table box-card"
                                    data={props.formInputs.products}
                                    columns={columns}  
                                    fit={true}
                                    sumText='Total'
                                    showSummary={true}
                                    summaryMethod={(columns, data)=>{
                                        const dataList = [];
                                        for(var i=0; i < columns.length; i++){
                                            let total = 0;
                                            if(i === 4){
                                                dataList[i] = 'Subtotal:';
                                                continue;
                                            }
                                            for(let j=0; j < data.length; j++){
                                                let value = data[j][columns[i]['property']];
                                                
                                                if(isNaN(value)){
                                                    total = ''
                                                    break;
                                                }else{
                                                    total += parseFloat(value);
                                                }
                                            }
                                            
                                            dataList[i] = toCurrency(total) + ( i === 5 ? ' ' + props.formInputs.currency : '');
                                        }
                                        return dataList;
                                    }}
                                    emptyText={<>
                                        <div><b>{t('orders.form.product-table.no-data-1')}</b></div>
                                        <br/>
                                        {
                                            props.readOnly ?
                                                <div></div>
                                            :
                                                <div>{t('orders.form.product-table.no-data-2')}</div>
                                        }
                                    </>}
                                    //onRowClick={dotsClick}
                                />
                            </Layout.Col>
                </Layout.Row>
           
        </>
    )
}

export default ProductInfo;