import { S3Image } from '../components/S3Image';
import { Card} from 'element-react';
import {toCurrency} from '../utils/FormatUtils';
import { useHistory } from "react-router-dom";
function ProductBox (props){
    console.log('KEY ::: ' + props.img);
    let history = useHistory();
    const goToProductView = () =>{
        history.push("/product-detail/"+props.id);
    }
    return(
        <div className="product-box cursor" onClick={goToProductView}>
            <Card style={{minHeight: '254px', maxWidth:'250px'}}>
                <div className="text-center">
                    <S3Image
                        imgKey={props.img}
                        theme={{
                            photoImg: { maxWidth: "70px", maxHeight: "70px" }
                        }}
                        level="public"
                    />
                </div>
                <p style={{marginBottom: '-5px'}}>
                    {props.name}
                </p>
                <div style={{display:'inline-flex' , marginTop : '-15px'}} className="card-company-info">
                    by: &nbsp;<div className="card-company-title">{props.companyName}</div>
                </div>
                <br/>
                <div style={{display:'inline-flex', marginTop : '-15px'}}>
                    <p className="price-ranges">{toCurrency(props.minPrice)} - {toCurrency(props.maxPrice)}</p>
                </div>
                
            </Card>
        </div>
    )
}

export default ProductBox;
