import {useState, useRef, useEffect} from 'react';
import { 
    Layout, Form, Select, Checkbox, Input, Dialog, Button,
    Notification, MessageBox
    } 
from 'element-react';
import { useTranslation } from 'react-i18next';
import { countryOptions } from '../utils/Catalogs';
import EditSolidIcon from '../components/EditSolidIcon';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { listUsers, listCompanys } from '../graphql/queries';
import { updateUser } from '../graphql/mutations';
import {personalDataFormRules, passwdFormRules} from '../utils/OrderFormRules'
import userStore from '../store/user';
import MyPreferencesWizard from '../components/layouts/MyPreferencesWizard';
import PagerQuery from '../utils/PagerQuery';

export default function MyAccount(){
    const { t } = useTranslation();
    const [phoneCode, setPhoneCode] = useState("-");
    const [showDialog, setShowDialog] = useState(false);
    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const formPasswordRef = useRef(null);
    const confirmPassInputRef = useRef();
    const [showWizard, setShowWizard] = useState(false);
    const [passwordForm, setPasswordForm] = useState({
        newPassword : '',
        confirmPassword : '',
        passwd: ''
    });
    const form = useRef(null);
    const [rules, setRules] = useState(null);
    const [passRules, setPassRules] = useState(null);
    const [phoneForm, setPhoneForm] = useState({
        phoneNumber : ''
    });
    const [userData, setUserData] = useState({
            id: "",
            city: null,
            cognito_id: "",
            company_name: "",
            country: "",
            createdAt: "",
            email: "",
            first_name: "",
            id: "",
            last_name: "",
            phone_number: "",
            updatedAt: "",
            user_type: "",
            username: ""
    });
    useEffect(() => {
        console.log('My Account');
        loadPersonalInfo();
    },[]);

    useEffect(() => {
        setRules(personalDataFormRules(t));
        setPassRules(passwdFormRules(t, confirmPassInputRef));
    }, [t]);

    const loadPersonalInfo = async () =>{
        try{
            console.log('loadPersonalInfo');
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
            //const userResult = await API.graphql({ query: listUsers, variables: { filter: { cognito_id: { eq: u.username } }}});
            const input = { filter: { cognito_id: { eq: u.username } }}
            const pagerQuery = new PagerQuery(listUsers, input, Object.keys({ listUsers }));
            const userResult = await pagerQuery.searchPrivateData();
            console.log({userResult});
            let _userData = userResult.data?.listUsers.items[0];
            const companyResult = await API.graphql({ query: listCompanys, variables: { filter: { owner: { eq: u.username } }}});
            //console.log({companyResult});
            if(companyResult.data != null && companyResult.data.listCompanys != null && companyResult.data.listCompanys.items.length > 0 ){
                setUserData({..._userData, company_name: companyResult.data.listCompanys.items[0].name});
            }else{
                setUserData(_userData);
            }

            const found = countryOptions.options.find(element => element.value == _userData.country);
            setPhoneCode(found.phoneCode);
            setPhoneForm({phoneNumber : _userData.phone_number})
        }catch(e){
            console.error(e);
        }
    }
    const updatePhoneNumber = async() =>{
        try{
            await form.current.validate(async (valid) => {
                console.log('validator :: ' + valid);
                if (valid) {
                    const input = {
                        id: userData.id,
                        phone_number: phoneForm.phoneNumber
                    }
                    const result = await API.graphql(
                        graphqlOperation(updateUser, { input })
                    );
                    console.log("User updated", result);
                    setUserData({...userData, phone_number : phoneForm.phoneNumber});
                    setShowDialog(false);
                    Notification({
                        title: 'Success',
                        message: t('my-account.form.success-message'),
                        type: 'success'
                    });
                } else {
                    console.log('invalid form');
                }
            })
        }catch(e){
            console.error(e);
        }
    }

    const updateUserPassword = async() =>{
        try{
            console.log('updateUserPassword');
            await formPasswordRef.current.validate(async (valid) => {
                if(valid){
                    const u = await Auth.currentAuthenticatedUser();
                    console.log({u});
                    try{
                        const resultChangePasswd = await Auth.changePassword(u, passwordForm.passwd, passwordForm.confirmPassword);
                    
                        console.log({resultChangePasswd});
                        setShowPasswordDialog(false);
                        if(resultChangePasswd == "SUCCESS"){
                            Notification({
                                title: 'Success',
                                message: t('my-account.form.success-message'),
                                type: 'success'
                            });
                        }else{
                            Notification({
                                title: 'Error',
                                message: t('my-account.form.error-message'),
                                type: 'error'
                            });
                        }
                    }catch(ex){
                        console.error(ex);
                        Notification({
                            title: 'Error',
                            message: ex.message,
                            type: 'error'
                        });
                    }
                    
                }else{
                    console.log('invalid form');
                }
            })
            
        }catch(e){
            console.error(e);
        }
    }
    const wizardOff = () =>{
        console.log('wizardOff');
        setShowWizard(false);
    }
    function showPasswordRules() { MessageBox.alert(t("general.password-rules"), t("general.password-rules-title")) }
    return(
        <>
        <Dialog
            size="large"
            visible={showWizard}
            onCancel={ () => { 
                console.log('cancel Diaglog');
                setShowWizard(false);
            } }
        >
            <Dialog.Body>
                <MyPreferencesWizard onWizardComplete={wizardOff} />
            </Dialog.Body>
            
        </Dialog>
        <Dialog size="small"
                visible={showDialog}
                onCancel={ () => { 
                    console.log('cancel Diaglog');
                    setShowDialog(false);
                } }
                title={t('my-account.form.title')}
            >
            <Dialog.Body>
                <Form ref={form} model={phoneForm} className="en-US" rules={rules} label-position="top">
                    <Layout.Row gutter="20">
                        <Layout.Col lg={15} md={14} xs={22} sm={22}>
                            <Form.Item label={t('my-account.form.phone')} prop="phoneNumber" >
                                <br/>
                                <Input 
                                    value={phoneForm.phoneNumber}
                                    onChange={(v)=>{
                                        setPhoneForm({phoneNumber : v})
                                    }}
                                />
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>
                </Form>
            </Dialog.Body>
            <Dialog.Footer className="dialog-footer">
                <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Layout.Row type="flex" justify="end">
                                        <Layout.Col tag="form-button">
                                            <div style={{display: 'inline-flex'}}>
                                                <form-button-inverse class="left-space">
                                                <Button className="left-separator" type="primary" onClick={() => setShowDialog(false)}>
                                                        {t('orders.form.cancel-prd-btn')}
                                                </Button>
                                                </form-button-inverse>
                                            </div>
                                        </Layout.Col>
                                        <Layout.Col tag="form-button">
                                                <Button className="left-separator" type="primary" onClick={() => updatePhoneNumber()}>
                                                        {t('my-account.form.update-btn')}
                                                </Button>
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                </Layout.Row>
            </Dialog.Footer>
        </Dialog>
        <Dialog size="small"
                visible={showPasswordDialog}
                onCancel={ () => { 
                    console.log('cancel Diaglog');
                    setShowPasswordDialog(false);
                } }
                title={t('my-account.security.form.title')}
            >
            <Dialog.Body>
                <Form ref={formPasswordRef} model={passwordForm} className="en-US" rules={passRules} label-position="top">
                <Layout.Row gutter="20">
                        <Layout.Col lg={15} md={14} xs={22} sm={22}>
                            <Form.Item label={t('my-account.security.form.passwd')} prop="passwd" >
                                <br/>
                                <Input 
                                    type='password'
                                    value={passwordForm.passwd}
                                    
                                    onChange={(v)=>{
                                        setPasswordForm({...passwordForm, passwd : v})
                                    }}
                                />
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <Layout.Col lg={15} md={14} xs={22} sm={22}>
                            <Form.Item label={t('my-account.security.form.new-passwd')} prop="newPassword" >
                                <br/>
                                <Input 
                                    type='password'
                                    value={passwordForm.newPassword}
                                    ref={confirmPassInputRef}
                                    onChange={(v)=>{
                                        setPasswordForm({...passwordForm, newPassword : v})
                                    }}
                                />
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <Layout.Col lg={15} md={14} xs={22} sm={22}>
                            <Form.Item label={t('my-account.security.form.confirm-passwd')} prop="confirmPassword" >
                                <br/>
                                <Input 
                                    type='password'
                                    value={passwordForm.confirmPassword}
                                    onChange={(v)=>{
                                        setPasswordForm({...passwordForm, confirmPassword : v})
                                    }}
                                />
                            </Form.Item>
                        </Layout.Col>
                    </Layout.Row>
                </Form>
            </Dialog.Body>
            <Dialog.Footer className="dialog-footer">
                <Layout.Row type="flex">
                                <Layout.Col span="24" xs="20" sm="20" lg="23">
                                    <Layout.Row type="flex" justify="end">
                                        <Layout.Col tag="form-button">
                                            <div style={{display: 'inline-flex'}}>
                                                <form-button-inverse class="left-space">
                                                <Button className="left-separator" type="primary" onClick={() => setShowPasswordDialog(false)}>
                                                        {t('orders.form.cancel-prd-btn')}
                                                </Button>
                                                </form-button-inverse>
                                            </div>
                                        </Layout.Col>
                                        <Layout.Col tag="form-button">
                                                <Button className="left-separator" type="primary" onClick={() => updateUserPassword()}>
                                                        {t('my-account.security.form.update-btn')}
                                                </Button>
                                        </Layout.Col>
                                    </Layout.Row>
                                </Layout.Col>
                </Layout.Row>
            </Dialog.Footer>
        </Dialog>
        <Layout.Row gutter="20">
                                &nbsp;
        </Layout.Row>
        <Layout.Row gutter="20">
            <Layout.Col offset="1" span="20">
                <div className="order-wizard-div">
                    <Layout.Row type='flex' gutter="20" justify="space-between">
                        <Layout.Col span="14" >
                            <h2 style={{color:'#707070'}}>{t('my-account.hedaer')}</h2>
                        </Layout.Col>
                        
                    </Layout.Row>
                    
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <Layout.Row>
                    <Layout.Col>       
                    <p>To change your personal information, please contact Bainub Support.</p>                 
                            <div onClick={()=>setShowDialog(true)} className="cursor" style={{ display: 'inline-flex', paddingTop: 10}}>
                                <EditSolidIcon width={30} height={30}/> <div style={{color: '#FF6D73', paddingTop: 2}}>{t('my-account.form.edit')}</div>
                            </div>
                        </Layout.Col>
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <Layout.Col span="24" xs="20" sm="20" lg="23">                       
                            <Form>
                                <Layout.Row gutter="10">
                                    <Layout.Col lg={4} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('register-form.country')}>
                                            <Select 
                                                filterable={false} 
                                                disabled={true}
                                            >
                                                    {
                                                        countryOptions.options.map(el => {
                                                            console.log('ELVALUE :: ' + el.value + ' :: ' + (userData.country ==  el.value));
                                                            if(userData.country ==  el.value){
                                                                return(<Select.Option selected={true} key={el.value} value={el.value} label={el.label}  />)
                                                            }
                                                            else
                                                                return <></>
                                                        })
                                                    }
                                            </Select>
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row gutter="10">
                                    <Layout.Col lg={4} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('my-account.form.account-type')}>
                                            <div style={{display: 'inline-flex'}}>
                                                { userData.user_type == 'S' 
                                                    ?  
                                                        <Checkbox checked disabled>{t('my-account.form.option1')}</Checkbox>
                                                    : 
                                                        <Checkbox disabled>{t('my-account.form.option1')}</Checkbox>
                                                }
                                                
                                                <Checkbox checked disabled>{t('my-account.form.option2')}</Checkbox>
                                            </div>
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row gutter="10">
                                    <Layout.Col lg={10} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('my-account.form.name')}>
                                            <Input 
                                                disabled={true}
                                                value={userData.first_name}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                    <Layout.Col lg={10} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('my-account.form.last-name')}>
                                            <Input 
                                                disabled={true}
                                                value={userData.last_name}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row gutter="10">
                                    <Layout.Col lg={10} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('my-account.form.second-last-name')}>
                                            <Input 
                                                disabled={true}
                                                value={''}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                    <Layout.Col lg={10} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('my-account.form.email')}>
                                            <Input 
                                                disabled={true}
                                                value={userData.email}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
                                <Layout.Row gutter="10">
                                    <Layout.Col lg={10} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('my-account.form.company-name')}>
                                            <Input 
                                                disabled={true}
                                                value={userData.company_name}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                    <Layout.Col lg={10} md={10} xs={10} sm={10}>
                                        <Form.Item label={t('my-account.form.phone')}>
                                            <Input 
                                                disabled={true}
                                                value={userData.phone_number}
                                                prepend={phoneCode}
                                            />
                                        </Form.Item>
                                    </Layout.Col>
                                </Layout.Row>
                            </Form>
                        </Layout.Col>
                    </Layout.Row>
                </div>
            </Layout.Col>
        </Layout.Row>
        <Layout.Row gutter="10">
            <Layout.Col offset="1" span="20">
                <div className="order-wizard-div">
                    <Layout.Row type='flex' gutter="20" justify="space-between">
                        <Layout.Col span="14" >
                            <h2 style={{color:'#707070'}}>{t('my-account.security.header')}</h2>
                        </Layout.Col>
                        
                    </Layout.Row>
                    
                    <Layout.Row gutter="10">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <Layout.Row gutter="10">
                        <Layout.Col span="20">
                            <div onClick={()=>{
                                        setShowPasswordDialog(true);
                                        setPasswordForm({
                                                newPassword : '',
                                                confirmPassword : '',
                                        });
                                    }} 
                                className="cursor" style={{ display: 'inline-flex', paddingTop: 10}}>
                                <EditSolidIcon width={30} height={30}/> <div style={{color: '#FF6D73', paddingTop: 2}}>{t('my-account.security.change-link')}</div>
                            </div>
                        </Layout.Col>
                    </Layout.Row>
            </div>
            </Layout.Col>
        </Layout.Row>
        <Layout.Row>
            &nbsp;
        </Layout.Row>
        <Layout.Row>
            &nbsp;
        </Layout.Row>
        
        <Layout.Row gutter="20">
                     <div style={{paddingTop: 60}}></div>
            </Layout.Row>
       
        </>
    )
}