import React from 'react';
import { Layout } from 'element-react';
const VendorIcon = (props) => {
  return (
            <svg className="cursor" onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 33 33">
                <text id="_" data-name="👤" transform="translate(0 17)" fill="#FF6D73" font-size="16" font-family="SegoeUIEmoji, Segoe UI Emoji"><tspan x="0" y="0">👤</tspan></text>
            </svg>
  )
}

export default VendorIcon;