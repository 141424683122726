import { useState, useEffect } from "react"

import { API, graphqlOperation } from 'aws-amplify';
import { listChats } from '../graphql/queries';
import { onUpdateChat, onCreateChat } from '../graphql/subscriptions';

import userStore from '../store/user';

import { Layout } from "element-react"
import ChatItem from "../components/ChatItem"
import PagerQuery from "../utils/PagerQuery";

let onUpdateChatSubscription;
let onCreateChatSubscription;
export default function ChatItemsContainer( { indexChat, onChatSelect, filterValue , chatTypeFilter ,  onEmptyChats } ) {
    const { user } = userStore();
    
    const [[chats, setChats], [loadingChats, setLoadingChats], [selectedChatId, setSelectedChatId]] = [useState([]), useState(false), useState([]), useState(null)]
    
    useEffect(function() {
        console.log("subscribing to on update chat ...")
        onUpdateChatSubscription = API.graphql(graphqlOperation(onUpdateChat)).subscribe({ next: subscription, error: error => console.warn(error) });
        onCreateChatSubscription = API.graphql(graphqlOperation(onCreateChat)).subscribe({ next: subscription, error: error => console.warn(error) });
        //setAmplifySubscription([onUpdateChatSubscription, onCreateChatSubscription])
        setLoadingChats(true)
        loadChats()
        return () =>{
            console.log('UNMOUNT SUBSCRIPTION');
            onUpdateChatSubscription.unsubscribe();
            onCreateChatSubscription.unsubscribe();
            console.log('AFTER SUBSCRIPTION');
        }
    }, [user])

    const subscription = (data) => {
        console.log('CALLING SUBSCRIPTION METHOD')
        const chatItem = data.value.data.onUpdateChat
        if(!loadingChats) {
            if(chatItem && (chatItem.company_user === user.cognitoId || chatItem.owner === user.cognitoId)) {
            //if((user.type === "S" && chatItem && chatItem.company_user === user.cognitoId) || (user.type === "B" && chatItem.owner === user.cognitoId)) {
                setLoadingChats(true)
                loadChats()
            }
        }
    }

    async function loadChats() {
        let filter = {
            or: [
                { 'company_user': { eq: user.cognitoId } },
                { 'owner': { eq: user.cognitoId } }
            ]
        }

        // filter[user.type === "S" ? "company_user" : "owner"]= { eq: user.cognitoId }
        const pagerQuery = new PagerQuery(listChats, { filter }, Object.keys({ listChats }));
        /*const listChatsResult = await API.graphql({ 
            query: listChats,
            variables: { filter: filter }
        })*/
        const listChatsResult = await pagerQuery.searchPrivateData();
        let resultChats = listChatsResult.data.listChats.items

        for(let i = 0; i < resultChats.length; i++) {
            resultChats[i]["chat_type"] = user.cognitoId === resultChats[i].company_user ? "S" : "B"
        }

        resultChats = resultChats.sort(function(a,b) { 
            if(a.updatedAt < b.updatedAt) { return 1; }
            if(a.updatedAt > b.updatedAt) { return -1; }
            return 0;
        })
        if(resultChats.length == 0 && onEmptyChats){
            onEmptyChats(true)
        }
        setChats(resultChats)
        setLoadingChats(false)
        chatFromParameter(resultChats);
    }

    function chatFromParameter(resultChats){
        console.log('indexChat :: ' + indexChat);
        if(indexChat !== 0 && indexChat !== "0"){
            try{
                let chat = resultChats.find((chat) => chat.id === indexChat);
                if(chat === undefined)
                    chat = resultChats[indexChat-1];
                setSelectedChatId(chat.id);
                onChatSelect(chat)
            }catch(e){
                console.error(e);
            }
        }else{
            console.log('Current CHAT ID :: ' + selectedChatId);
            if(selectedChatId){
                setSelectedChatId("");
                onChatSelect(null);
            }
        }
    }

    function chatSelectHandler(chatId) {
        const chat = chats.filter((chat) => chat.id === chatId)[0]
        setSelectedChatId(chat.id)
        if(onChatSelect)
            onChatSelect(chat)
    }

    const renderChatItems = chats.map(chat => {
        let chatItem = <ChatItem chat={chat} key={chat.id} onClick={chatSelectHandler} isActiveChat={selectedChatId && selectedChatId === chat.id} />
        if(chat.company_user_name.toLowerCase().includes(filterValue.toLowerCase()) || chat.owner_name.toLowerCase().includes(filterValue.toLowerCase())) {
            if(chatTypeFilter === "" || chat.chat_type === chatTypeFilter) 
                return chatItem
            return <></>
        } else 
            return <></>
    })

    return (
        <Layout.Col span="24" className="m-t-md chat-items-container">
            {chats ? renderChatItems : <></>}
        </Layout.Col>
    )
}
