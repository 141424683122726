module.exports = `
<div style="position:absolute;top:0.78in;left:0.55in;width:1.36in;line-height:0.24in;"><span style="font-style:normal;font-weight:normal;font-size:16pt;font-family:Calibri;color:#2f5496">Cookie Policy</span><span style="font-style:normal;font-weight:normal;font-size:16pt;font-family:Calibri;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:1.08in;left:0.55in;width:1.54in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Updated at 2022-</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">08</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">23</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.51in;left:0.55in;width:2.06in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">Definitions and key terms</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:1.76in;left:0.55in;width:7.90in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">To help explain things as clearly as possible in this Cookie Policy, every time any of these terms are referenced, are strictly</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:1.95in;left:0.55in;width:0.75in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">defined as:</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.34in;left:0.55in;width:7.99in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">Cookie</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: small amount of data generated by a website and saved by your web browser. It is used to identify your browser,</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.53in;left:0.55in;width:6.97in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">provide analytics, remember information about you such as your language preference or login information.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.73in;left:0.55in;width:7.75in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">Company</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Bainub, that is responsible for your</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:2.92in;left:0.55in;width:2.43in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">information under this Cookie Policy.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.12in;left:0.55in;width:7.74in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">Device</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: any internet connected device such as a phone, tablet, computer or any other device that can be used to visit</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.31in;left:0.55in;width:1.87in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Bainub and use the services.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.51in;left:0.55in;width:7.85in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">Personal Data</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: any information that directly, indirectly, or in connection with other information — </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">including a personal</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.70in;left:0.55in;width:5.81in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">identification number — allows for the identification or identifiability of a natural person.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:3.89in;left:0.55in;width:7.70in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">Service</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: refers to the service provided by Bainub as described in the relative terms (if available) and on this platform.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.09in;left:0.55in;width:8.01in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">Third-party service</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.28in;left:0.55in;width:4.53in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">our content or whose products or services we think may interest you.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.48in;left:0.55in;width:5.43in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">Website</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: Bainub.&quot;’s&quot; site, which can be accessed via this URL: wwww.bainub.com</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:4.67in;left:0.55in;width:4.87in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-</span><span style="font-style:normal;font-weight:bold;font-size:10pt;font-family:Calibri;color:#000000">You</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">: a person or entity that is registered with Bainub to use the Services.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.10in;left:0.55in;width:1.03in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">Introduction</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:5.35in;left:0.55in;width:8.16in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">This Cookie Policy explains how Bainub and its affiliates (collectively &quot;Bainub&quot;, &quot;we&quot;, &quot;us&quot;, and &quot;ours&quot;), use cookies and similar</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.54in;left:0.55in;width:7.95in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">technologies to recognize you when you visit our website, including without limitation wwww.bainub.com and any related</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.74in;left:0.55in;width:8.01in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">URLs, mobile or localized versions and related domains / sub-domains (&quot;Websites&quot;). It explains what these technologies are</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:5.93in;left:0.55in;width:4.55in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">and why we use them, as well as the choices for how to control them.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.36in;left:0.55in;width:1.43in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">What is a cookie?</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:6.61in;left:0.55in;width:7.71in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">A cookie is a small text file that is stored on your computer or other internet connected device in order to identify your</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:6.80in;left:0.55in;width:8.09in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">browser, provide analytics, remember information about you such as your language preference or login information. They&apos;re</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.00in;left:0.55in;width:5.46in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">completely safe and can&apos;t be used to run programs or deliver viruses to your device.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:7.43in;left:0.55in;width:1.98in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">Why do we use cookies?</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:7.68in;left:0.55in;width:5.83in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">We use first party and/or third party cookies on our website for various purposes such as:</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.06in;left:0.55in;width:3.99in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-To facilitate the operation and functionality of our website;</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.26in;left:0.55in;width:6.46in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-To improve your experience of our website and make navigating around them quicker and easier;</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.45in;left:0.55in;width:7.64in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-To allow us to make a bespoke user experience for you and for us to understand what is useful or of interest to you;</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.65in;left:0.55in;width:4.70in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-To analyze how our website is used and how best we can customize it;</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:8.84in;left:0.55in;width:5.63in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-To identify future prospects and personalize marketing and sales interactions with it;</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.04in;left:0.55in;width:4.25in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-To facilitate the tailoring of online advertising to your interests.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.46in;left:0.55in;width:3.15in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">What type of cookies does Bainub use?</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:9.71in;left:0.55in;width:7.75in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:9.91in;left:0.55in;width:7.87in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.10in;left:0.55in;width:8.17in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.30in;left:0.55in;width:2.97in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">you’re visiting are called “first party cookies”.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.69in;left:0.55in;width:7.79in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Strictly Necessary cookies are necessary for our website to function and cannot be switched off in our systems. They are</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:10.88in;left:0.55in;width:8.04in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">essential in order to enable you to navigate around the website and use its features. If you remove or disable these cookies,</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:11.07in;left:0.55in;width:4.04in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">we cannot guarantee that you will be able to use our website.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<br/>
<br/>
<div style="position:absolute;top:11.58in;left:0.55in;width:3.48in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">We use the following types of cookies in our website:</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:12.00in;left:0.55in;width:1.29in;line-height:0.18in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763">Essential Cookies</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763"> </span><br/></div>
<div style="position:absolute;top:12.24in;left:0.55in;width:8.07in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">We use essential cookies to make our website work. These cookies are strictly necessary to enable core functionality such as</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:12.43in;left:0.55in;width:7.97in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">security, network management, your cookie preferences and accessibility. Without them you wouldn&apos;t be able to use basic</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:12.62in;left:0.55in;width:7.43in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">services. You may disable these by changing your browser settings, but this may affect how the Websites function.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:13.05in;left:0.55in;width:2.90in;line-height:0.18in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763">Performance and Functionality Cookies</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763"> </span><br/></div>
<div style="position:absolute;top:13.28in;left:0.55in;width:7.75in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">These cookies are used to enhance the performance and functionality of our website but are non-essential to their use.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:13.48in;left:0.55in;width:8.09in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:13.67in;left:0.55in;width:7.97in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">your login details every time you visit the website as we would not be able to remember that you had logged in previously.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:14.09in;left:0.55in;width:1.40in;line-height:0.18in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763">Marketing Cookies</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763"> </span><br/></div>
<div style="position:absolute;top:14.33in;left:0.55in;width:7.46in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">These account-based marketing cookies enable us to identify future prospects and personalize sales and marketing</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:14.52in;left:0.55in;width:1.55in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">interactions with them.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:14.95in;left:0.55in;width:2.71in;line-height:0.18in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763">Analytics and Customization Cookies</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763"> </span><br/></div>
<div style="position:absolute;top:15.18in;left:0.55in;width:7.78in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">These cookies collect information that is used to help us understand how our website is being used or how effective our</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:15.38in;left:0.55in;width:4.59in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">marketing campaigns are, or to help us customize our website for you.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:15.76in;left:0.55in;width:7.81in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable us to better</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:15.96in;left:0.55in;width:7.32in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">understand your use of our website. Further information on how Google collects and uses this data can be found</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:16.15in;left:0.55in;width:8.18in;line-height:0.16in;"><a href="https://www.google.com/policies/privacy/partners/."><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">at: https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported analytics on our Websites by</span></a>
<span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:16.35in;left:0.55in;width:3.37in;line-height:0.16in;"><a href="https://tools.google.com/dlpage/gaoptout."><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">visiting: https://tools.google.com/dlpage/gaoptout.</span></a>
<span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:16.77in;left:0.55in;width:1.47in;line-height:0.18in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763">Advertising Cookies</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763"> </span><br/></div>
<div style="position:absolute;top:17.01in;left:0.55in;width:8.22in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">These cookies collect information over time about your online activity on the website and other online services to make onlin</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">e</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:17.20in;left:0.55in;width:8.05in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">advertisements more relevant and effective to you. This is known as interest-based advertising. They also perform functions</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:17.39in;left:0.55in;width:7.79in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">like preventing the same ad from continuously reappearing and ensuring that ads are properly displayed for advertisers.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:17.59in;left:0.55in;width:8.15in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Without cookies, it’s really hard for an advertiser to reach its audience, or to know how many ads were shown and how many</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:17.78in;left:0.55in;width:1.33in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">clicks they received.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:18.21in;left:0.55in;width:1.45in;line-height:0.18in;"><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763">Third Party Cookies</span><span style="font-style:normal;font-weight:normal;font-size:12pt;font-family:Calibri Light;color:#1f3763"> </span><br/></div>
<div style="position:absolute;top:18.44in;left:0.55in;width:8.06in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Some cookies that have been set on our website are not set on a first party basis by Bainub. The Websites can be embedded</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:18.64in;left:0.55in;width:7.93in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">with content from third parties to serve advertising. These third party service providers may set their own cookies on your</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:18.83in;left:0.55in;width:7.93in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">web browser. Third party service providers control many of the performance and functionality, advertising, marketing and</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:19.02in;left:0.55in;width:8.07in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:19.22in;left:0.55in;width:2.56in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">the third party that originally set them.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:19.64in;left:0.55in;width:2.47in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">How you can manage cookies?</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:19.90in;left:0.55in;width:8.05in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Most browsers allow you to control cookies through their &apos;settings&apos; preferences. However, if you limit the ability of websites</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:20.09in;left:0.55in;width:7.98in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:20.29in;left:0.55in;width:7.83in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">you from saving customized settings like login information. Browser manufacturers provide help pages relating to cookie</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:20.48in;left:0.55in;width:2.05in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">management in their products.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:20.87in;left:0.55in;width:7.77in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Browser manufacturers provide help pages relating to cookie management in their products. Please see below for more</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:21.06in;left:0.55in;width:0.85in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">information.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:21.45in;left:0.55in;width:1.17in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Google Chrome</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:21.65in;left:0.55in;width:1.26in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Internet Explorer</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:21.84in;left:0.55in;width:1.11in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Mozilla Firefox</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:22.03in;left:0.55in;width:1.19in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Safari (Desktop)</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:22.23in;left:0.55in;width:1.11in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Safari (Mobile)</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:22.58in;left:0.55in;width:1.25in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Android Browser</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:22.77in;left:0.55in;width:0.56in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Opera</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:22.97in;left:0.55in;width:1.05in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">  </span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">-Opera Mobile</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:23.39in;left:0.55in;width:4.39in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">Blocking and disabling cookies and similar technologies</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:23.64in;left:0.55in;width:8.03in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Wherever you&apos;re located you may also set your browser to block cookies and similar technologies, but this action may block</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:23.84in;left:0.55in;width:7.84in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:24.03in;left:0.55in;width:8.06in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:24.23in;left:0.55in;width:7.94in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:24.42in;left:0.55in;width:8.14in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:24.61in;left:0.55in;width:4.83in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">browser, you should visit your browser&apos;s help menu for more information.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:25.04in;left:0.55in;width:2.39in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">Changes To Our Cookie Policy</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:25.29in;left:0.55in;width:7.82in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they accurately</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:25.49in;left:0.55in;width:8.19in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:25.68in;left:0.55in;width:7.92in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">we make changes to this Cookie Policy and give you an opportunity to review them before they go into effect. Then, if you</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:25.88in;left:0.55in;width:7.66in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want to agree to this or any</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:26.07in;left:0.55in;width:3.42in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">updated Cookie Policy, you can delete your account.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:26.50in;left:0.55in;width:1.11in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">Your Consent</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:26.75in;left:0.55in;width:8.07in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">By using our website, registering an account, or making a purchase, you hereby consent to our Cookie Policy and agree to its</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:26.94in;left:0.55in;width:0.45in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">terms.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:27.37in;left:0.55in;width:0.91in;line-height:0.20in;"><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496">Contact Us</span><span style="font-style:normal;font-weight:normal;font-size:13pt;font-family:Calibri Light;color:#2f5496"> </span><br/></div>
<div style="position:absolute;top:27.62in;left:0.55in;width:5.35in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000">Don&apos;t hesitate to contact us if you have any questions regarding our Cookie Policy.</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>
<div style="position:absolute;top:28.01in;left:0.55in;width:2.80in;line-height:0.16in;"><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> -Via Email:  customerservice@bainub.com</span><span style="font-style:normal;font-weight:normal;font-size:10pt;font-family:Calibri;color:#000000"> </span><br/></div>

`;