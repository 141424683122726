import {Storage } from 'aws-amplify';
import { FiFile } from "react-icons/fi";
import { Auth } from 'aws-amplify'
import { GET_SIGNED_URL } from '../utils/Constants';


export default function ChatAttachmentMessage({ messageSendBy, message }) {
    //const attachmentName = message.file.key.split("/")[-1]
    //const messageTimestamp = message.createdAt.substring(11, 16)
    const lastMessageDate = message.updatedAt.substring(0, 10).replaceAll("-", "/")
    const convertISODateStringToTimeZoneDate = (d) =>{
        let originalDate = new Date(d);        
        const local = new Date();
        originalDate = originalDate.getTime() - (local.getTimezoneOffset() / 60);
        originalDate = new Date(originalDate);
        return originalDate.toLocaleTimeString();
    }
    async function fileDownloadHandler() {
        //const signedURL = await Storage.get(message.file.key);
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        const response = await fetch(GET_SIGNED_URL + `?fileName=${message.file.key}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        const data = await response.json();
        console.log(data);
        window.open(data?.url, "_blank")
    }

    return (
        <div style={{ display: "block"}} className="m-none m-b-md">
            {/* own-message or other-message class */}
            <div className={"chat-attachment-message p-sm "+(messageSendBy === "own" ? "own-message": "other-message")}> 
                <div className="chat-attachment-message-body cursor" onClick={fileDownloadHandler} >
                    <FiFile size={28} className="m-r-sm" /> {message.message}
                </div>
            </div>
            {/* align-left or align-right class */}
            <div className={"message-datetime m-l-sm m-r-sm m-t-xxs "+(messageSendBy === "own" ? "align-right": "align-left")}>
                {lastMessageDate} {convertISODateStringToTimeZoneDate(message.createdAt)}
            </div>
        </div>
    )
}
