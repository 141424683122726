import { Layout, Button, Card, Form, 
     Select, Dialog,  DatePicker 
} from 'element-react';
import { formatDate } from '../../utils/FormatUtils';
import { useState, useRef, useEffect } from 'react';
import { historyFormRules } from '../../utils/OrderFormRules';
import { processStatus } from '../../utils/OrderHistoryStatus';
import DeleteIcon from '../../components/DeleteIcon';
function HistorySection(props){
    const t = props.t;
    const [showDialog, setShowDialog] = useState(false);
    const form = useRef(null);
    const [rules, setRules] = useState(null);
    const [historyForm, setHistoryForm] = useState(
        {
            process_status: null,
            date: null,
            type: 1,
        }
    );
    
    useEffect(() => {
        setRules(historyFormRules(t));
    }, [t]);

    const addHistoryItem = () =>{
        form.current.validate((valid) => {
            console.log('validator :: ' + valid);
            if (valid) {
                console.log({historyForm});
                let _prevHist = props.formInputs.history ? props.formInputs.history: [];
                props.setFormInputs({...props.formInputs, history: [..._prevHist, historyForm] })
                //console.log('props.formInputs.buyerStatus :: ' + props.formInputs.buyerStatus);
                //console.log('props.formInputs.sellerStatus :: ' + props.formInputs.sellerStatus);
                if(props.formInputs.sellerStatus != 5 && props.formInputs.buyerStatus != 4){
                    //Si orden esta cancelada ya no se enviara email
                    props.sendNewStatusEmail();
                }
                setShowDialog(false);
            } else {
                console.log('invalid form');
            }
        })
    }

    const deleteItem = (tableIndex) =>{
        console.log(`deleting ${tableIndex}`);
        props.setLoading(true);
        let _prevHist = props.formInputs.history.filter(function( obj, index ) {
            return index !== tableIndex;
        });
        //let _prevProducts = props.formInputs.products ? props.formInputs.products: [];
        props.setFormInputs({...props.formInputs, history: [..._prevHist] })
        props.setLoading(false);
    }

    const getHistoryDate = (date) =>{
        try{
            return formatDate(date);
        }catch(e){
            return date;
        }
    }

    const initForm = async() => {
        props.setLoading(true);
        setHistoryForm({
            process_status: null,
            date: null,
            type: 1,
        });
        setShowDialog(true);
        props.setLoading(false);
        
    }

    return(
        <>
            <Dialog size="small"
                    visible={showDialog}
                    onCancel={ () => { 
                        console.log('cancel Diaglog');
                        setShowDialog(false);
                    } }
                    title={t('orders.history.form.title')}
                >
                <Dialog.Body>
                    <Form ref={form} model={historyForm} className="en-US" rules={rules} label-position="top">
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                <Form.Item label={t('orders.history.form.status')} prop="process_status">
                                    <Select 
                                        filterable={false} 
                                        value={historyForm.process_status}
                                        onChange={(v) => {setHistoryForm({...historyForm, process_status: v })}} 
                                    >
                                        <Select.Option label={t('orders.history.form.status1')} value="1"/>
                                        <Select.Option label={t('orders.history.form.status2')} value="2"/>
                                        <Select.Option label={t('orders.history.form.status3')} value="3"/>
                                        <Select.Option label={t('orders.history.form.status4')} value="4"/>
                                        <Select.Option label={t('orders.history.form.status5')} value="5"/>
                                        <Select.Option label={t('orders.history.form.status6')} value="6"/>
                                        <Select.Option label={t('orders.history.form.status7')} value="7"/>
                                        <Select.Option label={t('orders.history.form.status8')} value="8"/>
                                    </Select>
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                        <Layout.Row gutter="20">
                            <Layout.Col lg={15} md={14} xs={22} sm={22}>
                                <Form.Item label={t('orders.history.form.date')} prop="date" >
                                    <br/>
                                    <DatePicker 
                                        value={historyForm.date}
                                        format="dd-MM-yyyy"
                                        onChange={(v) => {
                                            setHistoryForm({...historyForm, date: v })
                                        }} 
                                    />
                                    
                                </Form.Item>
                            </Layout.Col>
                        </Layout.Row>
                    </Form>
                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <Layout.Row type="flex">
                                    <Layout.Col span="24" xs="20" sm="20" lg="23">
                                        <Layout.Row type="flex" justify="end">
                                            <Layout.Col tag="form-button">
                                                <div style={{display: 'inline-flex'}}>
                                                    <form-button-inverse class="left-space">
                                                    <Button className="left-separator" type="primary" onClick={() => setShowDialog(false)}>
                                                            {t('orders.form.cancel-prd-btn')}
                                                    </Button>
                                                    </form-button-inverse>
                                                </div>
                                            </Layout.Col>
                                            <Layout.Col tag="form-button">
                                                    <Button className="left-separator" type="primary" onClick={() => addHistoryItem()}>
                                                            {t('orders.history.form.add-btn')}
                                                    </Button>
                                            </Layout.Col>
                                        </Layout.Row>
                                    </Layout.Col>
                    </Layout.Row>
                </Dialog.Footer>
            </Dialog>
            <Layout.Row gutter="20" >
                <Layout.Col span="24" xs="20" sm="20" lg="23">
                    <Card className="box-card order-card">
                    <Layout.Row gutter="20">
                        <Layout.Col lg={19}>
                            <div className='order-form-color' style={{'font-weight' : 'bold'}}>
                                    {t('orders.history.title')}
                            </div>     
                        </Layout.Col>
                        <Layout.Col lg={3}>
                            <form-button-inverse class="left-space">
                                    {
                                        props.readOnly
                                        ?
                                            <></>
                                        :
                                            props.formInputs.sellerStatus === 3
                                            ?
                                                <></>
                                            :
                                                <Button style={{width: '200px'}} type="primary" onClick={()=>initForm()}>
                                                    {t('orders.history.add-btn')}
                                                </Button>
                                    }
                            </form-button-inverse>
                        </Layout.Col>
                    </Layout.Row>
                    
                    {       
                            props.formInputs.history?.map((element, index) => {
                                return (
                                    <Layout.Row className="history-font" gutter="20">
                                        <Layout.Col lg={4}>
                                            <div className='order-history'>
                                                    {processStatus(element.process_status, t)}
                                            </div>     
                                        </Layout.Col>
                                        <Layout.Col lg={4}>
                                            <div className='order-history'>
                                                    {
                                                        getHistoryDate(element.date)
                                                    }
                                            </div>     
                                        </Layout.Col>
                                        <Layout.Col lg={4}>
                                            {
                                                element.type == 1 
                                                ?
                                                <div >
                                                    {
                                                        props.readOnly
                                                        ?
                                                            <></>
                                                        :
                                                            <DeleteIcon handleClick={()=>{deleteItem(index)}} width={30} height={30}/>
                                                    }
                                                </div>
                                                :
                                                <div style={{paddingBottom : '30px'}}>
                                                </div>
                                            }
                                        </Layout.Col>
                                    </Layout.Row>
                                )
                                    
                            })
                        
                    }
                    
                        <Layout.Row gutter="20">
                            <Layout.Col className='mt-4' lg={20}>
                                <div className='history-warning'>{t('orders.history.save-warning')}</div>
                            </Layout.Col>
                        </Layout.Row>
                    
                        
                    </Card>
                </Layout.Col>
                
            </Layout.Row>
        </>
    )
}

export default HistorySection;