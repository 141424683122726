import React from 'react';
const FeeIcon = (props) => {
  return (
      <svg onClick={props.handleClick} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={props.coords ? props.coords : "0 0 41 41"}>
      <g id="Group_1386" data-name="Group 1386" transform="translate(-371 -2972.494)">
        <g id="Ellipse_59" data-name="Ellipse 59" transform="translate(371 2972.494)" fill="#fff" stroke="#929498" stroke-width="2">
          <circle cx="20.5" cy="20.5" r="20.5" stroke="none"/>
          <circle cx="20.5" cy="20.5" r="19.5" fill="none"/>
        </g>
        <path id="comment-dollar-solid" d="M10.234,31.1C4.609,31.1.086,34.8.086,39.374a7.407,7.407,0,0,0,2.266,5.2,10.122,10.122,0,0,1-2.18,3.811.34.34,0,0,0-.094.382c.016.119.164.155.291.155a9.093,9.093,0,0,0,5.593-2.045,12.129,12.129,0,0,0,4.272.772c5.624,0,10.147-3.7,10.147-8.238S15.858,31.1,10.234,31.1Zm2.458,9.992a1.964,1.964,0,0,1-1.664,1.568v.409a.795.795,0,1,1-1.59,0v-.428a8.292,8.292,0,0,1-1-.3l-.17-.06a.8.8,0,0,1,.531-1.5l.178.065a5.573,5.573,0,0,0,.941.28c.546.085,1.151.007,1.206-.3.035-.2.054-.314-1.111-.648l-.232-.069c-.689-.2-2.3-.676-2.01-2.38a2.055,2.055,0,0,1,1.663-1.613V35.75a.8.8,0,0,1,1.591,0v.423a6.485,6.485,0,0,1,.738.21.8.8,0,0,1-.522,1.5,4.119,4.119,0,0,0-.7-.193c-.543-.082-1.183-.007-1.2.3-.032.143-.052.306.855.585l.221.063C11.339,38.9,12.99,39.37,12.692,41.092Z" transform="translate(381.283 2952.408)" fill={props.color ? props.color : "#929498"}/>
      </g>
    </svg>
    
  )
}

export default FeeIcon;