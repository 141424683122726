import { useTranslation } from 'react-i18next';
import { S3Image } from "../components/S3Image"
import { Card, Layout } from "element-react"
import { useHistory } from 'react-router';

export default function ChatProductReferenceMessage( { message, showContactPhone, showMessageText } ) {
    const history = useHistory()
    const {t} = useTranslation();
    
    const productImageTheme = {
        photoImg: { 
            maxWidth: "60px", 
            maxHeight: "60px", 
            minHeight: "60px",
            borderRadius: "5%"
        }
    }

    return (
        <Card className="p-none m-none m-b-md card-box-shadow-none" bodyStyle={{ padding: "10px", paddingLeft: "20px"}}>
            <Layout.Row className="chat-product-referece-message-body m-none p-none">
                <Layout.Col span="24" className="m-b-sm">
                    <label className="chat-product-referece-message-title">
                        {t('chat.messages.system.product-reference-title')}
                        { showContactPhone ? <> , {t('chat.messages.system.show-contact-phone-message')} <b>{message.message}</b> </> : <></> }
                    </label>
                </Layout.Col>
                <Layout.Col span="3">
                    {
                        message && message.product_image ?

                        <S3Image className="cursor" onClick={() => history.push("/product-detail/"+message.product_id)}
                        imgKey={message?.product_image?.key}
                        theme={productImageTheme}
                        level="public"
                        />

                        : <></>
                    }
                    
                </Layout.Col>
                <Layout.Col span="21" className="chat-product-referece-message-product-detail">
                    <label onClick={() => history.push("/company-store/"+message.product_company_id)} className="cursor">
                        {message.product_company_name}
                    </label> <b>&gt;</b>  
                    <label onClick={() => history.push("/company-store/"+message.product_company_id)} className="cursor">
                        {t('chat.messages.system.products-link')}
                    </label> <b>&gt;</b>
                    <label className="chat-product-referece-message-product-name cursor" onClick={() => history.push("/product-detail/"+message.product_id)}>
                        {message.product_name}
                    </label>
                </Layout.Col>
                {
                    showMessageText ? 
                        <Layout.Col span="24" className="m-t-sm chat-product-referece-message-product-message">
                            <label>{message.message}</label>
                        </Layout.Col>
                    : <></>
                }
            </Layout.Row>

        </Card>
    )
}
