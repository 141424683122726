import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { S3Image } from '../components/S3Image';
import { useHistory } from "react-router-dom";
import { Card, Loading, Layout } from 'element-react';
import {GET_PRODUTCS_URL} from '../utils/Constants';


export default function SupplierSwiperItem({ supplier }) {
    const { t } = useTranslation();
    let history = useHistory();
    const [[loadingSupplierProducts , setLoadingSupplierProducts], [supplierProducts , setSupplierProducts]]  = [useState(true), useState([])]

    useEffect(function () {
        loadSupplierProducts()
    }, [])

    async function loadSupplierProducts() {
        console.log("loading supplier products ...");
        //let input = { filter: { company_id: { eq: supplier.id }, status : { eq: 1} }, limit: 3 };
        //const supplierProducts = await API.graphql({ query: listProducts, variables: { filter: { company_id: { eq: supplier.id } }, limit: 3 }, authMode: GRAPHQL_AUTH_MODE.AWS_IAM  })
        //const pagerQuery = new PagerQuery(listProducts, input, Object.keys({ listProducts }));
        //const supplierProducts = await pagerQuery.searchPublicData();
        // console.log(supplierProducts)
        const response = await fetch(GET_PRODUTCS_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                //'Authorization': token
            }
        });
        const data = await response.json();
        const supplierProducts = data.filter((item) => item.company_id === supplier.id);
        setSupplierProducts(supplierProducts.slice(0, 3))
        setLoadingSupplierProducts(false)
    }

    //TODO: DEFINE A DEFAULT IMAGE
    const defaultImageKey = "/DEEMSA/cover_picture/1628555070378-cover_picture-003.jpg"

    //TODO: DEFINE A URL
    const redirectToSupplierStore = () => { window.open("/company-store/"+supplier.id, "_blank") }//history.push("/company-store/"+supplier.id) 
    
    const principalImageThemeConfig = {
        photoImg: {
            maxWidth: "250px",
            maxHeight: "180px",
            minHeight: "125px",
            borderRadius: "4px 4px 0px 0px"
        }
    }
    
    const productImageThemeConfig = {
        photoImg: {
            maxWidth: "75px",
            maxHeight: "75px",
            borderRadius: "5%"
        }
    }

    const supplierImageKey = supplier.cover_picture && supplier.cover_picture["key"] ? supplier.cover_picture["key"] : defaultImageKey;

    function renderSupplierProducts() {
        //let i = 0;
        return supplierProducts && supplierProducts.map(product => {
            //i++;
            //if(i > 3) return(<></>);
            const productImageKey = product && product.photo && product.photo[0] && product.photo[0]["key"] ? product.photo[0]["key"] : "";
            return(
                <Layout.Col span="8" key={product.id}>
                    <S3Image imgKey={productImageKey} theme={productImageThemeConfig} level="public" onClick={() => history.push("/product-detail/"+product.id)} className="cursor" />
                </Layout.Col>
            )
        })
    }

    return (
        <Card className="supplier-swiper-item">
            <div className="text-center" >
                <S3Image imgKey={supplierImageKey} theme={principalImageThemeConfig} level="public" onClick={redirectToSupplierStore} className="cursor"/>
            </div>
            <p>{supplier.name}</p>
            <Layout.Row type="flex">
                {
                    loadingSupplierProducts ? 
                        <Loading text={t('general.loading')} loading={loadingSupplierProducts} fullscreen={false} />
                    :
                        renderSupplierProducts()
                }
            </Layout.Row>
        </Card>
    )
}
